import { useState, useEffect } from "react";
import { db } from "../firebase/config";
import { doc, onSnapshot } from "firebase/firestore";

export const useFetchDocument = (docCollection, id, docCollection2, id2) => {
  const [document, setDocument] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true); 

  useEffect(() => {
    let unsubscribe;

    const loadDocument = async () => {
      setLoading(true);
      try {
        if (!docCollection || !id) {
          return;
        } else if (docCollection2 && id2) {
          const docRef = doc(db, docCollection, id, docCollection2, id2);
          unsubscribe = onSnapshot(docRef, (docSnap) => {
            setDocument(docSnap.data());
            setLoading(false);
          });
        } else {
          const docRef = doc(db, docCollection, id);
          unsubscribe = onSnapshot(docRef, (docSnap) => {
            setDocument(docSnap.data());
            setLoading(false);
          });
        }
      } catch (error) {
        console.log(error);
        setError(error.message);
        setLoading(false);
      }
    };

    loadDocument();

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [docCollection, id, docCollection2, id2]);

  return { document, loading, error };
};
