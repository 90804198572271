//Translation
import i18n from '../i18n';
import { useTranslation } from 'react-i18next';
//Styles
import styles from './ChooseProject.module.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
// Dev Hooks
import { useLanguageValue } from '../context/LanguageContext';
// React Hooks
import { useState, useEffect } from 'react';
//Images
import menu1 from '../images/menu1.png';
import menu2 from '../images/menu2.png';
import menu3 from '../images/menu3.png';
import menu4 from '../images/menu4.png';

const ChooseProject = ({ handleChangeProjectSelected, domainActive }) => {
    const { preferenceLanguage } = useLanguageValue();
    const { t } = useTranslation([`chooseproject`]);

    useEffect(() => {
        if (preferenceLanguage) {
            var language = "en";
            switch (preferenceLanguage) {
                case "Português":
                    language = "pt"
                    break;
                case "Espanhol":
                    language = "es"
                    break;
                case "Inglês":
                    language = "en"
                    break;
            }
            changeLanguage(language);
            i18n.changeLanguage(language);
        }
    }, [preferenceLanguage])

    const changeLanguage = (language) => {
        localStorage.setItem('preferenceLanguage', language);
        i18n.changeLanguage(language);
    };

    return (
        <div className={`container m-0 p-0 ${styles.mainMenu}`}>
            <div className="col-12 text-center">
                <h1 className={styles.menu_title}>
                    {t('chooseproject:title')}
                </h1>
            </div>
            <div className={`row align-items-center justify-content-center mt-4`}>

                <div className={`col-12 col-md-2 col-lg-2 text-center ${domainActive ? styles.menu_item : styles.menu_item_disabled}`} onClick={domainActive ? () => handleChangeProjectSelected("file") : null}>
                    <img src={menu1} alt="menu1" />
                    <h1>{t('chooseproject:menu.scorm')}</h1>
                    <h5 className={styles.subtitle}>
                        {t('chooseproject:description.scorm')}
                    </h5>
                </div>

                <div className={`col-12 col-md-2 col-lg-2 text-center ${domainActive ? styles.menu_item : styles.menu_item_disabled}`} onClick={domainActive ? () => handleChangeProjectSelected("assessment") : null}>
                    <img src={menu2} alt="menu2" />
                    <h1>{t('chooseproject:menu.assessment')}</h1>
                    <h5>
                        {t('chooseproject:description.assessment')}
                    </h5>
                </div>

                <div className={`col-12 col-md-2 col-lg-2 text-center ${domainActive ? styles.menu_item : styles.menu_item_disabled}`} onClick={domainActive ? () => handleChangeProjectSelected("content") : null}>
                    <img src={menu3} alt="menu3" />
                    <h1>{t('chooseproject:menu.text')}</h1>
                    <h5>
                        {t('chooseproject:description.text')}
                    </h5>
                </div>

                <div className={`col-12 col-md-2 col-lg-2 text-center ${domainActive ? styles.menu_item : styles.menu_item_disabled}`} onClick={domainActive ? () => handleChangeProjectSelected("subtitle") : null}>
                    <img src={menu4} alt="menu4" />
                    <h1>{t('chooseproject:menu.caption')}</h1>
                    <h5>
                        {t('chooseproject:description.caption')}
                    </h5>
                </div>
            </div>
        </div>
    );
};

export default ChooseProject;