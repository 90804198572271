import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { getDownloadURL, ref, uploadBytesResumable, deleteObject, listAll, getMetadata } from "firebase/storage";
import $ from 'jquery';
import Popper from 'popper.js';
import { useAuthValue } from '../context/AuthContext';
import { storage } from "../firebase/config";
import { useFetchProjects } from '../hooks/useFetchProjects';
import styles from './ProjectSetup.module.css';

import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useLanguageValue } from '../context/LanguageContext';
import { useAskChatGPT } from '../hooks/useAskChatGPT';
import { useCreateProject } from '../hooks/useCreateProject';
import { useGenerateHTML } from '../hooks/useGenerateHTML';
import { useUpdateDocument } from '../hooks/useUpdateDocument';
import { useFetchDocument } from '../hooks/useFetchDocument'
import useExcelToJson from '../hooks/useLoadExcelLanguage';

import { Timestamp } from 'firebase/firestore';
import AssessmentContent from './AssessmentContent';
import AssessmentTopics from './AssessmentTopics';
import DialogBox from './DialogBox';
import ProjectExport from './ProjectExport';
import ProjectTemplate from './ProjectTemplate';
import SimpleChoiceQuestion from './SimpleChoiceQuestion';
import { file } from 'jszip';

window.jQuery = $;
window.Popper = Popper;

let isCarouselChanged = false;

const ProjectSetup = ({ handleChangePageSettings, hideCardFolderProject, userDomainPlan, actualFolder, pptVideoInfos }) => {
  const { user } = useAuthValue();
  const location = useLocation();
  const { previewHTML } = useGenerateHTML();
  const { handleOpenAiApi, returnGPT, textTranslated, loading, error, generateGPT } = useAskChatGPT();
  const projectFolder = actualFolder;
  const { documents: templates, loadingTemplates, errorTemplates } = useFetchProjects("Templates");

  const pptProjectsLimit = pptVideoInfos.pptLimit;
  const pptQty = pptVideoInfos.pptQtd;
  const videoProjectsLimit = pptVideoInfos.videoLimit;
  const videoQty = pptVideoInfos.videoQtd;

  const [createUserProject, setCreateUserProject] = useState(false);
  const [projectName, setProjectName] = useState("");
  const [projectDescription, setProjectDescription] = useState("");
  const [search, setSearch] = useState();
  const [msgDialog, setMsgDialog] = useState("");
  const [optionsDialog, setOptionsDialog] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [AIHelp, setAIHelp] = useState(true);
  const [disableIA, setDisableIA] = useState(false);
  const [disableForm, setDisableForm] = useState(false);
  const [actualSlide, setActualSlide] = useState(1);
  const [questionQ, setQuestionQ] = useState(10);
  const [choiceQ, setChoiceQ] = useState(4);
  const [feedback, setFeedback] = useState(true);
  const [language, setLanguage] = useState("");
  const [level, setLevel] = useState("médio");
  const [projectTemplate, setProjectTemplate] = useState("default");
  const [selectedTopics, setSelectedTopics] = useState([]);
  const [errorGPT, setErrorGPT] = useState(error);
  const [generateAssessment, setGenerateAssessment] = useState(false);
  const [manualGenerate, setManualGenerate] = useState(false);
  const [topics, setTopics] = useState();
  const [assessment, setAssessment] = useState();
  const [steps, setSteps] = useState(6);
  const [changeReturnGPT, setChangeReturnGPT] = useState();

  const [assesmentSend, setAssessmentSend] = useState("");
  const [idProject, setIdProject] = useState(null);
  const [userEdit, setUserEdit] = useState(false);
  const [projectIntro, setProjectIntro] = useState("");
  const [originalTemplate, setOriginalTemplate] = useState(projectTemplate);
  const [newProjectTemplate, setNewProjectTemplate] = useState(projectTemplate);
  const [cssTemplate, setCssTemplate] = useState();

  const [translating, setTranslating] = useState(false)
  const [translatedTopics, setTranslatedTopics] = useState(false);
  const [translatedCase, setTranslatedCase] = useState(false);
  const [changePageCarousel, setChangePageCarousel] = useState(true);

  const [methodChoiced, setMethodChoiced] = useState('choice1');
  const [textType, setTextType] = useState('usertext');
  const [subject, setSubject] = useState("");
  const [contentSubject, setContentSubject] = useState();
  const [showTopics, setShowTopics] = useState(false);
  const [includeContent, setIncludeContent] = useState(false);
  const [userText, setUserText] = useState("");
  const [userUrl, setUserUrl] = useState("");
  const [userInput, setUserInput] = useState("");
  const [generatingIA, setGeneratingIA] = useState(false);
  const [generatingTopics, setGeneratingTopics] = useState(false);
  const [generatingContent, setGeneratingContent] = useState(false);
  const [generatingCase, setGeneratingCase] = useState(false);
  const [generatingProject, setGeneratingProject] = useState(false);
  const [contentGenerated, setContentGenerated] = useState();
  const [contentReviewed, setContentReviweed] = useState();
  const [caseSubject, setCaseSubject] = useState("");
  const [caseDescription, setCaseDescription] = useState("");
  const [caseGenerated, setCaseGenerated] = useState();
  const [userFile, setUserFile] = useState("");
  const [userFileVideo, setUserFileVideo] = useState("");
  const [userVideoUrl, setUserVideoUrl] = useState("");
  const [videoType, setVideoType] = useState("file");
  const [userFileAudio, setUserFileAudio] = useState("");
  const [userAudioUrl, setUserAudioUrl] = useState("");
  const [audioType, setAudioType] = useState("file");
  const [showInfoTopics, setShowInfoTopics] = useState(false)
  const [showInfoUserText, setShowInfoUserText] = useState(false)
  const [showInfoUserUrl, setShowInfoUserUrl] = useState(false)
  const [showInfoIncludeContent, setShowInfoIncludeContent] = useState(false)
  const [showInfoContent, setShowInfoContent] = useState(false);
  const [showInfoCase, setShowInfoCase] = useState(false);
  const [showDescCase, setShowDescCase] = useState(false);
  const [showInfoPPT, setShowInfoPPT] = useState(false);
  const [showInfoSlides, setShowInfoSlides] = useState(false);
  const [showInfoVideo, setShowInfoVideo] = useState(false);
  const [showInfoVideoTogether, setShowInfoVideoTogether] = useState(false);
  const [showInfoUrlVideo, setShowInfoUrlVideo] = useState(false);
  const [showInfoAudio, setShowInfoAudio] = useState(false);
  const [showInfoUrlAudio, setShowInfoUrlAudio] = useState(false);
  const [showInfoLanguage, setShowInfoLanguage] = useState(false);
  const [showInfoLevel, setShowInfoLevel] = useState(false);
  const [showButtonReturn, setShowButtonReturn] = useState(true);
  const [pageTexts, setPageTexts] = useState([]);
  const [carouselTexts, setCarouselTexts] = useState([]);
  const [textMaxLenght, setTextMaxLength] = useState(8000);
  const [newAssemssment, setNewAssessment] = useState(assessment);
  const [newIncludeContent, setNewIncludeContent] = useState(includeContent);
  const [msgText, setMsgText] = useState("");
  const [presentTogether, setPresentTogether] = useState(false);
  const [controlComponet, setControlComponent] = useState(false);

  const [uploadingFile, setUploadingFile] = useState(false)

  const [userId, setUserId] = useState(user.uid)
  const initialPPtFileName = "my_ppt_quizai_control.pptx";
  const initialPPtFile = new File([], initialPPtFileName, { type: "application/vnd.openxmlformats-officedocument.presentationml.presentation" });
  const [pptxFile, setPptxFile] = useState(initialPPtFile);

  const [pptFileName, setPptFileName] = useState();
  const [pptSubject, setPptSubject] = useState(false);
  const [generatingPpt, setGeneratingPpt] = useState(false);
  const [pptxUrl, setPptxUrl] = useState(null);
  const [fileMessange, setFileMessange] = useState("")
  const [pptGenerated, setPptGenerated] = useState(false);
  const [listUrlSlides, setListUrlSlides] = useState([]);
  const [textPpt, setTextPpt] = useState("");
  const [pathToSlides, setPathToSlides] = useState("");
  const [progresspercent, setProgresspercent] = useState(0);
  const [includeSlides, setIncludeSlides] = useState(false);
  const [errorPpt, setErrorPpt] = useState("");
  const [newShowSlides, setNewShowSlides] = useState(includeSlides);

  const initialVideoFileName = "my_video_quizai_control.mp4";
  const initialVideoFile = new File([], initialVideoFileName, { type: "application/vnd.openxmlformats-officedocument.presentationml.presentation" });
  const [videoFile, setVideoFile] = useState(initialVideoFile);
  const [videoFileName, setVideoFileName] = useState("");
  const [videoSubject, setVideoSubject] = useState(false);
  const [videoUrl, setVideoUrl] = useState(null);
  const [videoPath, setVideoPath] = useState(null);
  const [pptPath, setPptPath] = useState(null);
  const [generatingVideo, setGeneratingVideo] = useState(false);
  const [videoGenerated, setVideoGenerated] = useState(false);
  const [errorVideo, setErrorVideo] = useState("");
  const [videoTranscript, setVideoTranscript] = useState("");
  const [includeVideo, setIncludeVideo] = useState(false);
  const [videoLanguage, setVideoLanguage] = useState("");
  const [codeVideoLanguage, setCodeVideoLanguage] = useState("");
  const [presentVideoTogether, setPresentVideoTogether] = useState(false);
  const [projectVideos, setProjectVideos] = useState([])
  const [projectPpt, setProjectPPt] = useState([])
  const [projectVideosLimit, setProjectVideosLimit] = useState(false);
  const [projectPptLimit, setProjectPPtLimit] = useState(false);
  const [storageVideoSize, setStorageVideoSize] = useState(0);
  const [storagePPtSize, setStoragePPtSize] = useState(0);
  const [projectSize, setProjectSize] = useState(0);
  const [projectSizeLimit, setProjectSizeLimit] = useState(false);
  const [storageLimit, setStorageLimit] = useState(0);

  const { preferenceLanguage } = useLanguageValue();
  const sheetName = 'newproject';
  const jsonData = useExcelToJson(sheetName);
  const [generationPhase, setGenerationPhase] = useState(`${pageTexts[1]} \\ ${pageTexts[2]}`);

  const [buttonLabel, setButtonLabel] = useState(pageTexts[42]);

  const [templatesList, setTemplatesList] = useState();

  const { createProject } = useCreateProject();

  const fileInputRef = useRef(null);
  const { document: domain, loadingDocDomain, errorDocDomain } = useFetchDocument("Domains", user.uid)
  const { updateDocument, responseUpdate } = useUpdateDocument("Domains", user.uid);

  let checkStepsTimeline = 1;

  let stepsNameOriginal = [`${pageTexts[3]} \n${pageTexts[4]}`, `${pageTexts[5]} \n${pageTexts[6]}`, `${pageTexts[9]} \n${pageTexts[10]}`, `${pageTexts[11]} \n${pageTexts[12]}`, `${pageTexts[15]} \n${pageTexts[16]}`, `${pageTexts[17]} \n${pageTexts[18]}`]

  let stepsNameManual = [`${pageTexts[3]} \n${pageTexts[4]}`, `${pageTexts[19]} \n${pageTexts[20]}`, `${pageTexts[15]} \n${pageTexts[16]}`, `${pageTexts[17]} \n${pageTexts[18]}`]
  const [stepsName, setStepsName] = useState(stepsNameOriginal);

  const biCircle = ["bi-1-circle-fill", "bi-2-circle-fill", "bi-3-circle-fill", "bi-4-circle-fill", "bi-5-circle-fill", "bi-6-circle-fill", "bi-7-circle-fill", "bi-8-circle-fill"]
  const biCircleFill = ["bi-1-circle-fill", "bi-2-circle-fill", "bi-3-circle-fill", "bi-4-circle-fill", "bi-5-circle-fill", "bi-6-circle-fill", "bi-7-circle-fill", "bi-8-circle-fill"]

  const contentReviewedRef = useRef(false);

  const loadPptImages = async () => {

    const checkUrls = async () => {
      try {
        const listRef = ref(storage, pathToSlides);
        const items = await listAll(listRef);
        const imageUrls = await Promise.all(items.items.map(async (item) => {
          const itemName = item.name;
          if (itemName.endsWith('.png')) {
            const imageUrl = await getDownloadURL(item);
            return imageUrl;
          }
          return null;
        }));
        const filteredImageUrls = imageUrls.filter((imageUrl) => imageUrl !== null);
        return filteredImageUrls;
      } catch (error) {
        console.error('Erro na solicitação:', error);
      }
    }
    const listUrls = await checkUrls()
    setListUrlSlides(listUrls)
  }

  const cancelProject = () => {
    setCreateUserProject(false);
    setSubject();
    setActualSlide(1);
    hideCardFolderProject();
    if (actualSlide === 2 && (methodChoiced === "choice5" || methodChoiced === "choice6") || actualSlide === 3 && methodChoiced === "choice5") {
      deletePPtVideo();
    }
  }

  const deletePPtVideo = () => {
    let folderFile;
    if (methodChoiced === "choice5") {
      folderFile = "ppt"
    } else {
      folderFile = "video"
    }
    deleteFolderContents(storage, `${folderFile}/${userId}/${idProject}`)
  }

  const handleCheckContentFont = (choice) => {
    if (!projectName) {
      setOpenDialog(true);
      setMsgDialog(pageTexts[130])
      setOptionsDialog(["Ok"])
      return
    }
    if (actualSlide > 1) {
      switch (choice) {
        case "choice1":
          if (!subject) {
            setOpenDialog(true);
            setMsgDialog(pageTexts[131])
            setOptionsDialog(["Ok"])
            return
          }
          break;
        case "choice2":
          if (!userText && !userUrl) {
            setOpenDialog(true);
            setMsgDialog(pageTexts[132])
            setOptionsDialog(["Ok"])
            return
          }
          break;
        case "choice3":
          if (!contentSubject) {
            setOpenDialog(true);
            setMsgDialog(pageTexts[133])
            setOptionsDialog(["Ok"])
            return
          }
          break;
        case "choice4":
          if (!caseSubject) {
            setOpenDialog(true);
            setMsgDialog(pageTexts[134])
            setOptionsDialog(["Ok"])
            return
          }
          if (!caseDescription) {
            setOpenDialog(true);
            setMsgDialog(pageTexts[135])
            setOptionsDialog(["Ok"])
            return
          }
          break;
        case "choice5":
          if (pptxFile.name === "my_ppt_quizai_control.pptx") {
            setOpenDialog(true);
            setMsgDialog(pageTexts[136])
            setOptionsDialog(["Ok"])
            return
          }
          break;
        case "choice6":
          if (videoFile.name === "my_video_quizai_control.mp4") {
            setOpenDialog(true);
            setMsgDialog(pageTexts[137])
            setOptionsDialog(["Ok"])
            return
          }
          break;
        case "choice7":
          if (!userFileAudio && !userAudioUrl) {
            setOpenDialog(true);
            setMsgDialog(pageTexts[138])
            setOptionsDialog(["Ok"])
            return
          }
          break;
      }
    }
    if (showTopics && !topics) {
      handleAskTopicsChatGPT();
    } else if (generatingContent && !contentGenerated && !showTopics) {
      handleAskContentChatGPT();
    } else if (generatingCase && !contentGenerated) {
      handleAskCaseChatGPT();
    } else if (generatingVideo && !videoTranscript) {
      handleGenerateVideo();
    } else {
      handleChangeCarousel();
    }
  }

  const handleClosDialogBox = () => {
    setOpenDialog(false);
    (error || errorPpt) && cancelProject();
  }

  const handleReturnCarousel = () => {
    setActualSlide(actualSlide - 1);
    checkStepsTimeline = actualSlide - 2;
    timelineUpdate_alnl(checkStepsTimeline);
    const prevButton = document.querySelector('.prevButton').click();
  }

  const handleSubmitProject = (e) => {
    e.preventDefault();
    handleChangeCarousel();
  }

  const timelineUpdate_alnl = (actual) => {
    if (isCarouselChanged) {
      const timelineProgressPercentage = ((100 / (steps - 1)) * (actual)) * 0.93;
      const timelineFillElement = document.querySelector(`.${styles.timeline_fill}`);

      if (timelineFillElement) {
        timelineFillElement.style.width = `${timelineProgressPercentage}%`;

        const timelineFillBeforeElement = timelineFillElement.style.getPropertyValue('--before-width');
        if (timelineFillBeforeElement) {
          timelineFillElement.style.setProperty('--before-width', `${timelineProgressPercentage}%`);
        }
      }
      timelineFillElement.style.setProperty('--before-width', `${timelineProgressPercentage}%`);
    }
  };

  const handleChangeCarousel = () => {
    isCarouselChanged = true;
    timelineUpdate_alnl(actualSlide);
    if (actualSlide === steps - 1 && projectTemplate) {
      updateProjectTemplate()
    }
    if (actualSlide < steps + 1) {
      setActualSlide(actualSlide + 1)
      checkStepsTimeline = checkStepsTimeline + 1;
    } else {
      setActualSlide(1)
    }
    if (!AIHelp && actualSlide === 1) {
      const id = projectName + Timestamp.now();
      setIdProject(id);

      const newQuestion = [{
        alternatives: [
          { option: "a", text: "" },
          { option: "b", text: "" },
          { option: "c", text: "" },
          { option: "d", text: "" },
        ],
        ask_question: "",
        correct_answer: "",
        feedback: ""
      }]
      setSubject(projectName)
      setGenerateAssessment(true)
      setAssessment(newQuestion)
      setManualGenerate(true)
    }
    const nextButton = document.querySelector('.nextButton').click();
    window.scrollTo(0, 0);
  }

  const handleProjectName = (e) => {
    setProjectName(e.target.value)
  }

  const changeQuestionQty = (questionqty) => {
    setQuestionQ(questionqty);
  }

  const changeChoicesQty = (choicesqty) => {
    setChoiceQ(choicesqty);
  }

  const changeFeedback = (feed) => {
    setFeedback(feed);
  }

  const changeLevel = (level) => {
    setLevel(level);
  }

  const changeLanguage = (lang) => {
    setLanguage(lang);
  }

  const changeVideoLanguage = (lang) => {
    setVideoLanguage(lang);
  }

  const handleAskTopicsChatGPT = async () => {
    setMsgText(pageTexts[90])
    setGeneratingIA(true);
    setGeneratingTopics(true)

    handleChangeCarousel();
    try {
      const res = await handleOpenAiApi({ subject: subject, userInput: userInput, orderGPT: "topics" });
    } catch (error) {
      setErrorGPT(error);
    }
  }

  const handleAskContentChatGPT = async () => {
    setGeneratingIA(true);
    changePageCarousel && handleChangeCarousel()

    if (selectedTopics && !translatedTopics) {
      handleTranslateGPT(selectedTopics)
      setChangePageCarousel(false)
    } else {
      try {
        const res = await handleOpenAiApi({
          subject,
          selectedTopics,
          language,
          orderGPT: "content"
        });
      } catch (error) {
        setErrorGPT(error);
      }
    }
  }

  const handleAskCaseChatGPT = async () => {
    setGeneratingIA(true);
    changePageCarousel && handleChangeCarousel();

    if (!translatedCase) {
      handleTranslateGPT(caseDescription)
      setChangePageCarousel(false)
    }
    try {
      const res = await handleOpenAiApi({
        subject,
        caseDescription,
        language,
        orderGPT: "case"
      });
    } catch (error) {
      setErrorGPT(error);
    }
  }

  const handleAskAssessmentCaseGPT = async () => {
    setGeneratingIA(true);
    setGeneratingProject(true)
    handleChangeCarousel()
    try {
      const res = await handleOpenAiApi({
        subject,
        userInput,
        caseDescription,
        contentReviewed,
        questionQ,
        choiceQ,
        feedback,
        level,
        language,
        orderGPT: "caseAssessment"
      });
    } catch (error) {
      setErrorGPT(error);
    }
  };

  const handleTranslateGPT = async (textToTranslate) => {
    setTranslating(true);
    try {
      const res = await handleOpenAiApi({
        textToTranslate,
        orderGPT: "translate"
      });
    } catch (error) {
      setErrorGPT(error);
    }
  }

  const handleAskChatGPT = async () => {

    setMsgText(pageTexts[104])
    setGeneratingIA(true);
    setGeneratingProject(true);
    changePageCarousel && handleChangeCarousel();

    if (selectedTopics && !translatedTopics && !generatingVideo) {
      handleTranslateGPT(selectedTopics);
      setChangePageCarousel(false)
    } else {
      try {
        const res = await handleOpenAiApi({
          subject,
          userInput,
          selectedTopics,
          questionQ,
          choiceQ,
          feedback,
          level,
          language,
          orderGPT: userInput ? "userText" : "assessment"
        });
      } catch (error) {
        setErrorGPT(error);
      }
    }
  };

  const handleSetTopics = (topic) => {
    if (selectedTopics.includes(topic)) {
      setSelectedTopics(selectedTopics.filter(item => item !== topic));
    } else {
      setSelectedTopics([...selectedTopics, topic]);
    }
  }

  const handleReviewContentGenerated = (reviewed, jsonContentReviwed) => {
    setContentReviweed(reviewed);
    jsonContentReviwed && setContentGenerated(jsonContentReviwed)
    setIncludeContent(true);
  }

  const handleFormNotEditable = () => {
    actualSlide > 2 && (showTopics || generatingCase || generatingContent) && setDisableForm(true);
  }

  const handleProjectContent = (include) => {
    setNewIncludeContent(include)
  }

  const handlePreviewHTML = () => {
    const project = {
      css: cssTemplate,
      projectName: projectName,
      projectDestiny: "preview",
      introAssessment: projectIntro,
      showTogether: presentTogether,
      language: language,
      projectContent: newIncludeContent
        ? userInput
          ? userInput
          : contentReviewed
        : null,
      assessment: newAssemssment,
      showSlides: includeSlides,
      listUrlSlides: listUrlSlides,
      videoUrl: videoUrl,
      showVideo: includeVideo,
      showVideoTogether: presentVideoTogether,
    }
    const htmlString = previewHTML(project);
  };

  const handleChangeTemplate = (template, css) => {
    setNewProjectTemplate(template)
    setCssTemplate(css)

  }

  const handleChangeAssessment = (assessment) => {
    setNewAssessment(assessment);
  }

  const handleProjectIntro = (intro) => {
    setProjectIntro(intro);
  }

  const updateProjectTemplate = () => {
    setOriginalTemplate(newProjectTemplate)
    const projectInfos = {
      actionDoc: "update",
      contentGenerated,
      idProject: idProject,
      introAssessment: "",
      projectName,
      projectDescription,
      projectContent: userInput ? userInput : contentReviewed,
      projectFolder,
      assessment: newAssemssment,
      language,
      subject,
      projectTemplate: newProjectTemplate,
      includeSlides: includeSlides,
      includeContent: includeVideo ? false : includeContent,
      presentTogether: presentTogether,
      pathToSlides: pathToSlides,
      videoUrl: videoUrl,
      videoPath: videoPath,
      includeVideo: includeVideo,
      presentVideoTogether: presentVideoTogether,
      textPpt,
      videoTranscript,
    }
    const res = createProject(projectInfos).then(
      setAssessmentSend(true)
    )
  }

  const createFBProject = () => {

    const id = idProject ? idProject : projectName + Timestamp.now();
    setIdProject(id);

    const newQuestion = [{
      alternatives: [
        { option: "a", text: "" },
        { option: "b", text: "" },
        { option: "c", text: "" },
        { option: "d", text: "" },
      ],
      ask_question: "",
      correct_answer: "",
      feedback: ""
    }]
    const projectInfos = {
      actionDoc: "insert",
      idProject: id,
      introAssessment: "",
      contentGenerated,
      projectName,
      projectDescription,
      projectContent: userInput ? userInput : contentReviewed,
      includeContent: includeVideo ? false : includeContent,
      includeSlides: includeSlides,
      presentTogether: presentTogether,
      projectFolder,
      assessment: assessment ? assessment : newQuestion,
      language,
      subject,
      pathToSlides,
      videoUrl,
      videoPath,
      includeVideo,
      presentVideoTogether,
      textPpt,
      videoTranscript,
    }
    const res = createProject(projectInfos).then(
      setAssessmentSend(true)
    )
  }

  const updateFBProject = () => {

    console.log("Content Generated: " + JSON.stringify(contentGenerated))

    const projectInfos = {
      actionDoc: "update",
      idProject: idProject,
      introAssessment: "",
      contentGenerated,
      projectName,
      projectDescription,
      projectContent: contentReviewed,
      includeContent: includeVideo ? false : includeContent,
      includeSlides: includeSlides,
      presentTogether: presentTogether,
      projectFolder,
      assessment: assessment,
      language,
      subject,
      subject,
      pathToSlides,
      videoUrl,
      videoPath,
      includeVideo,
      presentVideoTogether,
      textPpt,
      videoTranscript,
    }
    const res = createProject(projectInfos).then(
      setAssessmentSend(true)
    )
  }

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile && selectedFile.name != pptxFile.name && selectedFile.name != videoFile.name) {
      const fileSizeInBytes = selectedFile.size;
      const fileSizeInKB = fileSizeInBytes / 1024;
      const fileSizeInMB = fileSizeInKB / 1024;

      const filename = selectedFile.name;

      let fileMaxSize = 0;
      let msgExtraSize = "";
      let folderFile = "";

      if (methodChoiced === "choice5") {
        fileMaxSize = 20;
        msgExtraSize = pageTexts[165];
        setPptFileName(filename);
        setPptxFile(selectedFile);
        folderFile = "ppt";
      } else if (methodChoiced === "choice6") {
        fileMaxSize = 400;
        msgExtraSize = pageTexts[169];
        setVideoFileName(filename);
        setVideoFile(selectedFile);
        setGeneratingVideo(true);
        folderFile = "video";
      }

      if (fileSizeInMB > fileMaxSize) {
        setOpenDialog(true)
        setMsgDialog(msgExtraSize)
        setOptionsDialog(["Ok"])
        return
      }

      setProjectSize(fileSizeInMB);

      if (!idProject) {
        const id = projectName + Timestamp.now();
        setIdProject(id);
        uploadFileToStorage(selectedFile, id, folderFile);
      } else {
        deleteFolderContents(storage, `${folderFile}/${userId}/${idProject}`)
          .then(() => {
            uploadFileToStorage(selectedFile, idProject, folderFile);
          })
          .catch((error) => {
            console.error('Erro ao excluir conteúdo da pasta:', error);
          });
      }
    } else {
      return
    }
  };

  const deleteFolderContents = async (storage, path) => {
    setUploadingFile(true);
    const folderRef = ref(storage, path);
    const files = await listAll(folderRef);

    const deletePromises = files.items.map((item) => deleteObject(item));

    return Promise.all(deletePromises);
  };

  const uploadFileToStorage = async (file, id, folderFile) => {
    setUploadingFile(true)
    setFileMessange(pageTexts[159])
    const bucketName = 'quizzai-4b3cd.appspot.com';
    try {
      if (file) {

        const storageRef = ref(storage, `${folderFile}/${userId}/${id}/${file.name}`);

        const uploadTask = uploadBytesResumable(storageRef, file);

        uploadTask.on("state_changed",
          (snapshot) => {
            const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
            setProgresspercent(progress);
          },
          (error) => {
            alert(error);
          },
          () => {
            const storagePath = uploadTask.snapshot.ref.fullPath;
            if (storagePath.startsWith('video')) {
              setVideoPath(`gs://${bucketName}/${storagePath}`);
              console.log("storagePath: " + storagePath)
            } else if (storagePath.startsWith('ppt')) {
              setPptPath(`gs://${bucketName}/${storagePath}`);
            }
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
              methodChoiced === "choice5" && setPptxUrl(downloadURL)
              methodChoiced === "choice6" && setVideoUrl(downloadURL)
              setUploadingFile(false)
            });
          }
        );
      } else {
        console.error('Nenhum arquivo selecionado.');
      }
    } catch (error) {
      setErrorPpt('Erro no upload do arquivo: ');
    }
  }

  const handleGeneratePpt = async () => {
    setMsgText(pageTexts[161])
    setGeneratingIA(true);
    handleChangeCarousel();
    await convertToImages(pptxUrl);
    const pptxText = await convertToText(pptxUrl);
    setGeneratingProject(true);
    const pathToFile = `ppt/${userId}/${idProject}`;
    deletePpt(pptPath)
    setPathToSlides(pathToFile);
    setMsgText(pageTexts[104]);
    try {
      const res = await handleOpenAiApi({
        subject,
        userInput: pptxText,
        selectedTopics,
        questionQ,
        choiceQ,
        feedback,
        level,
        language,
        orderGPT: "assessment"
      });

    } catch (error) {
      setErrorGPT(error);
    }
  }

  const deletePpt = (pptPath) => {
    const fileRef = ref(storage, `${pptPath}`);
    const deletePromises = deleteObject(fileRef);
  }

  const convertToImages = async (url) => {
    let pptxPath = "";
    const regex = /\/o\/(.*?)\?alt=media/;
    const matches = url.match(regex);

    if (matches && matches.length > 1) {
      pptxPath = matches[1];
    } else {
      setErrorPpt("Não foi possível extrair o caminho relativo.");
    }
    try {
      const response = await fetch(`https://us-central1-quizzai-4b3cd.cloudfunctions.net/convertPPTtoImage?userId=${userId}&projectId=${idProject}&pptxPath=${pptxPath}`, {
        method: 'GET',
      });

      if (response.status === 200) {
        const { totalSize } = await response.json();
        const fileSizeInKB = totalSize / 1024;
        const fileSizeInMB = fileSizeInKB / 1024;
        handleUpdateProjectSize("ppt", fileSizeInMB);
        setPptGenerated(true);
      } else {
        setErrorPpt('Erro na conversão das imagens do PPT. Tente novamente.');
      }
    } catch (error) {
      setErrorPpt('Erro na solicitação. Tente novamente mais tarde.');
    }
  }

  const convertToText = async (url) => {
    setMsgText(pageTexts[160])
    let pptxPath = "";
    const regex = /\/o\/(.*?)\?alt=media/;
    const matches = url.match(regex);

    if (matches && matches.length > 1) {
      pptxPath = matches[1];
    } else {
      setErrorPpt("Não foi possível extrair o caminho relativo.");
    }

    try {
      const response = await fetch(`https://us-central1-quizzai-4b3cd.cloudfunctions.net/convertPPTtoText?userId=${userId}&projectId=${idProject}&pptxPath=${pptxPath}`, {
        method: 'GET',
      });
      if (response.status === 200) {
        setPptGenerated(true)
        const data = await response.json();
        setTextType("usertext");
        const pptText = data.extractedText
        setTextPpt(pptText);
        return pptText;
      } else {
        setErrorPpt('Erro na extração do texto do PPT.');
      }
    } catch (error) {
      setErrorPpt('Erro na solicitação. Tente novamente mais tarde.');
    }
  }

  const handleUpdateProjectSize = (project, fileSizeInMB) => {
    if (project === "ppt") {
      const projectTotalSize = storagePPtSize + fileSizeInMB;
      setStoragePPtSize(projectTotalSize);
    } else if (project === "video") {
      const projectTotalSize = storageVideoSize + fileSizeInMB;
      setStorageVideoSize(projectTotalSize);
    }
  }

  const handleChangeShowSlides = (show) => {
    setIncludeSlides(show)
  }

  const handleChangeShowVideo = (show) => {
    setIncludeVideo(show)
  }

  const handleGenerateVideo = async () => {
    changePageCarousel && handleChangeCarousel();
    setGeneratingIA(true);
    const videoText = await videoToText(videoPath);
    setGeneratingProject(true);
    setGeneratingIA(false);
  }

  const videoToText = async (url) => {
    setMsgText("Gerando a transcrição do vídeo.");
    const videoURL = url;
    try {
      const response = await fetch(`https://us-central1-quizzai-4b3cd.cloudfunctions.net/convertVideoToText?userId=${userId}&projectId=${idProject}&videoURL=${videoURL}&language=${codeVideoLanguage}`, {
        method: 'GET',
      });
      if (response.status === 200) {
        handleUpdateProjectSize("video", projectSize);
        setVideoGenerated(true);
        setTextType("usertext");
        const videoTxt = await response.text();
        setVideoTranscript(videoTxt);
        return videoTxt;
      } else {
        setErrorVideo('Erro na extração do texto do vídeo.');
      }
    } catch (error) {
      setErrorVideo('Erro na solicitação. Tente novamente mais tarde.');
    }
  }

  const updateVideoPptProjects = (projectSize) => {
    let userInfos = {
      domain: userId,
      ppt_storage_size: storagePPtSize,
      video_storage_size: storageVideoSize,
    };

    const newProject = {
      creation_date: Timestamp.now(),
      project_name: idProject
    };

    if (methodChoiced === "choice5") {
      const newProjectPPt = { ...projectPpt, [idProject]: newProject }
      userInfos.ppt_projects = newProjectPPt;
    } else if (methodChoiced === "choice6") {
      const newProjectVideos = { ...projectVideos, [idProject]: newProject }
      userInfos.video_projects = newProjectVideos;
    }
    const resDoc = updateDocument(userInfos);
  };

  useEffect(() => {
    if (storagePPtSize || storageVideoSize) {
      updateVideoPptProjects();
    }
  }, [storagePPtSize, storageVideoSize])

  useEffect(() => {
    if (domain) {
      setProjectPPt(domain.ppt_projects || []);
      setProjectVideos(domain.video_projects || []);
      setStoragePPtSize(domain.ppt_storage_size || 0);
      setStorageVideoSize(domain.video_storage_size || 0);
      setStorageLimit(domain.data_storage_limit)
    }
  }, [domain]);

  useEffect(() => {
    if (storagePPtSize || storageVideoSize) {
      const totalSize = storagePPtSize + storageVideoSize
      totalSize > storageLimit && setProjectSizeLimit(true)
    }
  }, [storagePPtSize, storageVideoSize])

  useEffect(() => {
    if (pptVideoInfos) {
      pptProjectsLimit <= pptQty && setProjectPPtLimit(true)
      videoProjectsLimit <= videoQty && setProjectVideosLimit(true)
    }
  }, [pptVideoInfos])

  useEffect(() => {
    if (methodChoiced === "choice5" && (projectPptLimit || projectSizeLimit)) {
      setOpenDialog(true);
      setOptionsDialog([pageTexts[140]]);
      const textMsg = projectPptLimit ? pageTexts[177] : pageTexts[183]
      setMsgDialog(textMsg);
      setMethodChoiced("choice1")
    }
    if (methodChoiced === "choice6" && (projectVideosLimit || projectSizeLimit)) {
      setOpenDialog(true);
      setOptionsDialog([pageTexts[140]]);
      const textMsg = projectVideosLimit ? pageTexts[178] : pageTexts[183]
      setMsgDialog(textMsg);
      setMethodChoiced("choice1")
    }
  }, [methodChoiced])

  useEffect(() => {
    if (projectVideosLimit) { }
  }, [projectVideosLimit])

  useEffect(() => {
    if (errorPpt) {
      setOpenDialog(true);
      setOptionsDialog([pageTexts[140]]);
      const textMsg = pageTexts[168];
      setMsgDialog(textMsg);
    }
  }, [errorPpt])

  useEffect(() => {
    if (returnGPT && generatingProject && returnGPT != changeReturnGPT) {
      setGeneratingIA(false);
      setGeneratingProject(false);
      setAssessment(returnGPT)
      setChangeReturnGPT(returnGPT)
      setGenerateAssessment(generateGPT);
      setTranslatedTopics(false);
      handleChangePageSettings("", true)
      setControlComponent(true)
    } else if (returnGPT && generatingTopics) {
      setGeneratingIA(false);
      setGeneratingTopics(false);
      handleChangePageSettings("", true)
      setTopics(returnGPT)
      setChangeReturnGPT(returnGPT)
    } else if (returnGPT && generatingContent && returnGPT != changeReturnGPT) {
      setGeneratingIA(false);
      handleChangePageSettings("", true)
      setChangeReturnGPT(returnGPT)
      setChangePageCarousel(true)
      setContentGenerated(returnGPT)
    } else if (returnGPT && generatingCase && returnGPT != changeReturnGPT) {
      setGeneratingIA(false);
      handleChangePageSettings("", true)
      setChangeReturnGPT(returnGPT)
      setChangePageCarousel(true)
      setContentGenerated(returnGPT)
    }
  }, [returnGPT, generateGPT]);

  useEffect(() => {
    if (textTranslated) {
      if (methodChoiced === "choice4") {
        setTranslatedCase(true)
        setCaseDescription(textTranslated)
      } else {
        setSelectedTopics([textTranslated])
        setTranslatedTopics(true)
      }
    }
  }, [textTranslated])

  useEffect(() => {
    if (translatedTopics) {
      if (methodChoiced === "choice1" || methodChoiced === "choice2") {
        handleAskChatGPT();
      } else if (methodChoiced === "choice3") {
        handleAskContentChatGPT();
      }
    }
  }, [translatedTopics])

  useEffect(() => {
    if (translatedCase) {
      handleAskCaseChatGPT();
    }
  }, [translatedCase])

  useEffect(() => {
    if (manualGenerate) {
      handleChangePageSettings("", true);
      setGenerateAssessment(true);
    }
  }, [manualGenerate])

  useEffect(() => {
    if (error) {
      setOpenDialog(true);
      setOptionsDialog([pageTexts[140]]);
      const textMsg = pageTexts[141];
      setMsgDialog(textMsg);
    }
  }, [error])

  useEffect(() => {
    userText && setUserInput(userText)
    userUrl && setUserInput(userUrl)
    videoTranscript && setUserInput(videoTranscript)
  }, [userText, userUrl, videoTranscript])


  useEffect(() => {
    contentSubject && setGeneratingContent(true)
    contentSubject && setSubject(contentSubject)
  }, [contentSubject])

  useEffect(() => {
    caseSubject && setGeneratingCase(true)
    caseSubject && setSubject(caseSubject)
  }, [caseSubject])

  useEffect(() => {
    (pptxFile && pptxFile.name != 'my_ppt_quizai_control.pptx') && setPptSubject(true)
  }, [pptxFile])

  useEffect(() => {
    (videoFile && videoFile.name != 'my_video_quizai_control.mp4') && setVideoSubject(true)
  }, [videoFile])

  useEffect(() => {
    const newStepsName = [...stepsName];
    if (showTopics) {
      newStepsName.splice(2, 0, `${pageTexts[7]} \n${pageTexts[8]}`);
      setCarouselTexts(newStepsName);
    } else {
      newStepsName[2] === `${pageTexts[7]} \n${pageTexts[8]}` && newStepsName.splice(2, 1);
      setCarouselTexts(newStepsName);
    }
    const newStepsNumber = newStepsName.length;
    setSteps(newStepsNumber)
    setStepsName(newStepsName);
  }, [showTopics]);

  useEffect(() => {
    setShowTopics(false)
    const newStepsName = [...stepsNameOriginal];
    const position = 2;
    if (methodChoiced === "choice3" || methodChoiced === "choice4" || methodChoiced === "choice6") {
      newStepsName.splice(position, 0, methodChoiced === "choice3" ? `${pageTexts[13]} \n${pageTexts[14]}` : methodChoiced === "choice4" ? `${pageTexts[13]} \ncase` : `${pageTexts[175]} \n${pageTexts[176]}`);
    }
    if (methodChoiced != "choice3" || methodChoiced != "choice4") {
      setGeneratingContent(false);
      setGeneratingCase(false);
    }
    if (methodChoiced != "choice2") {
      setUserText("");
    }
    if (methodChoiced != "choice6") {
      setIncludeVideo(false);
    }
    const newStepsNumber = newStepsName.length;
    setSteps(newStepsNumber)
    setStepsName(newStepsName);
    setCarouselTexts(newStepsName);
  }, [methodChoiced])

  useEffect(() => {
    handleFormNotEditable();
  }, [showTopics, generatingContent, generatingCase, actualSlide])

  useEffect(() => {
    if (contentReviewedRef.current === false && contentReviewed) {
      createFBProject();
      contentReviewedRef.current = true;
    }
  }, [contentReviewed]);

  useEffect(() => {
    if (contentGenerated || videoTranscript) {
      assessment && updateFBProject();
    } else {
      assessment && AIHelp && createFBProject();
    }
  }, [assessment])

  useEffect(() => {
    if (AIHelp) {
      setUserEdit(!AIHelp);
      setStepsName(stepsNameOriginal)
      setCarouselTexts(stepsNameOriginal)
      setSteps(6)
    } else {
      setSteps(4)
      setStepsName(stepsNameManual)
      setCarouselTexts(stepsNameManual)
    }
  }, [AIHelp])

  useEffect(() => {
    setGenerationPhase(carouselTexts[actualSlide - 1]);
  }, [carouselTexts, actualSlide])

  useEffect(() => {
    if (jsonData && preferenceLanguage) {
      const newPageTexts = jsonData.map((data) => data[preferenceLanguage]);
      setPageTexts(newPageTexts);
      if (preferenceLanguage === "Português") {
        setLanguage("Portuguese")
        setVideoLanguage("Portuguese")
        setCodeVideoLanguage("pt-BR")
      } else if (preferenceLanguage === "Inglês") {
        setLanguage("English")
        setVideoLanguage("English")
        setCodeVideoLanguage("en-US")
      } else {
        setLanguage("Spanish")
        setVideoLanguage("Spanish")
        setCodeVideoLanguage("es-ES")
      }
    }
  }, [jsonData, preferenceLanguage])

  useEffect(() => {
    if (videoLanguage) {
      switch (videoLanguage) {
        case "Portuguese":
          setCodeVideoLanguage("pt-BR")
          break;
        case "English":
          setCodeVideoLanguage("en-US")
          break;
        case "Spanish":
          setCodeVideoLanguage("es-ES")
          break;
        case "Italian":
          setCodeVideoLanguage("it-IT")
          break;
        case "German":
          setCodeVideoLanguage("de-DE")
          break;
        case "French":
          setCodeVideoLanguage("fr-FR")
          break;
        case "Russian":
          setCodeVideoLanguage("ru-RU")
          break;
        case "Mandarin":
          setCodeVideoLanguage("zh-CN")
          break;
        case "Japanese":
          setCodeVideoLanguage("ja-JP")
          break;
        case "Korean":
          setCodeVideoLanguage("ko-KR")
          break;
      }
    }
  }, [videoLanguage])

  useEffect(() => {
    pageTexts && setCarouselTexts(stepsNameOriginal);
    pageTexts && setStepsName(stepsNameOriginal)
    setButtonLabel(pageTexts[42])
  }, [pageTexts])

  useEffect(() => {
    if (newProjectTemplate && pageTexts) {
      if (newProjectTemplate != originalTemplate) {
        setButtonLabel(pageTexts[139]);
      } else {
        setButtonLabel(pageTexts[42]);
      };
    }
  }, [newProjectTemplate, originalTemplate])

  useEffect(() => {
    if (userDomainPlan) {

      userDomainPlan.includes('free') ? setTextMaxLength(2000) : setTextMaxLength(8000)
    }
  }, [userDomainPlan])

  useEffect(() => {
    checkStepsTimeline = actualSlide - 1;
    timelineUpdate_alnl(checkStepsTimeline);
  }, [steps]);

  useEffect(() => {
    if (!includeSlides && methodChoiced === "choice5") {
      setPresentTogether(false)
    }
  }, [includeSlides])

  useEffect(() => {
    if (!includeVideo && methodChoiced === "choice6") {
      setPresentVideoTogether(false)
    }
  }, [includeVideo])

  useEffect(() => {
    if (methodChoiced != "choice5") {
      setPresentTogether(true)
    } else {
      setPresentTogether(false)
    }
  }, [methodChoiced])

  useEffect(() => {
    if (templates && templates.length > 0) {
      setTemplatesList(templates);
    }
  }, [templates])

  useEffect(() => {
    pathToSlides && loadPptImages();
  }, [pathToSlides])


  return (
    <div>
      {generationPhase && <div>
        <h3>
          {pageTexts[0]}
        </h3>
        {pageTexts[1]} / {generationPhase.toUpperCase()}
      </div>}
      {pageTexts &&
        <div className="p-3">
          {carouselTexts &&
            <div className={`${styles.timeline}`}>
              <div className={`d-none ${styles.timeline_bg} d-sm-block`}></div>
              <div className={`d-none ${styles.timeline_fill} d-sm-block`}></div>
              <div className={`d-flex row  ${styles.icon_row}`}>

                <div className={`col-auto ${styles.icon_container}`}>
                  <i className={`col-auto ${actualSlide < 2 ? `${biCircle[0]}` : `${biCircleFill[0]}`}  ${actualSlide === 1 ? styles.current_icon : styles.did_icon}`}></i>
                  <h5 className={` ${actualSlide === 1 ? styles.txt_atual : styles.txt_feito}`}>{carouselTexts[0]}</h5>
                </div>

                <div className={`col-auto `}>
                  <i className={`carousel_2 ${actualSlide < 3 ? `${biCircle[1]}` : `${biCircleFill[1]}`} ${actualSlide === 2 ? styles.current_icon : actualSlide > 2 ? styles.did_icon : styles.bi}`}></i>
                  <h5 className={` ${actualSlide === 2 ? styles.txt_atual : actualSlide > 2 ? styles.txt_feito : styles.pre_line}`}>{carouselTexts[1]}</h5>
                </div>

                <div className={`col-auto `}>
                  <i className={`carousel_3 ${actualSlide < 4 ? `${biCircle[2]}` : `${biCircleFill[2]}`} ${actualSlide === 3 ? styles.current_icon : actualSlide > 3 ? styles.did_icon : styles.bi} `}></i>
                  <h5 className={` ${actualSlide === 3 ? styles.txt_atual : actualSlide > 3 ? styles.txt_feito : styles.pre_line}`}>{carouselTexts[2]}</h5>
                </div>

                {steps > 6 && <div className={`col-auto `}>
                  <i className={`carousel_4 ${actualSlide < 5 ? `${biCircle[3]}` : `${biCircleFill[3]}`} ${actualSlide === 4 ? styles.current_icon : actualSlide > 4 ? styles.did_icon : styles.bi}  `}></i>
                  <h5 className={`${actualSlide === 4 ? styles.txt_atual : actualSlide > 4 ? styles.txt_feito : styles.pre_line}`}>{carouselTexts[3]}</h5>
                </div>}

                {steps > 7 && <div className={`col-auto `}>
                  <i className={`carousel_5 ${actualSlide < 6 ? `${biCircle[4]}` : `${biCircleFill[4]}`} ${actualSlide === 5 ? styles.current_icon : actualSlide > 5 ? styles.did_icon : styles.bi}  `}></i>
                  <h5 className={`${actualSlide === 5 ? styles.txt_atual : actualSlide > 5 ? styles.txt_feito : styles.pre_line}`}>{carouselTexts[4]}</h5>
                </div>}

                {AIHelp && <div className={`col-auto `}>
                  <i className={`carousel_6 ${actualSlide < steps - 1 ? `${biCircle[steps - 3]}` : `${biCircleFill[steps - 3]}`} ${actualSlide === steps - 2 ? styles.current_icon : actualSlide > steps - 2 ? styles.did_icon : styles.bi} `}></i>
                  <h5 className={` ${actualSlide === steps - 2 ? styles.txt_atual : actualSlide > steps - 2 ? styles.txt_feito : styles.pre_line}`}>{carouselTexts[steps - 3]}</h5>
                </div>}

                {AIHelp && <div className={`col-auto `}>
                  <i className={`carousel_7 ${actualSlide < steps ? `${biCircle[steps - 2]}` : `${biCircleFill[steps - 2]}`} ${actualSlide === steps - 1 ? styles.current_icon : actualSlide > steps - 1 ? styles.did_icon : styles.bi} `}></i>
                  <h5 className={` ${actualSlide === steps - 1 ? styles.txt_atual : actualSlide > steps - 1 ? styles.txt_feito : styles.pre_line}`}>{carouselTexts[steps - 2]}</h5>
                </div>}

                <div className={`col-auto`}>
                  <i className={`carousel_end ${actualSlide < steps + 1 ? `${biCircle[steps - 1]}` : `${biCircleFill[steps - 1]}`} ${actualSlide === steps ? styles.current_icon : actualSlide < steps ? styles.bi : generatingProject ? styles.current_icon : styles.did_icon}  `}></i>
                  <h5 className={`${actualSlide < steps ? styles.pre_line : generatingProject ? styles.txt_atual : actualSlide === steps ? styles.txt_atual : styles.txt_feito}`}>{carouselTexts[steps - 1]}</h5>
                </div>

              </div>
            </div>}

          {
            <div>

              <div id="carouselProject" className="carousel slide">
                <div className="carousel-inner">

                  {/* CAROUSEL 1 */}
                  {<div className="carousel-item active">
                    <div className={`${styles.container}`}>
                      <div className="">
                        <form onSubmit={handleSubmitProject}>
                          <div className="row mt-4 d-flex align-items-center ">
                            <h6 className="col-3 text-end">{pageTexts[21]}</h6>
                            <input
                              className={`col-6 ${styles.form_field}`}
                              type="text"
                              required
                              disabled={disableForm}
                              name="projectName"
                              placeholder={pageTexts[22]}
                              value={projectName}
                              onChange={(e) => { handleProjectName(e) }}
                            />
                          </div>

                          <div className="row mt-3">
                            <h6 className="col-3 text-end">{pageTexts[23]}</h6>
                            <textarea
                              className={`col-6 ${styles.form_field} ${styles.text_field}`}
                              type="text"
                              rows={3}
                              name="projectDescription"
                              placeholder={pageTexts[24]}
                              value={projectDescription}
                              onChange={(e) => { setProjectDescription(e.target.value) }}
                            />
                          </div>

                          {AIHelp && <div className="row d-flex align-items-center mt-3">
                            <h6 className="col-3 text-end">{pageTexts[25]} </h6>
                            <select
                              className={styles.form_select}
                              disabled={disableForm}
                              name="language"
                              value={language}
                              onChange={(e) => changeLanguage(e.target.value)}>
                              {preferenceLanguage === 'Português' && <option value="Portuguese" defaultValue>{pageTexts[27]}</option>}
                              {preferenceLanguage === 'Inglês' && <option value="English" defaultValue>{pageTexts[28]}</option>}
                              {preferenceLanguage === 'Espanhol' && <option value="Spanish" defaultValue>{pageTexts[29]}</option>}
                              {preferenceLanguage != 'Português' && <option value="Portuguese">{pageTexts[27]}</option>}
                              {preferenceLanguage != 'Inglês' && <option value="English">{pageTexts[28]}</option>}
                              {preferenceLanguage != 'Espanhol' && <option value="Spanish">{pageTexts[29]}</option>}
                              <option value="French">{pageTexts[30]}</option>
                              <option value="German">{pageTexts[31]}</option>
                              <option value="Italian">{pageTexts[32]}</option>
                              <option value="Mandarin">{pageTexts[33]}</option>
                              <option value="Russian">{pageTexts[34]}</option>
                              <option value="Japanese">{pageTexts[35]}</option>
                              <option value="Korean">{pageTexts[36]}</option>
                            </select>
                            <div
                              className="col-auto"
                              onMouseOver={() => setShowInfoLanguage(true)}
                              onMouseLeave={() => setShowInfoLanguage(false)}>
                              <a>
                                <i className={`bi bi-info-circle-fill fs-4 col-auto  ${styles.icon_info}`}></i>
                              </a>

                            </div>
                            {showInfoLanguage && (
                              <div
                                className={`col-3 ${styles.info_boxes}`}
                              >
                                <div
                                  className={`alert alert-info alert-dismissible fade show ${styles.alert_costumization}`}
                                  role="alert"
                                >
                                  {pageTexts[37]}
                                </div>
                              </div>
                            )}
                          </div>}
                          <div className="row align-items-center mt-4">
                            <label className="col-auto col-3 text-end">{pageTexts[38]}</label>
                            <div className="col-auto">
                              <div className="form-check ">
                                <input
                                  className={`form-check-input ${styles.option_check}`}
                                  type="radio"
                                  name="meu_radio"
                                  value="manual"
                                  disabled={disableIA}
                                  checked={!AIHelp}
                                  onChange={() => setAIHelp(false)}
                                  style={{ border: "1px solid #ced4da" }}
                                />
                                <label className="form-check-label">{pageTexts[39]}</label>
                              </div>
                            </div>
                            <div className="col-auto">
                              <div className="form-check">
                                <input
                                  className={`form-check-input ${styles.option_check}`}
                                  type="radio"
                                  name="meu_radio"
                                  value="ai"
                                  disabled={disableIA}
                                  checked={AIHelp}
                                  onChange={() => setAIHelp(true)}
                                  style={{ border: "1px solid #ced4da" }}
                                />
                                <label className="form-check-label">{pageTexts[40]}</label>
                              </div>
                            </div>
                            <div>
                              <button className={` d-none form_next `}>{pageTexts[42]}</button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>}

                  {/* CAROUSEL 2 */}
                  {AIHelp && <div className="carousel-item">
                    <div className={`mt-1 ${styles.container}`}>
                      <div className="mt-4">
                        <h4>
                          {pageTexts[44]}
                        </h4>
                      </div>
                      <div className="row mt-3">
                        <div className="col-md-3">
                          <div className={`form-check mt-2 ${methodChoiced === 'choice1' ? styles.actual_choice : ''}`}>
                            <input className={`form-check-input ${styles.option_check}`} type="radio" disabled={disableForm} name="methodRadioDefault" id="choice1" onChange={(event) => setMethodChoiced(event.target.id)} checked={methodChoiced === 'choice1'} />
                            <span className="form-check-label">
                              {pageTexts[45]}
                            </span>
                          </div>
                          <div className={`form-check mt-3 ${methodChoiced === 'choice2' ? styles.actual_choice : ''}`}>
                            <input className={`form-check-input ${styles.option_check}`} type="radio" disabled={disableForm} name="methodRadioDefault" id="choice2" onChange={(event) => setMethodChoiced(event.target.id)} checked={methodChoiced === 'choice2'} />
                            <span className="form-check-label">
                              {pageTexts[50]}
                            </span>
                          </div>
                          <div className={`form-check mt-3 ${methodChoiced === 'choice3' ? styles.actual_choice : ''}`}>
                            <input className={`form-check-input ${styles.option_check}`} type="radio" disabled={disableForm} name="methodRadioDefault" id="choice3" onChange={(event) => setMethodChoiced(event.target.id)} checked={methodChoiced === 'choice3'} />
                            <span className="form-check-label">
                              {pageTexts[59]}
                            </span>
                          </div>
                          <div className={`form-check mt-3 ${methodChoiced === 'choice4' ? styles.actual_choice : ''}`}>
                            <input className={`form-check-input ${styles.option_check}`} type="radio" disabled={disableForm} name="methodRadioDefault" id="choice4" onChange={(event) => setMethodChoiced(event.target.id)} checked={methodChoiced === 'choice4'} />
                            <span className="form-check-label">
                              {pageTexts[64]}
                            </span>
                          </div>
                          <div className={`form-check mt-3 ${methodChoiced === 'choice5' ? styles.actual_choice : ''}`}>
                            <input className={`form-check-input ${styles.option_check}`} type="radio" disabled={false} name="methodRadioDefault" id="choice5" onChange={(event) => setMethodChoiced(event.target.id)} checked={methodChoiced === 'choice5'} />
                            <span className="form-check-label">
                              {pageTexts[69]}
                            </span>
                          </div>
                          <div className={`form-check mt-3 ${methodChoiced === 'choice6' ? styles.actual_choice : ''}`}>
                            <input className={`form-check-input ${styles.option_check}`} type="radio" disabled={false} name="methodRadioDefault" id="choice6" onChange={(event) => setMethodChoiced(event.target.id)} checked={methodChoiced === 'choice6'} />
                            <span className="form-check-label">
                              {pageTexts[75]}
                            </span>
                          </div>
                          {/* <div className={`form-check mt-3 ${methodChoiced === 'choice7' ? styles.actual_choice : ''}`}>
                            <input className={`form-check-input ${styles.option_check}`} type="radio" disabled={true} name="methodRadioDefault" id="choice7" onChange={(event) => setMethodChoiced(event.target.id)} checked={methodChoiced === 'choice7'} />
                            <span className="form-check-label">
                              {pageTexts[82]}
                            </span>
                          </div> */}
                        </div>
                        <div className={`col-md-8 ${styles.choice_panel}`}>

                          {methodChoiced === "choice1" &&
                            <div className="pt-4 ms-3">
                              <div className="row align-items-center">

                                <div className="col-auto">
                                  <input
                                    className={`${styles.form_field}`}
                                    type="text"
                                    required
                                    id="subject"
                                    disabled={disableForm}
                                    name="subject"
                                    placeholder={pageTexts[46]}
                                    value={subject}
                                    onChange={(e) => { setSubject(e.target.value) }}
                                  />
                                </div>
                              </div>
                              <div className="row align-items-center">
                                <div className="mt-4 ms-2 form-check col-auto">
                                  <input className={`form-check-input ${styles.option_check}`} type="checkbox" disabled={disableForm} name="flexRadioDefault" id="flexRadioDefault1" value={showTopics} onChange={(e) => setShowTopics(e.target.checked)} />
                                  <span className="ms-2">
                                    {pageTexts[47]}
                                  </span>
                                </div>
                                <div className="col-auto d-flex mt-4" onMouseOver={() => setShowInfoTopics(true)} onMouseLeave={() => setShowInfoTopics(false)} >
                                  <a>
                                    <i className={`bi bi-info-circle-fill fs-4 ${styles.icon_info}`}></i>
                                  </a>
                                </div>
                                {showInfoTopics && <div className={`col-8`}>
                                  <div className={`alert alert-info alert-dismissible fade show ${styles.alert_costumization}`} role="alert">
                                    {pageTexts[48]}
                                  </div>
                                </div>}
                              </div>
                            </div>}

                          {methodChoiced === "choice2" && (
                            <div>
                              <div className="row pt-4">
                                <div className="form-check mt-2 col-md-11">
                                  <div className="d-flex align-items-start">
                                    <input
                                      className={`form-check-input ms-1 ${styles.option_check}`}
                                      type="radio"
                                      disabled={disableForm}
                                      name="methodChoice"
                                      value="usertext"
                                      checked={textType === "usertext"}
                                      onChange={(e) => setTextType(e.target.value)}
                                    />
                                    <textarea
                                      className={`form-control ms-3 ${styles.form_field} ${styles.text_field} `}
                                      type="text"
                                      disabled={disableForm}
                                      name="intro"
                                      placeholder={pageTexts[51]}
                                      rows={3}
                                      value={userText}
                                      maxLength={textMaxLenght}
                                      onClick={() => setTextType("usertext")}
                                      onChange={(e) => setUserText(e.target.value)}
                                    />

                                    <div
                                      className="d-flex align-items-center ms-2"
                                      onMouseOver={() => setShowInfoUserText(true)}
                                      onMouseLeave={() => setShowInfoUserText(false)}
                                    >
                                      <a>
                                        <i className={`bi bi-info-circle-fill fs-4 col-auto ${styles.icon_info}`}></i>
                                      </a>
                                    </div>
                                    {showInfoUserText && (
                                      <div
                                        className={`col-5 ${styles.info_boxes_choice2}`}
                                      >
                                        <div
                                          className={`alert alert-info alert-dismissible fade show ${styles.alert_costumization}`}
                                          role="alert"
                                        >
                                          {pageTexts[55]}
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                  <div className="text-end mt-1 col-md-11 ">
                                    {userText.length}/{textMaxLenght}
                                  </div>

                                </div>
                              </div>
                              <div className="row pt-4">
                                <div className="form-check mt-2 col-md-11">
                                  <div className="d-flex align-items-start">
                                    <input
                                      className={`form-check-input ms-1 ${styles.option_check}`}
                                      type="radio"
                                      disabled={disableForm}
                                      name="methodChoice"
                                      value="userurl"
                                      checked={textType === "userurl"}
                                      onChange={(e) => setTextType(e.target.value)}
                                    />
                                    <textarea
                                      className={`form-control ms-3 ${styles.form_field} ${styles.url_field}`}
                                      type="text"
                                      disabled={disableForm}
                                      name="intro"
                                      placeholder={pageTexts[52]}
                                      rows={1}
                                      value={userUrl}
                                      onClick={() => setTextType("userurl")}
                                      onChange={(e) => setUserUrl(e.target.value)}
                                    />
                                    <div
                                      className="d-flex align-items-center ms-2"
                                      onMouseOver={() => setShowInfoUserUrl(true)}
                                      onMouseLeave={() => setShowInfoUserUrl(false)}
                                    >
                                      <a>
                                        <i className={`bi bi-info-circle-fill fs-4 col-auto ${styles.icon_info}`}></i>
                                      </a>
                                    </div>
                                    {showInfoUserUrl && (
                                      <div
                                        className={`col-5 ${styles.info_boxes_choice2_url}`}
                                      >
                                        <div
                                          className={`alert alert-info alert-dismissible fade show ${styles.alert_costumization}`}
                                          role="alert"
                                        >
                                          {pageTexts[56]}
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className="row align-items-center mb-3">
                                <div className="mt-4 ms-4 form-check col-8">
                                  <input
                                    className={`form-check-input ${styles.option_check}`}
                                    type="checkbox"
                                    name="showTopics"
                                    id="flexRadioDefault1"
                                    value={includeContent}
                                    onChange={(e) => setIncludeContent(e.target.checked)}
                                  />
                                  <span className="ms-2">{pageTexts[53]}</span>
                                </div>
                                <div
                                  className="col-1 d-flex mt-4"
                                  onMouseOver={() => setShowInfoIncludeContent(true)}
                                  onMouseLeave={() => setShowInfoIncludeContent(false)}
                                >
                                  <a>
                                    <i className={`bi bi-info-circle-fill fs-4 col-auto ${styles.icon_info}`}></i>
                                  </a>
                                </div>
                                {showInfoIncludeContent && (
                                  <div
                                    className={`col-5 ${styles.info_boxes_choice2_bottom}`}
                                  >
                                    <div
                                      className={`alert alert-info alert-dismissible fade show ${styles.alert_costumization}`}
                                      role="alert"
                                    >
                                      {pageTexts[57]}
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          )}

                          {methodChoiced === "choice3" &&
                            <div className="pt-4 ms-3 mt-2">
                              <div className="row align-items-center">
                                <div className="col-auto">
                                  <input
                                    className={`${styles.form_field}`}
                                    type="text"
                                    required
                                    id="subject"
                                    placeholder={pageTexts[60]}
                                    disabled={disableForm}
                                    name="subject"
                                    value={contentSubject}
                                    onChange={(e) => { setContentSubject(e.target.value) }}
                                  />
                                </div>
                                <div className="col-auto d-flex mt-1" onMouseOver={() => setShowInfoTopics(true)} onMouseLeave={() => setShowInfoTopics(false)}>
                                  <a>
                                    <i className={`bi bi-info-circle-fill fs-4 ${styles.icon_info}`}></i>
                                  </a>
                                </div>
                                {showInfoTopics && (
                                  <div
                                    className={`col-5 ${styles.info_boxes_choice3}`}
                                  >
                                    <div
                                      className={`alert alert-info alert-dismissible fade show ${styles.alert_costumization}`}
                                      role="alert"
                                    >
                                      {pageTexts[62]}
                                    </div>
                                  </div>
                                )}
                              </div>

                              <div className="row align-items-center">
                                <div className="mt-4 ms-2 form-check col-auto">
                                  <input className={`form-check-input ${styles.option_check}`} type="checkbox" disabled={disableForm} name="flexRadioDefault" id="flexRadioDefault1" value={showTopics} onChange={(e) => setShowTopics(e.target.checked)} />
                                  <span className="ms-2">
                                    {pageTexts[61]}
                                  </span>
                                </div>
                                <div className="col-1 d-flex mt-4" onMouseOver={() => setShowInfoContent(true)} onMouseLeave={() => setShowInfoContent(false)} >
                                  <a>
                                    <i className={`bi bi-info-circle-fill fs-4 ${styles.icon_info}`}></i>
                                  </a>
                                </div>
                                {showInfoContent && <div className={`col-9`}>
                                  <div className={`alert alert-info alert-dismissible fade show ${styles.alert_costumization}`} role="alert">
                                    {pageTexts[63]}
                                  </div>
                                </div>}
                              </div>
                            </div>}

                          {methodChoiced === "choice4" &&
                            <div className="pt-4 ms-3 mt-2">
                              <div className="row align-items-center">
                                <div className="col-auto">
                                  <input
                                    className={`${styles.form_field}`}
                                    type="text"
                                    required
                                    id="subject"
                                    placeholder={pageTexts[65]}
                                    disabled={disableForm}
                                    name="subject"
                                    value={caseSubject}
                                    onChange={(e) => { setCaseSubject(e.target.value) }}
                                  />
                                </div>
                                <div className="col-auto d-flex mt-1" onMouseOver={() => setShowInfoCase(true)} onMouseLeave={() => setShowInfoCase(false)}>
                                  <a>
                                    <i className={`bi bi-info-circle-fill fs-4 ${styles.icon_info}`}></i>
                                  </a>
                                </div>
                                {showInfoCase && (
                                  <div
                                    className={`col-6 ${styles.info_boxes_choice4}`}
                                  >
                                    <div
                                      className={`alert alert-info alert-dismissible fade show ${styles.alert_costumization}`}
                                      role="alert"
                                    >
                                      {pageTexts[67]}
                                    </div>
                                  </div>
                                )}
                              </div>
                              <div className="row align-items-center">
                                <div className='col-auto mt-3 mb-2'>
                                  <textarea
                                    className={`form-control ${styles.text_field_case}`}
                                    type="text"
                                    disabled={disableForm}
                                    name="intro"
                                    placeholder={pageTexts[66]}
                                    rows={3}
                                    value={caseDescription}
                                    onChange={(e) => setCaseDescription(e.target.value)}
                                  />
                                </div>
                                <div className="col-auto d-flex" onMouseOver={() => setShowDescCase(true)} onMouseLeave={() => setShowDescCase(false)}>
                                  <a>
                                    <i className={`bi bi-info-circle-fill fs-4 col-auto ${styles.icon_info}`}></i>
                                  </a>
                                </div>
                                {showDescCase && (
                                  <div
                                    className={`col-6 ${styles.info_boxes_choice4} ${styles.choice4_adjust}`}
                                  >
                                    <div
                                      className={`alert alert-info alert-dismissible fade show col-auto ${styles.alert_costumization}`}
                                      role="alert"
                                    >
                                      {pageTexts[68]}
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>}

                          {methodChoiced === "choice5" && (uploadingFile
                            ? <div>
                              <div className="d-flex justify-content-center mt-5">
                                <div className="align-self-center">
                                  <div className="spinner-border text-primary" role="status">
                                    <span className="visually-hidden"></span>
                                  </div>
                                </div>
                              </div>
                              <div className="d-flex justify-content-center mt-5">
                                {`${fileMessange} ${pptFileName}`}
                              </div>
                            </div>
                            : <div className="pt-4 ms-3 ">
                              <div className="row mt-1 align-items-center d-flex">
                                <div className="col-auto">
                                  {pageTexts[179]}
                                </div>
                                <div className="col-auto p-0">
                                  <h4>{pptQty}</h4>
                                </div>
                                <div className="col-auto">
                                  {pageTexts[180]}
                                </div>
                                <div className="col-auto p-0">
                                  <h4>{pptProjectsLimit}</h4>
                                </div>
                                <div className="col-auto">

                                  {pageTexts[181]}.
                                </div>
                              </div>
                              <div className="row mt-4">
                                <div className="col-auto">
                                  {pageTexts[70]}
                                  <h6>{pageTexts[142]}</h6>
                                </div>
                                <div className="col-auto me-1">
                                  <input type="file" ref={fileInputRef} onChange={handleFileChange} accept=".pptx" className='d-none' />
                                  <button onClick={() => fileInputRef.current.click()} className={`${styles.btn_style_select}`}>{pageTexts[71]}</button>
                                </div>
                                <div
                                  className="col-1 d-flex mt-2"
                                  onMouseOver={() => setShowInfoPPT(true)}
                                  onMouseLeave={() => setShowInfoPPT(false)}
                                >
                                  <a>
                                    <i className={`bi bi-info-circle-fill fs-4 ${styles.icon_info}`}></i>
                                  </a>
                                </div>
                                {showInfoPPT && (
                                  <div
                                    className={`col-6 ${styles.info_boxes_choice5}`}
                                  >
                                    <div
                                      className={`alert alert-info alert-dismissible fade show ${styles.alert_costumization}`}
                                      role="alert"
                                    >
                                      {pageTexts[73]}
                                    </div>
                                  </div>
                                )}
                              </div>
                              {pptFileName &&
                                <div className='mt-1'>
                                  <span className={`${styles.badge_customize}`}>{pptFileName}</span>
                                </div>}
                              <div className="row align-items-center">
                                <div className="mt-4 ms-3 form-check col-auto">
                                  <input
                                    className={`form-check-input ${styles.option_check}`}
                                    type="checkbox"
                                    name="showTopics"
                                    id="flexRadioDefault1"
                                    value={includeSlides}
                                    onChange={(e) => setIncludeSlides(e.target.checked)}
                                  />
                                  <span className="ms-2">{pageTexts[72]}</span>
                                </div>
                                <div
                                  className="col-1 d-flex mt-4"
                                  onMouseOver={() => setShowInfoIncludeContent(true)}
                                  onMouseLeave={() => setShowInfoIncludeContent(false)}
                                >
                                  <a>
                                    <i className={`bi bi-info-circle-fill fs-4 ${styles.icon_info}`}></i>
                                  </a>
                                </div>
                                {showInfoIncludeContent && (
                                  <div
                                    className={`col-4 ${styles.info_boxes_choice6}`}
                                  >
                                    <div
                                      className={`alert alert-info alert-dismissible fade show ${styles.alert_costumization}`}
                                      role="alert"
                                    >
                                      {pageTexts[74]}
                                    </div>
                                  </div>
                                )}
                              </div>
                              <div className="row align-items-center">
                                <div className="mt-4 ms-3 form-check col-auto">
                                  <input
                                    className={`form-check-input ${styles.option_check}`}
                                    disabled={!includeSlides}
                                    type="checkbox"
                                    name="showTopics"
                                    id="flexRadioDefault1"
                                    value={presentTogether}
                                    checked={presentTogether}
                                    onChange={(e) => setPresentTogether(e.target.checked)}
                                  />
                                  <span className={`ms-2 ${includeSlides ? '' : `${styles.disabled_text}`}`}>{pageTexts[163]}</span>
                                </div>
                                <div
                                  className={`col-1 d-flex mt-4 `}
                                  onMouseOver={() => includeSlides && setShowInfoSlides(true)}
                                  onMouseLeave={() => setShowInfoSlides(false)}
                                >
                                  <a>
                                    <i className={`bi bi-info-circle-fill fs-4 ${styles.icon_info} ${includeSlides ? '' : `${styles.disabled_text}`}`}></i>
                                  </a>
                                </div>
                                {showInfoSlides && (
                                  <div
                                    className={`col-4 ${styles.info_boxes_choice7}`}
                                  >
                                    <div
                                      className={`alert alert-info alert-dismissible fade show ${styles.alert_costumization}`}
                                      role="alert"
                                    >
                                      {pageTexts[164]}
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>)}

                          {methodChoiced === "choice6" && (uploadingFile
                            ? <div>
                              <div className="d-flex justify-content-center mt-5">
                                <div className="align-self-center">
                                  <div className="spinner-border text-primary" role="status">
                                    <span className="visually-hidden"></span>
                                  </div>
                                </div>
                              </div>
                              <div className="d-flex justify-content-center mt-5">
                                {`${fileMessange} ${videoFileName}`}
                              </div>
                            </div>
                            : <div className=" pt-4">
                              <div className="row ms-1 mt-1 align-items-center d-flex">
                                <div className="col-auto">
                                  {pageTexts[179]}
                                </div>
                                <div className="col-auto p-0">
                                  <h4>{videoQty}</h4>
                                </div>
                                <div className="col-auto">
                                  {pageTexts[180]}
                                </div>
                                <div className="col-auto p-0">
                                  <h4>{videoProjectsLimit}</h4>
                                </div>
                                <div className="col-auto">
                                  {pageTexts[182]}.
                                </div>
                              </div>
                              <div className="row mt-4 ms-2">
                                <div className="col-auto mt-3 form-check">
                                  <input
                                    className={`form-check-input me-3 ${styles.option_check}`}
                                    type="radio"
                                    name="methodChoice"
                                    value="file"
                                    checked={videoType === "file"}
                                    onChange={(e) => setVideoType(e.target.value)}
                                  />
                                </div>
                                <div className="col-6">
                                  <div>
                                    {pageTexts[76]}
                                    <h6>{pageTexts[143]}</h6>
                                  </div>
                                </div>
                                <div className="col-auto me-1">
                                  <input type="file" ref={fileInputRef} onChange={handleFileChange} accept=".mp4" className='d-none' />
                                  <button onClick={() => fileInputRef.current.click()} className={`${styles.btn_style_select}`}>{pageTexts[71]}</button>
                                </div>
                                <div className="col-auto">
                                  <div
                                    className="col-auto d-flex"
                                    onMouseOver={() => setShowInfoVideo(true)}
                                    onMouseLeave={() => setShowInfoVideo(false)}
                                  >
                                    <a>
                                      <i className={`bi bi-info-circle-fill fs-4 ${styles.icon_info}`}></i>
                                    </a>
                                  </div>
                                </div>
                                {showInfoVideo && (
                                  <div
                                    className={`col-5 ${styles.info_boxes_choice8}`}
                                  >
                                    <div
                                      className={`alert alert-info alert-dismissible fade show ${styles.alert_costumization}`}
                                      role="alert"
                                    >
                                      {pageTexts[80]}
                                    </div>
                                  </div>
                                )}
                              </div>
                              {videoFileName &&
                                <div className='mt-1'>
                                  <span className={`${styles.badge_customize}`}>{videoFileName}</span>
                                </div>}

                              <div className="row d-flex align-items-center justify-content-start mt-3 ms-1">
                                <h6 className="col-auto text-end">{pageTexts[184]} </h6>
                                <select
                                  className={styles.form_select}
                                  disabled={disableForm}
                                  name="videolanguage"
                                  value={videoLanguage}
                                  onChange={(e) => changeVideoLanguage(e.target.value)}>
                                  {preferenceLanguage === 'Português' && <option value="Portuguese" defaultValue>{pageTexts[27]}</option>}
                                  {preferenceLanguage === 'Inglês' && <option value="English" defaultValue>{pageTexts[28]}</option>}
                                  {preferenceLanguage === 'Espanhol' && <option value="Spanish" defaultValue>{pageTexts[29]}</option>}
                                  {preferenceLanguage != 'Português' && <option value="Portuguese">{pageTexts[27]}</option>}
                                  {preferenceLanguage != 'Inglês' && <option value="English">{pageTexts[28]}</option>}
                                  {preferenceLanguage != 'Espanhol' && <option value="Spanish">{pageTexts[29]}</option>}
                                  <option value="French">{pageTexts[30]}</option>
                                  <option value="German">{pageTexts[31]}</option>
                                  <option value="Italian">{pageTexts[32]}</option>
                                  <option value="Mandarin">{pageTexts[33]}</option>
                                  <option value="Russian">{pageTexts[34]}</option>
                                  <option value="Japanese">{pageTexts[35]}</option>
                                  <option value="Korean">{pageTexts[36]}</option>
                                </select>
                                <div
                                  className="col-auto"
                                  onMouseOver={() => setShowInfoLanguage(true)}
                                  onMouseLeave={() => setShowInfoLanguage(false)}>
                                  <a>
                                    <i className={`bi bi-info-circle-fill fs-4 col-auto  ${styles.icon_info}`}></i>
                                  </a>

                                </div>
                                {showInfoLanguage && (
                                  <div
                                    className={`col-3 ${styles.info_boxes_choice11}`}
                                  >
                                    <div
                                      className={`alert alert-info alert-dismissible fade show ${styles.alert_costumization}`}
                                      role="alert"
                                    >
                                      {pageTexts[185]}
                                    </div>
                                  </div>
                                )}
                              </div>

                              <div className="row ms-2">

                              </div>
                              {/* <div className="row mt-4 ms-2">
                                <div className="col-auto mt-2 form-check">
                                  <input
                                    className={`form-check-input me-3 ${styles.option_check}`}
                                    type="radio"
                                    name="methodChoice"
                                    value="url"
                                    checked={videoType === "url"}
                                    onChange={(e) => setVideoType(e.target.value)}
                                  />
                                </div>
                                <div className="col-10">
                                  <div className="input-group">
                                    <span class="input-group-text" id="basic-addon1"> <i class={`bi bi-youtube fs-5 ${styles.icon_projects}`}></i></span>
                                    <span class="input-group-text" id="basic-addon1"><i class={`bi bi-vimeo fs-5 ${styles.icon_projects}`}></i></span>
                                    <textarea
                                      className="form-control"
                                      type="text"
                                      name="url"
                                      placeholder={pageTexts[77]}
                                      rows={1}
                                      value={userVideoUrl}
                                      onClick={() => setVideoType("url")}
                                      onChange={(e) => setUserVideoUrl(e.target.value)}
                                    />
                                  </div>
                                  <div>
                                    <h6 className="mt-1">{pageTexts[78]}</h6>
                                  </div>
                                </div>
                                <div className="col-auto">
                                  <div
                                    className="col-1  d-flex"
                                    onMouseOver={() => setShowInfoUrlVideo(true)}
                                    onMouseLeave={() => setShowInfoUrlVideo(false)}
                                  >
                                    <a>
                                      <i className={`bi bi-info-circle-fill fs-4  ${styles.icon_info}`}></i>
                                    </a>
                                  </div>
                                </div>
                                {showInfoUrlVideo && (
                                  <div
                                    className={`col-5 ${styles.info_boxes_choice8}`}
                                  >
                                    <div
                                      className={`alert alert-info alert-dismissible fade show ${styles.alert_costumization}`}
                                      role="alert"
                                    >
                                      {pageTexts[80]}
                                    </div>
                                  </div>
                                )}
                                </div> */}

                              <div className="row align-items-center">
                                <div className="mt-4 ms-4 form-check col-6">
                                  <input
                                    className={`form-check-input ${styles.option_check}`}
                                    type="checkbox"
                                    name="showTopics"
                                    id="flexRadioDefault1"
                                    value={includeVideo}
                                    onChange={(e) => setIncludeVideo(e.target.checked)}
                                  />
                                  <span className="ms-2">{pageTexts[79]}</span>
                                </div>
                                <div
                                  className="col-1 d-flex mt-4"
                                  onMouseOver={() => setShowInfoIncludeContent(true)}
                                  onMouseLeave={() => setShowInfoIncludeContent(false)}
                                >
                                  <a>
                                    <i className={`bi bi-info-circle-fill fs-4 ${styles.icon_info}`}></i>
                                  </a>
                                </div>
                                {showInfoIncludeContent && (
                                  <div
                                    className={`col-5 ${styles.info_boxes_choice9}`}
                                  >
                                    <div
                                      className={`alert alert-info alert-dismissible fade show ${styles.alert_costumization}`}
                                      role="alert"
                                    >
                                      {pageTexts[81]}
                                    </div>
                                  </div>
                                )}
                              </div>
                              <div className="row mt-2 mb-4 align-items-center">
                                <div className=" ms-4 form-check col-6">
                                  <input
                                    className={`form-check-input ${styles.option_check}`}
                                    disabled={!includeVideo}
                                    type="checkbox"
                                    name="showTopics"
                                    id="flexRadioDefault1"
                                    value={presentVideoTogether}
                                    checked={presentVideoTogether}
                                    onChange={(e) => setPresentVideoTogether(e.target.checked)}
                                  />
                                  <span className={`ms-2 ${includeVideo ? '' : `${styles.disabled_text}`}`}>{pageTexts[171]}</span>
                                </div>
                                <div
                                  className={`col-1 d-flex `}
                                  onMouseOver={() => includeVideo && setShowInfoVideoTogether(true)}
                                  onMouseLeave={() => setShowInfoVideoTogether(false)}
                                >
                                  <a>
                                    <i className={`bi bi-info-circle-fill fs-4 ${styles.icon_info} ${includeVideo ? '' : `${styles.disabled_text}`}`}></i>
                                  </a>
                                </div>
                                {showInfoVideoTogether && (
                                  <div
                                    className={`col-5 ${styles.info_boxes_choice10}`}
                                  >
                                    <div
                                      className={`alert alert-info alert-dismissible fade show ${styles.alert_costumization}`}
                                      role="alert"
                                    >
                                      {pageTexts[172]}
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>)}

                          {methodChoiced === "choice7" && <div className="pt-4">
                            <div className="row mt-2 ms-2">
                              <div className="col-auto mt-3 form-check">
                                <input
                                  className={`form-check-input me-3 ${styles.option_check}`}
                                  type="radio"
                                  name="methodChoice"
                                  value="file"
                                  checked={audioType === "file"}
                                  onChange={(e) => setAudioType(e.target.value)}
                                />
                              </div>
                              <div className="col-6">
                                <div>
                                  {pageTexts[83]}
                                  <h6>{pageTexts[144]}</h6>
                                </div>
                              </div>
                              <div className="col-4">
                                <button className={` ${styles.btn_style_select}`}>{pageTexts[71]}</button>
                              </div>
                              <div className="col-auto">
                                <div
                                  className="col-auto d-flex"
                                  onMouseOver={() => setShowInfoAudio(true)}
                                  onMouseLeave={() => setShowInfoAudio(false)}
                                >
                                  <a>
                                    <i className={`bi bi-info-circle-fill fs-4 ${styles.icon_info}`}></i>
                                  </a>
                                </div>
                              </div>
                              {showInfoAudio && (
                                <div className="">
                                  <div className={`alert alert-info alert-dismissible fade show ${styles.alert_costumization}`} role="alert">
                                    {pageTexts[87]}
                                  </div>
                                </div>
                              )}
                            </div>
                            <div className="row mt-4 ms-2">
                              <div className="col-auto mt-2 form-check">
                                <input
                                  className={`form-check-input me-3 ${styles.option_check}`}
                                  type="radio"
                                  name="methodChoice"
                                  value="url"
                                  checked={audioType === "url"}
                                  onChange={(e) => setAudioType(e.target.value)}
                                />
                              </div>
                              <div className="col-10">
                                <div className="input-group">
                                  <span class="input-group-text" id="basic-addon1"><i class={`bi bi-spotify fs-5  ${styles.icon_projects}`}></i></span>
                                  <textarea
                                    className="form-control"
                                    type="text"
                                    name="url"
                                    placeholder="Insira um link para o Spotify"
                                    rows={1}
                                    value={userVideoUrl}
                                    onClick={() => setAudioType("url")}
                                    onChange={(e) => setUserAudioUrl(e.target.value)}
                                  />
                                </div>
                                <div>
                                  <h6 className="mt-1">{pageTexts[85]}</h6>
                                </div>
                              </div>
                              <div className="col-auto">
                                <div
                                  className="col-1  d-flex"
                                  onMouseOver={() => setShowInfoUrlAudio(true)}
                                  onMouseLeave={() => setShowInfoUrlAudio(false)}
                                >
                                  <a>
                                    <i className={`bi bi-info-circle-fill fs-4  ${styles.icon_info}`}></i>
                                  </a>
                                </div>
                              </div>
                              {showInfoUrlAudio && (
                                <div className="">
                                  <div className={`alert alert-info alert-dismissible fade show mt-2 ${styles.alert_costumization}`} role="alert">
                                    {pageTexts[88]}
                                  </div>
                                </div>
                              )}
                            </div>
                            <div className="row mb-4 align-items-center">
                              <div className="mt-4 ms-4 form-check col-6">
                                <input
                                  className={`form-check-input ${styles.option_check}`}
                                  type="checkbox"
                                  name="showTopics"
                                  id="flexRadioDefault1"
                                  value={includeContent}
                                  onChange={(e) => setIncludeContent(e.target.checked)}
                                />
                                <span className="ms-2">{pageTexts[79]}</span>
                              </div>
                              <div
                                className="col-1 d-flex mt-4"
                                onMouseOver={() => setShowInfoIncludeContent(true)}
                                onMouseLeave={() => setShowInfoIncludeContent(false)}
                              >
                                <a>
                                  <i className={`bi bi-info-circle-fill fs-4 ${styles.icon_info}`}></i>
                                </a>
                              </div>
                              {showInfoIncludeContent && (
                                <div className="">
                                  <div className={`alert alert-info alert-dismissible fade show ${styles.alert_costumization}`} role="alert">
                                    {pageTexts[89]}
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>}

                        </div>
                      </div>

                    </div>
                  </div>}

                  {/* CAROUSEL 3 */}
                  {showTopics && <div className="carousel-item">
                    <div className="mt-3">
                      {topics
                        ? <div className="">
                          <AssessmentTopics topics={topics} handleSetTopics={handleSetTopics} topicText={pageTexts[156]} />
                        </div>
                        : <div className={`row justify-content-center`}>
                          <div className="col-5 text-center">
                            <div className="col-12">
                              <h3>{pageTexts[90]}</h3>
                            </div>
                          </div>
                          <div className="ps-1 row d-flex justify-content-center mt-2">
                            <div className={`spinner-grow text-primary ${styles.spinner_customize}`} role="status"></div>
                          </div>
                        </div>
                      }
                    </div>
                  </div>}

                  {/* CAROUSEL 4 */}
                  {(generatingContent || generatingCase || generatingVideo) && <div className="carousel-item">
                    <div className="mt-3">
                      {contentGenerated || videoTranscript
                        ? <div className="">
                          <AssessmentContent contentGenerated={contentGenerated} videoTranscript={videoTranscript} language={language} handleReviewContentGenerated={handleReviewContentGenerated} contentText={pageTexts[149]} />
                        </div>
                        : <div className={`row justify-content-center`}>
                          <div className="col-5 text-center">
                            <div className="col-12">
                              {generatingContent && <h3>{pageTexts[145]}</h3>}
                              {generatingCase && <h3>{pageTexts[146]}</h3>}
                              {generatingVideo && <h3>{pageTexts[170]}</h3>}
                              <div>{pageTexts[147]}</div>
                            </div>
                          </div>
                          <div className="ps-1 row d-flex justify-content-center mt-2">
                            <div className={`spinner-grow text-primary ${styles.spinner_customize}`} role="status"></div>
                          </div>
                        </div>
                      }
                    </div>
                  </div>}

                  {/* CAROUSEL 5 */}
                  {AIHelp && <div className="carousel-item">
                    <div className={`mt-4`}>
                      <div>
                        <h4>
                          {pageTexts[93]}
                        </h4>
                      </div>
                      <div className={`mt-4`}>
                        <form>

                          <div className="row mt-4">
                            <h6 className="col-3 text-end">{pageTexts[94]} </h6>
                            <div className="col-9">
                              {questionQ}
                              <input
                                className={`ms-3 ${styles.range_customize}`}
                                type="range"
                                min="1"
                                max="20"
                                required
                                name="qtyQuestion"
                                value={questionQ}
                                onChange={(e) => changeQuestionQty(e.target.value)}
                              />
                            </div>
                          </div>
                          <p></p>

                          <div className="row d-flex align-items-center">
                            <h6 className="col-3 text-end">{pageTexts[95]} </h6>
                            <div className="col-auto">
                              <select
                                className={styles.form_parameters}
                                required
                                name="qtyChoice"
                                value={choiceQ}
                                onChange={(e) => changeChoicesQty(e.target.value)}>
                                <option value="">{pageTexts[96]}</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                              </select>
                            </div>
                          </div>

                          <p></p>
                          <div className="row d-flex align-items-center">
                            <h6 className="col-3 text-end">{pageTexts[97]} </h6>
                            <div className="col-3">
                              <input
                                className={`form-check-input ${styles.option_check}`}
                                type="checkbox"
                                id="flex-switch-disabled"
                                name="feedback"
                                checked={feedback}
                                onChange={(e) => changeFeedback(e.target.checked)}
                              />
                            </div>
                          </div>
                          <p></p>
                          <div className="row  d-flex align-items-center">
                            <h6 className="col-3 text-end">{pageTexts[98]} </h6>
                            <div className="col-auto">
                              <select
                                className={styles.form_parameters}
                                required
                                name="level"
                                value={level}
                                onChange={(e) => changeLevel(e.target.value)}>
                                <option value="easy">{pageTexts[100]}</option>
                                <option value="medium">{pageTexts[101]}</option>
                                <option value="difficult">{pageTexts[102]}</option>
                              </select>
                            </div>
                            <div
                              className="col-auto"
                              onMouseOver={() => setShowInfoLevel(true)}
                              onMouseLeave={() => setShowInfoLevel(false)}
                            >
                              <a>
                                <i className={`bi bi-info-circle-fill fs-4 ${styles.icon_info}`}></i>
                              </a>
                            </div>
                            {showInfoLevel && (
                              <div
                                className={`col-5 ${styles.info_boxes_choice4} ${styles.choice5_adjust}`}
                              >
                                <div
                                  className={`alert alert-info alert-dismissible fade show col-auto ${styles.alert_costumization}`}
                                  role="alert"
                                >
                                  {pageTexts[103]}
                                </div>
                              </div>
                            )}
                          </div>
                          <p></p>
                        </form>
                      </div>
                    </div>
                  </div>}

                  {/* CAROUSEL 6 */}
                  <div className="carousel-item">
                    {generateAssessment
                      ? (<div className="mt-3 ms-3" >
                        <SimpleChoiceQuestion
                          AIHelp={AIHelp}
                          actionDoc={AIHelp ? "update" : "insert"}
                          subject={subject ? subject : projectName}
                          idProject={idProject}
                          language={language}
                          projectName={projectName}
                          projectDescription={projectDescription}
                          projectFolder={projectFolder}
                          handleProjectIntro={handleProjectIntro}
                          projectIntro={projectIntro ? projectIntro : ""}
                          includeContent={includeContent}
                          includeSlides={includeSlides}
                          presentTogether={presentTogether}
                          projectUserInput={userInput ? userInput : null}
                          projectTemplate={"default"}
                          contentGenerated={contentReviewed}
                          assessmentObject={assessment}
                          hideCardFolderProject={hideCardFolderProject}
                          origem={true}
                          userEdit={!AIHelp}
                          userUrl={userUrl}
                          textPpt={textPpt}
                          pathToSlides={pathToSlides}
                          videoUrl={videoUrl}
                          includeVideo={includeVideo}
                          presentVideoTogether={presentVideoTogether}
                          handleChangeCarousel={handleChangeCarousel}
                          handleChangeAssessment={handleChangeAssessment}
                          handleProjectContent={handleProjectContent}
                          handleChangeShowSlides={handleChangeShowSlides}
                          handleChangeShowVideo={handleChangeShowVideo}
                        />
                      </div>)
                      : <div className="mt-3 ">
                        <div className={`row justify-content-center`}>
                          <div className="col-5 text-center">
                            <div className="col-12">
                              <h3>{msgText}</h3>
                              <div>{pageTexts[147]}</div>
                            </div>
                          </div>
                          <div className="ps-1 row d-flex justify-content-center mt-2">
                            <div className={`spinner-grow text-primary ${styles.spinner_customize}`} role="status"></div>
                          </div>
                        </div>
                      </div>}
                  </div>

                  {/* CAROUSEL 7 */}
                  <div className="carousel-item">
                    {assessment && <div className="mt-3 ms-3">
                      <ProjectTemplate
                        handleChangeTemplate={handleChangeTemplate}
                        projectTemplate={"default"}
                        templatesListPre={templatesList}
                        controlComponent={controlComponet}
                      />
                    </div>}
                  </div>

                  {/* CAROUSEL 8 */}
                  <div className="carousel-item">
                    {assessment && <div className="mt-3 ms-3">
                      <ProjectExport
                        subject={subject ? subject : projectName}
                        assessment={newAssemssment}
                        contentGenerated={newIncludeContent ? contentReviewed ? contentReviewed : userInput : null}
                        projectIntro={projectIntro}
                        nameTemplate={newProjectTemplate}
                        cssTemplate={cssTemplate}
                        projectName={projectName}
                        language={language}
                        pathToSlides={pathToSlides}
                        showTogether={presentTogether}
                        showSlides={includeSlides}
                        videoUrl={videoUrl}
                        showVideo={includeVideo}
                        showVideoTogether={presentVideoTogether}
                      />
                    </div>}
                  </div>
                </div>

                <div className="return d-none">
                  <button className={` ${styles.carousel_prev}`} type="button" data-bs-target="#carouselProject" data-bs-slide="prev">
                    <span className="prevButton btn btn-primary" aria-hidden="true">Retornar</span>
                    <span className="visually-hidden">Previous</span>
                  </button>
                </div>
                <div className="next d-none" >
                  <button className={`${styles.carousel_next}`} type="button" data-bs-target="#carouselProject" data-bs-slide="next">
                    <span className="nextButton btn btn-primary" aria-hidden="true">Avançar </span>
                    <span className="visually-hidden">Next</span>
                  </button>
                </div>

                {AIHelp
                  //Generating project with AI Help = buttons
                  ? (!uploadingFile && !generatingIA && actualSlide != steps - 2) &&
                  <div className='row justify-content-end'>
                    <button className={`${styles.btn_cancel} ${styles.carousel_buttons} `} onClick={() => cancelProject()}>{actualSlide < steps - 2 ? pageTexts[115] : pageTexts[157]}</button>
                    {(actualSlide != 1) && <button className={`${styles.btn_return} ${styles.carousel_buttons} `} onClick={() => handleReturnCarousel()}><i class="bi bi-chevron-left fw-bold"></i>  {pageTexts[49]}</button>}
                    {actualSlide === steps - 1 && <button className={`${styles.btn_style}  ${styles.carousel_buttons}`} onClick={() => handlePreviewHTML()} >{pageTexts[126]}</button>}
                    {actualSlide === steps - 3
                      ? <button className={`${styles.btn_style} ${styles.carousel_buttons} `}
                        onClick={
                          generatingCase
                            ? () => handleAskAssessmentCaseGPT()
                            : pptSubject
                              ? () => handleGeneratePpt()
                              : () => handleAskChatGPT()}>
                        {generatingCase || generatingContent ? pageTexts[139] : pageTexts[42]} <i class="bi bi-chevron-right fw-bold"></i></button>

                      : actualSlide != steps && <button className={`${styles.btn_style}  ${styles.carousel_buttons} `}
                        onClick={

                          actualSlide === 1 || actualSlide === 2
                            ? () => handleCheckContentFont(methodChoiced)
                            : actualSlide === 3 && showTopics && !contentGenerated && generatingContent
                              ? () => handleAskContentChatGPT()
                              : () => handleChangeCarousel()}>
                        {buttonLabel} <i class="bi bi-chevron-right fw-bold"></i></button>}
                  </div>

                  //Generating project without AI - buttons
                  : actualSlide != steps - 2 &&
                  <div className="row justify-content-end">
                    <button className={`${styles.btn_cancel} ${styles.carousel_buttons} `} onClick={() => cancelProject()}>{actualSlide < steps - 2 ? pageTexts[115] : pageTexts[157]}</button>
                    {(actualSlide != 1) && <button className={`${styles.btn_return} ${styles.carousel_buttons} `} onClick={() => handleReturnCarousel()}><i class="bi bi-chevron-left fw-bold"></i>  {pageTexts[49]}</button>}
                    {actualSlide === steps - 1 && <button className={`${styles.btn_style}  ${styles.carousel_buttons}`} onClick={() => handlePreviewHTML()}>{pageTexts[126]}</button>}
                    <button className={`${styles.btn_style}  ${styles.carousel_buttons} `}
                      onClick={
                        actualSlide === 1
                          ? () => handleCheckContentFont(methodChoiced)
                          : () => handleChangeCarousel()}>
                      {buttonLabel} <i class="bi bi-chevron-right fw-bold"></i></button>
                  </div>
                }
              </div>
            </div>}
        </div>}

      <div>
        {openDialog && <DialogBox
          isopen={true}
          questionDialog={msgDialog}
          optionsDialog={optionsDialog}
          handleClosDialogBox={handleClosDialogBox}
        />}
      </div>
    </div>

  );
}

export default ProjectSetup