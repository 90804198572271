import styles from './UserInfosUpdate.module.css'
import { useState, useEffect } from 'react';
import { useUpdateDocument } from '../hooks/useUpdateDocument';
import { useFetchDocument } from '../hooks/useFetchDocument';
import { useLanguageValue } from '../context/LanguageContext';
import useExcelToJson from '../hooks/useLoadExcelLanguage';
import DialogBox from './DialogBox';
//Translation
import i18n from '../i18n';
import { useTranslation } from 'react-i18next';

const UserInfosUpdate = ({ userId, isActive }) => {
    const [userName, setUserName] = useState("");
    const [email, setEmail] = useState("");
    const [company, setCompany] = useState("");
    const [jobTitle, setJobTitle] = useState("");
    const [error, setError] = useState("");
    const [openDialog, setOpenDialog] = useState(false);
    const [message, setMessage] = useState("Informações atualizadas.");
    const [loading, setLoading] = useState(true);
    const [pageTexts, setPageTexts] = useState("");
    const [userLanguage, setUserLanguage] = useState();

    const { t } = useTranslation(['myaccount']);
    const { preferenceLanguage } = useLanguageValue();
    const sheetName = 'account';
    const jsonData = useExcelToJson(sheetName);

    const { updateDocument, responseUpdate } = useUpdateDocument("Users", userId)
    const { document: userInfo, loadingUser, errorUser } = useFetchDocument("Users", userId);

    const handleClosDialogBox = () => {
        setOpenDialog(false);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        setError("");

        if (!userName) {
            setError(`Você deve indicar seu nome.`);
            return
        }

        const userInfos = {
            domain: userId,
            name: userName,
            company: company,
            job_title: jobTitle,
        }
        const resDoc = updateDocument(userInfos);
        setOpenDialog(true)
    }

    const checkUser = () => {
        setUserName(userInfo.name);
        setEmail(userInfo.email);
        setCompany(userInfo.company);
        setJobTitle(userInfo.job_title);

    }

    useEffect(() => {
        userInfo && checkUser();
        userInfo && setLoading(loadingUser);
    }, [userInfo])

    useEffect(() => {
        if (jsonData && preferenceLanguage) {
            const newPageTexts = jsonData.map((data) => data[preferenceLanguage]);
            setPageTexts(newPageTexts);
        }
    }, [jsonData, preferenceLanguage])

    useEffect(() => {
        if (preferenceLanguage) {
            var language = "en";
            switch (preferenceLanguage) {
                case "Português":
                    language = "pt";
                    break;
                case "Espanhol":
                    language = "es";
                    break;
                case "Inglês":
                    language = "en";
                    break;
                default:
                    language = "en";
            }
            changeLanguage(language);
            i18n.changeLanguage(language);
        }
    }, [preferenceLanguage])

    const changeLanguage = (language) => {
        localStorage.setItem('preferenceLanguage', language);
        i18n.changeLanguage(language);
    };

    return (
        <div >
            {loading
                ? <div class="d-flex justify-content-center mt-5">
                    <div class="align-self-center">
                        <div class="spinner-border text-primary" role="status">
                            <span class="visually-hidden">{`Carregando...`}</span>
                        </div>
                    </div>
                </div>

                : <div className={`col-12`}>
                    <form onSubmit={handleSubmit}>
                        <label className={`row me-2`}>
                            <div>{`Nome:`}</div>
                            <input
                                className={`${styles.form_field}`}
                                type="text"
                                name="displayName"
                                required
                                placeholder={t('myaccount:userinfos.Insira o seu nome')}
                                value={userName}
                                onChange={(e) => setUserName(e.target.value)} />
                        </label>
                        <p></p>
                        <label className={`row me-2`}>
                            <div>E-mail:</div>
                            <input
                                className={`${styles.form_field}`}
                                type="email"
                                name="email"
                                placeholder={t('myaccount:userinfos.Insira a seu e-mail')}
                                value={email}
                                disabled />
                        </label>
                        <p></p>
                        <label className={`row me-2`}>
                            <div>{`Empresa:`}</div>
                            <input
                                className={`${styles.form_field}`}
                                type="text"
                                name="company"
                                placeholder={t('myaccount:userinfos.Insira a sua empresa')}
                                value={company}
                                onChange={(e) => setCompany(e.target.value)} />
                        </label>
                        <p></p>
                        <label className={`row me-2`}>
                            <div>{`Cargo:`}</div>
                            <input
                                className={`${styles.form_field}`}
                                type="text"
                                name="jobTitle"
                                placeholder={t('myaccount:userinfos.Insira o seu cargo')}
                                value={jobTitle}
                                onChange={(e) => setJobTitle(e.target.value)} />
                        </label>
                        <p></p>
                        <p></p>
                        <div className={`row d-flex  me-2 justify-content-end`}>
                            <button className={`${styles.btn_style}`} disabled={!isActive}>{t('myaccount:userinfos.Atualizar')}</button>
                        </div>
                        {error && <p className="error">{error}</p>}
                    </form>
                    {openDialog && <DialogBox
                        isopen={true}
                        questionDialog={message}
                        optionsDialog={["Ok"]}
                        actionScript={handleClosDialogBox}
                        action={""}
                    />}
                </div>}
        </div>
    )
}

export default UserInfosUpdate