import React, { useEffect, useRef, useState } from 'react';
import { useForm, ValidationError } from '@formspree/react';
import useExcelToJson from '../../hooks/useLoadExcelLanguage';
import { useNavigate } from 'react-router-dom';
import { useAuthValue } from '../../context/AuthContext';
import { useLanguageValue } from '../../context/LanguageContext';
//Translation
import i18n from '../../i18n';
import { useTranslation } from 'react-i18next';
import styles from './Faq.module.css';


const Faq = ({ userSetLanguage }) => {
    const fadeInElements = useRef([]);
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const [state, handleSubmit] = useForm("mnqkgzdz");
    const [formClass, setFormClass] = useState("");

    /* --- INICIO LINGUAGEM --- */
    const { t } = useTranslation(['faq']);
    const { user } = useAuthValue();
    const { preferenceLanguage } = useLanguageValue();
    const [preferenceBrowserLanguage, setPreferenceBrowserLanguage] = useState();
    const [pageTexts, setPageTexts] = useState([]);

    const sheetName = 'FAQ';
    const jsonData = useExcelToJson(sheetName);

    const navigate = useNavigate();

    const [userLanguage, setUserLanguage] = useState(userSetLanguage ? userSetLanguage : navigator.language);
    //const userLanguage = "pt"

    const goRegister = () => {
        window.scrollTo(0, 0);
        navigate("/register")
    }

    useEffect(() => {
        if (userSetLanguage) {
            setUserLanguage(userSetLanguage)
        }
    }, [userSetLanguage])

    useEffect(() => {
        switch (userLanguage) {
            case "pt-BR":
            case "pt":
                setPreferenceBrowserLanguage("Português");
                break;
            case "en-US":
            case "en":
                setPreferenceBrowserLanguage("Inglês");
                break;
            case "es-ES":
            case "es":
                setPreferenceBrowserLanguage("Espanhol");
                break;
            default:
                setPreferenceBrowserLanguage("Inglês");
                break;
        }
    }, [userLanguage]);

    useEffect(() => {
        if (jsonData && preferenceLanguage && preferenceBrowserLanguage) {
            const newPageTexts = jsonData.map((data) => data[user ? preferenceLanguage : preferenceBrowserLanguage]);
            setPageTexts(newPageTexts);
        }
    }, [jsonData, preferenceLanguage, preferenceBrowserLanguage])



    /* --- FIM LINGUAGEM --- */


    /* --- INICIO ANIMA ELEMENTOS --- */
    useEffect(() => {
        const options = {
            root: null,
            rootMargin: '0px',
            threshold: 0.2,
        };

        const observer = new IntersectionObserver((entries, observer) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add(styles.fadeIn);
                    observer.unobserve(entry.target);
                }
            });
        }, options);

        fadeInElements.current.forEach(element => {
            observer.observe(element);
        });

        return () => {
            observer.disconnect();
        };
    }, []);
    /* --- FIM ANIMA ELEMENTOS --- */
    /* --- INICIO --- adicionar para retirar o padding do container parent da página */
    useEffect(() => {
        const capsula = document.querySelector('.container');
        if (capsula) {
            capsula.classList.add('p-0');
        }
    }, []);
    /* --- FIM --- adicionar para retirar o padding do container parent da página */
    /* --- INICIO INTERACAO FORMULÁRIO --- */
    const handleFormSubmit = async (event) => {
        event.preventDefault();

        try {
            await handleSubmit(event);
            setIsFormSubmitted(true);
            setFormClass("p-0");
        } catch (error) {
            console.error(error);
        }
    };
    /* --- FIM INTERACAO FORMULÁRIO --- */
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    useEffect(() => {
        if (preferenceBrowserLanguage) {
            var language = "en";
            switch (preferenceBrowserLanguage) {
                case "Português":
                    language = "pt";
                    break;
                case "Espanhol":
                    language = "es";
                    break;
                case "Inglês":
                    language = "en";
                    break;
                default:
                    language = "en";
            }
            changeLanguage(language);
            i18n.changeLanguage(language);
        }
    }, [preferenceBrowserLanguage])

    useEffect(() => {
        user && preferenceLanguage && setPreferenceBrowserLanguage(preferenceLanguage)
    }, [preferenceLanguage, user])

    const changeLanguage = (language) => {
        localStorage.setItem('preferenceLanguage', language);
        i18n.changeLanguage(language);
    };

    return (
        <div>
            <section id="faq" className={`${styles.call_to_action} ${styles.hideFadeIn} ${styles.secao}`} ref={el => fadeInElements.current.push(el)}>
                <div className={`${styles.overlay}`}></div>
                <div className={`container text-center ${styles.text_md_left}`}>
                    <div className={`row`}>
                        <div className={`col-sm-12 col-md-6 ${styles.cabecalho}`}>
                            <h1>{t('faq:home.Precisa de ajuda')}</h1>
                            <p>{t('faq:home.Explore abaixo nossa seção de perguntas frequentes ou envie-nos uma mensagem')}</p>
                            <h2>{t('faq:home.FAQ (Perguntas Frequentes)')}</h2>
                        </div>
                    </div>
                </div>
            </section>

            <div className={`${styles.zap}`}>
                <div className={`${styles.area} mt-5 text-center`}>
                    <div className={`row align-items-center`}>
                        <div className={`col-12 col-md-5 offset-md-7 text-left`}>
                            <div className={`${styles.message} ${styles.pergunta} ${styles.hideFadeIn}`} ref={el => fadeInElements.current.push(el)}>
                                <div className={`${styles.message_content}`}>
                                    {t('faq:home.O que é QuizAI')}
                                </div>
                            </div>
                        </div>
                        <div className={`col-12 col-md-8 text-left`}>
                            <div className={`${styles.message} ${styles.resposta} ${styles.hideFadeIn}`} ref={el => fadeInElements.current.push(el)}>
                                <div className={`${styles.message_content}`}>
                                    {t('faq:home.É uma ferramenta de autoria que utiliza inteligência artificial para criar perguntas, avaliações e questionários')}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={`${styles.area} mt-5 text-center`}>
                    <div className={`row align-items-center`}>
                        <div className={`col-12 col-md-5 offset-md-7  text-left`}>
                            <div className={`${styles.message} ${styles.pergunta} ${styles.hideFadeIn}`} ref={el => fadeInElements.current.push(el)}>
                                <div className={`${styles.message_content}`}>
                                    {t('faq:home.O QuizAI usa o ChatGPT')}
                                </div>
                            </div>
                        </div>
                        <div className={`col-12 col-md-8 text-left`}>
                            <div className={`${styles.message} ${styles.resposta} ${styles.hideFadeIn}`} ref={el => fadeInElements.current.push(el)}>
                                <div className={`${styles.message_content}`}>
                                    {t('faq:home.Sim, funciona integrado com ChatGPT 3.5 (plano Basic) e ChatGPT 4 (plano Pro)')}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={`${styles.area} mt-5`}>
                    <div className={`row align-items-center`}>
                        <div className={`col-12 col-md-5 offset-md-7 text-left`}>
                            <div className={`${styles.message} ${styles.pergunta} ${styles.hideFadeIn}`} ref={el => fadeInElements.current.push(el)}>
                                <div className={`${styles.message_content}`}>
                                    {t('faq:home.Posso testar o QuizAI antes de assinar')}
                                </div>
                            </div>
                        </div>
                        <div className={`col-12 col-md-8 text-left`}>
                            <div className={`${styles.message} ${styles.resposta} ${styles.hideFadeIn}`} ref={el => fadeInElements.current.push(el)}>
                                <div className={`${styles.message_content}`}>
                                    {t('faq:home.Sim, você pode se inscrever para experimentar o plano Pro gratuitamente (período de teste de 15 dias). Não é necessário cartão de crédito')}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={`${styles.area} mt-5`}>
                    <div className={`row align-items-center`}>
                        <div className={`col-12 col-md-5 offset-md-7 text-left`}>
                            <div className={`${styles.message} ${styles.pergunta} ${styles.hideFadeIn}`} ref={el => fadeInElements.current.push(el)}>
                                <div className={`${styles.message_content}`}>
                                    {t('faq:home.Preciso assinar um contrato de longo prazo')}
                                </div>
                            </div>
                        </div>
                        <div className={`col-12 col-md-8 text-left`}>
                            <div className={`${styles.message} ${styles.resposta} ${styles.hideFadeIn}`} ref={el => fadeInElements.current.push(el)}>
                                <div className={`${styles.message_content}`}>
                                    {t('faq:home.Não, se você estiver em um plano de pagamento mensal, poderá cancelar e interromper sua assinatura a qualquer momento. Você também pode escolher a opção de cobrança anual em vez da cobrança mensal, se preferir. Você pode encontrar mais informações importantes na seção Termos de Serviço')}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={`${styles.area} mt-5`}>
                    <div className={`row align-items-center`}>
                        <div className={`col-12 col-md-5 offset-md-7 text-left`}>
                            <div className={`${styles.message} ${styles.pergunta} ${styles.hideFadeIn}`} ref={el => fadeInElements.current.push(el)}>
                                <div className={`${styles.message_content}`}>
                                    {t('faq:home.Posso alterar meu plano de assinatura a qualquer momento')}
                                </div>
                            </div>
                        </div>
                        <div className={`col-12 col-md-8 text-left`}>
                            <div className={`${styles.message} ${styles.resposta} ${styles.hideFadeIn}`} ref={el => fadeInElements.current.push(el)}>
                                <div className={`${styles.message_content}`}>
                                    {t('faq:home.Sim, você pode fazer isso a qualquer momento no painel do usuário')}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={`${styles.area} mt-5`}>
                    <div className={`row align-items-center`}>
                        <div className={`col-12 col-md-5 offset-md-7 text-left`}>
                            <div className={`${styles.message} ${styles.pergunta} ${styles.hideFadeIn}`} ref={el => fadeInElements.current.push(el)}>
                                <div className={`${styles.message_content}`}>
                                    {t('faq:home.Existe um plano gratuito do QuizAI')}
                                </div>
                            </div>
                        </div>
                        <div className={`col-12 col-md-8 text-left`}>
                            <div className={`${styles.message} ${styles.resposta} ${styles.hideFadeIn}`} ref={el => fadeInElements.current.push(el)}>
                                <div className={`${styles.message_content}`}>
                                    {t('faq:home.Sim, o plano Básico! É uma versão simples do QuizAI com recursos básicos (não inclui todos os recursos do plano Pro) e um limite de 3 projetos ativos por mês. Saiba mais sobre todos os planos disponíveis em nossa seção de preços')}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={`${styles.area} mt-5`}>
                    <div className={`row align-items-center`}>
                        <div className={`col-12 col-md-5 offset-md-7 text-left`}>
                            <div className={`${styles.message} ${styles.pergunta} ${styles.hideFadeIn}`} ref={el => fadeInElements.current.push(el)}>
                                <div className={`${styles.message_content}`}>
                                    {t('faq:home.Quais idiomas o QuizAI suporta')}
                                </div>
                            </div>
                        </div>
                        <div className={`col-12 col-md-8 text-left`}>
                            <div className={`${styles.message} ${styles.resposta} ${styles.hideFadeIn}`} ref={el => fadeInElements.current.push(el)}>
                                <div className={`${styles.message_content}`}>
                                    {t('faq:home.Neste momento você pode gerar perguntas e questionários em 10 idiomas: inglês, espanhol, italiano, português, francês, alemão, russo, coreano, chinês e japonês. O painel de gerenciamento e operação do QuizAI está disponível em inglês, espanhol e português')}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={`${styles.area} mt-5`}>
                    <div className={`row align-items-center`}>
                        <div className={`col-12 col-md-5 offset-md-7 text-left`}>
                            <div className={`${styles.message} ${styles.pergunta} ${styles.hideFadeIn}`} ref={el => fadeInElements.current.push(el)}>
                                <div className={`${styles.message_content}`}>
                                    {t('faq:home.Qual versão do SCORM está disponível para exportar meus quizzes')}
                                </div>
                            </div>
                        </div>
                        <div className={`col-12 col-md-8 text-left`}>
                            <div className={`${styles.message} ${styles.resposta} ${styles.hideFadeIn}`} ref={el => fadeInElements.current.push(el)}>
                                <div className={`${styles.message_content}`}>
                                    {t('faq:home.Você pode exportar e baixar seus quizzes ou avaliações como SCORM 1.2 ou 2004. Saiba mais sobre todos os formatos de exportação disponíveis em nossa seção de funcionalidades')}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <section className={`row align-items-center ${styles.botoesChamadas}  ${styles.hideFadeIn} ${styles.secao}`} ref={el => fadeInElements.current.push(el)}>
                <div className={`col-12 text-center`}>
                    <h2>{t('faq:home.Você está pronto(a) para começar a usar o QuizAI')}</h2>
                </div>
                <div className={`col-12 text-center ${styles.botoes} `}>
                    <a href="https://quizzai-4b3cd.web.app/Register" target="_blank" className={`mb-3 mb-md-0 me-md-3 ${styles.btn}  ${styles.btn_primary}`}>{t('faq:home.Comece agora')}</a>
                </div>
            </section>

            <section className={`row align-items-center ${styles.formulario}  ${styles.hideFadeIn} ${styles.secao}`} ref={el => fadeInElements.current.push(el)}>
                <div className={`${styles.area}`}>
                    <div className={`col-12 text-center`}>
                        {isFormSubmitted ? (
                            <h2>{t('faq:home.Se tiver outras dúvidas, por favor preencha o seguinte formulário')}</h2>
                        ) : (
                            <h2>{t('faq:home.Se tiver outras dúvidas, por favor preencha o seguinte formulário')}</h2>
                        )}
                    </div>
                    <form action="https://formspree.io/f/mnqkgzdz" method="POST" id="contact-form" className={`${styles.text_md_left}`} onSubmit={handleFormSubmit}>
                        {isFormSubmitted ? (
                            <p></p>
                        ) : (
                            <>
                                <div className={`form-group`}>
                                    <label htmlFor="firstName">{t('faq:home.Primeiro Nome')}</label>
                                    <input type="text" className={`form-control`} id="firstName" name="firstName" required />
                                </div>
                                <div className={`form-group`}>
                                    <label htmlFor="lastName">{t('faq:home.Sobrenome')}</label>
                                    <input type="text" className={`form-control`} id="lastName" name="lastName" required />
                                </div>
                                <div className={`form-group`}>
                                    <label htmlFor="email">{t('faq:home.Email')}</label>
                                    <input type="email" className={`form-control`} id="email" name="email" required />
                                    <ValidationError prefix="Email" field="email" errors={state.errors} />
                                </div>
                                <div className={`form-group`}>
                                    <label htmlFor="message">{t('faq:home.Sua mensagem')}</label>
                                    <textarea className={`form-control`} id="message" name="message" rows="3" maxLength="1800" required></textarea>
                                    <ValidationError prefix="Message" field="message" errors={state.errors} />
                                </div>
                                <div className={`form-group text-center mt-4`}>
                                    <button type="submit" className={`text-center ${styles.btn}  ${styles.btn_primary} ${styles.btn_enviar}`} disabled={state.submitting}>
                                        {t('faq:home.Enviar Mensagem')}
                                    </button>
                                </div>
                            </>
                        )}
                    </form>
                </div>
            </section>
        </div>
    )
}

export default Faq;