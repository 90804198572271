import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, GoogleAuthProvider, signInWithPopup, updateProfile, signOut } from 'firebase/auth';
import { useState, useEffect } from 'react';
import useExcelToJson from '../hooks/useLoadExcelLanguage';

export const useAuthentication = (userSetLanguage) => {
    const [error, setError] = useState();
    const [loading, setLoading] = useState();
    const [cancelled, setCancelled] = useState();
    const [preferenceBrowserLanguage, setPreferenceBrowserLanguage] = useState("");
    const [pageTexts, setPageTexts] = useState([]);

    const [userLanguage, setUserLanguage] = useState(userSetLanguage ? userSetLanguage : navigator.language);

    const auth = getAuth();
    const googleAuthProvider = new GoogleAuthProvider();

    const sheetName = 'auth';
    const jsonData = useExcelToJson(sheetName);

    function checkIfIsCancelled() {
        if (cancelled) {
            return;
        }
    }

    const signInWithGoogle = async () => {
        checkIfIsCancelled();

        setLoading(true);
        setError(null);

        try {
            const result = await signInWithPopup(auth, googleAuthProvider);
            const user = result.user;
            setLoading(false);

            await updateProfile(user, {
                displayName: user.displayName || 'Nome de usuário padrão',
            });
        } catch (error) {
            let systemErrorMessage;
            if (error.message.includes("Password")) {
                systemErrorMessage = pageTexts[2];
            } else if (error.message.includes("email-already")) {
                systemErrorMessage = pageTexts[3];
            } else {
                systemErrorMessage = pageTexts[4];
            }
            setError(systemErrorMessage);
            setLoading(false);
        }
    };

    const loginWithGoogle = async () => {
        checkIfIsCancelled();

        setLoading(true);
        setError(null);

        try {
            const provider = new GoogleAuthProvider();
            const result = await signInWithPopup(auth, provider);
            setLoading(false);
        } catch (error) {
            let systemErrorMessage;

            if (error.message.includes("user-not-found")) {
                systemErrorMessage = pageTexts[0];
            } else if (error.message.includes("wrong-password")) {
                systemErrorMessage = pageTexts[1];
            } else {
                systemErrorMessage = pageTexts[5];
            }

            setLoading(false);
            setError(systemErrorMessage);
        }
    }

    const createUser = async (data) => {

        checkIfIsCancelled();

        setLoading(true);
        setError(null);

        try {
            const { user } = await createUserWithEmailAndPassword(
                auth,
                data.email,
                data.password,
            )
            await updateProfile(user, {
                displayName: data.displayName
            });
            setLoading(false);
        } catch (error) {
            let systemErrorMessage;
            if (error.message.includes("Password")) {
                systemErrorMessage = pageTexts[2];
            } else if (error.message.includes("email-already")) {
                systemErrorMessage = pageTexts[3];
            } else {
                systemErrorMessage = pageTexts[4];
            }
            setError(systemErrorMessage);
            setLoading(false);
        }
    };

    //logout
    const logout = () => {
        checkIfIsCancelled();
        signOut(auth);
    }

    //login
    const login = async (data) => {
        checkIfIsCancelled();

        setLoading(true);
        setError("");

        try {
            await signInWithEmailAndPassword(auth, data.email, data.password);
            setLoading(false);
        } catch (error) {
            let systemErrorMessage;

            if (error.message.includes("user-not-found")) {
                systemErrorMessage = pageTexts[0];
            } else if (error.message.includes("wrong-password")) {
                systemErrorMessage = pageTexts[1];
            } else {
                systemErrorMessage = pageTexts[5];
            }

            setLoading(false);
            setError(systemErrorMessage);
        }
    }

    useEffect(() => {
        if (userSetLanguage) {
            setUserLanguage(userSetLanguage)
        }
    }, [userSetLanguage])

    useEffect(() => {
        switch (userLanguage) {
            case "pt-BR":
            case "pt":
                setPreferenceBrowserLanguage("Português");
                break;
            case "en-US":
            case "en":
                setPreferenceBrowserLanguage("Inglês");
                break;
            case "es-ES":
            case "es":
                setPreferenceBrowserLanguage("Espanhol");
                break;
            default:
                setPreferenceBrowserLanguage("Inglês");
                break;
        }
    }, [userLanguage]);

    useEffect(() => {
        if (jsonData && preferenceBrowserLanguage) {
            const newPageTexts = jsonData.map((data) => data[preferenceBrowserLanguage]);
            setPageTexts(newPageTexts);
        }
    }, [jsonData, preferenceBrowserLanguage])

    useEffect(() => {
        return () => setCancelled(true);
    }, []);

    return {
        auth,
        createUser,
        error,
        loading,
        logout,
        login,
        signInWithGoogle,
        loginWithGoogle
    }

};