//Styles
import 'bootstrap/dist/css/bootstrap.min.css';
import { Accordion, Card, Button } from 'react-bootstrap';
import styles from './AssessmentCase.module.css';
import defaultStyles from '../templates/default.module.css';
import aquaStyles from '../templates/aqua.module.css';
import blueStyles from '../templates/blue.module.css';
import cleanDarkStyles from '../templates/cleanDark.module.css';
import cleanLightStyles from '../templates/cleanLight.module.css';
import cleanNeutralStyles from '../templates/cleanNeutral.module.css';
import coralStyles from '../templates/coral.module.css';
import freshStyles from '../templates/fresh.module.css';
import greenStyles from '../templates/green.module.css';
import pastelStyles from '../templates/pastel.module.css';
import purpleRainStyles from '../templates/purpleRain.module.css';
import quizAIStyles from '../templates/quizai.module.css';
//React Hooks
import { useState, useEffect } from 'react';

const AssessmentContent = ({ contentGenerated, editProject, template, language, handleReviewContentGenerated, handleChangeContent, infoText, changeProjectName }) => {

    const [contentReview, setContentReview] = useState();
    const [chooseTemplate, setChooseTemplate] = useState(template);
    const [contentText, setContentText] = useState(null)
    const [textTitle, setTextTitle] = useState("");
    const [textIntro, setTextIntro] = useState("");
    const [topic1Title, setTopic1Title] = useState("");
    const [topic1Text, setTopic1Text] = useState("");
    const [topic2Title, setTopic2Title] = useState("");
    const [topic2Text, setTopic2Text] = useState("");
    const [topic3Title, setTopic3Title] = useState("");
    const [topic3Text, setTopic3Text] = useState("");
    const [topic4Title, setTopic4Title] = useState("");
    const [topic4Text, setTopic4Text] = useState("");
    const [topic5Title, setTopic5Title] = useState("");
    const [topic5Text, setTopic5Text] = useState("");
    const [textConclusion, setTextConclusion] = useState("");

    let wichLanguage = 0;
    let review;

    const saveReviewedContent = () => {
        handleChangeContent(true)
        review = formatarObjetoString(contentText)
        setContentReview(review)
        const jsonContentReviwed = contentText
        handleReviewContentGenerated(review, jsonContentReviwed)
    }

    const formatarObjetoString = (objeto) => {
        const words = [
            ["Título:", "Introdução:", "Descrição", "Contexto", "Desafio/Problema", "Análise do Problema", "Solução Proposta", "Implementação da Solução", "Resultados e Impactos", "Lições Aprendidas", "Conclusão"],
            ["Title:", "Introduction:", "Description", "Context", "Challenge/Problem", "Problem Analysis", "Proposed Solution", "Solution Implementation", "Results and Impacts", " Lessons Learned", "Conclusion"],
            ["Título:", "Introducción:", "Descripción", "Contexto", "Desafío/Problema", "Análisis del Problema", "Solución Propuesta", "Implementación de la Solución", "Resultados e Impactos", "Lecciones Aprendidas", "Conclusión"],
            ["Titel:", "Einleitung:", "Beschreibung", "Kontext", "Herausforderung/Problem", "Problemanalyse", "Vorgeschlagene Lösung", " Umsetzung der Lösung", " Ergebnisse und Auswirkungen", "Erfahrungswerte", "Schlussfolgerung"],
            ["Titre:", "Introduction:", "Description", "Contexte", "Défi/Problème", "Analyse du Problème", "Solution Proposée", "Mise en œuvre de la Solution", "Résultats et Impacts", "Leçons Apprises", "Conclusion"],
            ["Titolo:", "Introduzione:", "Descrizione", "Contesto", "Sfida/Problema", "Analisi del Problema", "Soluzione Proposta", "Implementazione della Soluzione", "Risultati e Impatti", "Lezioni Apprese", "Conclusione"],
            ["Заголовок:", "Введение:", "Описание", "Контекст", "Вызов/Проблема", "Анализ Проблемы", "Предлагаемое Решение", "Внедрение Решения", "Результаты и Влияние", "Извлеченные Уроки", "Заключение"],
            ["标题:", "介绍:", "描述", "上下文", "挑战/问题", "问题分析", "提议的解决方案", "解决方案的实施", "结果和影响", "启示与教训", "结论"],
            ["タイトル:", "紹介:", "説明", "コンテキスト", "チャレンジ/問題", "問題分析", "提案された解決策", "解決策の実施", "結果と影響", "得られた教訓", "結論"],
            ["제목:", "소개:", "설명", "문맥", "도전/문제", "문제 분석", "제안된 해결책", "해결책의 구현", "결과 및 영향", "배운 교훈", "결론"]
        ];

        let objetoString = `${words[wichLanguage][0]} ${objeto.title}\n`;
        objetoString += `${words[wichLanguage][1]} ${objeto.intro}\n`;

        if (objeto.topics) {
            objeto.topics.forEach((topic, index) => {
                objetoString += `${topic.subtitle}:`;
                objetoString += `${topic.content}\n`;
            });
        }

        if (objeto.company_description) {
            objetoString += `${words[wichLanguage][2]}: `;
            objetoString += `${objeto.company_description}\n`;
            objetoString += `${words[wichLanguage][3]}: `;
            objetoString += `${objeto.problem_context}\n`;
            objetoString += `${words[wichLanguage][4]}: `;
            objetoString += `${objeto.problem_or_challenge_description}\n`;
            objetoString += `${words[wichLanguage][5]}: `;
            objetoString += `${objeto.problem_analysis}\n`;
            objetoString += `${words[wichLanguage][6]}: `;
            objetoString += `${objeto.proposed_solution}\n`;
            objetoString += `${words[wichLanguage][7]}: `;
            objetoString += `${objeto.solution_implementation}\n`;
            objetoString += `${words[wichLanguage][8]}: `;
            objetoString += `${objeto.results_and_impacts}\n`;
            objetoString += `${words[wichLanguage][9]}: `;
            objetoString += `${objeto.lessons_learned}\n`;
        }

        objetoString += `${words[wichLanguage][10]}: `;
        objetoString += `${objeto.conclusion}`;

        return objetoString;
    };

    const parseContent = (content) => {
        const sections = content.split('\n');

        const title = sections[0].split(': ')[1];
        const intro = sections[1].split(': ')[1];

        const topics = [];
        for (let i = 2; i < sections.length - 1; i++) {
            const [subtitle, content] = sections[i].split(':');
            topics.push({
                subtitle: subtitle.trim(),
                content: content.trim(),
            });
        }

        const conclusion = sections[sections.length - 1].split(': ')[1];

        const parsedContent = {
            title,
            intro,
            topics,
            conclusion,
        };

        return parsedContent;
    };

    useEffect(() => {
        switch (language) {
            case "Portuguese":
                wichLanguage = 0;
                break;
            case "English":
                wichLanguage = 1;
                break;
            case "Spanish":
                wichLanguage = 2;
                break;
            case "German":
                wichLanguage = 3;
                break;
            case "French":
                wichLanguage = 4;
                break;
            case "Italian":
                wichLanguage = 5;
                break;
            case "Russian":
                wichLanguage = 6;
                break;
            case "Mandarin":
                wichLanguage = 7;
                break;
            case "Japanese":
                wichLanguage = 8;
                break;
            case "Korean":
                wichLanguage = 9;
                break;
            default:
                wichLanguage = 0;
                break;
        }
    }, [language])

    useEffect(() => {
        setTextTitle(contentGenerated.title)
        setTextIntro(contentGenerated.intro)
        setTopic1Title(contentGenerated.topics[0].subtitle)
        setTopic1Text(contentGenerated.topics[0].content)
        setTopic2Title(contentGenerated.topics[1].subtitle)
        setTopic2Text(contentGenerated.topics[1].content)
        setTopic3Title(contentGenerated.topics[2].subtitle)
        setTopic3Text(contentGenerated.topics[2].content)
        setTopic4Title(contentGenerated.topics[3].subtitle)
        setTopic4Text(contentGenerated.topics[3].content)
        setTopic5Title(contentGenerated.topics[4].subtitle)
        setTopic5Text(contentGenerated.topics[4].content)
        setTextConclusion(contentGenerated.conclusion)
    }, [contentGenerated])

    useEffect(() => {
        if (textTitle, textIntro, topic1Title, topic1Text, topic2Title, topic2Text, topic3Title, topic3Text, topic4Title, topic4Text, topic5Title, topic5Text, textConclusion) {
            const rev = {
                title: textTitle,
                intro: textIntro,
                topics: [
                    {
                        subtitle: topic1Title,
                        content: topic1Text,
                    },
                    {
                        subtitle: topic2Title,
                        content: topic2Text,
                    },
                    {
                        subtitle: topic3Title,
                        content: topic3Text,
                    },
                    {
                        subtitle: topic4Title,
                        content: topic4Text,
                    },
                    {
                        subtitle: topic5Title,
                        content: topic5Text,
                    },
                ],
                conclusion: textConclusion,
            }
            setContentText(rev)
        }
    }, [textTitle, textIntro, topic1Title, topic1Text, topic2Title, topic2Text, topic3Title, topic3Text, topic4Title, topic4Text, topic5Title, topic5Text, textConclusion])

    useEffect(() => {
        if (contentText && contentText != contentGenerated) {
            saveReviewedContent();
        }
    }, [contentText])

    useEffect(() => {
        if (template) {
            setChooseTemplate(template);
        }
    }, [template])

    useEffect(() => {
        textTitle && changeProjectName(textTitle)
    }, [textTitle])

    return (
        <div>
            <div className='container'>
                <div className="d-flex align-items-start">
                    <div className={`nav flex-column nav-pills me-3 ${chooseTemplate.navBar_menu}`} id="v-pills-tab" role="tablist" aria-orientation="vertical">
                        <button className={`nav-link active rounded-0 ${chooseTemplate.navBar_text_menu}`} id="v-pills-intro-tab" data-bs-toggle="pill" data-bs-target="#v-pills-intro" type="button" role="tab" aria-controls="v-pills-intro" aria-selected="true">Introdução</button>

                        <button className={`nav-link rounded-0 ${chooseTemplate.navBar_text_menu}`} id="v-pills-topic1-tab" data-bs-toggle="pill" data-bs-target="#v-pills-topic1" type="button" role="tab" aria-controls="v-pills-topic1" aria-selected="false">{topic1Title}</button>

                        <button className={`nav-link rounded-0 ${chooseTemplate.navBar_text_menu}`} id="v-pills-topic2-tab" data-bs-toggle="pill" data-bs-target="#v-pills-topic2" type="button" role="tab" aria-controls="v-pills-topic2" aria-selected="false">{topic2Title}</button>

                        <button className={`nav-link rounded-0 ${chooseTemplate.navBar_text_menu}`} id="v-pills-topic3-tab" data-bs-toggle="pill" data-bs-target="#v-pills-topic3" type="button" role="tab" aria-controls="v-pills-topic3" aria-selected="false">{topic3Title}</button>

                        <button className={`nav-link rounded-0 ${chooseTemplate.navBar_text_menu}`} id="v-pills-topic4-tab" data-bs-toggle="pill" data-bs-target="#v-pills-topic4" type="button" role="tab" aria-controls="v-pills-topic4" aria-selected="false">{topic4Title}</button>

                        <button className={`nav-link rounded-0 ${chooseTemplate.navBar_text_menu}`} id="v-pills-topic5-tab" data-bs-toggle="pill" data-bs-target="#v-pills-topic5" type="button" role="tab" aria-controls="v-pills-topic5" aria-selected="false">{topic5Title}</button>

                        <button className={`nav-link rounded-0 ${chooseTemplate.navBar_text_menu}`} id="v-pills-conclusion-tab" data-bs-toggle="pill" data-bs-target="#v-pills-conclusion" type="button" role="tab" aria-controls="v-pills-conclusion" aria-selected="false">Conclusão</button>
                    </div>
                    <div className={`tab-content ${chooseTemplate.nabBar_topics}`} id="v-pills-tabContent">
                        <div className={`tab-pane fade show active ${chooseTemplate.nabBar_topicsBox}`} id="v-pills-intro" role="tabpanel" aria-labelledby="v-pills-intro-tab" tabindex="0">
                            <h5 >
                                <textarea
                                    disabled={!editProject}
                                    className={`${chooseTemplate.navBar_boxTexts}`}
                                    type="text"
                                    name="intro"
                                    placeholder="Introdução"
                                    rows={10}
                                    value={textIntro}
                                    onChange={(e) => setTextIntro(e.target.value)}
                                />
                            </h5>
                        </div>
                        <div className={`tab-pane fade ${chooseTemplate.nabBar_topicsBox}`} id="v-pills-topic1" role="tabpanel" aria-labelledby="v-pills-topic1-tab" tabindex="0">
                            <h5 >
                                <textarea
                                    disabled={!editProject}
                                    className={`${chooseTemplate.navBar_boxTexts}`}
                                    type="text"
                                    name="topic1"
                                    placeholder="Digite o texto"
                                    rows={10}
                                    value={topic1Text}
                                    onChange={(e) => setTopic1Text(e.target.value)}
                                />
                            </h5>
                        </div>
                        <div className={`tab-pane fade ${chooseTemplate.nabBar_topicsBox}`} id="v-pills-topic2" role="tabpanel" aria-labelledby="v-pills-topic2-tab" tabindex="0">
                            <h5 >
                                <textarea
                                    disabled={!editProject}
                                    className={`${chooseTemplate.navBar_boxTexts}`}
                                    type="text"
                                    name="topic2"
                                    placeholder="Digite o texto"
                                    rows={10}
                                    value={topic2Text}
                                    onChange={(e) => setTopic2Text(e.target.value)}
                                />
                            </h5>

                        </div>
                        <div className={`tab-pane fade ${chooseTemplate.nabBar_topicsBox}`} id="v-pills-topic3" role="tabpanel" aria-labelledby="v-pills-topic3-tab" tabindex="0">
                            <h5 >
                                <textarea
                                    disabled={!editProject}
                                    className={`${chooseTemplate.navBar_boxTexts}`}
                                    type="text"
                                    name="topic3"
                                    placeholder="Digite o texto"
                                    rows={10}
                                    value={topic3Text}
                                    onChange={(e) => setTopic3Text(e.target.value)}
                                />
                            </h5>
                        </div>
                        <div className={`tab-pane fade ${chooseTemplate.nabBar_topicsBox}`} id="v-pills-topic4" role="tabpanel" aria-labelledby="v-pills-topic4-tab" tabindex="0">
                            <h5 >
                                <textarea
                                    disabled={!editProject}
                                    className={`${chooseTemplate.navBar_boxTexts}`}
                                    type="text"
                                    name="topic4"
                                    placeholder="Digite o texto"
                                    rows={10}
                                    value={topic4Text}
                                    onChange={(e) => setTopic4Text(e.target.value)}
                                />
                            </h5>
                        </div>
                        <div className={`tab-pane fade ${chooseTemplate.nabBar_topicsBox}`} id="v-pills-topic5" role="tabpanel" aria-labelledby="v-pills-topic5-tab" tabindex="0">
                            <h5 >
                                <textarea
                                    disabled={!editProject}
                                    className={`${chooseTemplate.navBar_boxTexts}`}
                                    type="text"
                                    name="topic5"
                                    placeholder="Digite o texto"
                                    rows={10}
                                    value={topic5Text}
                                    onChange={(e) => setTopic5Text(e.target.value)}
                                />
                            </h5>
                        </div>
                        <div className={`tab-pane fade ${chooseTemplate.nabBar_topicsBox}`} id="v-pills-conclusion" role="tabpanel" aria-labelledby="v-pills-conclusion-tab" tabindex="0">
                            <h5 >
                                <textarea
                                    disabled={!editProject}
                                    className={`${chooseTemplate.navBar_boxTexts}`}
                                    type="text"
                                    name="conclusion"
                                    placeholder="Conclusão"
                                    rows={10}
                                    value={textConclusion}
                                    onChange={(e) => setTextConclusion(e.target.value)}
                                />
                            </h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AssessmentContent