import React, { useEffect, useRef, useState } from 'react';
import 'bootstrap-icons/font/bootstrap-icons.css';
import './DropdownMenu.css';

function DropdownMenu({ handleChangeDropdown, project, folderName, menuProject, items, lastProjectRow }) {

  const [isOpen, setIsOpen] = useState(false);

  const dropdownRef = useRef(null);

  const handleClick = (action) => {
    setIsOpen((prevIsOpen) => !prevIsOpen);
    handleChangeDropdown(action, project, folderName);
  }

  const handleToggle = () => {
    setIsOpen(!isOpen);
  }

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    }
  }, []);

  return (
    <div className={`custom-dropdown ${isOpen ? 'open' : ''}`} ref={dropdownRef}>
      <button className="custom-toggle" onClick={handleToggle}>
        {menuProject ? (
          <i className="bi bi-three-dots-vertical "></i>
        ) : (
          <i class="bi bi-chevron-double-down"></i>
        )}
      </button>

      {isOpen && (
        <ul className="custom-menu" style={lastProjectRow ? { right: 0 } : { left: 0 }}>
          {menuProject && (
            <li>
              <button className="dropdown-item" onClick={() => handleClick("edit")}>
                Editar
              </button>
            </li>
          )}
          <li>
            <button className="dropdown-item" onClick={() => handleClick("rename")}>
              Renomear
            </button>
          </li>
          {menuProject && (
            <li>
              <button className="dropdown-item" onClick={() => handleClick("move")}>
                Mover
              </button>
            </li>
          )}
          <li>
            <button className="dropdown-item" onClick={() => handleClick("delete")}>
              Apagar
            </button>
          </li>

        </ul>
      )}
    </div>
  );
}

export default DropdownMenu;
