import styles from './MyAccount.module.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import $ from 'jquery';
import Popper from 'popper.js';

import UserInfosUpdate from '../../components/UserInfosUpdate';
import ChangePassword from '../../components/ChangePassword';
import Preferences from '../../components/Preferences';
import PlanDetail from '../../components/PlanDetail';
import DeleteAccount from '../../components/DeleteAccount';
import useExcelToJson from '../../hooks/useLoadExcelLanguage';

import { useLanguageValue } from '../../context/LanguageContext';
import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useFetchProjects } from '../../hooks/useFetchProjects';
import { useFetchDocument } from '../../hooks/useFetchDocument'
import { useAuthValue } from '../../context/AuthContext';
import { Timestamp } from 'firebase/firestore';
import { PaddleSDK } from 'paddle-sdk';

//Images
import bgMenu from '../../images/bgMenu.jpg';
//Translation
import i18n from '../../i18n';
import { useTranslation } from 'react-i18next';

window.jQuery = $;
window.Popper = Popper;

const MyAccount = ({ hideMenuItem }) => {

  const location = useLocation();
  const tab = "Meus dados";

  const [folder, setFolder] = useState("Folder");
  const [search, setSearch] = useState();
  const [domainPlan, setDomainPlan] = useState("");
  const [numberCC, setNumberCC] = useState("");
  const [ccOperator, setCcOperator] = useState("");
  const [dataStorageLimit, setDataStorageLimit] = useState(0);
  const [dataStorageUsed, setDataStorageUsed] = useState(0);
  const [baseDate, setBaseDate] = useState(new Date());
  const [nextBirthDate, setNextBirthDate] = useState(new Date());
  const [totalProjects, setTotalProjects] = useState(0);
  const [totalMonthProjects, setTotalMonthProjects] = useState(0);
  const [monthProjectsLimit, setMonthProjectsLimit] = useState(0);
  const [totalDeletedProjects, setTotalDeletedProjects] = useState(0);
  const [deletedProjects, setDeletedProjects] = useState([]);
  const [daysUse, setDaysUse] = useState(30);
  const [email, setEmail] = useState("");
  const [language, setLanguage] = useState("");
  const [cancelUrl, setCancelUrl] = useState("");
  const [updateUrl, setUpdateUrl] = useState("");
  const [subscriptionId, setSubscriptionId] = useState(0);
  const [status, setStatus] = useState();
  const [domainIsActive, setDomainIsActive] = useState(true);
  const [cancelDate, setCancelDate] = useState(new Date());
  const [cancelEffectiveDate, setCancelEffectiveDate] = useState(new Date());
  const [pageTexts, setPageTexts] = useState("");
  const [showPage, setShowPage] = useState(false);
  const [couponCode, setCouponCode] = useState("");
  const [actionStatus, setActionStatus] = useState("active");

  const { t } = useTranslation(['myaccount']);
  const { preferenceLanguage } = useLanguageValue();
  const sheetName = 'account';
  const jsonData = useExcelToJson(sheetName);
  
  const { user } = useAuthValue();
  const [userId, setUserId] = useState(user.uid);

  const { document: domain, loadingDocDomain, errorDocDomain } = useFetchDocument("Domains", userId)
  const { document: userInfos, loadinguserInfos, erroruserInfos } = useFetchDocument("Users", userId)
  const { document: plan, loadingPlans, errorPlans } = useFetchDocument("Plans", domainPlan);
  const { documents: projects, loading, error } = useFetchProjects(folder, search, userId);

  const [activeTab, setActiveTab] = useState(tab);

  const paddleClient = new PaddleSDK(
    process.env.REACT_APP_PADDLE_SELLER_ID,
    process.env.REACT_APP_PADDLE_API_KEY
  );

  const checkTotalProjectsMonth = () => {
    const filteredProjects = projects.filter((project) => {
      if (project.id === "folder") {
        return false;
      }

      const signatureDateTime = domain.signature_date.toDate();
      const actualDateTime = Timestamp.now().toDate();

      signatureDateTime.setHours(0, 0, 0, 0);
      actualDateTime.setHours(0, 0, 0, 0);

      const signatureDateDay = signatureDateTime.getDate();
      const actualDay = actualDateTime.getDate();
      const actualMonth = actualDateTime.getMonth();
      const actualYear = actualDateTime.getFullYear();
      const baseMonth = signatureDateDay > actualDay ? actualMonth - 1 : actualMonth;
      const baseDate = new Date(actualYear, baseMonth, signatureDateDay);

      const creationDate = project.creation_date.toDate();
      creationDate.setHours(0, 0, 0, 0);

      return creationDate >= baseDate && creationDate <= actualDateTime;
    });

    const totalMonthProjects = filteredProjects.length;
    return totalMonthProjects;
  };

  const checkDeletedProjects = () => {
    const filterProjects = projects.filter((project) => {
      return !project.project_active
    })
    const projectsDeleted = filterProjects;
    return projectsDeleted;
  }

  useEffect(() => {
    if (domain) {
      setCouponCode(domain.coupon)
      setDomainIsActive(domain.domain_active);
      setStatus(domain.status)
      setDomainPlan(domain.plan);
      setBaseDate(new Date(domain.signature_date.toDate()));
      setMonthProjectsLimit(domain.monthly_project_limit);
      setCancelUrl(domain.cancel_url)
      setUpdateUrl(domain.update_url)
      setSubscriptionId(domain.subscription_id)
      setActionStatus(domain.action_status)

      const nextBillDate = domain.next_bill_date ? domain.next_bill_date.toDate() : domain.expired_trial_date.toDate();

      setNextBirthDate(nextBillDate);
      const pptStorage = domain.ppt_storage_size;
      const videoStorage = domain.video_storage_size;
      const totalSizeInKb = pptStorage + videoStorage;
      const totalSizeInMb = totalSizeInKb / 1024;
      setDataStorageUsed(totalSizeInKb);
      domain.last_cancel_date && setCancelDate(domain.last_cancel_date.toDate());
      domain.cancellation_effective_date && setCancelEffectiveDate(domain.cancellation_effective_date.toDate());
    }

    if (plan) {
      setDaysUse(plan.days_use);
      setDataStorageLimit(plan.data_storage_limit);
    }

    if (projects && projects.length > 0) {
      if (projects.some(project => project.id === "folder")) {
        setTotalProjects(projects.length - 1)
      } else {
        setTotalProjects(projects.length)
      }
      const projectsDeleted = checkDeletedProjects();
      setTotalDeletedProjects(projectsDeleted.length - 1);
      setDeletedProjects(projectsDeleted);
    }

  }, [domain, plan, projects]);

  useEffect(() => {
    if (domain && projects && projects.length > 0 && nextBirthDate) {
      const totalMonthProjects = checkTotalProjectsMonth();
      setTotalMonthProjects(totalMonthProjects);
    }
  }, [domain, projects, nextBirthDate])

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  useEffect(() => {
    if (jsonData && preferenceLanguage) {
      const newPageTexts = jsonData.map((data) => data[preferenceLanguage]);
      setPageTexts(newPageTexts);
    }
  }, [jsonData, preferenceLanguage])

  useEffect(() => {
    userInfos && setEmail(userInfos.email)
  }, [userInfos])

  useEffect(() => {
    location.state && setActiveTab(location.state.tab)
  }, [location.state])

  useEffect(() => {
    if (preferenceLanguage) {
      var language = "en";
      switch (preferenceLanguage) {
        case "Português":
          language = "pt";
          break;
        case "Espanhol":
          language = "es";
          break;
        case "Inglês":
          language = "en";
          break;
        default:
          language = "en";
      }
      changeLanguage(language);
      i18n.changeLanguage(language);
    }
  }, [preferenceLanguage])

  const changeLanguage = (language) => {
    localStorage.setItem('preferenceLanguage', language);
    i18n.changeLanguage(language);
  };

  useEffect(() => {
    const delay = 400;
    setTimeout(() => {
      setShowPage(true)
    }, delay);
  }, []);

  useEffect(() => {
    hideMenuItem("myaccount")
  }, [])

  return (
    <div>
      {showPage ?
        <div >
          <p></p>
          <div className={`${styles.main_background}`}>
            <div className="row">
              <div className="col-2">
                <ul className={`nav nav-tabs flex-column ${styles.nav_tabs_custom} ${styles.nav_custom}` }>
                  <li className={`nav-item ${styles.nav_item}`}>
                    <a
                      className={`nav-link nav-link-tab ${activeTab === 'Meus dados' && 'active'} ${activeTab === 'Meus dados' ? styles.nav_link_custom : ''} `}
                      onClick={() => handleTabClick('Meus dados')}
                    >
                      {t('myaccount:menu.Meus dados')}
                    </a>
                  </li>
                  <li className={`nav-item ${styles.nav_item}`}>
                    <a
                      className={`nav-link nav-link-tab ${activeTab === 'Senha' && 'active'} ${activeTab === 'Senha' && styles.nav_link_custom}`}
                      onClick={() => handleTabClick('Senha')}
                    >
                      {t('myaccount:menu.Senha')}
                    </a>
                  </li>
                  <li className={`nav-item ${styles.nav_item}`}>
                    <a
                      className={`nav-link nav-link-tab ${activeTab === 'Preferências' && 'active'} ${activeTab === 'Preferências' && styles.nav_link_custom}`}
                      onClick={() => handleTabClick('Preferências')}
                    >
                      {t('myaccount:menu.Preferências')}
                    </a>
                  </li>
                  <li className={`nav-item ${styles.nav_item}`}>
                    <a
                      className={`nav-link nav-link-tab ${activeTab === 'Detalhes do plano' && 'active'} ${activeTab === 'Detalhes do plano' && styles.nav_link_custom}`}
                      onClick={() => handleTabClick('Detalhes do plano')}
                    >
                      {t('myaccount:menu.Detalhes do plano')}
                    </a>
                  </li>

                  <li className={`nav-item ${styles.nav_item}`}>
                    <a
                      className={`nav-link nav-link-tab ${activeTab === 'Apagar conta' && 'active'} ${activeTab === 'Apagar conta' && styles.nav_link_custom}`}
                      onClick={() => handleTabClick('Apagar conta')}
                    >
                      {t('myaccount:menu.Apagar conta')}
                    </a>
                  </li>
                </ul>
              </div>
              <div className={`col-8 components ${styles.choice_panel} ${styles.components_custom}`}>
                {activeTab === 'Meus dados' && (
                  <div className={`mt-4 ms-3 mb-4`}>
                    <UserInfosUpdate
                      userId={userId}
                      isActive={domainIsActive}
                    />
                  </div>
                )}
                {activeTab === 'Senha' && (
                  <ChangePassword
                    userId={userId}
                    userEmail={email}
                    isActive={domainIsActive}
                  />
                )}
                {activeTab === 'Preferências' && (
                  <Preferences
                    userId={userId}
                    userLanguage={language}
                    isActive={domainIsActive}
                  />
                )}
                {activeTab === 'Detalhes do plano' && (
                  <PlanDetail
                    userId={userId}
                    userDomainPlan={domainPlan}
                    userTotalProjects={totalProjects}
                    userTotalMonthProjects={totalMonthProjects}
                    userMonthProjectsLimit={monthProjectsLimit}
                    userTotalDeletedProjects={totalDeletedProjects}
                    userDeletedProjects={deletedProjects}
                    userNumberCC={numberCC}
                    userccOperator={ccOperator}
                    userDomainStorage={dataStorageLimit}
                    userUsedStorage={dataStorageUsed}
                    userCancelUrl={cancelUrl}
                    userUpdateUrl={updateUrl}
                    userNextBbirthDate={nextBirthDate}
                    userSubscriptionId={subscriptionId}
                    isActive={domainIsActive}
                    status={status}
                    cancelDate={cancelDate}
                    cancelEffectiveDate={cancelEffectiveDate}
                    coupon={couponCode}
                    actionStatus={actionStatus}
                  />
                )}
                {activeTab === 'Apagar conta' && (
                  <DeleteAccount
                    userId={userId}
                    isActive={domainIsActive}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        : <div class="d-flex justify-content-center mt-5">
          <div class="align-self-center">
            <div class="spinner-border text-primary" role="status">
              <span class="visually-hidden"></span>
            </div>
          </div>
        </div>}
    </div>
  );

}

export default MyAccount