//Translation
import i18n from '../i18n';
import { useTranslation } from 'react-i18next';
//Styles
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import styles from './ContentParameters.module.css'
//React Hooks
import { useEffect, useState } from 'react';
//Dev Hooks
import { useLanguageValue } from '../context/LanguageContext';
import { useAskChatGPT } from '../hooks/useAskChatGPT';
//Firebase
import { Timestamp } from 'firebase/firestore';
//Dev components
import AssessmentTopics from './AssessmentTopics';

const ContentParameters = ({ changeIdProject, handleMsgError }) => {

    const { t } = useTranslation(['projectpreview']);
    const { preferenceLanguage } = useLanguageValue();
    const { handleOpenAiApi, returnGPT, textTranslated, loading, error, generateGPT } = useAskChatGPT();

    const [contentType, setContentType] = useState("text")
    const [language, setLanguage] = useState("Portuguese");
    const [disableForm, setDisableForm] = useState(false);
    const [contentSubject, setContentSubject] = useState("");
    const [caseDescription, setCaseDescription] = useState("");
    const [generatingTopics, setGeneratingTopics] = useState(false);
    const [showTopics, setShowTopics] = useState(false);
    const [topics, setTopics] = useState([]);
    const [selectedTopics, setSelectedTopics] = useState([]);
    const [buttonText, setButtonText] = useState(t('projectpreview:contentparameters.Texto'))
    const [askingIA, setAskingIA] = useState(false);
    const [msgText, setMsgText] = useState("");
    const [generatingText, setGeneratingText] = useState(false);
    const [projectText, setProjectText] = useState("");
    const [generatingCase, setGeneratingCase] = useState(false);
    const [projectCase, setProjectCase] = useState("")
    const [errorGPT, setErrorGPT] = useState("");
    const [progresstimer, setProgresstimer] = useState(0);
    const [totaltime, setTotaltime] = useState(0);
    const [contentParameters, setContentParameters] = useState(true);
    const [translatedTopics, setTranslatedTopics] = useState(false);
    const [translating, setTranslating] = useState(false);

    const handleIncreaseTime = () => {
        const total = 10;
        setTotaltime(total)
        const increasetimer = 1
        const timer = setInterval(() => {
            setProgresstimer((prevProgress) => Math.min(prevProgress + increasetimer, total));
        }, 1000);

        return () => clearInterval(timer);
    }

    const changeProjectLanguage = (lang) => {
        setLanguage(lang);
    }

    const handleDirectionClick = () => {
        if (buttonText === "Tópicos" || buttonText === "Topics" || buttonText === "Temas") {
            handleAskTopicsChatGPT();
        } else if (buttonText === "Texto" || buttonText === "Text") {
            handleAskContentChatGPT();
        } else if (buttonText === "Case") {
            handleAskCaseChatGPT();
        }
    }

    const handleAskTopicsChatGPT = async () => {
        handleIncreaseTime();
        setMsgText(t('projectpreview:contentparameters.tópicos sendo gerados'))
        setAskingIA(true);
        setGeneratingTopics(true)

        try {
            const res = await handleOpenAiApi({ subject: contentSubject, orderGPT: "topics" });
        } catch (error) {
            setErrorGPT(error);
        }
    }

    const handleSetTopics = (topic) => {
        if (selectedTopics.includes(topic)) {
            setSelectedTopics(selectedTopics.filter(item => item !== topic));
        } else {
            setSelectedTopics([...selectedTopics, topic]);
        }
    }

    const handleAskContentChatGPT = async () => {
        handleIncreaseTime();
        setMsgText(t('projectpreview:contentparameters.texto sendo gerado'))
        setAskingIA(true);
        setGeneratingText(true);

        try {
            const res = await handleOpenAiApi({
                subject: contentSubject,
                selectedTopics,
                language,
                orderGPT: "content"
            });
        } catch (error) {
            setErrorGPT(error);
        }
    }

    const handleAskCaseChatGPT = async () => {
        handleIncreaseTime();
        setMsgText(t('projectpreview:contentparameters.case sendo gerado'))
        setAskingIA(true);
        setGeneratingCase(true);

        try {
            const res = await handleOpenAiApi({
                subject: contentSubject,
                caseDescription,
                language,
                orderGPT: "case"
            });
        } catch (error) {
            setErrorGPT(error);
        }
    }

    const handleTranslateGPT = async (textToTranslate) => {
        setTranslating(true);
        try {
            const res = await handleOpenAiApi({
                textToTranslate,
                orderGPT: "translate"
            });
        } catch (error) {
            setErrorGPT(error);
        }
    }

    useEffect(() => {
        if (contentType === "text") {
            setButtonText(t('projectpreview:contentparameters.Texto'))
        } else if (contentType === "case") {
            setButtonText("Case")
            setShowTopics(false)
        }
    }, [contentType])

    useEffect(() => {
        if (showTopics) {
            setButtonText(t('projectpreview:contentparameters.Tópicos'))
        } else {
            setButtonText(t('projectpreview:contentparameters.Texto'))
        }
    }, [showTopics])

    useEffect(() => {
        if (returnGPT && generatingTopics) {
            setAskingIA(false);
            setTopics(returnGPT);
            setContentParameters(false);
            setGeneratingTopics(false);
            setButtonText(t('projectpreview:contentparameters.Texto'));
        } else if (returnGPT && generatingText) {
            setAskingIA(false);
            setGeneratingText(false);
            setProjectText(returnGPT);
        } else if (returnGPT && generatingCase) {
            setAskingIA(false);
            setGeneratingCase(false);
            setProjectCase(returnGPT);
        }
    }, [returnGPT])

    useEffect(() => {
        if (projectText) {
            const id = contentSubject + Timestamp.now();
            changeIdProject(id, contentSubject, false, projectText, language, contentType)
        }
    }, [projectText])

    useEffect(() => {
        if (projectCase) {
            const id = contentSubject + Timestamp.now();
            changeIdProject(id, contentSubject, false, projectCase, language, contentType)
        }
    }, [projectCase])


    useEffect(() => {
        if (error) {
            setErrorGPT(error);
            setAskingIA(false);
        }
    }, [error])

    useEffect(() => {
        if (errorGPT) {
            setProgresstimer(0);
            setTotaltime(0);
            setErrorGPT("");
            handleMsgError(errorGPT)
        }
    }, [errorGPT])

    useEffect(() => {
        if (preferenceLanguage) {
            var language = "en";
            switch (preferenceLanguage) {
                case "Português":
                    language = "pt"
                    break;
                case "Espanhol":
                    language = "es"
                    break;
                case "Inglês":
                    language = "en"
                    break;
            }
            changeLanguage(language);
            i18n.changeLanguage(language);
        }
    }, [preferenceLanguage])

    const changeLanguage = (language) => {
        localStorage.setItem('preferenceLanguage', language);
        i18n.changeLanguage(language);
    };

    return (
        <div className={`p-0 m-0`}>
            {askingIA
                ? <div className='d-flex align-items-center justify-content-center flex-column'>
                    <div className={`col-6 text-center `}>
                        <div className={`progress ${styles.progressContainer}`} role="progressbar" aria-label="Info striped example" aria-valuenow={progresstimer} aria-valuemin="0" aria-valuemax="100">
                            <div className={`progress-bar progress-bar-striped bg-info`} style={{ width: `${(progresstimer / totaltime) * 100}%` }}></div>
                        </div>
                    </div>
                    <h5 className={`mt-3 ${styles.subTitle}`}>
                        {`${msgText}`}
                    </h5>
                </div>
                : <div>
                    {contentParameters && <div>
                        <h6 className={`m-3`}>{`Indique abaixo os parâmetros para o seu Texto:`} </h6>
                        <div className="pt-4 ms-3 mt-2">
                            <div className="row align-items-center">
                                <label className=""><h6>{t('projectpreview:contentparameters.Como deseja criar as questões')}</h6></label>
                                <div className="">
                                    <div className="form-check ">
                                        <input
                                            className={`form-check-input ${styles.option_check}`}
                                            type="radio"
                                            name="meu_radio"
                                            value="text"
                                            checked={contentType === "text"}
                                            onChange={() => setContentType("text")}
                                        />
                                        <label className="form-check-label"><h6>{t('projectpreview:contentparameters.Texto')}</h6></label>
                                    </div>
                                </div>
                                <div className="">
                                    <div className="form-check mt-1">
                                        <input
                                            className={`form-check-input ${styles.option_check}`}
                                            type="radio"
                                            name="meu_radio"
                                            value="case"
                                            checked={contentType === "case"}
                                            onChange={() => setContentType("case")}
                                        />
                                        <label className="form-check-label"><h6>Case</h6></label>
                                    </div>
                                </div>
                                <div className="col-auto">
                                    <input
                                        className={`mt-3 ${styles.form_parameters} ${styles.adjust_width}`}
                                        type="text"
                                        required
                                        id="subject"
                                        placeholder={contentType === 'case'
                                            ? t('projectpreview:contentparameters.assunto geração case')
                                            : t('projectpreview:contentparameters.assunto geração texto')
                                        }
                                        disabled={disableForm}
                                        name="subject"
                                        value={contentSubject}
                                        onChange={(e) => { setContentSubject(e.target.value) }}
                                    />
                                </div>
                            </div>
                            {contentType === "text" &&
                                <div className="row align-items-center">
                                    <div className="mt-4 ms-3 form-check col-11">
                                        <input className={`form-check-input ${styles.option_check}`} type="checkbox" disabled={disableForm} name="flexRadioDefault" id="flexRadioDefault1" value={showTopics} onChange={(e) => setShowTopics(e.target.checked)} />
                                        <span className={``}>
                                            <h6>{t('projectpreview:contentparameters.QuizAI sugira tópicos')}</h6>
                                        </span>
                                    </div>
                                </div>}
                            {contentType === "case" &&
                                <div className="row align-items-center">
                                    <div className='col-auto mt-3 mb-2'>
                                        <textarea
                                            className={`form-control ${styles.form_field}`}
                                            type="text"
                                            disabled={disableForm}
                                            name="intro"
                                            placeholder={t('projectpreview:contentparameters.descrição case')}
                                            rows={5}
                                            value={caseDescription}
                                            onChange={(e) => setCaseDescription(e.target.value)}
                                        />
                                    </div>
                                </div>}
                            <div className="row d-flex align-items-center mt-3">
                                <h6 className="col-12 ">{t('projectpreview:asssessmentparameters.Idioma')} </h6>
                                <div className="col-12">
                                    <select
                                        className={styles.form_parameters}
                                        disabled={disableForm}
                                        name="language"
                                        value={language}
                                        onChange={(e) => changeProjectLanguage(e.target.value)}>
                                        <option value="">{`Selecione uma opção`}</option>
                                        {preferenceLanguage === 'Português' && <option value="Portuguese" defaultValue>{t('projectpreview:asssessmentparameters.Português')}</option>}
                                        {preferenceLanguage === 'Inglês' && <option value="English" defaultValue>{t('projectpreview:asssessmentparameters.Inglês')}</option>}
                                        {preferenceLanguage === 'Espanhol' && <option value="Spanish" defaultValue>{t('projectpreview:asssessmentparameters.Espanhol')}</option>}
                                        {preferenceLanguage != 'Português' && <option value="Portuguese">{t('projectpreview:asssessmentparameters.Português')}</option>}
                                        {preferenceLanguage != 'Inglês' && <option value="English">{t('projectpreview:asssessmentparameters.Inglês')}</option>}
                                        {preferenceLanguage != 'Espanhol' && <option value="Spanish">{t('projectpreview:asssessmentparameters.Espanhol')}</option>}
                                        <option value="French">{t('projectpreview:asssessmentparameters.Francês')}</option>
                                        <option value="German">{t('projectpreview:asssessmentparameters.Alemão')}</option>
                                        <option value="Italian">{t('projectpreview:asssessmentparameters.Italiano')}</option>
                                        <option value="Mandarin">{t('projectpreview:asssessmentparameters.Chinês')}</option>
                                        <option value="Russian">{t('projectpreview:asssessmentparameters.Russo')}</option>
                                        <option value="Japanese">{t('projectpreview:asssessmentparameters.Japonês')}</option>
                                        <option value="Korean">{t('projectpreview:asssessmentparameters.Coreano')}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>}
                    {(topics && topics.length > 0) &&
                        <div className="ms-3 mt-3">
                            <AssessmentTopics
                                topics={topics}
                                handleSetTopics={handleSetTopics}
                                topicText={t('projectpreview:asssessmentparameters.tópicos refinar projeto')} />
                        </div>}
                    <div>
                        <button className={`mt-5 ms-3 mb-4 ${styles.button_quizz} ${styles.btn_style}`} disabled={!contentSubject} onClick={() => handleDirectionClick()}>
                            {`${t('projectpreview:asssessmentparameters.Gerar')} ${buttonText}`}
                        </button>
                    </div>
                </div>}
        </div>
    )
}

export default ContentParameters