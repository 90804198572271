//React Hooks
import { useState, useEffect } from 'react';
//Dev Hooks
import { useAuthValue } from '../context/AuthContext';
//Translation
import i18n from '../i18n';
import { useTranslation } from 'react-i18next';
import { useLanguageValue } from '../context/LanguageContext';

export const useConvertVideoToText = () => {

    const { user } = useAuthValue();;
    const [userId, setUserId] = useState(user.uid);
    const [msgText, setMsgText] = useState("");
    const [idProject, setIdProject] = useState(null);
    const [audioExtracted, setAudioExtracted] = useState(false);
    const [videoGenerated, setVideoGenerated] = useState(false);
    const [textType, setTextType] = useState("");
    const [videoTranscript, setVideoTranscript] = useState("");
    const [errorConvertingVideoText, setConvertingVideoText] = useState("");
    const [projectSize, setProjectSize] = useState(0);
   //const [codeVideoLanguage, setCodeVideoLanguage] = useState("pt-BR");
    const [videoSize, setVideoSize] = useState(0);

    const { preferenceLanguage } = useLanguageValue();
    const { t } = useTranslation(['choosefile']);

    const convertVideoSubToWebVTT = (videoSub) => {
        let webVTTContent = 'WEBVTT\n\n';
        let index = 1;
        let currentStart = parseFloat(videoSub[0].startTime);
        let durationAccumulator = 0;
        let text = '';
    
        videoSub.forEach((wordObj, i) => {
            const wordStart = parseFloat(wordObj.startTime);
            const wordEnd = parseFloat(wordObj.endTime);
            const wordDuration = wordEnd - wordStart;
    
            if (durationAccumulator + wordDuration <= 4) {
                text += wordObj.word + ' ';
                durationAccumulator += wordDuration;
            } else {
                const currentEnd = currentStart + durationAccumulator;
                webVTTContent += `${index}\n${formatTime(currentStart)} --> ${formatTime(currentEnd)}\n${text.trim()}\n\n`;
    
                currentStart = wordStart;
                durationAccumulator = wordDuration;
                text = wordObj.word + ' ';
                index++;
            }
    
            // Para o último grupo de palavras
            if (i === videoSub.length - 1) {
                const currentEnd = currentStart + durationAccumulator;
                webVTTContent += `${index}\n${formatTime(currentStart)} --> ${formatTime(currentEnd)}\n${text.trim()}\n\n`;
            }
        });
    
        return webVTTContent;
    }

    const formatTime = (timeInSeconds) => {
        const hours = Math.floor(timeInSeconds / 3600).toString().padStart(2, '0');
        const minutes = Math.floor((timeInSeconds % 3600) / 60).toString().padStart(2, '0');
        const seconds = (timeInSeconds % 60).toFixed(3).toString().padStart(6, '0');
        return `${hours}:${minutes}:${seconds}`;
    }

    useEffect(() => {
        if (preferenceLanguage) {
            var language = "en";
            switch (preferenceLanguage) {
                case "Português":
                    language = "pt"
                    break;
                case "Espanhol":
                    language = "es"
                    break;
                case "Inglês":
                    language = "en"
                    break;
            }
            changeLanguage(language);
            i18n.changeLanguage(language);
        }
    }, [preferenceLanguage])

    const changeLanguage = (language) => {
        localStorage.setItem('preferenceLanguage', language);
        i18n.changeLanguage(language);
    };

    const parseTime = (timeStr) => {
        const [hours, minutes, seconds, frames] = timeStr.split(':').map(Number);
        return hours * 3600 + minutes * 60 + seconds + frames / 80;
    }

    const groupSubtitles = (objectVideoSub, silenceThreshold = 2) => {
        const groupedSubtitles = [];
        let currentGroup = [];
        let currentTime = 0;
        let lastEndTime = 0;
    
        objectVideoSub.forEach((sub, index) => {
            const startTime = parseTime(sub.startTime);
            const endTime = parseTime(sub.endTime);
            const silenceGap = startTime - lastEndTime;
    
            if ((currentTime > 0 && currentTime + (endTime - startTime) > 8) || silenceGap > silenceThreshold) {
                groupedSubtitles.push(currentGroup);
                currentGroup = [];
                currentTime = 0;
            }
    
            currentGroup.push(sub);
            currentTime += endTime - startTime;
            lastEndTime = endTime;
    
            if (index === objectVideoSub.length - 1) {
                groupedSubtitles.push(currentGroup);
            }
        });
    
        return groupedSubtitles.map(group => ({
            words: group.map(g => g.word).join(' '),
            startTime: group[0].startTime,
            endTime: group[group.length - 1].endTime
        }));
    }

    const extractAudioFromVideo = async (url) => {
        setMsgText(t('choosefile:messages.Identificando idioma video'));

        let videoPath = "";
        const regex = /\/o\/(.*?)\?alt=media/;
        const matches = url.match(regex);
        if (matches && matches.length > 1) {
            videoPath = matches[1];
        } else {
            setConvertingVideoText(t('choosefile:messages.Não foi possível extrair o caminho relativo'));
        }
        try {
            const response = await fetch(`https://us-central1-quizzai-4b3cd.cloudfunctions.net/extractAudio?videoURL=${videoPath}`, {
                method: 'GET',
            });
            if (response.status === 200) {
                const data = await response.json();
                
                setAudioExtracted(true);
                const videoTxt = data.transcription;
                console.log("videoTxt: " + videoTxt)
                const videoLanguage = data.detectedLanguage;
                
                return { videoLanguage };
            } else {
                setConvertingVideoText(t('choosefile:messages.Erro na extração do texto do vídeo'));
            }
        } catch (error) {
            setConvertingVideoText(t('choosefile:messages.Erro na solicitação'));
        }
        //https://us-central1-quizzai-4b3cd.cloudfunctions.net/extractAudio
    }

    const convertVideoToText = async (url, lang) => {
        //setVideoSize(fileSizeInMB);
        setMsgText(t('choosefile:messages.Gerando a transcrição do vídeo'));
        const videoURL = url;
        const codeVideoLanguage = lang;
        console.log("codeVideoLanguage: " + codeVideoLanguage)

        try {
            const response = await fetch(`https://us-central1-quizzai-4b3cd.cloudfunctions.net/convertVideoToText?userId=${userId}&projectId=${idProject}&videoURL=${videoURL}&language=${codeVideoLanguage}`, {
                method: 'GET',
            });
            if (response.status === 200) {
                const data = await response.json();
                
                setVideoGenerated(true);
                setTextType("usertext");

                const videoTxt = data.transcript;
                const objectVideoSub = data.subTitle;
                const subtitlesGrouped = groupSubtitles(objectVideoSub);
                const videoSub = convertVideoSubToWebVTT(data.subTitle)
                
                setVideoTranscript(videoTxt);
                
                return { videoTxt, videoSub, subtitlesGrouped };
            } else {
                setConvertingVideoText(t('choosefile:messages.Erro na extração do texto do vídeo'));
            }
        } catch (error) {
            setConvertingVideoText(t('choosefile:messages.Erro na solicitação'));
        }
    }
    return { convertVideoToText, extractAudioFromVideo, videoSize, errorConvertingVideoText }
}