import React, { useState, useEffect, useRef } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import $ from 'jquery';
import { useNavigate } from 'react-router-dom';

import styles from './Terms.module.css'

/* --- IMAGENS --- */
import arrowLegal from '../../images/arrowLegal.svg';

const Terms = () => {
	/* --- INICIO DA ANIMAÇÃO POR SCROLL --- */
	const fadeInElements = useRef([]);

	const navigate = useNavigate();

	const goPrivacy = () => {
		navigate("/privacy")
	}

	const goGdpr = () => {
		navigate("/gdpr")
	}

	const goDpa = () => {
		navigate("/dpa")
	}

	useEffect(() => {
		const options = {
			root: null,
			rootMargin: '0px',
			threshold: 0.2,
		};

		const observer = new IntersectionObserver((entries, observer) => {
			entries.forEach(entry => {
				if (entry.isIntersecting) {
					entry.target.classList.add(styles.fadeIn);
					observer.unobserve(entry.target);
				}
			});
		}, options);

		fadeInElements.current.forEach(element => {
			observer.observe(element);
		});

		return () => {
			observer.disconnect();
		};
	}, []);
	/* --- FIM DA ANIMAÇÃO POR SCROLL --- */

	useEffect(() => {
		const indicatorsElements = document.querySelectorAll('.carousel-indicators');
		if (indicatorsElements) {
			indicatorsElements.forEach(element => {
				element.classList.add(styles.seletores);
			});
		}
	}, []);

	/* --- INICIO --- adicionar para retirar o padding do container parent da página */
	useEffect(() => {
		const capsula = document.querySelector('.container');
		if (capsula) {
			capsula.classList.add('p-0');
		}
	}, []);
	/* --- FIM --- adicionar para retirar o padding do container parent da página */

	/* --- INICIO SCROLL TOP --- */
	const [showScrollButton, setShowScrollButton] = useState(false);
	const [showScrollToTop, setShowScrollToTop] = useState(false);

	const handleScroll = () => {
		if (window.pageYOffset > 100) {
			setShowScrollButton(true);
			$('.scrollToTop').show();
		} else {
			setShowScrollButton(false);
			$('.scrollToTop').hide();
		}

		if (window.pageYOffset > 30) {
			setShowScrollToTop(true);
			$('.scrollToTop').show();
		} else {
			setShowScrollToTop(false);
			$('.scrollToTop').hide();
		}
	};

	useEffect(() => {
		window.addEventListener('scroll', handleScroll);

		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	const scrollToTop = () => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth',
		});
	};
	/* --- FIM SCROLL TOP --- */

	return (
		<div className={`${styles.bg} ${styles.text_left}`}>
			<section className={`${styles.secao} ${styles.call_to_action} vertical-center fade-in text-center text-md-left`}>
				<div className={`${styles.overlay}`}></div>
				<div className={`${styles.area}  text-center ${styles.text_md_left}`}>
					<div className={`row`}>
						<div className={`col-sm-12 col-md-10`}>
							<h1>Terms of Service - QuizAI</h1>
						</div>
					</div>
				</div>
			</section>

			<section id="termos" className={`${styles.area} ${styles.hideFadeIn} ${styles.legais} text-left`} ref={el => fadeInElements.current.push(el)}>
				<div className={`row align-items-center justify-content-center`}>
					<div className={`col-12 col-md-11`}>
						<h1>Terms of Service - QuizAI</h1>
						<p id="intro">Ao usar o site da QuizAI ("Serviço"), você concorda com os seguintes termos e condições.</p>
						<p>
							The Service reserves the right to update and change this agreement from time to time without notice.
						</p>
						<p>
							Any new features that augment or enhance the current Service, including the release of new tools and resources, shall be subject to this agreement. Continued use of the Service after any such changes shall constitute your consent to such changes. You can review the most current version of this agreement at any time at <a href="termos.html">this link</a>.
						</p>

						<p>Violation of any of the terms below will result in the termination of your Quiz account.
						</p>

						<h3>Who we are</h3>

						<p>Our company, Clarity Solutions (owner and developer of QuizAI, our product commercial name) is based in Brazil (Address: Rua Bom Pastor 2224 – Ipiranga – São Paulo – SP – CEP 04203-002).
						</p>

						<ul>
							<h3>Accounts</h3>
							<li>You must provide your legal full name, a valid email address, and any other information requested in order to complete the registering/sign up process.</li>
							<li>You are responsible for maintaining the security of your account and password. The Service cannot and will not be liable for any loss or damage from your failure to comply with this security obligation.</li>
							<li>You are responsible for all activity that occurs under your account.</li>
							<li>One person or legal entity may not sign up for more than one test or trial account.</li>
							<li>You may not use the Service for any illegal or unauthorized purpose. You must not, in the use of the Service, violate any laws in your jurisdiction (including but not limited to copyright or trademark laws).</li>
						</ul>

						<ul>
							<h3>Privacy</h3>
							<li>
								Submitted data and the generated files are kept for the time the user subscription is active (generated files can be deleted by the user any time from his control panel).
							</li>
							<li>
								The Service will not share generated files to anyone.
							</li>
							<li>
								The Service may send you email concerning the Service or your account at any time.
							</li>
							<li>
								The Service will not sell your email address to anyone.
							</li>
							<li>
								The Service may provide information concerning your account and your use of the Service to 3rd parties, at its sole discretion, if it is requested by law enforcement authorities.
							</li>
						</ul>

						<ul>
							<h3>Warranties</h3>
							<li>
								You understand that the Service uses third party vendors and hosting partners to provide the necessary hardware, software, networking, storage, and related technology required to run the Service.
							</li>
							<li>
								The Service does not warrant that the service will meet your specific requirements.
							</li>
							<li>
								The Service does not warrant that the results that may be obtained from the use of the service will be accurate or reliable.
							</li>
							<li>
								The Service does not warrant that any errors in the service will be corrected.
							</li>
							<li>
								You understand that the Service consists of generating content using Artificial Intelligence software and that there will always be a need for you to review all generated content before applying and/or using it. Therefore, you are responsible for reviewing, editing, and applying the content generated by using the Service.
							</li>
						</ul>

						<h4>About Plans and Pricing</h4>
						<p>
							The Service reserves the right to create new plans or update and change existing plans at any time, including possible changes to their respective commercial terms. When price changes occur in existing plans, the subscriber of the current plan will be notified in advance.
						</p>

						<h4>About the Trial version</h4>
						<p>
							The Trial version is a limited and temporary version of the Pro version, intended for new users who are interested in testing and experiencing the service for free. The Trial version has a maximum duration of 15 (fifteen) days from the user's registration date. The current features and characteristics of the Pro plan can be found in the Plans and Pricing section.
						</p>
						<p>
							Upon the conclusion of the Trial period, the service will be deactivated for the registered user. Reactivation of the service can only occur with the subscription to an active commercial plan. The files and data generated by the user during the Trial period and stored by the Service will be automatically deleted after 90 (ninety) days from the end of the Trial period.
						</p>
						<p>
							The current terms of service do not allow a user who has used the Trial version to use it again after its expiration, even if using different registration data.
						</p>

						<h4>About the Basic plan</h4>
						<p>
							The Basic plan is a limited version of the Service with permanent availability, respecting the limits stipulated by the plan. The current features and characteristics of the Basic plan can be found in the <a href="planos.html">Plans and Pricing</a> section.
						</p>
						<p>
							In the case of prolonged inactivity accessing the Service in the Basic plan, the files and data generated by the user and stored by the Service will be automatically deleted after 180 (one hundred and eighty) days from the date of the last access record (activity).
						</p>
						<p>
							The access account to the Service will remain valid for the user to resume using it.
						</p>
						<h4>About the Pro plan</h4>
						<p>
							The Pro plan is the full and paid version of the Service with permanent availability, respecting the limits stipulated by the plan. All current features and characteristics of the Pro plan can be found in the <a href="planos.html">Plans and Pricing</a> section.
						</p>

						<h3>Cancellation Policy and Financial Refund</h3>
						<p>
							The user subscribed to a paid plan of the QuizAI service can cancel their subscription at any time using the user panel within the authenticated area of the website.
						</p>
						<p>
							Cancellation of a subscription by the user will stop the next scheduled billing according to the contracted payment plan (monthly payment or annual payment). Cancellation does not imply a refund of previously charged amounts by the Service or paid by the user.
						</p>
						<h4>Financial Refund</h4>
						<p>
							Requests for a financial refund can be made only by users who have opted for annual billing through the email support@quizai.bz. Financial refunds do not apply to users who have opted for monthly billing.
						</p>
						<p>
							Each refund request will be reviewed, and a response will be sent to the requesting user within 72 (seventy-two) hours from the receipt of the email by the QuizAI team.
						</p>
						<p>
							If a financial refund is approved, a 10% (ten percent) retention of the charged amount will be applied. It means the user will receive a partial refund due to commercial costs associated with the transaction already made.
						</p>
						<p>
							If you have any questions, users can always contact our team via email (support@quizai.bz) or through the contact form on our website.
						</p>

						<h3>Disclaimer</h3>
						<p>
							Use of the Service is at your own risk.
						</p>

						<p>
							THE SERVICE IS PROVIDED "AS IS" AND ANY EXPRESS OR IMPLIED WARRANTIES, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE ARE DISCLAIMED. IN NO EVENT SHALL THE SERVICE BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, OR CONSEQUENTIAL DAMAGES (INCLUDING, BUT NOT LIMITED TO, PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES; LOSS OF USE, DATA, OR PROFITS; OR BUSINESS INTERRUPTION) HOWEVER CAUSED AND ON ANY THEORY OF LIABILITY, WHETHER IN CONTRACT, STRICT LIABILITY, OR TORT (INCLUDING NEGLIGENCE OR OTHERWISE) ARISING IN ANY WAY OUT OF THE USE OF THIS Service, EVEN IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGE.
						</p>


					</div>
				</div>
			</section>

			<div className={`row justify-content-center align-items-center ${styles.botoesChamadas} ${styles.hideFadeIn}`} ref={el => fadeInElements.current.push(el)}>
				<div className={`col-12 col-md-10 text-center`}>
					<h4>Additional resources:</h4>
					<a href="#" onClick={() => { goPrivacy() }} className={`${styles.btn} btn ${styles.btn_primary}  mb-3 mb-md-0 me-md-3`}>Privacy</a>
					<a href="#" onClick={() => { goGdpr() }} className={`${styles.btn} btn ${styles.btn_primary} mb-3 mb-md-0 me-md-3`}>GDPR</a>
					<a href="#" onClick={() => { goDpa() }} className={`${styles.btn_primary} btn  mb-3 mb-md-0 me-md-3`}>Data Processing Agreement</a>
				</div>
			</div>

			<div className={`${styles.scrollToTop}`} style={{ display: showScrollToTop ? 'block' : 'none' }}>
				{showScrollButton && (
					<img
						src={arrowLegal}
						alt="Arrow to the top"
						onClick={scrollToTop}
						style={{ cursor: 'pointer' }}
					/>
				)}
			</div>
		</div>
	)
}

export default Terms