import React from 'react'

export const useGenerateManifest = () => {

    const removeSpecialChar = (str) => {
        str = removeAccents(str);
        str = str.replace(/ /g, "_");
        str = str.replace(/[&\/\\#,+()$~%'":*?<>{}!@¨]/g, '');
        str = str.toLowerCase();
        return str;
      }
    
      const removeAccents = (text) => {
        const accents = {
          'á': 'a',
          'à': 'a',
          'â': 'a',
          'ã': 'a',
          'ä': 'a',
          'é': 'e',
          'è': 'e',
          'ê': 'e',
          'ë': 'e',
          'í': 'i',
          'ì': 'i',
          'î': 'i',
          'ï': 'i',
          'ó': 'o',
          'ò': 'o',
          'ô': 'o',
          'õ': 'o',
          'ö': 'o',
          'ú': 'u',
          'ù': 'u',
          'û': 'u',
          'ü': 'u',
          'ñ': 'n',
          'ç': 'c',
          '&': 'and',
        };
    
        return text
          .split('')
          .map((char) => accents[char] || char)
          .join('');
      };

    const generateManifest = (subject) => {
        const organization = "QuizAI";
        const organizationWhithoutSpecialChar = removeSpecialChar(organization);
        const subjectWithouSpecialChar = removeSpecialChar(subject);
        const idOrganization = organizationWhithoutSpecialChar + "_" + subjectWithouSpecialChar;
        const defaultOrganization = idOrganization;
        const idScorm = organizationWhithoutSpecialChar + "_" + subjectWithouSpecialChar + "_SCO_01";
        const idItemIdentifier = idOrganization + "_ITEM_01"
        const scormTitle = organization + " - " + subject;
        return generateManifestFile(idOrganization, scormTitle, idItemIdentifier, idScorm);
    }


    const generateManifestFile = (idOrganization, scormTitle, idItemIdentifier, idScorm) => {
        const manifestString = `<?xml version="1.0" encoding="UTF-8"?>

        <manifest identifier="${idOrganization}_QUIZAI" version="1.0"
            xmlns="http://www.imsproject.org/xsd/imscp_rootv1p1p2"
            xmlns:adlcp="http://www.adlnet.org/xsd/adlcp_rootv1p2"
            xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"
            xsi:schemaLocation="http://www.imsproject.org/xsd/imscp_rootv1p1p2 imscp_rootv1p1p2.xsd
          http://www.imsglobal.org/xsd/imsmd_rootv1p2p1 imsmd_rootv1p2p1.xsd
          http://www.adlnet.org/xsd/adlcp_rootv1p2 adlcp_rootv1p2.xsd" >
        
          <metadata>
            <schema>ADL SCORM</schema>
            <schemaversion>1.2</schemaversion>
          </metadata>
        
          <organizations default="${idOrganization}">
            <organization identifier="${idOrganization}">
              <title>${scormTitle}</title>
              <item identifier="${idItemIdentifier}" identifierref="${idScorm}">
                <title>${scormTitle}</title>
              </item>
            </organization>
          </organizations>
        
          <resources>
            <resource identifier="${idScorm}" type="webcontent" adlcp:scormtype="sco" href="index.html">
              <file href="index.html"/>
            </resource>
          </resources>
        
        </manifest>
        `;
        return manifestString;
    }
    return { generateManifest }
}