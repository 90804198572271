import React, { useEffect, useState, useRef } from 'react';
import styles from './Homepage.module.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import useExcelToJson from '../../hooks/useLoadExcelLanguage';
import { useNavigate } from 'react-router-dom';
import { useAuthValue } from '../../context/AuthContext';
import { useLanguageValue } from '../../context/LanguageContext';
//Translation
import i18n from '../../i18n';
import { useTranslation } from 'react-i18next';

const Homepage = ({ userSetLanguage }) => {
  const { user } = useAuthValue();
  const { t } = useTranslation(['homepage']);
  const { preferenceLanguage } = useLanguageValue();

  const [preferenceBrowserLanguage, setPreferenceBrowserLanguage] = useState("");
  const [pageTexts, setPageTexts] = useState([]);
  const [videoVersion, setVideoVersion] = useState("");

  const sheetName = 'homepage';
  const jsonData = useExcelToJson(sheetName);

  const navigate = useNavigate();

  const [userLanguage, setUserLanguage] = useState(userSetLanguage ? userSetLanguage : navigator.language);

  /* --- INICIO --- adicionar para retirar o padding do container parent da página */
  useEffect(() => {
    const capsula = document.querySelector('.container');
    if (capsula) {
      capsula.classList.add('p-0');
    }
  }, []);
  /* --- FIM --- adicionar para retirar o padding do container parent da página */

  /* --- INICIO DA ANIMAÇÃO POR SCROLL --- */
  const fadeInElements = useRef([]);

  useEffect(() => {
    if (userSetLanguage) {
      setUserLanguage(userSetLanguage)
    }
  }, [userSetLanguage])

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.2,
    };

    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add(styles.fadeIn);
          observer.unobserve(entry.target);
        }
      });
    }, options);

    fadeInElements.current.forEach(element => {
      observer.observe(element);
    });

    return () => {
      observer.disconnect();
    };
  }, []);
  /* --- FIM DA ANIMAÇÃO POR SCROLL --- */

  const goRegister = () => {
    window.scrollTo(0, 0);
    navigate("/register")
  }

  const goPrices = () => {
    navigate("/catalog")
  }

  const goFeatures = () => {
    navigate("/features")
  }

  useEffect(() => {
    switch (userLanguage) {
      case "pt-BR":
      case "pt":
        setPreferenceBrowserLanguage("Português");
        setVideoVersion("https://www.youtube.com/embed/rZ8wQPS09Go")
        break;
      case "en-US":
      case "en":
        setPreferenceBrowserLanguage("Inglês");
        setVideoVersion("https://www.youtube.com/embed/KZTfjidiGDg")
        break;
      case "es-ES":
      case "es":
        setPreferenceBrowserLanguage("Espanhol");
        setVideoVersion("https://www.youtube.com/embed/fa5hniJEI2U")
        break;
      default:
        setPreferenceBrowserLanguage("Inglês");
        setVideoVersion("https://www.youtube.com/embed/KZTfjidiGDg")
        break;
    }
  }, [userLanguage]);

  useEffect(() => {
    if (jsonData && preferenceLanguage && preferenceBrowserLanguage) {
      const newPageTexts = jsonData.map((data) => data[user ? preferenceLanguage : preferenceBrowserLanguage]);
      setPageTexts(newPageTexts);
    }
  }, [jsonData, preferenceLanguage, preferenceBrowserLanguage])

  useEffect(() => {
    if (preferenceBrowserLanguage) {
      var language = "en";
      switch (preferenceBrowserLanguage) {
        case "Português":
          language = "pt";
          break;
        case "Espanhol":
          language = "es";
          break;
        case "Inglês":
          language = "en";
          break;
        default:
          language = "en";
      }
      changeLanguage(language);
      i18n.changeLanguage(language);
    }
  }, [preferenceBrowserLanguage])

  const changeLanguage = (language) => {
    localStorage.setItem('preferenceLanguage', language);
    i18n.changeLanguage(language);
  };

  /* --- FIM --- forçar para que todas as divs .card tenham a mesma altura */

  return (
    <div className={`${styles.bg}`}>
      <section className={`${styles.secao} ${styles.call_to_action} vertical-center text-center ${styles.text_md_left} ${styles.hideFadeIn}`} ref={el => fadeInElements.current.push(el)}>
        <div className={`${styles.overlay}`}></div>
        <div className={`${styles.area} text-center ${styles.text_md_left}`}>
          <div className={`row`}>
            <div className={`col-12 col-md-6 ${styles.content}`}>
              <h1> {t(`homepage:home.Crie questões avaliações e quizzes usando o poder da IA`)}</h1>
              <p>{t(`homepage:home.Ganhe produtividade`)}</p>
              <a href="#" onClick={() => { goRegister() }} className={`${styles.btn} ${styles.btn_primary}`}>{t(`homepage:home.Iniciar Teste Grátis`)}</a>
            </div>
          </div>
        </div>
      </section>

      <section className={`${styles.secao} ${styles.funcionalidades} ${styles.equal_height}`}>
        <div className={`row center-horizontal justify-content-center align-items-center`}>
          <div className={`col-12 col-md-4`}>
            <div className={`${styles.card} d-flex flex-column ${styles.hideFadeIn}`} ref={el => fadeInElements.current.push(el)}>
              <h2>{t(`homepage:home.Teste o QuizAI por 15 dias`)}</h2>
              <p>{t(`homepage:home.Crie questões e quizzes em segundos`)}</p>
            </div>
          </div>
          <div id="card2" className={`col-12 col-md-4`}>
            <div className={`${styles.card} d-flex flex-column ${styles.hideFadeIn}`} ref={el => fadeInElements.current.push(el)}>
              <h2>{t(`homepage:home.Digite tópico texto arquivo PPT ou vídeo`)}</h2>
              <p>{t(`homepage:home.Incorpore o seu conteúdo e converta-o em SCORM`)}</p>
            </div>
          </div>
          <div id="card3" className={`col-12 col-md-4`}>
            <div className={`${styles.card} d-flex flex-column ${styles.hideFadeIn}`} ref={el => fadeInElements.current.push(el)}>
              <h2>{t(`homepage:home.Converta seu arquivo PPT`)}</h2>
              <p>{t(`homepage:home.Gere conteúdo em 10 idiomas diferentes`)}</p>
            </div>
          </div>
        </div>
      </section>

      <section className={`${styles.secao} ${styles.video} slide-left vertical-center ${styles.hideFadeIn}`} ref={el => fadeInElements.current.push(el)}>
        <div className={`${styles.area} text-center`}>
          <div className={`row`}>
            <div className={`col-sm-12 d-flex align-items-center justify-content-center`}>
              <div className={`col-12 text-center`}>
                <h2>{t(`homepage:home.Todos idiomas incluídos`)}</h2>

                <div className={`${styles.embed_responsive} ${styles.embed_responsive_16by9}`}>
                  <iframe src={videoVersion} title="YouTube Video" style={{ height: '100%', width: '100%' }} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                </div>
              </div>
            </div>
            <div className={`${styles.botoes} col-12 text-center ${styles.hideFadeIn}`} ref={el => fadeInElements.current.push(el)}>
              <div className={`d-flex flex-column flex-md-row justify-content-center align-items-center`}>
                <a href="" onClick={() => { goFeatures() }} className={`${styles.btn} ${styles.btn_primary} mb-3 mb-md-0 me-md-3`}>{t(`homepage:home.Assista vídeo para entender como funciona`)}</a>
                <span className={`mb-3 mb-md-0`}>{t(`homepage:home.Confira mais recursos`)}</span>
                <a href="" onClick={() => { goPrices() }} className={`${styles.btn} ${styles.btn_other} mt-3 mt-md-0 ms-md-3`}>{t(`homepage:home.Explore planos e preços`)}</a>
              </div>
            </div>
          </div>
        </div>
      </section>



    </div>

  )
}

export default Homepage