import React, { useState, useEffect, useRef } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import styles from './Plans.module.css';
import CheckIcon from './CheckIcon';
import CrossIcon from './CrossIcon';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import useExcelToJson from '../../hooks/useLoadExcelLanguage';
import DialogBox from '../../components/DialogBox';

import { useLanguageValue } from '../../context/LanguageContext';
import { useAuthValue } from '../../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import { useFetchProjects } from '../../hooks/useFetchProjects';
import { useFetchDocument } from '../../hooks/useFetchDocument'
import { useUpdateDocument } from '../../hooks/useUpdateDocument';
import { Timestamp } from 'firebase/firestore';
import { format } from 'date-fns';
import { PaddleSDK } from 'paddle-sdk';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import axios from 'axios';
import { initializePaddle, Paddle } from '@paddle/paddle-js';

//Translation
import i18n from '../../i18n';
import { useTranslation } from 'react-i18next';

const Plans = ({ userSetLanguage }) => {

  const paddleClient = new PaddleSDK(
    process.env.REACT_APP_PADDLE_SELLER_ID,
    process.env.REACT_APP_PADDLE_API_KEY
  );

  let countryIndex = 0;

  let periodo = 'mensal';

  const navigate = useNavigate();

  const { t } = useTranslation(['plans']);
  const { user } = useAuthValue();
  const userEmail = user ? user.email : null;

  const [selectedLanguage, setSelectedLanguage] = useState('pt_br');
  const [mensal, setMensal] = useState(true);
  const [pricePro, setPricePro] = useState('');
  const [periodPro, setPeriodPro] = useState('');
  const [priceBasico, setPriceBasico] = useState('');
  const [periodBasico, setPeriodBasico] = useState('');
  const [pageTexts, setPageTexts] = useState([]);
  const [preferenceBrowserLanguage, setPreferenceBrowserLanguage] = useState("");
  const [language, setLanguage] = useState("");
  const [userId, setUserId] = useState(user ? user.uid : "");
  const [search, setSearch] = useState();

  const [choicedPlan, setChoicedPlan] = useState("");
  const [planName, setPlanName] = useState("");
  const [actualPlan, setActualPlan] = useState("");
  const [planId, setPlanId] = useState("");
  const [dataStorage, setDataStorage] = useState(0);
  const [projectsLimit, setProjectsLimit] = useState(0);
  const [subscriptionId, setSubscriptionId] = useState("");
  const [paddleUserId, setPaddleUserId] = useState(null);
  const [paddleAlert, setPaddleAlert] = useState(null);
  const [signatureDate, setSignatureDate] = useState(new Date());
  const [nextBirthDate, setNextBirthDate] = useState(new Date());
  const [showPlanInfos, setShowPlanInfos] = useState(false);
  const [keepSubscribe, setKeepSubscribe] = useState(false);
  const [loadingPlan, setLoadingPlan] = useState(true);
  const [updatePlan, setUpdatePlan] = useState(false);
  const [updatingPlan, setUpdatingPlan] = useState(false);
  const [errorPaddle, setErrorPaddle] = useState("");
  const [status, setStatus] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [message, setMessage] = useState("");
  const [dialogOption, setDialogOption] = useState([]);
  const [keepSubscription, setKeepSubscription] = useState(false);
  const [paddle, setPaddle] = useState();

  const sheetName = 'plans';
  const jsonData = useExcelToJson(sheetName);
  const { preferenceLanguage } = useLanguageValue();
  const [userLanguage, setUserLanguage] = useState(userSetLanguage ? userSetLanguage : navigator.language);
  //const userLanguage = "en" //ANA MUDAR ESSA LINHA PARA pt ou en ou es

  let planIdChoiced;

  const { document: domain, loadingDomain, errorDomain } = useFetchDocument("Domains", userId);

  const { documents: avaliablePlans, loadingPlans, error } = useFetchProjects("Plans", search);

  const { updateDocument, responseUpdate } = useUpdateDocument("Domains", userId)

  const myModal = document.getElementById('myModal')
  const myInput = document.getElementById('myInput')

  const cancelPaddlePlan = async (subscriptionId) => {

    setUpdatingPlan(true)

    paddle.Spinner.show()
    try {
      const response = await axios.post(
        `https://us-central1-quizzai-4b3cd.cloudfunctions.net/postCancelSubscription?subscriptionId=${subscriptionId}`
      );
    } catch (error) {

      console.error('Erro:', error);
    }

    paddle.Spinner.hide()

  }

  const checkUserPlan = () => {
    domain.status === "deleted" ? setActualPlan("") : setActualPlan(domain.plan);

    setSignatureDate(domain.signature_date);
    setSubscriptionId(domain.subscription_id ? domain.subscription_id : "");
    setStatus(domain.status);
    const nextBillDate = domain.next_bill_date && domain.next_bill_date.toDate();
    const nextBillDateObj = new Date(nextBillDate);
    setNextBirthDate(nextBillDateObj);
    setLoadingPlan(false);
  }

  const handleConfirmPlanChange = (pname) => {

    setPlanName(pname);

    const planObject = avaliablePlans.find(plan => plan.plan_name === pname);
    if (planObject) {
      const planPaddleId = planObject.plan_paddle_id;
      setPlanId(planPaddleId);
    } else {
      return
    }

    if (pname === "Free" && actualPlan.includes("trial")) {
      setDataStorage(50);
      setProjectsLimit(5);
      setOpenDialog(true);
      setPlanId("free");
      const textMessage = t(`plans:home.Prosseguir com sua assinatura ao Plano Basic`)
      setMessage(textMessage);
      setDialogOption([t(`plans:home.Sim`), t(`plans:home.Não`)]);
    } else

      if (actualPlan.includes("trial") || actualPlan === "" || actualPlan.includes("free")) {
        setKeepSubscribe(true);
      } else {
        setOpenDialog(true);
        const formattedDate = format(nextBirthDate, 'dd/MM/yyyy');
        const textMessage = `${t(`plans:home.Você tem uma assinatura vigente do plano`)} ${actualPlan} ${t(`plans:home.que vencerá em`)} ${formattedDate}. 
        ${t(`plans:home.Você quer continuar mesmo assim`)}
        ${t(`plans:home.Ao clicar em Sim, você estará realizando uma nova assinatura que substituíra a sua já existente`)}`
        setMessage(textMessage);
        setDialogOption([t(`plans:home.Sim`), t(`plans:home.Não`)]);
      }
  }

  const goRegister = () => {
    window.scrollTo(0, 0);
    navigate("/register")
  }

  const handleClosDialogBox = (state, option) => {
    setOpenDialog(false);
    if (option === "Sim" || option === "Yes" || option === "Sí") {
      handleUpdateUserPlan();
    }
    if (option === "OK") {
      navigateAccount();
    }
  };

  const handleUpdateUserPlan = async () => {
    setUpdatingPlan(true)

    //await cancelPaddlePlan(subscriptionId);
    if (planName === "Free" && actualPlan.includes("trial")) {
      const newDataFree = {
        coupon: "",
        domain: userId,
        domain_active: true,
        data_storage_limit: dataStorage,
        monthly_project_limit: projectsLimit,
        signature_date: Timestamp.now(),
        status: "free",
        plan: "free"
      };
      const resDoc = updateDocument(newDataFree);
      navigateAccount();
    } else {
      try {
        paddle.Spinner.show()
        try {
          const response = await axios.patch(
            `https://us-central1-quizzai-4b3cd.cloudfunctions.net/patchChangeSubscription?subscriptionId=${subscriptionId}&priceId=${planId}`
          );
        } catch (error) {

          console.error('Erro:', error);
        }

        paddle.Spinner.hide()
        setUpdatingPlan(false)
        navigateAccount();
      } catch (error) {
        console.log('Erro:', error);
        setUpdatingPlan(false)
        setErrorPaddle(error);
      }
    }
  }

  const navigateAccount = () => {
    navigate("/seuperfil", { state: { tab: "Detalhes do plano" } })
  }

  const updatePlanValues = (language, isMensal) => {
    const priceValues = {
      'Português': { mensal: 'R$ 69,90', anual: 'R$ 699' },
      'Inglês': { mensal: '$ 24', anual: '$ 240' },
      'Espanhol': { mensal: '$ 24', anual: '$ 240' },
    };

    const periodValues = {
      'Português': { mensal: 'por mês', anual: 'por ano' },
      'Inglês': { mensal: 'per month', anual: 'per year' },
      'Espanhol': { mensal: 'por mes', anual: 'por año' },
    };

    const selectedPrice = isMensal ? priceValues[language].mensal : priceValues[language].anual;
    const selectedPeriod = periodValues[language][isMensal ? 'mensal' : 'anual'];

    setPricePro(selectedPrice);
    setPriceBasico(language === 'pt_br' ? 'R$ 0' : '$ 0');
    setPeriodPro(selectedPeriod);
    setPeriodBasico(selectedPeriod);
  };

  const togglePlan = event => {
    if (periodo == 'mensal') {
      setMensal(true);
      updatePlanValues(language, true);
    } else {
      setMensal(false);
      updatePlanValues(language, false);
    }
  };

  useEffect(() => {
    if (userSetLanguage) {
      setUserLanguage(userSetLanguage)
    }
  }, [userSetLanguage])

  useEffect(() => {
    switch (userLanguage) {
      case "pt-BR":
      case "pt":
        setPreferenceBrowserLanguage("Português");
        break;
      case "en-US":
      case "en":
        setPreferenceBrowserLanguage("Inglês");
        break;
      case "es-ES":
      case "es":
        setPreferenceBrowserLanguage("Espanhol");
        break;
      default:
        setPreferenceBrowserLanguage("Inglês");
        break;
    }
  }, [userLanguage]);

  useEffect(() => {
    if (jsonData && preferenceLanguage && preferenceBrowserLanguage) {
      const newPageTexts = jsonData.map((data) => data[user ? preferenceLanguage : preferenceBrowserLanguage]);
      setLanguage(user ? preferenceLanguage : preferenceBrowserLanguage)
      setPageTexts(newPageTexts);
    }
  }, [jsonData, preferenceLanguage, preferenceBrowserLanguage])

  useEffect(() => {
    language && updatePlanValues(language, mensal);

  }, [language, mensal]);

  useEffect(() => {
    if (domain) {
      checkUserPlan();
      domain.paddle_alert && setPaddleAlert(domain.paddle_alert)
    }
  }, [domain])

  useEffect(() => {
    if (keepSubscribe && !updatePlan) {
      if (planName === "Pro-monthly") {
        document.querySelector(".subscribe_pro").click();
      } else if (planName === "Pro-annual") {
        document.querySelector(".subscribe_annual").click();
      } else if (planName === "Free" && !actualPlan.includes("trial")) {
        document.querySelector(".subscribe_basic").click();
      }
      setKeepSubscribe(false);
    }
  }, [keepSubscribe])

  useEffect(() => {
    if (errorPaddle) {
      setOpenDialog(true);
      const textMessage = textMessage[69];
      setMessage(textMessage);
      setDialogOption(["OK"]);
    }
  }, [errorPaddle])

  /* --- INICIO DA ANIMAÇÃO POR SCROLL --- */
  const fadeInElements = useRef([]);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.2,
    };

    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add(styles.fadeIn);
          observer.unobserve(entry.target);
        }
      });
    }, options);

    fadeInElements.current.forEach(element => {
      observer.observe(element);
    });

    return () => {
      observer.disconnect();
    };
  }, []);
  /* --- FIM DA ANIMAÇÃO POR SCROLL --- */

  /* --- INICIO SUMMARY IGUALAR ALTURA --- */
  const [maxSummaryHeight, setMaxSummaryHeight] = useState(0);

  useEffect(() => {
    function updateMaxSummaryHeight() {
      const basicSummary = document.getElementById('Basico-summary');
      const proSummary = document.getElementById('Pro-summary');
      const maxHeight = Math.max(basicSummary.scrollHeight, proSummary.scrollHeight);
      setMaxSummaryHeight(maxHeight);
    }

    updateMaxSummaryHeight();
    window.addEventListener('resize', updateMaxSummaryHeight);

    return () => {
      window.removeEventListener('resize', updateMaxSummaryHeight);
    };
  }, []);
  /* --- FIM SUMMARY IGUALAR ALTURA --- */

  /* --- INICIO BTNS MENSAL E ANUAL --- */
  const [activeButton, setActiveButton] = useState('mensal');

  const handleButtonClick = (buttonType) => {
    setActiveButton(buttonType);
    periodo = buttonType;
    togglePlan(buttonType);
  };
  /* --- FIM BTNS MENSAL E ANUAL --- */

  /* --- INICIO --- adicionar para retirar o padding do container parent da página */
  useEffect(() => {
    const capsula = document.querySelector('.container');
    if (capsula) {
      capsula.classList.add('p-0');
    }
  }, []);
  /* --- FIM --- adicionar para retirar o padding do container parent da página */

  useEffect(() => {
    initializePaddle({ seller: 177179 }).then(
      (paddleInstance) => {
        if (paddleInstance) {
          setPaddle(paddleInstance);
        }
      },
    );
  }, []);

  useEffect(() => {
    if (preferenceBrowserLanguage) {
      var language = "en";
      switch (preferenceBrowserLanguage) {
        case "Português":
          language = "pt";
          break;
        case "Espanhol":
          language = "es";
          break;
        case "Inglês":
          language = "en";
          break;
        default:
          language = "en";
      }
      changeLanguage(language);
      i18n.changeLanguage(language);
    }
  }, [preferenceBrowserLanguage])

  useEffect(() => {
    user && preferenceLanguage && setPreferenceBrowserLanguage(preferenceLanguage)
  }, [preferenceLanguage, user])

  const changeLanguage = (language) => {
    localStorage.setItem('preferenceLanguage', language);
    i18n.changeLanguage(language);
  };

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <script src="https://cdn.paddle.com/paddle/v2/paddle.js"></script>
          <script>{`Paddle.Setup({ 
                    seller: 177179, 
                    eventCallback: function(data) {
                      if (data.name === "checkout.completed") {
                        console.log("Data name: " + data);
                        navigateAccount();
                      }}
                    })`}
          </script>
        </Helmet>
        <a href="#" className="paddle_button subscribe_basic d-none" data-customer-email={userEmail} data-items={`[{"priceId": "pri_01hcb1k61e29tr22j1a8w5nz0e",
      "quantity": 1}]`} >Subscribe now</a>
        <a href="#" className="paddle_button subscribe_pro d-none" data-customer-email={userEmail} data-items={`[{"priceId": "pri_01haw3tsnx1hsewphcdggtpqbk",
      "quantity": 1}]`} >Compre agora</a>
        <a href="#" className="paddle_button subscribe_annual d-none" data-customer-email={userEmail} data-items={`[{"priceId": "pri_01haw3w97tca8rkr4n8hv8p29t",
      "quantity": 1}]`} >Compre agora</a>

        {updatingPlan
          ? <div className="d-flex justify-content-center mt-5">
            <div className="align-self-center">
              <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">{t('plans:home.Comece agora')}</span>
              </div>
            </div>
          </div>
          : <div className={`${styles.bg}`}>
            <section id="planos" className={`${styles.secao} ${styles.call_to_action} ${styles.hideFadeIn} ${styles.secao} vertical-center text-center ${styles.text_md_left}`} ref={el => fadeInElements.current.push(el)}>
              <div className={`${styles.overlay}`}></div>
              <div className={`container  ${styles.area} ${styles.banner} text-center ${styles.text_md_left}`}>
                <div className={`row`}>
                  <div className={`col-sm-12 ${styles.col_md_6} ${styles.cabecalho}`}>
                    <h1>{t('plans:home.Planos e Preços')}</h1>
                    <p className={`${styles.p_}`}>{t('plans:home.Escolha o melhor plano para você')}</p>
                  </div>
                </div>
              </div>
            </section>

            <div className={`${styles.area}`}>

              <section className={`${styles.secao} ${styles.tabela} row align-items-center justify-content-center`}>
                <div className={`col-12 col-md-11 ${styles.partes} text-center ${styles.hideFadeIn}`} ref={el => fadeInElements.current.push(el)}>
                  <div className={`${styles.btn_group}`} role="group" aria-label="Basic example">
                    <button id="mensal-btn" className={`${styles.borderLeft} ${activeButton === 'mensal' ? styles.activeButton : styles.inactiveButton}`} onClick={() => handleButtonClick('mensal')}>{t('plans:home.Mensal')}</button>
                    <button id="anual-btn" className={`${styles.borderRight} ${activeButton === 'anual' ? styles.activeButton : styles.inactiveButton}`} onClick={() => handleButtonClick('anual')}>{t('plans:home.Anual')}</button>
                  </div>
                  <table className={`${styles.table_} ${styles.table_bordered}  ${styles.hideFadeIn}`} ref={el => fadeInElements.current.push(el)}>
                    <thead>
                      <tr>
                        <th className={`${styles.categoria} ${styles.bordaZ2}`}></th>
                        <th id="Basico" className={`${styles.planos} ${styles.bordaZ2}`}>
                          <h2>{t('plans:home.Basic')}</h2>
                          <summary id="Basico-summary" style={{ height: maxSummaryHeight + 'px' }}>
                            {t('plans:home.Perfeito para projetos pessoais. Não é necessário cartão de crédito')}
                          </summary>
                          <h1>{priceBasico}</h1>
                          <p className={`${styles.medida}`}>{periodBasico}</p>
                          {actualPlan === "free"
                            ? <div className={`${styles.yourplan}`}>{t('plans:home.Seu plano')}</div>
                            : <a href="#" onClick={user ? () => handleConfirmPlanChange('Free') : () => { goRegister() }} className={`${styles.btn} ${styles.btn_other} mt-3 ${styles.mt_md_0}`}>
                              {user ? t('plans:home.Assinar') : t('plans:home.Comece agora')}
                            </a>}
                        </th>
                        <th id="Pro" className={`${styles.planos} ${styles.bordaZ2}`}>
                          <h2>{t('plans:home.Pro')}</h2>
                          <summary id="Pro-summary" style={{ height: maxSummaryHeight + 'px' }}>
                            {t('plans:home.Para profissionais, escolas e empresas')}
                          </summary>
                          <h1>{pricePro}</h1>
                          <p className={`medida`}>{periodPro}</p>
                          {(actualPlan === "pro-monthly" && activeButton === "mensal") || (actualPlan === "pro-annual" && activeButton === "anual")
                            ? <div className={`${styles.yourplan}`}>{t('plans:home.Seu plano')}</div>
                            : <a href="#" onClick={user ? () => handleConfirmPlanChange(activeButton === 'mensal' ? 'Pro-monthly' : 'Pro-annual') : () => { goRegister() }} className={`${styles.btn} ${styles.btn_primary} mb-3 ${styles.mt_md_0}`}>
                              {user ? t('plans:home.Assinar') : t('plans:home.Teste grátis')}
                            </a>}
                        </th>
                      </tr>
                    </thead>
                  </table>
                </div>

                <div className={`col-12 col-md-11 ${styles.parte} text-left ${styles.hideFadeIn} `} ref={el => fadeInElements.current.push(el)}>
                  <h3 className={`${styles.text_left} `}>{t('plans:home.Funcionalidades gerais')}</h3>
                  <table className={`${styles.table_} ${styles.table_bordered} `}>
                    <tbody>
                      <tr>
                        <td className={`${styles.categoria} ${styles.bordaZ3} `}>{t('plans:home.Crie perguntas e quizzes usando IA e revise/edite o conteúdo')}</td>
                        <td className={`${styles.planos} ${styles.bordaZ3} `}><CheckIcon /></td>
                        <td className={`${styles.planos} ${styles.bordaZ3} `}><CheckIcon /></td>
                      </tr>
                      <tr>
                        <td className={`${styles.categoria}`}>
                          <OverlayTrigger placement="top" overlay={<Tooltip className={`${styles.tooltipCustom}`}><strong>{t('plans:home.Idiomas atualmente disponíveis no QuizAI')}</strong> {t('plans:home.inglês, espanhol, italiano, português, francês, alemão, russo, coreano, chinês e japonês')}.</Tooltip>}>
                            <span className={`${styles.tooltip_term}`}>{t('plans:home.Crie perguntas e quizzes em 10 idiomas diferentes')}</span></OverlayTrigger>
                        </td>
                        <td className={`${styles.planos}`}><CheckIcon /></td>
                        <td className={`${styles.planos}`}><CheckIcon /></td>
                      </tr>
                      <tr>
                        <td className={`${styles.categoria}`}>{t('plans:home.Insira e use suas próprias perguntas nos quizzes')}</td>
                        <td className={`${styles.planos}`}><CheckIcon /></td>
                        <td className={`${styles.planos}`}><CheckIcon /></td>
                      </tr>
                      <tr>
                        <td className={`${styles.categoria}`}>{t('plans:home.Randomize perguntas e respostas em seus quizzes')}</td>
                        <td className={`${styles.planos} ${styles.soonTxt}`}>{t('plans:home.Em Breve')}</td>
                        <td className={`${styles.planos} ${styles.soonTxt}`}>{t('plans:home.Em Breve')}</td>
                      </tr>
                      <tr>
                        <td className={`${styles.categoria}`}>{t('plans:home.Copie seu texto e gere perguntas sobre ele automaticamente')}</td>
                        <td className={`${styles.planos}`}><CheckIcon /></td>
                        <td className={`${styles.planos}`}><CheckIcon /></td>
                      </tr>
                      <tr>
                        <td className={`${styles.categoria}`}>{t('plans:home.Gere um texto ou caso sobre qualquer assunto usando IA e crie perguntas sobre isso')}</td>
                        <td className={`${styles.planos}`}><CrossIcon /></td>
                        <td className={`${styles.planos}`}><CheckIcon /></td>
                      </tr>
                      <tr>
                        <td className={`${styles.categoria} ${styles.bordaZ}`}>{t('plans:home.Incorpore ou faça upload de')}
                          <OverlayTrigger placement="top" overlay={<Tooltip className={`${styles.tooltipCustom}`}><strong>{t('plans:home.conteúdo multimídia')}:</strong> {t('plans:home.Conteúdo multimidia: Vídeo (arquivo MP4). HTML (arquivo ou URL). Arquivos PPT')}</Tooltip>}>
                            <span className={`${styles.tooltip_term}`}> {t('plans:home.conteúdo multimídia')}</span></OverlayTrigger> {t('plans:home.e crie perguntas sobre ele automaticamente')}
                        </td>
                        <td className={`${styles.planos} ${styles.bordaZ}`}><CrossIcon /></td>
                        <td className={`${styles.planos}`}><CheckIcon /></td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div className={`col-12 col-md-11 ${styles.parte} text-left ${styles.hideFadeIn}`} ref={el => fadeInElements.current.push(el)}>
                  <h3 className={`${styles.text_left} `}>{t('plans:home.Incorpore conteúdo rico em seus quizzes')}</h3>
                  <table className={`${styles.table_} ${styles.table_bordered}  ${styles.hideFadeIn}`} ref={el => fadeInElements.current.push(el)}>
                    <tbody>
                      <tr>
                        <td className={`${styles.categoria} ${styles.bordaZ3}`}>{t('plans:home.Crie perguntas sobre o seu conteúdo multimídia automaticamente. Confira todos os formatos abaixo')}</td>
                        <td className={`${styles.planos} ${styles.bordaZ3}`}><CrossIcon /></td>
                        <td className={`${styles.planos}`}><CheckIcon /></td>
                      </tr>
                      <tr>
                        <td className={`${styles.categoria}`}>{t('plans:home.Faça upload de vídeo (arquivo MP4)')}</td>
                        <td className={`${styles.planos}`}><CrossIcon /></td>
                        <td className={`${styles.planos}`}><CheckIcon /></td>
                      </tr>

                      <tr>
                        <td className={`${styles.categoria}`}>{t('plans:home.Incorporar ou carregar HTML (arquivos ou URL)')}</td>
                        <td className={`${styles.planos}`}><CrossIcon /></td>
                        <td className={`${styles.planos}`}><CheckIcon /></td>
                      </tr>
                      <tr>
                        <td className={`${styles.categoria}`}>{t('plans:home.Adicione um texto simples para leitura')}</td>
                        <td className={`${styles.planos}`}><CrossIcon /></td>
                        <td className={`${styles.planos}`}><CheckIcon /></td>
                      </tr>
                      <tr>
                        <td className={`${styles.categoria} ${styles.bordaZ}`}>{t('plans:home.Carregue e converta arquivos PowerPoint em conteúdo de aprendizagem')}</td>
                        <td className={`${styles.planos} ${styles.bordaZ}`}><CrossIcon /></td>
                        <td className={`${styles.planos}`}><CheckIcon /></td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div className={`col-12 col-md-11 ${styles.parte} text-left ${styles.hideFadeIn}`} ref={el => fadeInElements.current.push(el)}>
                  <h3 className={`${styles.text_left} `}>{t('plans:home.Opções de formato de exportação')}</h3>
                  <table className={`${styles.table_} ${styles.table_bordered} `}>
                    <tbody>
                      <tr>
                        <td className={`${styles.categoria} ${styles.bordaZ3}`}>{t('plans:home.Word (inclui formato Aiken para Moodle e formato GIFT)')}</td>
                        <td className={`${styles.planos} ${styles.bordaZ3}`}><CheckIcon /></td>
                        <td className={`${styles.planos} ${styles.bordaZ3}`}><CheckIcon /></td>
                      </tr>
                      {/*
                      <tr>
                        <td className={`${styles.categoria}`}>{pageTexts[33]}</td>
                        <td className={`${styles.planos} ${styles.soonTxt}`}>{pageTexts[17]}</td>
                        <td className={`${styles.planos} ${styles.soonTxt}`}>{pageTexts[17]}</td>
                      </tr>
                      */}
                      <tr>
                        <td className={`${styles.categoria}`}>{t('plans:home.HTML (arquivo ZIP)')}</td>
                        <td className={`${styles.planos}`}><CheckIcon /></td>
                        <td className={`${styles.planos}`}><CheckIcon /></td>
                      </tr>

                      <tr>
                        <td className={`${styles.categoria} ${styles.bordaZ}`}>{t('plans:home.SCORM 1.2 ou 2004')}</td>
                        <td className={`${styles.planos} ${styles.bordaZ}`}><CrossIcon /></td>
                        <td className={`${styles.planos} ${styles.bordaZ}`}><CheckIcon /></td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div id="tabela_adm" className={`col-12 col-md-11 ${styles.parte} text-left ${styles.hideFadeIn}`} ref={el => fadeInElements.current.push(el)}>
                  <h3 className={`${styles.text_left} `}>{t('plans:home.Administração e outros')}</h3>
                  <table className={`${styles.table_} ${styles.table_bordered} text-left`}>
                    <tbody>
                      <tr>
                        <td className={`${styles.categoria} ${styles.bordaZ3}`}><span>{t('plans:home.Limite de')} </span>
                          <OverlayTrigger placement="top" overlay={<Tooltip className={`${styles.tooltipCustom}`}>{t('plans:home.Um')} <strong>{t('plans:home.projeto ativo')}</strong> {t('plans:home.é aquele que foi criado, editado, duplicado e/ou exportado no período mensal')}</Tooltip>}>
                            <span className={`${styles.tooltip_term}`}>{t('plans:home.projetos ativos')}</span></OverlayTrigger>
                        </td>
                        <td className={`${styles.planos} ${styles.catAdm} ${styles.bs} ${styles.bordaZ3}`}><span className={`${styles.check_icon}`}>
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M5 13l4 4L19 7"></path></svg></span> {t('plans:home.3 por mês')}
                        </td>
                        <td className={`${styles.planos} ${styles.catAdm} ${styles.pr} ${styles.bordaZ3}`}><span className={`${styles.check_icon}`}>
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M5 13l4 4L19 7"></path></svg></span> {t('plans:home.25 por mês')}
                        </td>
                      </tr>
                      <tr>
                        <td className={`${styles.categoria}`}>{t('plans:home.Versão ChatGPT')}</td>
                        <td className={`${styles.planos} ${styles.catAdm} ${styles.bs}`}><span className={`${styles.check_icon}`}>
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M5 13l4 4L19 7"></path></svg></span> {t('plans:home.ChatGPT 3.5')}
                        </td>
                        <td className={`${styles.planos} ${styles.catAdm} ${styles.pr}`}><span className={`${styles.check_icon}`}>
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M5 13l4 4L19 7"></path></svg></span> {t('plans:home.ChatGPT 4')}
                        </td>
                      </tr>
                      <tr>
                        <td className={`${styles.categoria}`}>{t('plans:home.Nível de prioridade quando a demanda do ChatGPT é alta')}</td>
                        <td className={`${styles.planos} ${styles.catAdm} ${styles.bs}`}><span className={`${styles.check_icon}`}>
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M5 13l4 4L19 7"></path></svg></span> {t('plans:home.Normal')}
                        </td>
                        <td className={`${styles.planos} ${styles.catAdm} ${styles.pr}`}><span className={`${styles.check_icon}`}>
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M5 13l4 4L19 7"></path></svg></span> {t('plans:home.Alta prioridade')}
                        </td>
                      </tr>
                      <tr>
                        <td className={`${styles.categoria}`}>{t('plans:home.Limite de armazenamento de arquivos')}</td>
                        <td className={`${styles.planos} ${styles.catAdm} ${styles.bs}`}><span className={`${styles.check_icon}`}>
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M5 13l4 4L19 7"></path></svg></span> {t('plans:home.50 MB')}
                        </td>
                        <td className={`${styles.planos} ${styles.catAdm} ${styles.pr}`}><span className={`${styles.check_icon}`}>
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M5 13l4 4L19 7"></path></svg></span> {t('plans:home.2 GB')}
                        </td>
                      </tr>
                      <tr>
                        <td className={`${styles.categoria}`}>{t('plans:home.Idiomas para o administrador')}</td>
                        <td className={`${styles.planos} ${styles.catAdm} ${styles.bs}`}>
                          <span className={`${styles.check_icon}`}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M5 13l4 4L19 7"></path></svg></span> {t('plans:home.Português, Inglês e Espanhol')}
                        </td>
                        <td className={`${styles.planos} ${styles.catAdm} ${styles.pr}`}>
                          <span className={`${styles.check_icon}`}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M5 13l4 4L19 7"></path></svg></span> {t('plans:home.Português, Inglês e Espanhol')}
                        </td>
                      </tr>
                      <tr>
                        <td className={`${styles.categoria} ${styles.bordaZ}`}>{t('plans:home.Suporte técnico')}</td>
                        <td className={`${styles.planos} ${styles.catAdm} ${styles.bs} ${styles.bordaZ} ${styles.brZ}`}>
                          <span className={`${styles.check_icon}`}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M5 13l4 4L19 7"></path></svg></span> {t('plans:home.FAQ e base de conhecimento aberta')}
                        </td>
                        <td className={`${styles.planos} ${styles.catAdm} ${styles.pr} ${styles.bordaZ} ${styles.brZ}`}>
                          <span className={`${styles.check_icon}`}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M5 13l4 4L19 7"></path></svg></span> {t('plans:home.Email (tempo de resposta do suporte 24 horas | dias úteis)')}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </section>
            </div>

            <section className={`${styles.secao} ${styles.botoesChamadas} row align-items-center ${styles.hideFadeIn}`} ref={el => fadeInElements.current.push(el)}>
              <div className={`col-12 text-center`}>
                <h3>{t('plans:home.Você está pronto(a) para começar a usar o QuizAI')}</h3>
              </div>
              <div className={`${styles.botoes} col-12 text-center`}>
                <a href="#" onClick={() => { goRegister() }} className={`btn ${styles.btn_primary} ${styles.adjust_primary}  mb-3 ${styles.mb_md_0}`}>{t('plans:home.Comece agora')}</a>
              </div>
            </section>

            {openDialog &&
              <DialogBox
                isopen={true}
                questionDialog={message}
                optionsDialog={dialogOption}
                handleClosDialogBox={handleClosDialogBox}
              />
            }
          </div>}
      </HelmetProvider>
    </>
  )
}

export default Plans