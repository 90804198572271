import React, { useEffect, useState } from 'react';
import UsercontentCarousel from "../../components/UsercontentCarousel";
import StartCarousel from '../../components/StartCarousel';
import { useFetchDocument } from '../../hooks/useFetchDocument';

const About = () => {

  const [userContent, setUserContent] = useState(null)

  const userId = "MzTyTkDwZQdkdZD7yoky6a8N4eF2"
  const projectId = "Marketing Viral063838594166.039000000"

  const { document: project, loadingDocProject, errorDocProject } = useFetchDocument("Domains", userId, "Folder", projectId)

  useEffect(() => {
    if (project) {
      //console.log("project: " + project.contentGenerated)
      setUserContent(project.contentGenerated)
    }
  }, [project])

  return (
    <div>
      {userContent
        ? <div>
          <UsercontentCarousel
            userContent={userContent}
          />
        </div>
        : <div>Carregando...</div>}
    </div>
  );
}

export default About