import styles from './Register.module.css'
import { useState, useEffect } from 'react';
import { useAuthentication } from '../../hooks/useAuthentications';
import { useCreateUserDoc } from '../../hooks/useCreateUserDoc';
import { useCreateUserDomain } from '../../hooks/useCreateUserDomain';
import { NavLink } from 'react-router-dom';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import useExcelToJson from '../../hooks/useLoadExcelLanguage';
import { useNavigate } from 'react-router-dom';
import DialogBox from '../../components/DialogBox';
//Translation
import i18n from '../../i18n';
import { useTranslation } from 'react-i18next';

const Register = ({ userSetLanguage }) => {

  const [displayName, setDisplayName] = useState("");
  const [email, setEmail] = useState("");
  const [company, setCompany] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [country, setCountry] = useState("");
  const [language, setLanguage] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [acceptAudience, setAcceptAudience] = useState(false);
  const [error, setError] = useState("");
  const [preferenceBrowserLanguage, setPreferenceBrowserLanguage] = useState("");
  const [pageTexts, setPageTexts] = useState([]);
  const [plan, setPlan] = useState("trial-15");
  const [keepSubscribe, setKeepSubscribe] = useState(false);
  const [showInfo, setShowInfo] = useState(false);
  const [showInfoBasic, setShowInfoBasic] = useState(false);
  const [showInfoTrial, setShowInfoTrial] = useState(false);
  const [showInfoProm, setShowInfoProm] = useState(false);
  const [showInfoProa, setShowInfoProa] = useState(false);
  const [showInfoCoupon, setShowInfoCoupon] = useState(false);
  const [signinGoogle, setSigninGoogle] = useState(false);
  const [couponCode, setCouponCode] = useState("");
  const [openDialogBox, setOpenDialogBox] = useState(false);
  const [msgDialog, setMsgDialog] = useState("");
  const [optionsDialog, setOptionsDialog] = useState([]);
  const [countries, setCountries] = useState([]);
  const [action, setAction] = useState("");

  const { auth, createUser, error: authError, loading, signInWithGoogle } = useAuthentication();
  const { createUserDoc } = useCreateUserDoc();
  const { createUserDomain } = useCreateUserDomain();

  const { t } = useTranslation(['register']);

  const navigate = useNavigate();

  const [userLanguage, setUserLanguage] = useState(userSetLanguage ? userSetLanguage : navigator.language);
  //const userLanguage = "pt"
  const sheetName = 'signup';
  const jsonData = useExcelToJson(sheetName);

  let planId;

  const signWithGoogle = async () => {

    if (!acceptTerms) {
      setOpenDialogBox(true)
      setMsgDialog(t(`register:messages.Termos de Uso e de Privacidade`))
      setOptionsDialog(["OK"])
      return
    }
    plan.includes("pro") && setKeepSubscribe(true);

    const res = await signInWithGoogle();

    const planRegister = plan.includes("pro") ? "trial-15" : plan
    const userID = auth.currentUser.uid;
    const userInfos = {
      email: auth.currentUser.email,
      displayName: auth.currentUser.displayName,
      company,
      jobTitle,
      country,
      language: preferenceBrowserLanguage,
      userID,
      plan: planRegister,
    }
    const resDoc = createUserDoc(userInfos);
    const resUserDomain = createUserDomain(userID, auth.currentUser.email, planRegister, couponCode, plan);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    plan.includes("pro") && setKeepSubscribe(true);

    setError("");

    const user = {
      displayName,
      email,
      password
    };

    if (password !== confirmPassword) {
      setError(t(`register:messages.A confirmação de senha não confere`));
      return
    }

    if (!country) {
      setError(t(`register:messages.Você deve indicar o país onde mora`));
      return
    }

    if (!language) {
      setError(t(`register:messages.Você deve indicar seu idioma de preferência`));
      return
    }

    const res = await createUser(user);

    const planRegister = plan.includes("pro") ? "trial-15" : plan

    const userID = auth.currentUser.uid;
    const userInfos = {
      email,
      displayName,
      company,
      jobTitle,
      country,
      language,
      userID,
      plan: planRegister,
    }
    const resDoc = createUserDoc(userInfos);
    const resUserDomain = createUserDomain(userID, email, planRegister, couponCode, plan);
  }

  const handleClosDialogBox = (f, option) => {
    setOpenDialogBox(false);

  }

  const actionCloseDialogBox = (f, option) => {
    setAction("");
    setOpenDialogBox(false);
    setMsgDialog("")
    setOptionsDialog([])
  }

  useEffect(() => {
    if (jsonData && preferenceBrowserLanguage) {
      const newPageTexts = jsonData.map((data) => data[preferenceBrowserLanguage]);
      setPageTexts(newPageTexts);
    }
  }, [jsonData, preferenceBrowserLanguage])

  useEffect(() => {
    if (authError) {
      setError(authError)
    }
  }, [authError])

  useEffect(() => {
    if (userSetLanguage) {
      setUserLanguage(userSetLanguage)
    }
  }, [userSetLanguage])

  useEffect(() => {
    if (couponCode) {

    }
  }, [couponCode])

  useEffect(() => {
    switch (userLanguage) {
      case "pt-BR":
      case "pt":
        setPreferenceBrowserLanguage("Português");
        break;
      case "en-US":
      case "en":
        setPreferenceBrowserLanguage("Inglês");
        break;
      case "es-ES":
      case "es":
        setPreferenceBrowserLanguage("Espanhol");
        break;
      default:
        setPreferenceBrowserLanguage("Inglês");
        break;
    }
  }, [userLanguage]);

  useEffect(() => {
    if (keepSubscribe) {
      if (plan === "pro-monthly") {
        document.querySelector(".subscribe_pro").click();
      } else if (plan === "pro-annual") {
        document.querySelector(".subscribe_annual").click();
      }
      setKeepSubscribe(false);
    }
  }, [keepSubscribe])

  useEffect(() => {
    fetch('https://restcountries.com/v3.1/all')
      .then((response) => response.json())
      .then((data) => {
        const countryList = data.map((country) => ({
          code: country.cca2,
          name: country.name.common,
        }));
        setCountries(countryList);
      })
      .catch((error) => console.error(error));
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  useEffect(() => {
    if (preferenceBrowserLanguage) {
      var language = "en";
      switch (preferenceBrowserLanguage) {
        case "Português":
          language = "pt";
          break;
        case "Espanhol":
          language = "es";
          break;
        case "Inglês":
          language = "en";
          break;
        default:
          language = "en";
      }
      changeLanguage(language);
      i18n.changeLanguage(language);
    }
  }, [preferenceBrowserLanguage])

  const changeLanguage = (language) => {
    localStorage.setItem('preferenceLanguage', language);
    i18n.changeLanguage(language);
  };

  return (
    <div className={`${styles.div_choosefile}`}>
      <HelmetProvider>
        <Helmet>
          <script src="https://cdn.paddle.com/paddle/v2/paddle.js"></script>
          <script>{`Paddle.Setup({ 
                    seller: 177179, 
                    eventCallback: function(data) {
                      if (data.name === "checkout.completed") {
                        console.log("Data name: " + data);
                        navigateAccount();
                      }}
                    })`}
          </script>
        </Helmet>
        <a href="#" className="paddle_button subscribe_pro d-none" data-customer-email={email} data-items={`[{"priceId": "pri_01haw3tsnx1hsewphcdggtpqbk",
      "quantity": 1}]`} >{t(`register:home.Compre agora`)}</a>
        <a href="#" className="paddle_button subscribe_annual d-none" data-customer-email={email} data-items={`[{"priceId": "pri_01haw3w97tca8rkr4n8hv8p29t",
      "quantity": 1}]`} >{t(`register:home.Compre agora`)}</a>

        <div>
          <div className={`row ${styles.row_items} ${styles.register}`}>
            <div className="col-3 mt-3">
              <i className={`bi bi-person-fill-add ${styles.icon_user}`}></i>
            </div>
            <h3 className={`${styles.texts}`} >{t(`register:home.Crie uma conta gratuita`)}</h3>
            <div className={`${styles.texts}`}>
              <span>{t(`register:home.Caso já possua uma conta faça`)}</span>
              <NavLink
                to="/login"
                className={`${styles.textsHyperlink}`}> {`login`}
              </NavLink>
            </div>
            <form onSubmit={handleSubmit}>
              <label>
                <input
                  className={`${styles.form_field} mt-4`}
                  type="text"
                  name="displayName"
                  required
                  placeholder={t(`register:home.Seu nome`)}
                  value={displayName}
                  onChange={(e) => setDisplayName(e.target.value)} />
              </label>
              <p></p>
              <label>
                <input
                  className={`${styles.form_field}`}
                  type="email"
                  name="email"
                  required
                  placeholder={t(`register:home.Seu email`)}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)} />
              </label>
              <p></p>
              <label>
                <input
                  className={`${styles.form_field}`}
                  type="text"
                  name="company"
                  placeholder={t(`register:home.Sua empresa`)}
                  value={company}
                  onChange={(e) => setCompany(e.target.value)} />
              </label>
              <p></p>
              <label>
                <input
                  className={`${styles.form_field}`}
                  type="text"
                  name="jobTitle"
                  placeholder={t(`register:home.Seu cargo`)}
                  value={jobTitle}
                  onChange={(e) => setJobTitle(e.target.value)} />
              </label>
              <p></p>
              <label>
                <select
                  required
                  name="country"
                  className={`${styles.form_field}`}
                  value={country}
                  onChange={(e) => setCountry(e.target.value)}>
                  <option value="" defaultValue>{t(`register:home.País onde mora`)}</option>
                  {countries.map((country) => (
                    <option key={country.code} value={country.code}>
                      {country.name}
                    </option>
                  ))}
                </select>
              </label>
              <p></p>
              <label>
                <select
                  //className={style.form_select} 
                  required
                  name="language"
                  className={`${styles.form_field}`}
                  value={language}
                  onChange={(e) => setLanguage(e.target.value)}>
                  <option value="" defaultValue>{t(`register:home.Idioma de preferência`)}</option>
                  <option value="Português">{t(`register:home.Português`)}</option>
                  <option value="Inglês">{t(`register:home.Inglês`)}</option>
                  <option value="Espanhol">{t(`register:home.Espanhol`)}</option>
                </select>
              </label>
              <p></p>
              <label>
                <input
                  className={`${styles.form_field}`}
                  type="password"
                  name="password"
                  required
                  placeholder={t(`register:home.Insira uma senha`)}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)} />
              </label>
              <p></p>
              <label>
                <input
                  className={`${styles.form_field}`}
                  type="password"
                  name="confirmPassword"
                  required
                  placeholder={t(`register:home.Confirme sua senha`)}
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)} />
              </label>
              <p></p>

              {/*
              <label className={`${styles.form_checked} pt-2 `}>
                <div className={`${styles.plans}`}>
                  {pageTexts[35]}

                  <div className={`row mt-3 d-flex ms-1 align-items-center`}>
                    <div className={`form-check col-9 ${plan === 'trial-15' ? styles.actual_choice : ''}`}>
                      <input className={`form-check-input ${styles.option_check}`} type="radio" name="planRadioDefault" id="trial-15" onChange={(event) => setPlan(event.target.id)} checked={plan === 'trial-15'} />
                      <span className="form-check-label">
                        {pageTexts[37]}
                      </span>
                    </div>
                    <div onMouseOver={() => setShowInfoTrial(true)} onMouseLeave={() => setShowInfoTrial(false)} className="col-auto">
                      <a >
                        <i className={`bi bi-info-circle-fill fs-4 col-auto ${styles.icon_info}`}></i>
                      </a>
                    </div>
                  </div>


                  <div className={`row mt-3 d-flex ms-1 align-items-center`}>
                    <div className={`form-check col-9 ${plan === 'pro-monthly' ? styles.actual_choice : ''}`}>
                      <input className={`form-check-input ${styles.option_check}`} type="radio" name="planRadioDefault" id="pro-monthly" onChange={(event) => setPlan(event.target.id)} checked={plan === 'pro-monthly'} />
                      <span className="form-check-label">
                        {pageTexts[38]}
                      </span>
                    </div>
                    <div onMouseOver={() => setShowInfoProm(true)} onMouseLeave={() => setShowInfoProm(false)} className="col-auto">
                      <a >
                        <i className={`bi bi-info-circle-fill fs-4 ${styles.icon_info}`}></i>
                      </a>
                    </div>

                  </div>

                  <div className={`row mt-3 d-flex ms-1 align-items-center`}>
                    <div className={`form-check col-8 ${plan === 'pro-annual' ? styles.actual_choice : ''}`}>
                      <input className={`form-check-input ${styles.option_check}`} type="radio" name="planRadioDefault" id="pro-annual" onChange={(event) => setPlan(event.target.id)} checked={plan === 'pro-annual'} />
                      <span className="form-check-label">
                        {pageTexts[39]}
                      </span>
                    </div>
                    <div onMouseOver={() => setShowInfoProa(true)} onMouseLeave={() => setShowInfoProa(false)} className="col-auto">
                      <a >
                        <i className={`bi bi-info-circle-fill fs-4 ${styles.icon_info}`}></i>
                      </a>
                    </div>

                  </div>
                  {showInfoTrial && (
                    <div
                      className={`col-lg-4 col-md-6  col-sm-6  ${styles.info_boxes_preferences} ${styles.adjust}`}
                    >
                      <div
                        className={`alert alert-info alert-dismissible fade show ${styles.alert_costumization}`}
                        role="alert"
                      >
                        <strong>{pageTexts[42]}</strong> {pageTexts[43]}
                      </div>
                    </div>
                  )}
                  {showInfoProm && (
                    <div
                      className={`col-lg-4 col-md-6  col-sm-6  ${styles.info_boxes_preferences} ${styles.adjust}`}
                    >
                      <div
                        className={`alert alert-info alert-dismissible fade show ${styles.alert_costumization}`}
                        role="alert"
                      >
                        <strong>{pageTexts[44]}</strong> {pageTexts[45]}
                      </div>
                    </div>
                  )}
                  {showInfoProa && (
                    <div
                      className={`col-lg-4 col-md-6  col-sm-6  ${styles.info_boxes_preferences} ${styles.adjust_top}`}
                    >
                      <div
                        className={`alert alert-info alert-dismissible fade show ${styles.alert_costumization}`}
                        role="alert"
                      >
                        <strong>{pageTexts[44]}</strong> {pageTexts[45]}
                      </div>
                    </div>
                  )}
                </div>
              </label>
              <p></p>

              <div className={`row  d-flex align-items-center justify-content-center mt-5`}>
                <label className='col-auto'>
                  <input
                    className={`${styles.form_coupon} `}
                    type="text"
                    name="jobTitle"
                    placeholder={pageTexts[46]}
                    value={couponCode}
                    onChange={(e) => setCouponCode(e.target.value)} />
                </label>
                <div onMouseOver={() => setShowInfoCoupon(true)} onMouseLeave={() => setShowInfoCoupon(false)} className="col-auto">
                  <a >
                    <i className={`bi bi-info-circle-fill fs-4 ${styles.icon_info} `}></i>
                  </a>
                </div>
                {showInfoCoupon && (
                  <div
                    className={`col-lg-3 col-md-5  col-sm-5 ${styles.info_boxes_preferences} ${styles.adjust_coupon}`}
                  >
                    <div
                      className={`alert alert-info alert-dismissible fade show ${styles.alert_costumization}`}
                      role="alert"
                    >
                      {pageTexts[47]}
                    </div>
                  </div>
                )}
              </div>
              */}

              <label className={`pt-4 ${styles.form_checked}`}>
                <input
                  className={`form-check-input me-3 ${styles.box_check}`}
                  type="checkbox"
                  name="acceptTerms"
                  required
                  value={acceptTerms}
                  id="flexCheckDefault"
                  onChange={(e) => setAcceptTerms(e.target.checked)}
                />
                <span className={`${styles.texts}`}>{t(`register:home.Li e aceito`)}</span>
                <a className={`${styles.textsHyperlink}`} href="https://www.quizai.biz/terms#" target="_blank">
                  {t(`register:home.os termos de uso`)}
                </a>
                <span className={`${styles.texts}`}> {t(`register:home.e`)}</span>
                <a className={`${styles.textsHyperlink}`} href="https://www.quizai.biz/privacy#" target="_blank">
                  {t(`register:home.privacidade`)}
                </a>
              </label>
              <p></p>
              <label className={`${styles.form_checked}`}>
                <div className={styles.form_checked_text}>
                  <input
                    className={`form-check-input me-3 ${styles.box_check}`}
                    type="checkbox"
                    name="acceptAudience"
                    value={acceptAudience}
                    id="flexCheckDefault"
                    onChange={(e) => setAcceptAudience(e.target.checked)}
                  />
                  <span className={`${styles.texts}`}>{t(`register:home.Quero receber atualizações do produto notícias`)}</span>
                  <div className={`${styles.texts}`}>{t(`register:home.e ofertas exclusivas`)}</div>
                </div>
              </label>

              <p></p>
              {!loading && <button className={`  mt-1 ${styles.btn_style}`}>{t(`register:home.Cadastrar`)} </button>}
              {loading && <button className={`mt-1 ${styles.btn_style}`} disabled>{t(`register:home.Aguarde`)}</button>}

            </form>

            <div className={`mt-3 google_login`}>
              <span className={`${styles.texts}`}>{t(`register:home.Ou se cadastre com`)}</span>
            </div>
            {!loading && <button onClick={() => signWithGoogle()} className={`mt-3 mb-5 ${styles.btn_google}`}><i class="bi bi-google me-3"></i> Google</button>}
            {loading && <button className={`${styles.btn_google}`} disabled><i class="bi bi-google me-3"></i>{t(`register:home.Aguarde`)}</button>}
            {error && <p className="error">{error}</p>}

          </div>
        </div>
        <div>
          {openDialogBox && <DialogBox
            action={action}
            actionScript={actionCloseDialogBox}
            isopen={true}
            questionDialog={msgDialog}
            optionsDialog={optionsDialog}
          />}
        </div>
      </HelmetProvider>
    </div>
  )
}

export default Register;