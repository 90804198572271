//Styles
import 'bootstrap/dist/css/bootstrap.min.css';
import { Accordion, Card, Button } from 'react-bootstrap';
import styles from './AssessmentCase.module.css';
import defaultStyles from '../templates/default.module.css';
import aquaStyles from '../templates/aqua.module.css';
import blueStyles from '../templates/blue.module.css';
import cleanDarkStyles from '../templates/cleanDark.module.css';
import cleanLightStyles from '../templates/cleanLight.module.css';
import cleanNeutralStyles from '../templates/cleanNeutral.module.css';
import coralStyles from '../templates/coral.module.css';
import freshStyles from '../templates/fresh.module.css';
import greenStyles from '../templates/green.module.css';
import pastelStyles from '../templates/pastel.module.css';
import purpleRainStyles from '../templates/purpleRain.module.css';
import quizAIStyles from '../templates/quizai.module.css';

//React Hooks
import { useState, useEffect, useRef } from 'react';

const AssessmentCase = ({ contentGenerated, editProject, template, language, handleReviewContentGenerated, handleChangeContent, infoText, changeProjectName }) => {

    const [caseTitle, setCaseTitle] = useState("");
    const [caseIntro, setCaseIntro] = useState("");
    const [companyDesc, setCompanyDesc] = useState("");
    const [problemContext, setProblemContext] = useState("");
    const [challengeCase, setChallengeCase] = useState("");
    const [problemAnalysis, setProblemAnalysis] = useState("");
    const [proposedSolution, setProposedSolution] = useState("");
    const [solutionImplemantation, setSolutionImplemantation] = useState("");
    const [resultsImpacts, setResultsImpacts] = useState("");
    const [lessonsLearned, setLessonsLearned] = useState("");
    const [caseConclusion, setCaseConclusion] = useState("");
    const [chooseTemplate, setChooseTemplate] = useState(template);
    const [contentCase, setContentCase] = useState(null)

    const [contentReview, setContentReview] = useState();
    let wichLanguage = 0;
    let review;

    const saveReviewedContent = () => {
        handleChangeContent(true)
        review = formatarObjetoString(contentCase)
        setContentReview(review)
        const jsonContentReviwed = contentCase
        handleReviewContentGenerated(review, jsonContentReviwed)
    }

    const formatarObjetoString = (objeto) => {
        const words = [
            ["Título:", "Introdução:", "Descrição", "Contexto", "Desafio/Problema", "Análise do Problema", "Solução Proposta", "Implementação da Solução", "Resultados e Impactos", "Lições Aprendidas", "Conclusão"],
            ["Title:", "Introduction:", "Description", "Context", "Challenge/Problem", "Problem Analysis", "Proposed Solution", "Solution Implementation", "Results and Impacts", " Lessons Learned", "Conclusion"],
            ["Título:", "Introducción:", "Descripción", "Contexto", "Desafío/Problema", "Análisis del Problema", "Solución Propuesta", "Implementación de la Solución", "Resultados e Impactos", "Lecciones Aprendidas", "Conclusión"],
            ["Titel:", "Einleitung:", "Beschreibung", "Kontext", "Herausforderung/Problem", "Problemanalyse", "Vorgeschlagene Lösung", " Umsetzung der Lösung", " Ergebnisse und Auswirkungen", "Erfahrungswerte", "Schlussfolgerung"],
            ["Titre:", "Introduction:", "Description", "Contexte", "Défi/Problème", "Analyse du Problème", "Solution Proposée", "Mise en œuvre de la Solution", "Résultats et Impacts", "Leçons Apprises", "Conclusion"],
            ["Titolo:", "Introduzione:", "Descrizione", "Contesto", "Sfida/Problema", "Analisi del Problema", "Soluzione Proposta", "Implementazione della Soluzione", "Risultati e Impatti", "Lezioni Apprese", "Conclusione"],
            ["Заголовок:", "Введение:", "Описание", "Контекст", "Вызов/Проблема", "Анализ Проблемы", "Предлагаемое Решение", "Внедрение Решения", "Результаты и Влияние", "Извлеченные Уроки", "Заключение"],
            ["标题:", "介绍:", "描述", "上下文", "挑战/问题", "问题分析", "提议的解决方案", "解决方案的实施", "结果和影响", "启示与教训", "结论"],
            ["タイトル:", "紹介:", "説明", "コンテキスト", "チャレンジ/問題", "問題分析", "提案された解決策", "解決策の実施", "結果と影響", "得られた教訓", "結論"],
            ["제목:", "소개:", "설명", "문맥", "도전/문제", "문제 분석", "제안된 해결책", "해결책의 구현", "결과 및 영향", "배운 교훈", "결론"]
        ];

        let objetoString = `${words[wichLanguage][0]} ${objeto.title}\n`;
        objetoString += `${words[wichLanguage][1]} ${objeto.intro}\n`;

        if (objeto.company_description) {
            objetoString += `${words[wichLanguage][2]}: `;
            objetoString += `${objeto.company_description}\n`;
            objetoString += `${words[wichLanguage][3]}: `;
            objetoString += `${objeto.problem_context}\n`;
            objetoString += `${words[wichLanguage][4]}: `;
            objetoString += `${objeto.problem_or_challenge_description}\n`;
            objetoString += `${words[wichLanguage][5]}: `;
            objetoString += `${objeto.problem_analysis}\n`;
            objetoString += `${words[wichLanguage][6]}: `;
            objetoString += `${objeto.proposed_solution}\n`;
            objetoString += `${words[wichLanguage][7]}: `;
            objetoString += `${objeto.solution_implementation}\n`;
            objetoString += `${words[wichLanguage][8]}: `;
            objetoString += `${objeto.results_and_impacts}\n`;
            objetoString += `${words[wichLanguage][9]}: `;
            objetoString += `${objeto.lessons_learned}\n`;
        }

        objetoString += `${words[wichLanguage][10]}: `;
        objetoString += `${objeto.conclusion}`;

        return objetoString;
    };

    useEffect(() => {
        switch (language) {
            case "Portuguese":
                wichLanguage = 0;
                break;
            case "English":
                wichLanguage = 1;
                break;
            case "Spanish":
                wichLanguage = 2;
                break;
            case "German":
                wichLanguage = 3;
                break;
            case "French":
                wichLanguage = 4;
                break;
            case "Italian":
                wichLanguage = 5;
                break;
            case "Russian":
                wichLanguage = 6;
                break;
            case "Mandarin":
                wichLanguage = 7;
                break;
            case "Japanese":
                wichLanguage = 8;
                break;
            case "Korean":
                wichLanguage = 9;
                break;
            default:
                wichLanguage = 0;
                break;
        }
    }, [language])

    useEffect(() => {
        setCaseTitle(contentGenerated.title)
        setCaseIntro(contentGenerated.intro)
        setCompanyDesc(contentGenerated.company_description)
        setProblemContext(contentGenerated.problem_context)
        setChallengeCase(contentGenerated.problem_or_challenge_description)
        setProblemAnalysis(contentGenerated.problem_analysis)
        setProposedSolution(contentGenerated.proposed_solution)
        setSolutionImplemantation(contentGenerated.solution_implementation)
        setResultsImpacts(contentGenerated.results_and_impacts)
        setLessonsLearned(contentGenerated.lessons_learned)
        setCaseConclusion(contentGenerated.conclusion)
        setChooseTemplate(template)

    }, [contentGenerated])

    useEffect(() => {
        if (caseTitle, caseIntro, companyDesc, problemContext, challengeCase, problemAnalysis, proposedSolution, solutionImplemantation, resultsImpacts, lessonsLearned, caseConclusion) {
            const rev = {
                title: caseTitle,
                intro: caseIntro,
                company_description: companyDesc,
                problem_context: problemContext,
                problem_or_challenge_description: challengeCase,
                problem_analysis: problemAnalysis,
                proposed_solution: proposedSolution,
                solution_implementation: solutionImplemantation,
                results_and_impacts: resultsImpacts,
                lessons_learned: lessonsLearned,
                conclusion: caseConclusion
            }
            setContentCase(rev)
        }
    }, [caseTitle, caseIntro, companyDesc, problemContext, challengeCase, problemAnalysis, proposedSolution, solutionImplemantation, resultsImpacts, lessonsLearned, caseConclusion])

    useEffect(() => {
        if (contentCase && contentCase != contentGenerated) {
            saveReviewedContent();
        }
    }, [contentCase])

    useEffect(() => {
        if (template) {
            setChooseTemplate(template);
        }
    }, [template])

    useEffect(() => {
        caseTitle && changeProjectName(caseTitle)
    }, [caseTitle])

    return (
        <div>
            <Accordion defaultActiveKey="0" >
                <Accordion.Item eventKey="0" className={`${chooseTemplate.acordionItem}`}>
                    <Accordion.Header className={`${chooseTemplate.accordion_header}`}>
                        <h5 className={`${chooseTemplate.subtitles}`}>Introdução</h5>
                    </Accordion.Header>
                    <Accordion.Body className={`${chooseTemplate.acordionBody}`}>
                        <h5 >
                            <textarea
                                disabled={!editProject}
                                className={`${chooseTemplate.intro}`}
                                type="text"
                                name="intro"
                                placeholder="Introdução"
                                rows={5}
                                value={caseIntro}
                                onChange={(e) => setCaseIntro(e.target.value)}
                            />
                        </h5>
                    </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="1" className={`${chooseTemplate.acordionItem}`}>
                    <Accordion.Header className={`${chooseTemplate.accordion_header}`}>
                        <h5 className={`${chooseTemplate.subtitles}`}>Descrição</h5>
                    </Accordion.Header>
                    <Accordion.Body className={`${chooseTemplate.acordionBody}`}>
                        <h5>
                            <textarea
                                disabled={!editProject}
                                className={`${chooseTemplate.companydesc}`}
                                type="text"
                                name="companyDesc"
                                placeholder="Descrição"
                                rows={5}
                                value={companyDesc}
                                onChange={(e) => setCompanyDesc(e.target.value)}
                            />
                        </h5>
                    </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="2" className={chooseTemplate.acordionItem}>
                    <Accordion.Header className={chooseTemplate.accordion_header}>
                        <h5 className={chooseTemplate.subtitles}>Contexto do Problema</h5>
                    </Accordion.Header>
                    <Accordion.Body className={chooseTemplate.acordionBody}>
                        <h5>
                            <textarea
                                disabled={!editProject}
                                className={chooseTemplate.problemcontext}
                                type="text"
                                name="problemContext"
                                placeholder="Contexto do Problema"
                                rows={5}
                                value={problemContext}
                                onChange={(e) => setProblemContext(e.target.value)}
                            />
                        </h5>
                    </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="3" className={`${chooseTemplate.acordionItem}`}>
                    <Accordion.Header className={`${chooseTemplate.accordion_header}`}>
                        <h5 className={`${chooseTemplate.subtitles}`}>Desafios</h5>
                    </Accordion.Header>
                    <Accordion.Body className={`${chooseTemplate.acordionBody}`}>
                        <h5 >
                            <textarea
                                disabled={!editProject}
                                className={`${chooseTemplate.challengecase}`}
                                type="text"
                                name="challengecase"
                                placeholder="Desafios"
                                rows={5}
                                value={challengeCase}
                                onChange={(e) => setChallengeCase(e.target.value)}
                            />
                        </h5>
                    </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="4">
                    <Accordion.Header className={`${chooseTemplate.accordion_header}`}>
                        <h5 className={`${chooseTemplate.subtitles}`}>Análise do Problema</h5>
                    </Accordion.Header>
                    <Accordion.Body className={`${chooseTemplate.acordionBody}`}>
                        <h5 >
                            <textarea
                                disabled={!editProject}
                                className={`${chooseTemplate.problemanalysis}`}
                                type="text"
                                name="problemanalysis"
                                placeholder="Análise do Problema"
                                rows={5}
                                value={problemAnalysis}
                                onChange={(e) => setProblemAnalysis(e.target.value)}
                            />
                        </h5>
                    </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="5">
                    <Accordion.Header className={`${chooseTemplate.accordion_header}`}>
                        <h5 className={`${chooseTemplate.subtitles}`}>Solução Proposta</h5>
                    </Accordion.Header>
                    <Accordion.Body className={`${chooseTemplate.acordionBody}`}>
                        <h5 >
                            <textarea
                                disabled={!editProject}
                                className={`${chooseTemplate.proposedsolution}`}
                                type="text"
                                name="proposedSolution"
                                placeholder="Solução Proposta"
                                rows={5}
                                value={proposedSolution}
                                onChange={(e) => setProposedSolution(e.target.value)}
                            />
                        </h5>
                    </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="6">
                    <Accordion.Header className={`${chooseTemplate.accordion_header}`}>
                        <h5 className={`${chooseTemplate.subtitles}`}>Implementação</h5>
                    </Accordion.Header>
                    <Accordion.Body className={`${chooseTemplate.acordionBody}`}>
                        <h5 >
                            <textarea
                                disabled={!editProject}
                                className={`${chooseTemplate.solution} `}
                                type="text"
                                name="solutionImplemantation"
                                placeholder="Implementação"
                                rows={5}
                                value={solutionImplemantation}
                                onChange={(e) => setSolutionImplemantation(e.target.value)}
                            />
                        </h5>
                    </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="7">
                    <Accordion.Header className={`${chooseTemplate.accordion_header}`}>
                        <h5 className={`${chooseTemplate.subtitles}`}>Resultados e Impactos</h5>
                    </Accordion.Header>
                    <Accordion.Body className={`${chooseTemplate.acordionBody}`}>
                        <h5 >
                            <textarea
                                disabled={!editProject}
                                className={`${chooseTemplate.impacts}`}
                                type="text"
                                name="resultsImpacts"
                                placeholder="Resultados e Impactos"
                                rows={5}
                                value={resultsImpacts}
                                onChange={(e) => setResultsImpacts(e.target.value)}
                            />
                        </h5>
                    </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="8">
                    <Accordion.Header className={`${chooseTemplate.accordion_header}`}>
                        <h5 className={`${chooseTemplate.subtitles}`}>Lições Aprendidas</h5>
                    </Accordion.Header>
                    <Accordion.Body className={`${chooseTemplate.acordionBody}`}>
                        <h5 >
                            <textarea
                                disabled={!editProject}
                                className={`${chooseTemplate.lessons}`}
                                type="text"
                                name="lessonsLearned"
                                placeholder="Lições Aprendidas"
                                rows={5}
                                value={lessonsLearned}
                                onChange={(e) => setLessonsLearned(e.target.value)}
                            />
                        </h5>
                    </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="9" className={`${chooseTemplate.accordionItem}`}>
                    <Accordion.Header className={`${chooseTemplate.accordion_header}`}>
                        <h5 className={`${chooseTemplate.subtitles}`}>Conclusão</h5>
                    </Accordion.Header>
                    <Accordion.Body className={`${chooseTemplate.acordionBody} ${chooseTemplate.conclusionBox}`}>
                        <h5 >
                            <textarea
                                disabled={!editProject}
                                className={`${chooseTemplate.conclusion}`}
                                type="text"
                                name="caseConclusion"
                                placeholder="Conclusão"
                                rows={5}
                                value={caseConclusion}
                                onChange={(e) => setCaseConclusion(e.target.value)}
                            />
                        </h5>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </div>
    )
}

export default AssessmentCase;
