import React, { useEffect, useRef, useState } from 'react';
import 'bootstrap-icons/font/bootstrap-icons.css';
import styles from './MenuCourse.module.css';

function MenuCourse({ handleChangeDropdown, items, visitedSlides }) {
    const [isOpen, setIsOpen] = useState(false);

    const dropdownRef = useRef(null);

    const handleChangeSlidePage = (page) => {
        handleChangeDropdown(page)
    }

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    }

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        }
    }, []);

    return (
        <div >

            <div class="dropup">
                <button className={`btn btn-secondary dropdown-toggle ${styles.button_menu} `} type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <i className={`bi bi-list ${styles.menu_icon}`}></i>
                </button>
                <ul class="dropdown-menu">
                    <li><button className="dropdown-item" disabled={!(0 < visitedSlides-1)} onClick={() => handleChangeSlidePage(0)} >{items[0]}</button></li>
                    <li><button className="dropdown-item" disabled={!(1 < visitedSlides-1)} onClick={() => handleChangeSlidePage(1)} >{items[1]}</button></li>
                    <li><button className="dropdown-item" disabled={!(2 < visitedSlides-1)} onClick={() => handleChangeSlidePage(2)} >{items[2]}</button></li>
                    <li><button className="dropdown-item" disabled={!(3 < visitedSlides-1)} onClick={() => handleChangeSlidePage(3)} >{items[3]}</button></li>
                    <li><button className="dropdown-item" disabled={!(4 < visitedSlides-1)} onClick={() => handleChangeSlidePage(4)} >{items[4]}</button></li>
                    <li><button className="dropdown-item" disabled={!(5 < visitedSlides-1)} onClick={() => handleChangeSlidePage(5)} >{items[5]}</button></li>
                    <li><button className="dropdown-item"  disabled={!(6 < visitedSlides-1)} onClick={() => handleChangeSlidePage(6)} >{items[6]}</button></li>
                </ul>
            </div>
        </div>
    );
}

export default MenuCourse;