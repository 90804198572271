import React, { useEffect, useRef, useState } from 'react';
import { useForm, ValidationError } from '@formspree/react';
import styles from './Contact.module.css';
import useExcelToJson from '../../hooks/useLoadExcelLanguage';
import { useLanguageValue } from '../../context/LanguageContext';
import { useAuthValue } from '../../context/AuthContext';
import { useNavigate } from 'react-router-dom';


const Contact = ({ userSetLanguage }) => {
    const fadeInElements = useRef([]);
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const [state, handleSubmit] = useForm("mnqkgzdz");
    const [formClass, setFormClass] = useState("");
    const [userLanguage, setUserLanguage] = useState(userSetLanguage ? userSetLanguage : navigator.language);
    const { user } = useAuthValue();
    const { preferenceLanguage } = useLanguageValue();

    const [preferenceBrowserLanguage, setPreferenceBrowserLanguage] = useState();
    const [pageTexts, setPageTexts] = useState([]);

    const sheetName = 'Contact';
    const jsonData = useExcelToJson(sheetName);

    const navigate = useNavigate();

    useEffect(() => {
        if (userSetLanguage) {
            setUserLanguage(userSetLanguage)
        }
    }, [userSetLanguage])

    useEffect(() => {
        switch (userLanguage) {
            case "pt-BR":
            case "pt":
                setPreferenceBrowserLanguage("Português");
                break;
            case "en-US":
            case "en":
                setPreferenceBrowserLanguage("Inglês");
                break;
            case "es-ES":
            case "es":
                setPreferenceBrowserLanguage("Espanhol");
                break;
            default:
                setPreferenceBrowserLanguage("Inglês");
                break;
        }
    }, [userLanguage]);

    useEffect(() => {
        if (jsonData && preferenceLanguage && preferenceBrowserLanguage) {
            const newPageTexts = jsonData.map((data) => data[user ? preferenceLanguage : preferenceBrowserLanguage]);
            setPageTexts(newPageTexts);
        }
    }, [jsonData, preferenceLanguage, preferenceBrowserLanguage])

    useEffect(() => {
        const options = {
            root: null,
            rootMargin: '0px',
            threshold: 0.2,
        };

        const observer = new IntersectionObserver((entries, observer) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add(styles.fadeIn);
                    observer.unobserve(entry.target);
                }
            });
        }, options);

        fadeInElements.current.forEach(element => {
            observer.observe(element);
        });

        return () => {
            observer.disconnect();
        };
    }, []);
    /* --- INICIO --- adicionar para retirar o padding do container parent da página */
    useEffect(() => {
        const capsula = document.querySelector('.container');
        if (capsula) {
            capsula.classList.add('p-0');
        }
    }, []);
    /* --- FIM --- adicionar para retirar o padding do container parent da página */
    /* --- INICIO INTERACAO FORMULÁRIO --- */
    const handleFormSubmit = async (event) => {
        event.preventDefault();

        try {
            await handleSubmit(event);
            setIsFormSubmitted(true);
            setFormClass("p-0");
        } catch (error) {
            console.error(error);
        }
    };




    /* --- FIM INTERACAO FORMULÁRIO --- */

    return (
        <div>
            <section id="contact" className={`${styles.call_to_action} ${styles.hideFadeIn} ${styles.secao}`} ref={el => fadeInElements.current.push(el)}>
                <div className={`${styles.overlay}`}></div>
                <div className={`container text-center ${styles.text_md_left}`}>
                    <div className={`row`}>
                        <div className={`col-sm-12 col-md-6 ${styles.cabecalho}`}>
                            <h1>{pageTexts[0]}</h1>
                        </div>
                    </div>
                </div>
            </section>

            <section className={`row align-items-center ${styles.formulario}  ${styles.hideFadeIn} ${styles.secao}`} ref={el => fadeInElements.current.push(el)}>
                <div className={`${styles.area}`}>
                    <div className={`col-12 text-center`}>
                        {isFormSubmitted ? (
                            <h2>{pageTexts[1]}</h2>
                        ) : (
                            <>
                                <h2>{pageTexts[2]}</h2>
                                <p className={`p-0 m-0`}>{pageTexts[3]}</p>
                                <p className={`p-0 m-0`}>{pageTexts[4]}</p>
                            </>
                        )}
                    </div>
                    <form action="https://formspree.io/f/mnqkgzdz" method="POST" id="contact-form" className={`${styles.text_md_left}`} onSubmit={handleFormSubmit}>
                        {isFormSubmitted ? (
                            <p></p>
                        ) : (
                            <>
                                <div className={`form-group`}>
                                    <label htmlFor="firstName">{pageTexts[5]}</label>
                                    <input type="text" className={`form-control`} id="firstName" name="firstName" required />
                                </div>
                                <div className={`form-group`}>
                                    <label htmlFor="lastName">{pageTexts[6]}</label>
                                    <input type="text" className={`form-control`} id="lastName" name="lastName" required />
                                </div>
                                <div className={`form-group`}>
                                    <label htmlFor="email">{pageTexts[7]}</label>
                                    <input type="email" className={`form-control`} id="email" name="email" required />
                                    <ValidationError prefix="Email" field="email" errors={state.errors} />
                                </div>
                                <div className={`form-group`}>
                                    <label htmlFor="message">{pageTexts[8]}</label>
                                    <textarea className={`form-control`} id="message" name="message" rows="3" maxLength="1800" required></textarea>
                                    <ValidationError prefix="Message" field="message" errors={state.errors} />
                                </div>
                                <div className={`form-group text-center mt-4`}>
                                    <button type="submit" className={`text-center ${styles.btn}  ${styles.btn_primary} ${styles.btn_enviar}`} disabled={state.submitting}>
                                        {pageTexts[9]}
                                    </button>
                                </div>
                            </>
                        )}
                    </form>
                </div>
            </section>
        </div>
    )
}

export default Contact;