import styles from './DialogBoxRename.module.css'
import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import quizAiLogo from '../images/logoQuizAI.png';
import { useLanguageValue } from '../context/LanguageContext';
import useExcelToJson from '../hooks/useLoadExcelLanguage';

function DialogBoxDeleteAccount({ actionDelete, questionDialog, isopen }) {

    const [newName, setNewName] = useState();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [modalIsOpen, setModalIsOpen] = useState(isopen);
    const [pageTexts, setPageTexts] = useState([]);
  
    const { preferenceLanguage } = useLanguageValue();
    const sheetName = 'dialogbox';
    const jsonData = useExcelToJson(sheetName);

    let actionChange;

    const handleCloseModal = (event) => {
        const confirm = event    
        const confirmed = (confirm != "Cancel" ) ? true : false;
        actionDelete(confirmed, email, password);
    };

    useEffect(() => {
        if (jsonData && preferenceLanguage) {
          const newPageTexts = jsonData.map((data) => data[preferenceLanguage]);
          setPageTexts(newPageTexts);
        }
      }, [jsonData, preferenceLanguage])

    return (
        <div>
            <Modal show={modalIsOpen} onHide={handleCloseModal} backdrop="static" centered>
                <Modal.Header >
                    <img src={quizAiLogo} alt="QuizAI" className={styles.logo} />

                </Modal.Header>
                <Modal.Body>
                    <div>

                        {questionDialog}

                        <label>
                            <p></p>
                            <input
                                className={`${styles.form_rename}`}
                                type="text"
                                required
                                name="email"
                                placeholder="email"
                                value={email}
                                onChange={(e) => { setEmail(e.target.value) }}
                            />
                        </label>
                        <label>
                            <p></p>
                            <input
                                className={`${styles.form_rename}`}
                                type="password"
                                required
                                name="password"
                                placeholder="password"
                                value={password}
                                onChange={(e) => { setPassword(e.target.value) }}
                            />
                        </label>

                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div>
                        <button className={`${styles.btn_cancel} me-3`} value={actionChange} onClick={() => handleCloseModal("Cancel")}>Cancelar</button>
                        <button className={`${styles.btn_style}`} value={actionChange} onClick={() => handleCloseModal()}>Excluir conta</button>
                    </div>
                </Modal.Footer>


            </Modal>
        </div>
    );
}

export default DialogBoxDeleteAccount;