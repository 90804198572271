//Translation
import i18n from '../i18n';
import { useTranslation } from 'react-i18next';
// styles
import Modal from 'react-bootstrap/Modal';
import styles from './ProjectExport.module.css'
import { Nav, NavItem, NavLink } from 'react-bootstrap';
// jquery
import JSZip from 'jszip';
import axios from 'axios';
// React hooks
import { useEffect, useState } from 'react';
// User hooks
import { useGenerateHTML } from '../hooks/useGenerateHTML';
//Scorm Files
import { useGenerateManifest } from '../hooks/useGenerateManifest';
import { useGenerateManifest2004 } from '../hooks/useGenerateManifest2004';
import { useGenerateAPIWrapper } from '../hooks/useGenerateAPIWrapper';
import { useGenerateSCOFunctions } from '../hooks/useGenerateSCOFunctions';
import { useGenerateScormFunctions } from '../hooks/useGenerateScormFunctions';
import { useGenerateContentFunctions } from '../hooks/useGenerateContentFunctions';
import { useGenerateImsmd } from '../hooks/useGenerateImsmd';
import { useGenerateImscp } from '../hooks/useGenerateImscp';
import { useGenerateIms } from '../hooks/useGenerateIms';
import { useGenerateAdlcp } from '../hooks/useGenerateAdlcp';
//Dev Hooks
import { useLanguageValue } from '../context/LanguageContext';
import { saveAs } from 'file-saver';
import HtmlDocx from 'html-docx-js/dist/html-docx';
import useExcelToJson from '../hooks/useLoadExcelLanguage';
//Firebase
import { getDownloadURL, listAll, ref } from "firebase/storage";
import { storage } from "../firebase/config";
//User components
import ExportScorm from './ExportScorm';
import ExportText from './ExportText';


const ProjectExport = ({ projectInfos, closeExportExportProject }) => {

  const { t } = useTranslation(['projectpreview']);
  const { preferenceLanguage } = useLanguageValue();

  const [exportFormat, setExportFormat] = useState("scorm")

  const changeExportFormat = (format) => {
    setExportFormat(format)
  }

  useEffect(() => {
    if (preferenceLanguage) {
      var language = "en";
      switch (preferenceLanguage) {
        case "Português":
          language = "pt"
          break;
        case "Espanhol":
          language = "es"
          break;
        case "Inglês":
          language = "en"
          break;
      }
      changeLanguage(language);
      i18n.changeLanguage(language);
    }
  }, [preferenceLanguage])

  const changeLanguage = (language) => {
    localStorage.setItem('preferenceLanguage', language);
    i18n.changeLanguage(language);
  };

  const handleCloseExportProject = (option) => {
    closeExportExportProject(option)
  }

  return (
    <div className='row p-0 m-0'>
      <div className={`col-4 ${exportFormat === "scorm" ? styles.menu_item_active : ''} ${styles.menu_items}`} onClick={() => changeExportFormat('scorm')}>
        SCORM
      </div>
      <div className={`col-4 ${exportFormat === "html" ? styles.menu_item_active : ''} ${styles.menu_items} `} onClick={() => changeExportFormat('html')}>
        HTML
      </div>
      <div className={`col-4 ${exportFormat === "text" ? styles.menu_item_active : ''}  ${styles.menu_items} `} onClick={() => changeExportFormat('text')}>
        {t('projectpreview:projectexport.TEXTOS')}
      </div>
      {exportFormat === "scorm" &&
        <div>
          <ExportScorm
            project={projectInfos}
            exportFormat={exportFormat}
            handleCloseExportProject={handleCloseExportProject}
          />
        </div>}
      {exportFormat === "html" &&
        <div>
          <ExportScorm
            project={projectInfos}
            exportFormat={exportFormat}
            handleCloseExportProject={handleCloseExportProject}
          />
        </div>}
      {exportFormat === "text" &&
        <div>
          <ExportText
            project={projectInfos}
          />
        </div>}
    </div>
  )
}

export default ProjectExport