//Translation
import i18n from '../i18n';
import { useTranslation } from 'react-i18next';
//Styles
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import styles from './AssessmentParameters.module.css'
//React Hooks
import { useEffect, useState, useRef } from 'react';
//Dev Hooks
import { useLanguageValue } from '../context/LanguageContext';
import { useAskChatGPT } from '../hooks/useAskChatGPT';
//Dev components
import AssessmentTopics from './AssessmentTopics';
//Firebase
import { Timestamp } from 'firebase/firestore';
//External Bib
import mammoth from 'mammoth';
import { Row } from 'react-bootstrap';

const AssessmentParameters = ({ idProject, projectContent, assessmentIAGenerated, showDivFloating, qtdQuestions, notConsider, changeIdProject, projectSelected, actionMsgDialog, addFileManualQuestion, generateQuestions, pn, handleMsgError, IAGeneratedQuestions, objectSubtitle, translateIAGenerated, handleChangeLanguage, originalLanguage }) => {

  const { t } = useTranslation(['projectpreview']);
  const { preferenceLanguage } = useLanguageValue();

  const [questionQ, setQuestionQ] = useState(0);
  const [choiceQ, setChoiceQ] = useState(4);
  const [feedback, setFeedback] = useState(true);
  const [language, setLanguage] = useState("Portuguese");
  const [level, setLevel] = useState("medium");
  const [disableForm, setDisableForm] = useState(false);
  const [errorGPT, setErrorGPT] = useState("");
  const [assessmentIA, setAssessmentIA] = useState([]);
  const [askingIA, setAskingIA] = useState(false);
  const [qtdMaxQuestions, setQtdMaxQuestions] = useState(20 - qtdQuestions);
  const [progresstimer, setProgresstimer] = useState(0);
  const [totaltime, setTotaltime] = useState(0);
  const [projectName, setProjectName] = useState(pn);
  const [projectDescription, setProjectDescription] = useState("");
  const [AIHelp, setAIHelp] = useState(true);
  const [msgText, setMsgText] = useState(t('projectpreview:asssessmentparameters.aguarde quizz sendo gerado'))
  const [IALimitQuestions, setIALimitQUestions] = useState(true);
  const [generatingTopics, setGeneratingTopics] = useState(false);
  const [generatingQuizz, setGeneratingQuizz] = useState(false);
  const [translatingSubtitles, setTranslatingSubtitles] = useState(false);
  const [showTopics, setShowTopics] = useState(false);
  const [topics, setTopics] = useState([]);
  const [selectedTopics, setSelectedTopics] = useState([]);
  const [buttonText, setButtonText] = useState("Quiz");
  const [fileContent, setFileContent] = useState('');
  const [fileName, setFileName] = useState("");
  const [url, setUrl] = useState("");
  const [subtitleLanguage, setSubtitleLanguage] = useState("");


  const { handleOpenAiApi, returnGPT, textTranslated, loading, error, generateGPT } = useAskChatGPT();

  const fileInputRef = useRef(null);

  const handleProjectName = (e) => {
    setProjectName(e.target.value)
  }

  const newIdProject = async (name) => {
    if (name === "") {
      actionMsgDialog(t('projectpreview:asssessmentparameters.definir Tema projeto'))
      return
    }

    const id = name + Timestamp.now();
    //setIdProject(id)
    changeIdProject(id, name, AIHelp, null, language, "assessment", fileContent);
  }

  const changeQuestionQty = (questionqty) => {
    setQuestionQ(questionqty);
  }

  const changeChoicesQty = (choicesqty) => {
    setChoiceQ(choicesqty);
  }

  const changeFeedback = (feed) => {
    setFeedback(feed);
  }

  const changeLevel = (level) => {
    setLevel(level);
  }

  const changeProjectLanguage = (lang) => {
    handleChangeLanguage(lang);
    setLanguage(lang);
  }

  const handleFileChange = async (filechoose) => {
    if (filechoose) {
      setFileName(filechoose.name)
      if (filechoose.name.endsWith('.docx')) {
        try {
          const arrayBuffer = await filechoose.arrayBuffer();
          const { value } = await mammoth.extractRawText({ arrayBuffer });
          setFileContent(value);
        } catch (error) {
          setMsgText(t('projectpreview:asssessmentparameters.Erro ler arquivo'), error);
        }
      } else if (filechoose.name.endsWith('.txt')) {
        const fileReader = new FileReader();
        fileReader.onloadend = (event) => {
          setFileContent(event.target.result);
        };
        fileReader.readAsText(filechoose);
      } else {
        setMsgText(t('projectpreview:asssessmentparameters.Arquivo não suportado'));
      }
    }
  }

  const handleAskTranslateGPT = async () => {
    window.scrollTo(0, 0);
    setMsgText(t('projectpreview:asssessmentparameters.traduzindo legendas'))
    const textToTranslate = JSON.stringify(objectSubtitle);
    setTranslatingSubtitles(true)
    handleIncreaseTime();
    setAskingIA(true);
    try {
      showDivFloating(false)
      const res = await handleOpenAiApi({
        objectSubtitle,
        language,
        orderGPT: "translate",

      });
    } catch (error) {
      showDivFloating(true)
      setErrorGPT(error);
      setAskingIA(false);
    }
  }

  const handleAskAssessmentGPT = async () => {
    window.scrollTo(0, 0);
    if (AIHelp) {
      setMsgText(t('projectpreview:asssessmentparameters.aguarde quizz sendo gerado'));
      setGeneratingQuizz(true)
      handleIncreaseTime();
      setAskingIA(true);
      try {
        showDivFloating(false)
        const res = await handleOpenAiApi({
          subject: projectName,
          selectedTopics,
          questionQ,
          choiceQ,
          feedback,
          level,
          language,
          orderGPT: fileContent ? "userText" : "assessment",
          userInput: fileContent ? fileContent : projectContent ? projectContent : "",
          notConsider
        });
      } catch (error) {
        showDivFloating(true)
        setErrorGPT(error);
        setAskingIA(false);
      }
    } else {
      addFileManualQuestion(true)
    }
  }

  const handleIncreaseTime = () => {
    var total = questionQ * 3;
    if (translatingSubtitles) {
      total = total * 2;
    }
    setTotaltime(total)
    const increasetimer = 1
    const timer = setInterval(() => {
      setProgresstimer((prevProgress) => Math.min(prevProgress + increasetimer, total));
    }, 1000);

    return () => clearInterval(timer);
  }

  const handleAskTopicsChatGPT = async () => {
    handleIncreaseTime();
    setMsgText(t('projectpreview:asssessmentparameters.tópicos sendo gerados'))
    setAskingIA(true);
    setGeneratingTopics(true)

    try {
      const res = await handleOpenAiApi({ subject: projectName, orderGPT: "topics" });
    } catch (error) {
      setErrorGPT(error);
    }
  }

  const handleSetTopics = (topic) => {
    if (selectedTopics.includes(topic)) {
      setSelectedTopics(selectedTopics.filter(item => item !== topic));
    } else {
      setSelectedTopics([...selectedTopics, topic]);
    }
  }

  const handleDirectionClick = () => {
    if (buttonText === "Tópicos" || buttonText === "Topics" || buttonText === "Temas") {
      handleAskTopicsChatGPT();
    } else if (buttonText === "Quiz" && idProject) {
      handleAskAssessmentGPT();
    } else if (buttonText === "Quiz" && !idProject) {
      newIdProject(projectName);
    }
  }

  const handleButtonClick = (event) => {
    event.preventDefault();
    fileInputRef.current.click();
  }

  const handleClearFile = (event) => {
    event.preventDefault();
    setFileName("");
    setFileContent("");
  }

  useEffect(() => {
    console.log("originalLanguage ", originalLanguage)
  }, [originalLanguage])

  useEffect(() => {
    language && console.log("language ", language)
  }, [language])

  useEffect(() => {
    subtitleLanguage && console.log("subtitleLanguage ", subtitleLanguage)
  }, [subtitleLanguage])

  useEffect(() => {
    if (preferenceLanguage) {
      var language = "en";
      switch (preferenceLanguage) {
        case "Português":
          language = "pt"
          break;
        case "Espanhol":
          language = "es"
          break;
        case "Inglês":
          language = "en"
          break;
      }
      changeLanguage(language);
      i18n.changeLanguage(language);
    }
  }, [preferenceLanguage])

  const changeLanguage = (language) => {
    localStorage.setItem('preferenceLanguage', language);
    i18n.changeLanguage(language);
  };

  useEffect(() => {
    if (returnGPT && generatingQuizz) {
      showDivFloating(true);
      setAskingIA(false);
      assessmentIAGenerated(returnGPT, questionQ);
      setGeneratingQuizz(false);
    } else if (returnGPT && generatingTopics) {
      setAskingIA(false);
      setTopics(returnGPT);
      setButtonText("Quiz");
      setGeneratingTopics(false);
      setShowTopics(false)
    } else if (returnGPT && translatingSubtitles) {
      setAskingIA(false);
      setTranslatingSubtitles(false);
      translateIAGenerated(returnGPT);
      handleAskAssessmentGPT();
    }
  }, [returnGPT])

  useEffect(() => {
    if (error) {
      setErrorGPT(error);
      setAskingIA(false);
    }
  }, [error])

  useEffect(() => {
    if (errorGPT) {
      setProgresstimer(0);
      setTotaltime(0);
      setErrorGPT("");
      handleMsgError(errorGPT)
    }
  }, [errorGPT])

  useEffect(() => {
    if (qtdQuestions >= 0) {
      setQtdMaxQuestions(20 - qtdQuestions);
      const dfqq = Math.ceil(qtdMaxQuestions / 2);
      setQuestionQ(dfqq);
    }
  }, [qtdQuestions])

  useEffect(() => {
    if (projectSelected === "file") {
      setAIHelp(true)
    } else if (projectSelected === "assessment") {
      setAIHelp(false)
    }
  }, [projectSelected])

  useEffect(() => {
    if (generateQuestions) {
      handleAskAssessmentGPT();
    }
  }, [generateQuestions])

  useEffect(() => {
    if (IAGeneratedQuestions) {
      setIALimitQUestions(IAGeneratedQuestions < 20)
    }
  }, [IAGeneratedQuestions])

  useEffect(() => {
    if (showTopics && AIHelp) {
      setButtonText(t('projectpreview:asssessmentparameters.Tópicos'))
    } else {
      setShowTopics(false)
      setButtonText("Quiz")
    }
  }, [showTopics, AIHelp])

  useEffect(() => {
    url && setFileContent(url);
  }, [url])

  useEffect(() => {
    originalLanguage && setLanguage(originalLanguage);
  }, [originalLanguage])

  useEffect(() => {
    setSubtitleLanguage(originalLanguage)
  }, [])

  return (
    <div>
      {askingIA
        ?
        <div className='d-flex align-items-center justify-content-center flex-column'>
          <div className={`col-6 text-center mt-5`}>
            <div className="spinner-grow text-primary" role="status"></div>
            <h5 className={`mt-3 `}>
              {`${msgText}`}
            </h5>
          </div>
        </div>
        :
        <div className={`p-0 m-0`}>
          <div >
            {qtdQuestions > 0
              ?
              <h6 className="m-3">
                {`${t('projectpreview:asssessmentparameters.projeto tem x questões', {
                  qtdQuestions: qtdQuestions,
                  interpolation: { prefix: '${', suffix: '}' }
                })} ${qtdQuestions === 1 ? t('projectpreview:asssessmentparameters.questão') : t('projectpreview:asssessmentparameters.questões')}. ${t('projectpreview:asssessmentparameters.Indique parâmetros novas questões')}`}
              </h6>
              : <h6 className={`m-3`}>{t('projectpreview:asssessmentparameters.Indique parâmetros avaliação')} </h6>}
          </div>
          <div className={`mb-3 d-flex justify-content-center`}>
            <div className={``}>
              <form className='ms-3'>

                {(!topics || topics.length === 0) && <div className="row d-flex align-items-center mt-4">
                  <label className=""><h6>{t('projectpreview:asssessmentparameters.Como deseja criar as questões')}</h6></label>
                  <div className="">
                    <div className="form-check ">
                      <input
                        className={`form-check-input ${styles.option_check}`}
                        type="radio"
                        name="meu_radio"
                        value="manual"
                        checked={!AIHelp}
                        onChange={() => setAIHelp(false)}
                      />
                      <label className="form-check-label"><h6>{t('projectpreview:asssessmentparameters.Cadastro manual')}</h6></label>
                    </div>
                  </div>
                  <div className="">
                    <div className="form-check mt-1">
                      <input
                        className={`form-check-input ${styles.option_check}`}
                        type="radio"
                        name="meu_radio"
                        value="ai"
                        checked={AIHelp}
                        onChange={() => setAIHelp(true)}
                      />
                      <label className="form-check-label"><h6>{t('projectpreview:asssessmentparameters.Inteligência Artificial')}</h6></label>
                    </div>
                  </div>
                  {(projectSelected === "assessment" && !idProject) && <div>
                    <div className="row mt-4 d-flex align-items-center ">
                      <h6 className="mb-2">{t('projectpreview:asssessmentparameters.Tema da avaliação')}</h6>
                      <input
                        className={`ms-3 ${styles.form_parameters} ${styles.adjust_width}`}
                        type="text"
                        required
                        disabled={idProject}
                        name="projectName"
                        placeholder={t('projectpreview:asssessmentparameters.Digite o tema da avaliação')}
                        value={projectName}
                        onChange={(e) => { handleProjectName(e) }}
                      />
                    </div>

                  </div>}
                </div>}
                {(AIHelp && IALimitQuestions)
                  ?
                  <div>
                    {(!topics || topics.length === 0 && !fileName && !projectContent && !url) && <div className="row align-items-center">
                      <div className="mt-4 ms-2 form-check col-11">
                        <input className={`form-check-input ${styles.option_check}`} type="checkbox" disabled={disableForm} name="flexRadioDefault" id="flexRadioDefault1" value={showTopics} onChange={(e) => setShowTopics(e.target.checked)} />
                        <span className={``}>
                          <h6>{t('projectpreview:asssessmentparameters.sugira tópicos')}</h6>
                        </span>
                      </div>
                    </div>}

                    {(topics && topics.length > 0) &&
                      <div className="mt-3">
                        <AssessmentTopics
                          topics={topics}
                          handleSetTopics={handleSetTopics}
                          topicText={t('projectpreview:asssessmentparameters.Selecione os tópicos')} />
                      </div>}
                    {!showTopics &&
                      <div>
                        {topics.length > 0 || projectContent
                          ? ``
                          : <div className={`mt-3`}>
                            <div>
                              <h6>{t('projectpreview:asssessmentparameters.incluir arquivo texto')}</h6>
                              {fileName
                                ? <div className="row d-flex align-content-center justify=content-center">
                                  <h5 className={`col-auto ${styles.fileName}`}>{fileName}</h5>
                                  <button className={`col-auto ${styles.clearFile}`} onClick={handleClearFile}><i className={`${styles.icon_clear} bi bi-x-circle`}></i></button>
                                </div>
                                : <div className="col-auto me-1">
                                  <input type="file" ref={fileInputRef} onChange={e => handleFileChange(e.target.files[0])} accept=".doc,.docx,.txt" className='d-none' />
                                  <button onClick={handleButtonClick} className={`${styles.btn_style_select}`} disabled={url}>{t('projectpreview:asssessmentparameters.Selecione o arquivo')}</button>
                                </div>}
                            </div>
                            {!fileName && <div className={`row mt-3 d-flex align-items-center`}>
                              <h6>{t('projectpreview:asssessmentparameters.incluir URL')}</h6>
                              <input
                                className={`ms-3 ${styles.form_parameters} ${styles.adjust_width}`}
                                type="text"
                                required
                                name="url"
                                placeholder={t('projectpreview:asssessmentparameters.Digite a URL')}
                                value={url}
                                onChange={(e) => { setUrl(e.target.value) }}
                                disabled={fileName}
                              />
                            </div>}
                          </div>}
                        <div className="row mt-4">
                          <h6 className="col-12">{t('projectpreview:asssessmentparameters.quantidade de questões')} </h6>
                          <div className={`col-12 ${styles.qty_question}`}>
                            {questionQ}
                            <input
                              className={`ms-3 ${styles.range_customize}`}
                              type="range"
                              min="1"
                              max={qtdMaxQuestions}
                              required
                              name="qtyQuestion"
                              value={questionQ}
                              onChange={(e) => changeQuestionQty(e.target.value)}
                            />
                          </div>
                        </div>
                        <p></p>

                        <div className="row d-flex align-items-center mt-4">
                          <h6 className="col-12">{t('projectpreview:asssessmentparameters.quantidade de alternativas')} </h6>
                          <div className="col-12">
                            <select
                              className={styles.form_parameters}
                              required
                              name="qtyChoice"
                              value={choiceQ}
                              onChange={(e) => changeChoicesQty(e.target.value)}>
                              <option value="">{`Selecione uma opção`}</option>
                              <option value="3">3</option>
                              <option value="4">4</option>
                              <option value="5">5</option>
                            </select>
                          </div>
                        </div>
                        <p></p>

                        <div className="row d-flex align-items-center mt-4">
                          <h6 className="col-8">{t('projectpreview:asssessmentparameters.texto de feedback')} </h6>
                          <div className="col-auto">
                            <input
                              className={`form-check-input ${styles.option_check}`}
                              type="checkbox"
                              id="flex-switch-disabled"
                              name="feedback"
                              checked={feedback}
                              onChange={(e) => changeFeedback(e.target.checked)}
                            />
                          </div>
                        </div>
                        <p></p>

                        <div className="row d-flex align-items-center mt-4">
                          <h6 className="col-12">{t('projectpreview:asssessmentparameters.nível de dificuldade')} </h6>
                          <div className="col-12">
                            <select
                              className={styles.form_parameters}
                              required
                              name="level"
                              value={level}
                              onChange={(e) => changeLevel(e.target.value)}>
                              <option value="">{`Selecione uma opção`}</option>
                              <option value="easy">{t('projectpreview:asssessmentparameters.Fácil')}</option>
                              <option value="medium">{t('projectpreview:asssessmentparameters.Médio')}</option>
                              <option value="difficult">{t('projectpreview:asssessmentparameters.Difícil')}</option>
                            </select>
                          </div>
                        </div>
                        <p></p>

                        <div className="row d-flex align-items-center mt-4">
                          <h6 className="col-12 ">{t('projectpreview:asssessmentparameters.Idioma')} </h6>
                          <div className="col-12">
                            <select
                              className={styles.form_parameters}
                              disabled={disableForm}
                              name="language"
                              value={language}
                              onChange={(e) => changeProjectLanguage(e.target.value)}>
                              <option value="">{t('projectpreview:asssessmentparameters.Selecione uma opção')}</option>
                              {preferenceLanguage === 'Português' && <option value="Portuguese" defaultValue>{t('projectpreview:asssessmentparameters.Português')}</option>}
                              {preferenceLanguage === 'Inglês' && <option value="English" defaultValue>{t('projectpreview:asssessmentparameters.Inglês')}</option>}
                              {preferenceLanguage === 'Espanhol' && <option value="Spanish" defaultValue>{t('projectpreview:asssessmentparameters.Espanhol')}</option>}
                              {preferenceLanguage != 'Português' && <option value="Portuguese">{t('projectpreview:asssessmentparameters.Português')}</option>}
                              {preferenceLanguage != 'Inglês' && <option value="English">{t('projectpreview:asssessmentparameters.Inglês')}</option>}
                              {preferenceLanguage != 'Espanhol' && <option value="Spanish">{t('projectpreview:asssessmentparameters.Espanhol')}</option>}
                              <option value="French">{t('projectpreview:asssessmentparameters.Francês')}</option>
                              <option value="German">{t('projectpreview:asssessmentparameters.Alemão')}</option>
                              <option value="Italian">{t('projectpreview:asssessmentparameters.Italiano')}</option>
                              <option value="Mandarin">{t('projectpreview:asssessmentparameters.Chinês')}</option>
                              <option value="Russian">{t('projectpreview:asssessmentparameters.Russo')}</option>
                              <option value="Japanese">{t('projectpreview:asssessmentparameters.Japonês')}</option>
                              <option value="Korean">{t('projectpreview:asssessmentparameters.Coreano')}</option>
                            </select>
                          </div>
                        </div>
                      </div>}
                  </div>
                  : AIHelp &&
                  <div>
                    <h6 className={`${styles.ia_limit}`}>
                      {t('projectpreview:asssessmentparameters.limite de 20 questões')}
                    </h6>
                  </div>
                }
              </form>
            </div>
          </div>
          <div>
            <button className={`mt-4 ms-3 mb-4 ${styles.button_quizz} ${styles.btn_style}`} disabled={!projectName || (AIHelp && !IALimitQuestions)} onClick={handleDirectionClick}>
              {`${t('projectpreview:asssessmentparameters.Gerar')} ${buttonText}`}
            </button>
          </div>
        </div>}
    </div>
  )
}

export default AssessmentParameters