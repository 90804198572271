import style from './AssessmentSetup.module.css'
import { useState } from 'react';

const AssessmentSetup = ({AIHelp, handleChangeConfig }) => {

  const [questionQ, setQuestionQ] = useState(10);
  const [choiceQ, setChoiceQ] = useState(4);
  const [feedback, setFeedback] = useState(true);
  const [language, setLanguage] = useState("Português");
  const [level, setLevel] = useState("médio");


  const changeQuestionQty = (questionqty) => {
    setQuestionQ(questionqty);
    handleChangeConfig(questionqty, choiceQ, feedback, level, language)
  }

  const changeChoicesQty = (choicesqty) => {
    setChoiceQ(choicesqty);
    handleChangeConfig(questionQ, choicesqty, feedback, level, language)
  }

  const changeFeedback = (feed) => {
    setFeedback(feed);
    handleChangeConfig(questionQ, choiceQ, feed, level, language)
  }

  const changeLevel = (level) => {
    setLevel(level);
    handleChangeConfig(questionQ, choiceQ, feedback, level, language)
  }

  const changeLanguage = (lang) => {
    setLanguage(lang);
    handleChangeConfig(questionQ, choiceQ, feedback, level, lang)
  }

  return (
    <div>
      <form>
        <label>
          <p>Indique a quantidade de questões: {questionQ}</p>
          <input
            type="range"
            min="1"
            max="20"
            required
            name="qtyQuestion"
            value={questionQ}
            onChange={(e) => changeQuestionQty(e.target.value)}
          />
        </label>
        <p></p>
        <span>Indique a quantidade de alternativas: </span>
        <select
          className={style.form_select}
          required
          name="qtyChoice"
          value={choiceQ}
          onChange={(e) => changeChoicesQty(e.target.value)}>
          <option value="">Selecione uma opção...</option>
          <option value="3">3</option>
          <option value="4">4</option>
          <option value="5">5</option>
        </select>
        <p></p>
        <span>Você quer feedback? </span>
        <input
          className="form-check-input"
          type="checkbox"
          id="flex-switch-disabled"
          name="feedback"
          checked={feedback}
          onChange={(e) => changeFeedback(e.target.checked)}
        />
        <p></p>
        <div>
          <span>Indique a dificuldade: </span>
          <select
            //className={style.form_select} 
            required
            name="level"
            value={level}
            onChange={(e) => changeLevel(e.target.value)}>
            <option value="">Selecione a dificuldade</option>
            <option value="fácil">Fácil</option>
            <option value="médio">Médio</option>
            <option value="difícil">Difícil</option>
          </select>
          <p></p>
          <span>Selecione um idioma:  </span>
          <select
            //className={style.form_select} 
            required
            name="language"
            value={language}
            onChange={(e) => changeLanguage(e.target.value)}>
            <option value="">Selecione um idioma</option>
            <option value="Português">Português</option>
            <option value="Inglês">Inglês</option>
            <option value="Espanhol">Espanhol</option>
            <option value="Francês">Francês</option>
            <option value="Alemão">Alemão</option>
            <option value="Italiano">Italiano</option>
            <option value="Mandarin">Mandarin</option>
            <option value="Russo">Russo</option>
            <option value="Japonês">Japonês</option>
            <option value="Coreano">Coreano</option>
          </select>
        </div>
        <p></p>
      </form>
    </div>
  )
}

export default AssessmentSetup;