import styles from './DialogBoxDelete.module.css'
import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import quizAiLogo from '../images/logoQuizAI.png';
import { useLanguageValue } from '../context/LanguageContext';
import useExcelToJson from '../hooks/useLoadExcelLanguage';

function DialogBoxDelete({ actionDelete, questionDialog, optionsDialog, isopen, index, indexChoice }) {

  const [modalIsOpen, setModalIsOpen] = useState(isopen);
  const [pageTexts, setPageTexts] = useState([]);

  const { preferenceLanguage } = useLanguageValue();
  const sheetName = 'dialogbox';
  const jsonData = useExcelToJson(sheetName);

  const handleCloseModal = (event) => {
    const confirm = event.target.value     
    const confirmed = (confirm != "Cancelar" && confirm != "Cancel" ) ? true : false;
    actionDelete(confirmed, index, indexChoice);
  };

  useEffect(() => {
    if (jsonData && preferenceLanguage) {
      const newPageTexts = jsonData.map((data) => data[preferenceLanguage]);
      setPageTexts(newPageTexts);
    }
  }, [jsonData, preferenceLanguage])


  return (
    <div>
      <Modal show={modalIsOpen} onHide={handleCloseModal} backdrop="static" centered>
        <Modal.Header >
          <img src={quizAiLogo} alt="QuizAI" className={styles.logo} />
        </Modal.Header>
        <Modal.Body>
          {questionDialog}
        </Modal.Body>
        <Modal.Footer>
          <ul className="list-unstyled d-flex justify-content-between">
            {optionsDialog.map(option => (
              <li key={option}>
                <button
                  className={`ms-3 ${(option === "Cancelar" || option === "Cancel" ) ? styles.btn_cancel : styles.btn_style}`}
                  value={option}
                  onClick={handleCloseModal}
                  data-bs-dismiss="modal"
                  key={option}>
                  {option}
                </button>
              </li>
            ))}
          </ul>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default DialogBoxDelete;