import React from 'react';
import styles from './Plans.module.css';

const CheckIcon = () => (
    <span className={`${styles.check_icon}`}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path d="M5 13l4 4L19 7"></path>
        </svg>
    </span> 
);

export default CheckIcon;
