import { useFetchDocument } from '../hooks/useFetchDocument'
import { useEffect, useState } from 'react';
import { Timestamp } from 'firebase/firestore';

export const useCheckCoupon = () => {

    let messageCoupon = "";
    let validDays = 15;
    let monthlyProjectLimit = 5;
    let validCoupon = true;

    const checkCouponisValid =  (plan, couponCode) => {

        const coupons = [{
            couponName: "quizai-beta",
            license_valid_days: 60,
            monthly_project_limit: 25,
            plan_apply: "trial-15",
            validate_date: new Date("2023-11-30"),
        }]
        const matchingCoupon = coupons.find(coupon => coupon.couponName === couponCode);

        if (matchingCoupon) {
            const userPlan = plan;
            const validDate = matchingCoupon.validate_date;
            const planApply = matchingCoupon.plan_apply;
    
            const dateNow = Timestamp.now().toDate();
    
            if (validDate < dateNow) {
                validCoupon = false;
                messageCoupon = "Esse cupon não é mais válido."
            } else if (planApply != userPlan) {
                validCoupon = false;
                messageCoupon = `Esse cupom não se aplica ao plano ${userPlan}.`
            } else {
                validCoupon = true;
                messageCoupon = "Cupom aplicado com sucesso"
                monthlyProjectLimit = matchingCoupon.monthly_project_limit;
                validDays = matchingCoupon.license_valid_days;

            }
        } else {
            validCoupon = false;
            messageCoupon = "Código de cupom inválido. Caso tenha um cupom válido, você pode tentar novamente na tela Meu Plano.";
        }

        return { messageCoupon, validDays, monthlyProjectLimit, validCoupon }
    }

    return { checkCouponisValid }
}
