import styles from './UpdateAssessment.module.css'

import { useNavigate } from 'react-router';
import { useEffect, useState } from 'react';
import { useCreateProject } from '../../hooks/useCreateProject';
import { useGenerateHTML } from '../../hooks/useGenerateHTML';
import { useLanguageValue } from '../../context/LanguageContext';
import { useFetchProjects } from '../../hooks/useFetchProjects';
// Components
import SimpleChoiceQuestion from '../../components/SimpleChoiceQuestion';
import ProjectTemplate from '../../components/ProjectTemplate';
import ProjectExport from '../../components/ProjectExport';
import useExcelToJson from '../../hooks/useLoadExcelLanguage';
import { getDownloadURL, listAll, ref } from "firebase/storage";
import { storage } from "../../firebase/config";

const UpdateAssessment = ({ projectInfos, hideCardFolderProject }) => {

    const { documents: templates, loadingTemplates, errorTemplates } = useFetchProjects("Templates");

    const navigate = useNavigate();
    const { createProject } = useCreateProject();
    const { previewHTML } = useGenerateHTML();

    const { preferenceLanguage } = useLanguageValue();
    const sheetName = 'buttons';
    const jsonData = useExcelToJson(sheetName);

    const projectName = projectInfos.projectName;
    const projectDescription = projectInfos.projectDescription;
    const subject = projectInfos.projectSubject;
    const language = projectInfos.projectLanguage;
    const intro = projectInfos.projectIntro;
    const projectContentUser = projectInfos.projectContent;
    const assessment = projectInfos.assessment;
    const idProject = projectInfos.idProject;
    let projectTemplate = projectInfos.projectTemplate;
    const projectFolder = projectInfos.projectFolder;
    const includeContent = projectInfos.projectIncludeContent;
    const includeSlides = projectInfos.projectIncludeSlides;
    const textPpt = projectInfos.textPpt;
    const pathToSlides = projectInfos.pathToSlides;
    const showSlides = projectInfos.showSlides;
    const presentTogether = projectInfos.showTogether;
    const videoUrl = projectInfos.videoUrl;
    const includeVideo = projectInfos.projectIncludeVideo;
    const showVideo = projectInfos.showVideo;
    const presentVideoTogether = projectInfos.showVideoTogether;
    const videoText = projectInfos.videoTranscript;
    const contentGenerated = projectInfos.projectContentGenerated;
    const userId = projectInfos.userId;

    const [updateProject, setUpdateProject] = useState(true);
    const [updateTemplate, setUpdateTemplate] = useState(false);
    const [publish, setPublish] = useState(false);
    const [assesmentSend, setAssessmentSend] = useState(false);
    const [newProjectTemplate, setNewProjectTemplate] = useState(projectTemplate);
    const [cssTemplate, setCssTemplate] = useState();
    const [buttonLabel, setButtonLabel] = useState("");
    const [originalTemplate, setOriginalTemplate] = useState(projectTemplate);
    const [pageTexts, setPageTexts] = useState();
    const [projectIntro, setProjectIntro] = useState(intro)
    const [isValidUrl, setIsValidUrl] = useState(false);
    const [newAssemssment, setNewAssessment] = useState(assessment);
    const [newIncludeContent, setNewIncludeContent] = useState(includeContent);
    const [templatesList, setTemplatesList] = useState();
    const [newShowSlides, setNewShowSlides] = useState(showSlides);
    const [newShowVideo, setNewShowVideo] = useState(showVideo);
    const [urlLogo, setUrlLogo] = useState("");

    const [listUrlSlides, setListUrlSlides] = useState([]);
    const [loadingSlides, setLoadingSlides] = useState(true)

    let projectDestiny;

    const checkUrlValidity = (url) => {
        const pattern = /^(ftp|http|https):\/\/[^ "]+$/;

        if (pattern.test(url)) {
            setIsValidUrl(true);
        } else {
            setIsValidUrl(false);
        }
    };

    const handleProjectIntro = (intro) => {
        setProjectIntro(intro);
    }

    const handleProjectContent = (include) => {
        setNewIncludeContent(include)
    }

    const handleChangeCarousel = () => {
        setUpdateProject(false);
        setUpdateTemplate(true);

    }

    const handleChangeTemplate = (template, css) => {
        setNewProjectTemplate(template)
        setCssTemplate(css)
    }

    const handleChangeAssessment = (assessment) => {
        setNewAssessment(assessment);
    }

    const handleCancelProject = () => {
        hideCardFolderProject()
    }

    const handleChangeShowSlides = (show) => {
        setNewShowSlides(show)
    }

    const handleChangeShowVideo = (show) => {
        setNewShowVideo(show)
    }

    const handleReturnPage = () => {

        if (updateTemplate) {
            setUpdateTemplate(false)
            setUpdateProject(true)
        } else {
            setUpdateTemplate(true)
            setPublish(false)
        }
    }

    const updateProjectTemplate = () => {
        setUpdateTemplate(false)
        setPublish(true)

        if (originalTemplate != newProjectTemplate) {
            setOriginalTemplate(newProjectTemplate);
            const projectInfos = {
                actionDoc: "update",
                contentGenerated,
                idProject: idProject,
                includeContent: includeVideo ? false : includeContent,
                includeSlides: includeSlides,
                introAssessment: projectIntro,
                projectName,
                projectDescription,
                projectContent: projectContentUser,
                projectFolder,
                assessment: assessment,
                language,
                subject,
                projectTemplate: newProjectTemplate,
                pathToSlides,
                textPpt,
                showSlides: newShowSlides,
                presentTogether,
                videoUrl,
                includeVideo,
                showVideo: newShowVideo,
                presentVideoTogether,
            }

            const res = createProject(projectInfos).then(
                setAssessmentSend(true)
            )
        }
    }

    const handlePreviewHTML = () => {
        const project = {
            css: cssTemplate,
            templateName: newProjectTemplate,
            projectName: projectName,
            projectDestiny: "preview",
            introAssessment: projectIntro,
            projectContent: newIncludeContent ? projectContentUser : null,
            language: language,
            assessment: newAssemssment,
            listUrlSlides: listUrlSlides,
            urlLogo: urlLogo,
            showSlides: newShowSlides,
            showTogether: presentTogether,
            videoUrl: videoUrl,
            showVideo: newShowVideo,
            showVideoTogether: presentVideoTogether,
        }
        const htmlString = previewHTML(project);
    };

    const loadPptImages = async () => {

        const checkUrls = async () => {
            try {
                const listRef = ref(storage, pathToSlides);
                const items = await listAll(listRef);
                const imageUrls = await Promise.all(items.items.map(async (item) => {
                    const itemName = item.name;
                    if (itemName.endsWith('.png')) {
                        const imageUrl = await getDownloadURL(item);
                        return imageUrl;
                    }
                    return null;
                }));
                const filteredImageUrls = imageUrls.filter((imageUrl) => imageUrl !== null);
                return filteredImageUrls;
            } catch (error) {
                console.error('Erro na solicitação:', error);
            }
        }
        const listUrls = await checkUrls()
        setListUrlSlides(listUrls)

    }

    const loadLogo = async () => {
        try {
            //const pathToLogo = pathToSlides.replace(/^(ppt|video)/, "logos");
            const pathToLogo = `logos/${userId}/${idProject}`
            const listRef = ref(storage, pathToLogo);
            const items = await listAll(listRef);

            const imageUrls = await Promise.all(items.items.map(async (item) => {
                const itemName = item.name;
                if (itemName.startsWith('logo')) {
                    const logoUrl = await getDownloadURL(item);
                    return logoUrl
                }
            }));
            const url = await imageUrls;
            if (url.length > 0) {
                setUrlLogo(url);
            }
        } catch (error) {
            console.error('Erro na solicitação:', error);
        }
    }

    const changeUrlLogo = (url) => {
        setUrlLogo(url)
    }

    useEffect(() => {
        if (jsonData && preferenceLanguage) {
            const newPageTexts = jsonData.map((data) => data[preferenceLanguage]);
            setPageTexts(newPageTexts)
        }
    }, [jsonData, preferenceLanguage])

    useEffect(() => {
        pageTexts && setButtonLabel(pageTexts[3])
    }, [pageTexts])

    useEffect(() => {
        if (newProjectTemplate && pageTexts) {
            if (newProjectTemplate != originalTemplate) {
                setButtonLabel(pageTexts[4]);
            } else {
                setButtonLabel(pageTexts[3]);
            };
        }
    }, [newProjectTemplate, originalTemplate])

    useEffect(() => {
        projectContentUser && checkUrlValidity(projectContentUser);
    }, [projectContentUser])

    useEffect(() => {
        if(pathToSlides){
            loadPptImages()
        }
    }, [pathToSlides])

    useEffect(() => {
        templates && setTemplatesList(templates)
    }, [templates])

    useEffect(() => {
        includeVideo && setNewIncludeContent(false)
    }, [includeVideo])

    useEffect(() => {
        updateTemplate && window.scrollTo(0, 0);
    }, [updateTemplate])

    useEffect(() => {
        loadLogo();
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0);
      }, [])

    return (
        <div>
            {updateProject &&
                <div >
                    <SimpleChoiceQuestion
                        actionDoc={"update"}
                        idProject={idProject}
                        contentGenerated={contentGenerated}
                        projectFolder={projectFolder}
                        subject={subject}
                        language={language}
                        projectName={projectName}
                        handleProjectIntro={handleProjectIntro}
                        projectDescription={projectDescription}
                        projectIntro={projectIntro}
                        projectContentUser={projectContentUser}
                        assessmentObject={assessment}
                        handleChangeCarousel={handleChangeCarousel}
                        hideCardFolderProject={hideCardFolderProject}
                        userUrl={isValidUrl ? projectContentUser : null}
                        origem={true}
                        handleChangeAssessment={handleChangeAssessment}
                        includeContent={newIncludeContent}
                        includeSlides={includeSlides}
                        projectUserInput={projectContentUser}
                        handleProjectContent={handleProjectContent}
                        userEdit={false}
                        pathToSlides={pathToSlides}
                        textPpt={textPpt}
                        presentTogether={presentTogether}
                        handleChangeShowSlides={handleChangeShowSlides}
                        videoUrl={videoUrl}
                        showVideoTogether={presentVideoTogether}
                        includeVideo={includeVideo}
                        presentVideoTogether={presentVideoTogether}
                        handleChangeShowVideo={handleChangeShowVideo}
                        changeUrlLogo={changeUrlLogo}
                    />
                </div>}
            <div className={`mt-3 ms-3 mt-3 ${updateTemplate ? styles.show : styles.hide}`}>
                <ProjectTemplate
                    handleChangeTemplate={handleChangeTemplate}
                    templatesListPre={templatesList}
                    projectTemplate={projectTemplate}

                />
            </div>
            {publish && <div className="mt-3 ms-3">
                <ProjectExport
                    projectName={projectName}
                    subject={subject}
                    assessment={newAssemssment}
                    contentGenerated={newIncludeContent ? projectContentUser : null}
                    projectIntro={projectIntro}
                    language={language}
                    cssTemplate={cssTemplate}
                    nameTemplate={newProjectTemplate}
                    pathToSlides={pathToSlides}
                    showSlides={newShowSlides}
                    showTogether={presentTogether}
                    videoUrl={videoUrl}
                    showVideo={showVideo}
                    showVideoTogether={presentVideoTogether}
                    idProject={idProject}
                    userId={userId}
                />
            </div>}
            {publish}
            {!updateProject && pageTexts &&
                <div className="row justify-content-end mt-3">
                    <button className={`${styles.btn_cancel} ${styles.carousel_buttons} me-2`} onClick={() => handleCancelProject()}>{pageTexts[0]}</button>
                    <button className={`${styles.btn_return} ${styles.carousel_buttons} me-2`} onClick={() => handleReturnPage()}><i class="bi bi-chevron-left fw-bold"></i>  {pageTexts[1]}</button>
                    {updateTemplate && <button className={`${styles.btn_style}  ${styles.carousel_buttons} me-2`} onClick={() => handlePreviewHTML()}>{pageTexts[2]}</button>}
                    {!publish && <button className={`${styles.btn_style}  ${styles.carousel_buttons} me-2`} onClick={() => updateProjectTemplate()}>{buttonLabel} <i class="bi bi-chevron-right fw-bold"></i></button>}
                </div>}
        </div>)
}

export default UpdateAssessment;