import styles from './SimpleChoiceQuestion.module.css'
import DialogBoxDelete from './DialogBoxDelete';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useCreateProject } from '../hooks/useCreateProject';

import { useAuthValue } from '../context/AuthContext';
import { useFetchDocument } from '../hooks/useFetchDocument';
import { useFetchProjects } from '../hooks/useFetchProjects';
import { useLanguageValue } from '../context/LanguageContext';
import { useGenerateHTML } from '../hooks/useGenerateHTML';

import SlidesCarousel from '../components/SlidesCarousel';
import { storage } from "../firebase/config";
import { getDownloadURL, ref, uploadBytesResumable, deleteObject, listAll, getMetadata } from "firebase/storage";
import useExcelToJson from '../hooks/useLoadExcelLanguage';

const SimpleChoiceQuestion = ({ AIHelp, actionDoc, idProject, subject, language, projectName, projectDescription, projectFolder, handleProjectIntro, projectIntro, includeContent, includeSlides, presentTogether, projectUserInput, contentGenerated, projectContentUser, assessmentObject, hideCardFolderProject, origem, userEdit, userUrl, includeVideo, presentVideoTogether, videoUrl, handleChangeCarousel, handleChangeAssessment, handleProjectContent, pathToSlides, textPpt, handleChangeShowSlides, handleChangeShowVideo, changeUrlLogo }) => {

  const { user } = useAuthValue();
  const { previewHTML } = useGenerateHTML();

  const [assessment, setAssessment] = useState(assessmentObject);
  const [assesmentSend, setAssessmentSend] = useState(false);
  const [introAssessment, setIntroAssessment] = useState(projectIntro);
  const [projectContent, setProjectContent] = useState(projectContentUser);
  const [showIntroText, setShowIntroText] = useState(false)

  const [showFeedback, setShowFeedback] = useState([]);
  const [openDialogDeleteQuestion, setOpenDialogDeleteQuestion] = useState(false);
  const [openDialogDeleteAlternative, setOpenDialogDeleteAlternative] = useState(false);
  const [openDialogDeleteText, setOpenDialogDeleteText] = useState(false);
  const [index, setIndex] = useState(0);
  const [indexChoice, setIndexChoice] = useState(0);
  const [disableButons, setDisableButtons] = useState(false);
  const [editProject, setEditProject] = useState(userEdit);
  const [showFixedButtons, setShowFixedButtons] = useState(true);
  const [projectTemplate, setProjectTemplate] = useState("default");
  const [cssTemplate, setCssTemplate] = useState("");
  const [search, setSearch] = useState();
  const [pageTexts, setPageTexts] = useState([]);
  const [originalCorrectAnswers, setOriginalCorrectAnswers] = useState([]);
  const [showProjectContent, setShowProjectContent] = useState(includeContent);
  const [showProjectSlides, setShowProjectSlides] = useState(includeSlides);
  const [showProjectVideo, setShowProjectVideo] = useState(includeVideo);
  const [newIncludeContent, setNewIncludeContent] = useState(includeContent);
  const [newIncludeSlides, setNewIncludeSlides] = useState(includeSlides);
  const [newIncludeVideo, setNewIncludeVideo] = useState(includeVideo);
  const [loadingSlides, setLoadingSlides] = useState(true)
  const [listUrlSlides, setListUrlSlides] = useState([]);
  const [urlLogo, setUrlLogo] = useState("");
  const [hideContent, setHideContent] = useState("");
  const [userId, setUserId] = useState("");
  const [progresspercent, setProgresspercent] = useState(0);
  const [uploadingFile, setUploadingFile] = useState(false);
  const [loadingLogo, setLoadingLogo] = useState(false);

  const { createProject } = useCreateProject();
  const { document: domain, loadingDocDomain, errorDocDomain } = useFetchDocument("Domains", user.uid)
  const { document: project, loadingDocProject, errorDocProject } = useFetchDocument("Domains", user.uid, "Folder", idProject)
  const { documents: templates, loading, error } = useFetchProjects("Templates", search);

  const { preferenceLanguage } = useLanguageValue();
  const sheetName = 'newproject';
  const jsonData = useExcelToJson(sheetName);

  const navigate = useNavigate();

  const assessmentWithOriginalCorrectAnswers = () => {
    const correctAnswers = assessment.map((q) => {
      return q.correct_answer;
    });
    setOriginalCorrectAnswers(correctAnswers);
  }

  function removeSpecialChar(str) {
    str = removeAccents(str);
    str = str.replace(/ /g, "_");
    str = str.replace(/[&\/\\#,+()$~%'":*?<>{}!@¨]/g, '');
    str = str.toLowerCase();
    return str;
  }

  const checkSubscription = () => {
    const domainPlan = domain.plan;

    if (domainPlan === "free") {
      setDisableButtons(true);
    }
  }

  const removeAccents = (text) => {
    const accents = {
      'á': 'a',
      'à': 'a',
      'â': 'a',
      'ã': 'a',
      'ä': 'a',
      'é': 'e',
      'è': 'e',
      'ê': 'e',
      'ë': 'e',
      'í': 'i',
      'ì': 'i',
      'î': 'i',
      'ï': 'i',
      'ó': 'o',
      'ò': 'o',
      'ô': 'o',
      'õ': 'o',
      'ö': 'o',
      'ú': 'u',
      'ù': 'u',
      'û': 'u',
      'ü': 'u',
      'ñ': 'n',
      'ç': 'c',
      '&': 'and',
    };

    return text
      .split('')
      .map((char) => accents[char] || char)
      .join('');
  };

  const handleScroll = () => {
    const isAtPageBottom = window.innerHeight + window.scrollY >= document.body.offsetHeight - 100;
    setShowFixedButtons(!isAtPageBottom);
  };

  const handleChangeQuestion = (index, value) => {
    const updatedQuestions = [...assessment];
    updatedQuestions[index].ask_question = value;
    setAssessment(updatedQuestions);
  }

  const handleChangeChoices = (questionIndex, optionIndex, value) => {
    const updatedQuestions = [...assessment];
    updatedQuestions[questionIndex].alternatives[optionIndex].text = value;
    setAssessment(updatedQuestions);
  }

  const handleChangeCorrectAnswer = (index, value) => {
    const updatedQuestions = [...assessment];
    updatedQuestions[index].correct_answer = value;
    setAssessment(updatedQuestions);
  };

  const handleChangeFeedback = (index, value) => {
    const updatedQuestions = [...assessment];
    updatedQuestions[index].feedback = value;
    setAssessment(updatedQuestions);
  };

  const openDialog = (content, i, j, text) => {
    if (text) {
      setOpenDialogDeleteText(true)
      return
    }

    setIndex(i)
    setIndexChoice(j)
    if (!content) {
      j === undefined ? handleDeleteQuestion(true, i) : handleDeleteChoice(true, i, j)
    } else {
      j === undefined ? setOpenDialogDeleteQuestion(true) : setOpenDialogDeleteAlternative(true)
    }
  }

  const handleAddQuestion = () => {
    const newQuestion = {
      alternatives: [
        { option: "a", text: "" },
        { option: "b", text: "" },
        { option: "c", text: "" },
        { option: "d", text: "" },
      ],
      ask_question: "",
      correct_answer: "",
      feedback: ""
    }

    const newAssessment = [...assessment, newQuestion];

    setAssessment(newAssessment);
  }

  const handleDeleteText = (confirmed, content) => {
    if (confirmed && content === "text") {
      setShowProjectContent(false);
      setNewIncludeContent(false);
    } else if (confirmed && content === "ppt") {
      setShowProjectSlides(false)
    }
    setOpenDialogDeleteText(false);
  }

  const handleDeleteQuestion = (confirmed, i) => {

    if (confirmed) {
      const newAssessment = [...assessment.slice(0, i), ...assessment.slice(i + 1)];
      setAssessment(newAssessment)
    }
    setOpenDialogDeleteQuestion(false)
  }

  const handleAddAlternative = (i) => {
    const alternativesQty = assessment[i].alternatives.length;

    let optionValue;
    switch (alternativesQty) {
      case 2:
        optionValue = "c";
        break
      case 3:
        optionValue = "d";
        break
      case 4:
        optionValue = "e";
        break
      default:
        optionValue = ""
        break
    }

    const newAlternative = { option: optionValue, text: "" }
    const alternatives = assessment[i].alternatives;
    const newAlternatives = [...alternatives, newAlternative]
    const newAssessment = [...assessment]
    newAssessment[i].alternatives = newAlternatives

    setAssessment(newAssessment)

  }

  const handleDeleteChoice = (confirmed, i, j) => {

    if (confirmed) {
      const choice = assessment[i].alternatives[j]
      let correct = assessment[i].correct_answer
      const newAlternatives = assessment[i].alternatives.filter(
        alt => alt !== choice
      )
      const options = ["a", "b", "c", "d", "e"]
      newAlternatives.map((alternative, i) => {
        if (alternative.option === correct) {
          correct = options[i]
        }
        alternative.option = options[i]
      })
      const newAssessment = [...assessment]
      newAssessment[i].alternatives = newAlternatives
      newAssessment[i].correct_answer = correct
      setAssessment(newAssessment)
    }

    setOpenDialogDeleteAlternative(false)
  }

  const handleShowFeedback = (i, feedState) => {
    const mapFeed = [...showFeedback];
    mapFeed[i] = feedState;
    setShowFeedback(mapFeed);
  }

  const handleCloseAssessment = () => {
    navigateHome();
  }

  const navigateHome = () => {
    origem && hideCardFolderProject();
    navigate("/", { state: { projectFolder: projectFolder } })
  }

  const handleSubmitAssessment = (e) => {
    let newAssessment = [...assessment];

    assessment.map((a, i) => {
      if (!showFeedback[i]) {
        newAssessment[i].feedback = "";
      }
    });

    setAssessment(newAssessment)

    const projectInfos = {
      actionDoc,
      idProject,
      includeContent: newIncludeContent,
      includeSlides: newIncludeSlides,
      includeVideo: newIncludeVideo,
      projectName,
      projectDescription,
      projectContent,
      projectFolder,
      projectTemplate,
      subject,
      introAssessment,
      assessment,
      language,
      pathToSlides,
      textPpt,
      presentTogether,
      videoUrl,
      presentVideoTogether
    }

    const res = createProject(projectInfos).then(
      setAssessmentSend(true)

    )
    setEditProject(false)
  }

  const handleReviewAssessment = () => {
    setEditProject(true)
  }

  const handlePreviewHTML = () => {
    const project = {
      css: cssTemplate,
      templateName: projectTemplate,
      projectName: projectName,
      projectDestiny: "preview",
      introAssessment: introAssessment,
      projectContent: newIncludeContent ? projectContent : null,
      showSlides: showProjectSlides,
      showTogether: presentTogether,
      language: language,
      assessment: assessment,
      listUrlSlides: listUrlSlides,
      urlLogo: urlLogo,
      videoUrl: videoUrl,
      showVideo: showProjectVideo,
      showVideoTogether: presentVideoTogether,
    }
    const htmlString = previewHTML(project);
  };

  const handleShowProjectContent = () => {
    setShowProjectContent(true);
    setNewIncludeContent(true);
  }

  const handleShowProjectSlides = () => {
    setShowProjectSlides(true);
    setNewIncludeSlides(true);
    handleChangeShowSlides && handleChangeShowSlides(true)
  }

  const handleShowProjectVideo = () => {
    setShowProjectVideo(true);
    setNewIncludeVideo(true);
    handleChangeShowVideo && handleChangeShowVideo(true)
  }

  const handleHideProjectContent = () => {
    setHideContent("text");
    setShowProjectContent(false);
    setNewIncludeContent(false)
  }

  const handleHideProjectSlides = () => {
    setHideContent("ppt");
    setShowProjectSlides(false);
    setNewIncludeSlides(false);
    handleChangeShowSlides && handleChangeShowSlides(false)
  }

  const handleHideProjectVideo = () => {
    setHideContent("video");
    setShowProjectVideo(false);
    setNewIncludeVideo(false);
    handleChangeShowVideo && handleChangeShowVideo(false)
  }

  const handleIncludeLogo = () => {

    const inputElement = document.createElement('input');
    inputElement.type = 'file';

    inputElement.addEventListener('change', (changeEvent) => {
      const selectedFiles = changeEvent.target.files;
      for (const file of selectedFiles) {
        uploadLogo(file);
      }
    });

    inputElement.click();
  }

  const uploadLogo = async (file) => {
    setUploadingFile(true)
    await handleDeleteLogo();

    const forcedFileName = 'logo';
    const originalFileNameParts = file.name.split('.');
    const fileExtension = originalFileNameParts.length > 1 ? `.${originalFileNameParts.pop()}` : '';
    const newFileName = `${forcedFileName}${fileExtension}`;

    try {
      if (file) {
        const storageRef = ref(storage, `logos/${userId}/${idProject}/${newFileName}`);
        const uploadTask = uploadBytesResumable(storageRef, file);

        uploadTask.on("state_changed",
          (snapshot) => {
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log(`Upload progress: ${progress}%`);
            if (progress === 100) {
              setTimeout(() => {
                loadLogo();
              }, 2000);
            }
          },
          (error) => {
            console.error('Erro durante o upload:', error);
          }
        );
      }
    } catch (error) {
      console.error('Erro geral:', error);
    }
  }

  const handleDeleteLogo = async () => {
    setUrlLogo("");
    changeUrlLogo("")
    const pathToDelete = `logos/${userId}/${idProject}`;

    try {
      const storageRefToDelete = ref(storage, pathToDelete);
      const items = await listAll(storageRefToDelete);
      const deletePromises = items.items.map(async (item) => {
        await deleteObject(item);
      });
      await Promise.all(deletePromises);
      console.log(`Folder ${pathToDelete} deleted successfully`);
    } catch (error) {
      console.error('Error deleting folder:', error.message);
    }
  }

  const loadLogo = async () => {
    setUploadingFile(true)
    try {
      const pathToLogo = `logos/${userId}/${idProject}`
      const listRef = ref(storage, pathToLogo);
      const items = await listAll(listRef);

      const imageUrls = await Promise.all(items.items.map(async (item) => {
        const itemName = item.name;
        if (itemName.startsWith('logo')) {
          const logoUrl = await getDownloadURL(item);
          return logoUrl
        }
      }));
      const url = await imageUrls;
      if (url.length > 0) {
        setUrlLogo(url);
      }
    } catch (error) {
      console.error('Erro na solicitação:', error);
    }
    setUploadingFile(false)
  }

  const loadPptImages = async () => {
    const checkUrls = async () => {
      try {
        const listRef = ref(storage, pathToSlides);
        const items = await listAll(listRef);
        const imageUrls = await Promise.all(items.items.map(async (item) => {
          const itemName = item.name;
          if (itemName.startsWith('slide') && itemName.endsWith('.png')) {
            const imageUrl = await getDownloadURL(item);
            return imageUrl;
          }
          return null;
        }));
        const filteredImageUrls = imageUrls.filter((imageUrl) => imageUrl !== null);
        return filteredImageUrls;
      } catch (error) {
        console.error('Erro na solicitação:', error);
      }
    }
    const listUrls = await checkUrls()
    setListUrlSlides(listUrls)
    setLoadingSlides(false)
  }

  useEffect(() => {
    userId && loadLogo()
  }, [userId])

  useEffect(() => {
    if (projectUserInput) {
      setProjectContent(projectUserInput);
    } else if (contentGenerated) {
      setProjectContent(contentGenerated)
    }
  }, [])

  useEffect(() => {
    let mapFeed = [];
    assessment.map((a) => {
      const state = a.feedback ? true : false
      mapFeed.push(state)
    });
    setShowFeedback(mapFeed);
  }, []);

  useEffect(() => {
    domain && checkSubscription();
  }, [domain])

  useEffect(() => {
    if (project) {
      setProjectTemplate(project.project_template ? project.project_template : "default")
    }
  }, [project])

  useEffect(() => {
    if (templates) {
      templates.map((template) => {
        if (template.id === projectTemplate) {
          setCssTemplate(template.css)
        }
      })
    }
  }, [templates, projectTemplate])

  useEffect(() => {
    assesmentSend && ScrollToTop()
  }, [assesmentSend]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (jsonData && preferenceLanguage) {
      const newPageTexts = jsonData.map((data) => data[preferenceLanguage]);
      setPageTexts(newPageTexts);
    }
  }, [jsonData, preferenceLanguage])

  useEffect(() => {
    introAssessment && handleProjectIntro(introAssessment)
  }, [introAssessment])

  useEffect(() => {
    if (assessment) {
      handleChangeAssessment(assessment)

    }
  }, [assessment])

  useEffect(() => {
    handleProjectContent(newIncludeContent)
  }, [newIncludeContent])

  useEffect(() => {
    pathToSlides && loadPptImages();
  }, [pathToSlides])

  useEffect(() => {
    assessmentWithOriginalCorrectAnswers();
  }, [])

  useEffect(() => {
    includeVideo && setShowProjectContent(false)
    includeVideo && setNewIncludeContent(false);
  }, [includeVideo])

  useEffect(() => {
    if (user.uid) {
      setUserId(user.uid)
    }
  }, [user.uid])

  useEffect(() => {
    if (urlLogo) {
      setUploadingFile(false)
      changeUrlLogo(urlLogo)
    }

  }, [urlLogo])

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  const formattedAssesment = (assessment) => {

    return (
      <>
        <h3 className="mb-5 mt-4">{pageTexts[105]} {subject}. {pageTexts[148]}</h3>
        <p></p>
        <div>
          <div>
            <form>
              <label>
                <div className="row d-flex align-items-center">
                  <h4 className="col-auto">{pageTexts[106]}</h4>
                  <div className="col-1" onMouseOver={() => setShowIntroText(true)} onMouseLeave={() => setShowIntroText(false)} >
                    <a>
                      <i className={`bi bi-info-circle-fill fs-4 ${styles.icon_info}`}></i>
                    </a>
                  </div>
                  {showIntroText && (
                    <div
                      className={`col-3 ${styles.info_boxes_choice}`}
                    >
                      <div
                        className={`alert alert-info alert-dismissible fade show ${styles.alert_costumization}`}
                        role="alert"
                      >
                        {pageTexts[108]}
                      </div>
                    </div>
                  )}
                </div>
                <textarea
                  disabled={!editProject}
                  type="text"
                  name="intro"
                  placeholder={pageTexts[107]}
                  rows={5}
                  value={introAssessment}
                  onChange={(e) => setIntroAssessment(e.target.value)}
                />
              </label>
            </form>
          </div>

          <p></p>
          <div>
            <h4>
              {pageTexts[186]}
            </h4>
            <div>
              {!uploadingFile
                ? <div>
                  <img src={urlLogo} className={`${styles.logo}`} alt={urlLogo} onClick={handleIncludeLogo} />
                  {urlLogo
                    ? (<div
                      className={styles.btn_plus}
                      onClick={handleDeleteLogo}>
                      <button
                        className={`${styles.btn_style} ${styles.btn_excludeQuestion}`}>
                        <i className="bi bi-dash-circle me-2"></i>
                        {pageTexts[188]}
                      </button>
                    </div>)
                    : (<div
                      className={styles.btn_plus}
                      onClick={handleIncludeLogo}>
                      <button
                        className={`${styles.btn_style} ${styles.btn_excludeQuestion}`}>
                        <i className="bi bi-plus-circle me-2"></i>
                        {pageTexts[187]}
                      </button>
                    </div>)}
                </div>
                : <div class="d-flex justify-content-left align-items-center mt-5 mb-5">
                  <div class="text-center">
                    <div class="spinner-border text-primary" role="status">
                      <span class="visually-hidden"></span>
                    </div>
                    <p>{pageTexts[189]}</p>
                  </div>
                </div>
              }
            </div>
          </div>
          <p></p>

          {showProjectVideo && videoUrl && (
            <div>
              <video controls width="100%" height="auto">
                <source src={videoUrl} type="video/mp4" />
              </video>
            </div>
          )}
          {(videoUrl && !showProjectVideo && editProject) && <div
            className={styles.btn_plus}
            onClick={() => handleShowProjectVideo()}>
            <button
              className={`${styles.btn_style} ${styles.btn_excludeQuestion} mb-3`}>
              <i className="bi bi-card-text me-3"></i>
              {pageTexts[173]}
            </button>
          </div>}
          {videoUrl && showProjectVideo && editProject &&
            <div
              className={styles.btn_plus}
              onClick={() => handleHideProjectVideo()}>
              <button
                className={`${styles.btn_style} ${styles.btn_excludeQuestion} mb-3`}>
                <i className="bi bi-trash me-3"></i>
                {pageTexts[174]}
              </button>
            </div>}

          <p></p>

          {showProjectSlides && loadingSlides
            ? <div class="d-flex justify-content-center align-items-center mt-5 mb-5">
              <div class="text-center">
                <div class="spinner-border text-primary" role="status">
                  <span class="visually-hidden"></span>
                </div>
                <p>{pageTexts[162]}</p>
              </div>
            </div>
            : showProjectSlides && <div >
              <SlidesCarousel listUrlSlides={listUrlSlides} />
              <p></p>
            </div>}
          {(pathToSlides && !showProjectSlides && editProject) && <div
            className={styles.btn_plus}
            onClick={() => handleShowProjectSlides()}>
            <button
              className={`${styles.btn_style} ${styles.btn_excludeQuestion} mb-3`}>
              <i className="bi bi-card-text me-3"></i>
              {pageTexts[166]}
            </button>
          </div>}
          {pathToSlides && showProjectSlides && editProject &&
            <div
              className={styles.btn_plus}
              onClick={() => handleHideProjectSlides()}>
              <button
                className={`${styles.btn_style} ${styles.btn_excludeQuestion} mb-3`}>
                <i className="bi bi-trash me-3"></i>
                {pageTexts[167]}
              </button>
            </div>}

        </div>

        {(!showProjectContent && !pathToSlides && editProject) && <div
          className={styles.btn_plus}
          onClick={() => handleShowProjectContent()}>
          <button
            className={`${styles.btn_style} ${styles.btn_excludeQuestion} mb-3`}>
            <i className="bi bi-card-text me-3"></i>
            {pageTexts[111]}
          </button>
        </div>}

        {showProjectContent && !pathToSlides && editProject &&
          <div
            className={styles.btn_plus}
            onClick={() => handleHideProjectContent()}>
            <button
              className={`${styles.btn_style} ${styles.btn_excludeQuestion} mb-3`}>
              <i className="bi bi-trash me-3"></i>
              {pageTexts[113]}
            </button>
          </div>}

        {(showProjectContent && !pathToSlides) &&
          <div>
            <div className="row">
              <h4>{userUrl ? pageTexts[155] : pageTexts[149]}</h4>
              <textarea
                disabled={!editProject}
                className="col-10 ms-2"
                type="text"
                name="userText"
                placeholder={pageTexts[150]}
                rows={userUrl ? 2 : 20}
                value={projectContent}
                onChange={(e) => setProjectContent(e.target.value)}
              />
            </div>
          </div>
        }

        <div>
          {openDialogDeleteText && <DialogBoxDelete
            actionDelete={handleDeleteText}
            questionDialog={pageTexts[114]}
            optionsDialog={[pageTexts[115], pageTexts[116]]}
            isopen={true}
            content={hideContent}
          />}
        </div>
        <div>
          {assessment.map((q, i) => (
            <div key={i}>
              <form >
                <div className={`mt-4`}>
                  <label>
                    <div className="row">
                      <div className="col-6">
                        <h4>{pageTexts[109]} {i + 1}: </h4>
                      </div>
                      {(assessment.length && editProject) > 0 &&
                        <div className={`col-6 d-flex justify-content-end `}>
                          <div className={`${styles.btn_style} ${styles.btn_excludeQuestion} d-flex align-items-center justify-content-center`} onClick={() => openDialog(q.ask_question, i)}>
                            <i className={`bi bi-trash me-1`}></i> {pageTexts[117]}
                          </div>
                        </div>}
                    </div>
                    <textarea
                      disabled={!editProject}
                      className={`mt-2`}
                      type="text"
                      name="question"
                      required
                      placeholder={pageTexts[122]}
                      rows={5}
                      value={q.ask_question}
                      onChange={(e) => handleChangeQuestion(i, e.target.value)}
                    />
                  </label>
                  <div className='mt-2'>
                    {openDialogDeleteQuestion && <DialogBoxDelete
                      actionDelete={handleDeleteQuestion}
                      questionDialog={`${pageTexts[118]} ${index + 1}? ${pageTexts[151]}`}
                      optionsDialog={[pageTexts[115], pageTexts[116]]}
                      index={index}
                      isopen={true}
                    />}
                  </div>
                  <div className="mt-1">

                    {q.alternatives.map((alternative, j) => (
                      <div className="row mt-1" key={j} >
                        <div className="form-check col-10" >
                          <input
                            disabled={!editProject}
                            className={`form-check-input ${styles.option_check}  `}
                            type="radio"
                            name="meu_radio"
                            value={alternative.option}
                            checked={alternative.option === q.correct_answer}
                            onChange={(e) => handleChangeCorrectAnswer(i, e.target.value)}
                          />
                          <input
                            disabled={!editProject}
                            className={`form-control ${!userEdit ? (alternative.option === originalCorrectAnswers[i]) ? styles.correct_answer : styles.wrong_answer : null} `}
                            type="text"
                            id="title"
                            name="title"
                            value={alternative.text}
                            onChange={(e) => handleChangeChoices(i, j, e.target.value)}
                            placeholder={pageTexts[123]}>
                          </input>
                        </div>
                        {q.alternatives.length > 3 && editProject && alternative.option != q.correct_answer && <div className="col-auto">
                          <div

                            onClick={() => openDialog(alternative.text, i, j)}>
                            <div
                              className={styles.icon_delete_alternative}>
                              <i className="bi bi-x"></i>
                            </div>
                          </div>
                        </div>
                        }
                        {/* Show Dialog Delete*/}
                        <div>
                          {openDialogDeleteAlternative && <DialogBoxDelete
                            actionDelete={handleDeleteChoice}
                            questionDialog={pageTexts[152]}
                            optionsDialog={[pageTexts[115], pageTexts[116]]}
                            index={index}
                            indexChoice={indexChoice}
                            isopen={true}
                          />}
                        </div>
                      </div>
                    ))}
                    {q.alternatives.length < 5 && editProject && <div
                      onClick={() => handleAddAlternative(i)}>
                      <div
                        className={styles.btn_plus}>
                        {pageTexts[124]}
                      </div>
                    </div>}
                  </div>
                  <p></p>
                  <div>
                    <div className="form-check form-switch">
                      <label className="form-check-label">
                        {pageTexts[110]}
                        <input
                          disabled={!editProject}
                          className={`form-check-input ${styles.option_check}`}
                          id="flex-switch"
                          type="checkbox"
                          checked={showFeedback[i]}
                          onChange={(e) => handleShowFeedback(i, e.target.checked)}
                        />
                      </label>
                    </div>
                    {showFeedback[i] && <label>
                      <p></p>
                      <textarea
                        disabled={!editProject}
                        type="text"
                        name="feedback"
                        required
                        placeholder={pageTexts[153]}
                        rows={5}
                        value={q.feedback}
                        onChange={(e) => handleChangeFeedback(i, e.target.value)}
                      />
                    </label>}
                    <hr />
                  </div>
                </div>
                <p></p>
                {assessment.length === i + 1 && editProject && assessment.length < 20 && <div
                  className={styles.btn_plus}
                  onClick={handleAddQuestion}>
                  <button
                    className={`${styles.btn_style} ${styles.btn_excludeQuestion}`}>
                    <i className="bi bi-plus-circle me-2"></i>
                    {pageTexts[121]}
                  </button>
                </div>}
                <p></p>
              </form>
              <p></p>
            </div>))}
          <div className={`mt-5 ${showFixedButtons ? styles.fixed_buttons : styles.flexible_buttons}`}>
            {editProject && !userEdit && <button className={`${styles.btn_cancel} me-5`} onClick={() => setEditProject(false)}>{pageTexts[119]}</button>}
            <button className={`${styles.btn_return} me-5`} onClick={handleCloseAssessment}><i class="bi bi-chevron-left fw-bold"></i> {pageTexts[49]}</button>
            {!editProject && <button className={`${styles.btn_style} me-5`} onClick={handleReviewAssessment}>{pageTexts[154]}</button>}
            {editProject && <button className={`${styles.btn_style} me-5`} onClick={handleSubmitAssessment}>{pageTexts[125]}</button>}
            <button className={`${styles.btn_style} me-5`} onClick={handlePreviewHTML}>Preview</button>
            <button className={`${styles.btn_style} me-5`} onClick={() => handleChangeCarousel()}>{pageTexts[42]} <i class="bi bi-chevron-right fw-bold"></i></button>
          </div>
        </div>
      </>
    )
  }

  const ScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  };

  return (
    <div >
      {assessment && formattedAssesment(assessment)}
    </div>
  );
}

export default SimpleChoiceQuestion;