import React, { useState, useEffect, useRef } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import $ from 'jquery';
import { useNavigate } from 'react-router-dom';

import styles from './Gdpr.module.css'

/* --- IMAGENS --- */
import arrowLegal from '../../images/arrowLegal.svg';

const Gdpr = () => {

	/* --- INICIO DA ANIMAÇÃO POR SCROLL --- */
	const fadeInElements = useRef([]);

	const navigate = useNavigate();

	const goPrivacy = () => {
		navigate("/privacy")
	}

	const goTerms = () => {
		navigate("/terms")
	}

	const goDpa = () => {
		navigate("/dpa")
	  }

	useEffect(() => {
		const options = {
			root: null,
			rootMargin: '0px',
			threshold: 0.2,
		};

		const observer = new IntersectionObserver((entries, observer) => {
			entries.forEach(entry => {
				if (entry.isIntersecting) {
					entry.target.classList.add(styles.fadeIn);
					observer.unobserve(entry.target);
				}
			});
		}, options);

		fadeInElements.current.forEach(element => {
			observer.observe(element);
		});

		return () => {
			observer.disconnect();
		};
	}, []);
	/* --- FIM DA ANIMAÇÃO POR SCROLL --- */

	useEffect(() => {
		const indicatorsElements = document.querySelectorAll('.carousel-indicators');
		if (indicatorsElements) {
			indicatorsElements.forEach(element => {
				element.classList.add(styles.seletores);
			});
		}
	}, []);

	/* --- INICIO --- adicionar para retirar o padding do container parent da página */
	useEffect(() => {
		const capsula = document.querySelector('.container');
		if (capsula) {
			capsula.classList.add('p-0');
		}
	}, []);
	/* --- FIM --- adicionar para retirar o padding do container parent da página */

	/* --- INICIO SCROLL TOP --- */
	const [showScrollButton, setShowScrollButton] = useState(false);
	const [showScrollToTop, setShowScrollToTop] = useState(false);

	const handleScroll = () => {
		if (window.pageYOffset > 100) {
			setShowScrollButton(true);
			$('.scrollToTop').show();
		} else {
			setShowScrollButton(false);
			$('.scrollToTop').hide();
		}

		if (window.pageYOffset > 30) {
			setShowScrollToTop(true);
			$('.scrollToTop').show();
		} else {
			setShowScrollToTop(false);
			$('.scrollToTop').hide();
		}
	};

	useEffect(() => {
		window.addEventListener('scroll', handleScroll);

		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	const scrollToTop = () => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth',
		});
	};
	/* --- FIM SCROLL TOP --- */

	return (
		<div className={`${styles.bg} ${styles.text_left}`}>
			<section className={`${styles.secao} ${styles.call_to_action} vertical-center fade-in text-center text-md-left`}>
				<div className={`${styles.overlay}`}></div>
				<div className={`${styles.area}  text-center ${styles.text_md_left}`}>
					<div className={`row`}>
						<div className={`col-sm-12 col-md-6`}>
							<h1>Data Protection | GDPR Notice - QuizAI</h1>
						</div>
					</div>
				</div>
			</section>

			<section id="gdpr" className={`${styles.area} ${styles.hideFadeIn} ${styles.legais} text-left`} ref={el => fadeInElements.current.push(el)}>
				<div className={`row h-100 justify-content-center align-items-center`}>
					<div className={`col-12 col-md-11`}>
						<h1>Data Protection | GDPR Notice - QuizAI</h1>
						<h2>Effective date: June 22, 2023</h2>
						<p>The General Data Protection Regulation (GDPR) is European Union legislation to strengthen and unify data protection laws for all individuals within the European Union. The regulation came into effect from May 25th, 2018.</p>

						<p>As a Brazilian company, founded and run by Brazilian citizens, but also as people who value privacy, we are fully committed to being compliant with GDPR and all data protection best practices.
						</p>

						<p>This page lays out our commitment to data protection and makes transparent what data we store about our users.</p>

						<h3>Who we are</h3>

						<p>Our company, Clarity Solutions (owner and developer of QuizAI, our product commercial name) is based in Brazil (Address: Rua Bom Pastor 2224 – Ipiranga – São Paulo – SP – CEP 04203-002).
						</p>

						<h3>Questions?</h3>

						<p>If you have any questions regarding this topic, feel free to send us an email at support@quzai.biz</p>

						<h3>What data do we collect?</h3>

						<p>Our service is hosted by Firebase in the United States.</p>

						<p>Our API servers are hosted with AWS (Amazon Web Services), GCP (Google Cloud Platform), both in the United States and ChatGPT (Microsoft Corporation).</p>

						<ul>
							<h3>Access to third-party accounts</h3>
							<li>Paddle account access.</li>
							<li>Personal Data: various types of Data as specified in the privacy policy of the service.</li>
						</ul>

						<ul>
							<h3>Analytics</h3>
							<li>Google Analytics, MixPanel, Hubspot, Simple Analytics, Google Ads conversion tracking.</li>
							<li>Personal Data: Cookies; Usage Data.</li>
						</ul>

						<ul>
							<h3>Heat mapping and session recording</h3>
							<li>Hotjar Heat Maps and Recordings.</li>
							<li>Personal Data: Trackers; Usage Data; various types of Data as specified in the privacy policy of the service.</li>
						</ul>

						<ul>
							<h3>Contacting the User</h3>
							<li>Contact form.</li>
							<li>Personal Data: company name; email address; first name; last name; website.</li>
							<li>Mailing list or newsletter.</li>
							<li>Personal Data: company name; email address; first name; last name.</li>
							<li>FreshChat.</li>
							<li>Personal Data: company name; email address; first name; last name.</li>
						</ul>

						<ul>
							<h3>Handling payments</h3>
							<li>Paddle.</li>
							<li>Personal Data: various types of Data as specified in the privacy policy of the service.</li>
						</ul>

						<ul>
							<h3>Displaying content from external platforms</h3>
							<li>Font Awesome and Google Fonts.</li>
							<li>Personal Data: Tracker; Usage Data.</li>
						</ul>

						<ul>
							<h3>Infrastructure monitoring</h3>
							<li>Sentry.</li>
							<li>Personal Data: various types of Data as specified in the privacy policy of the service.</li>
							<ol>
								<li><h4>Certificates:</h4>
									<p>Firebase is certified under major privacy and security standards (<a href="https://firebase.google.com/downloads/gdpr/2019_Firebase_ISO_27018.pdf" target="_blank">GDPR/ISO 27001</a> and <a href="https://cloud.google.com/security/compliance/soc-2?hl=pt-br" target="_blank">SOC-2</a>).</p>
								</li>
								<li><h4>Server-side encryption</h4>
									<p>Firestore (database service from Firebase) automatically encrypts all data before it is written to disk. There is no setup or configuration required and no need to modify the way you access the service. The data is automatically and transparently decrypted when read by an authorized user. <a href="https://cloud.google.com/firestore/docs/server-side-encryption?hl=pt-br#:~:text=Firestore%20automatically%20encrypts%20all%20data,read%20by%20an%20authorized%20user" target="_blank">See more information</a>.</p>
								</li>
							</ol>
						</ul>


						<h3>Customer/Financial transaction information</h3>
						<p>If you become a paying customer (as opposed to just using our trial or free plan) you will need to provide us and our payment partners Paddle for the payment processing. We will be able to see your name, billing address, email address, and VAT number (if you have provided one). We are not able to see your credit card number, only Paddle has access to that.
						</p>

						<p>As any business, we of course share transaction data with our accountants and with the relevant tax authorities when we pay VAT and file our annual tax return.</p>

						<ul>
							<a href="https://www.paddle.com/legal/privacy" target="_blank"><li>Paddle Privacy policy</li></a>
						</ul>

						<h3>Data processing agreement</h3>
						<p>Becoming a customer of our service implies acceptance of our <a href="#" target="_blank">Data Processing Agreement</a>, unless otherwise explicitly agreed with us in writing.
						</p>

						<h3>Data deletion</h3>
						<p>Any user (paid, free plan or trial) can request to have their account deleted at any time, this can be done by contacting us at support@quizai.biz. For paying customers we of course have to keep records of all completed transactions for tax purposes.
						</p>

						<ul>
							<h3>Last update</h3>
							<li>June 22 2023: Added link to Data Processing Agreement.</li>
						</ul>


						<h3>Stay informed</h3>
						<p>Meaningful changes to this document will be announced on our twitter account and our website.</p>



					</div>
				</div>
			</section>

			<div className={`row justify-content-center align-items-center ${styles.botoesChamadas} ${styles.hideFadeIn}`} ref={el => fadeInElements.current.push(el)}>
				<div className={`col-12 col-md-10 text-center`}>
					<h4>Additional resources:</h4>
					<a href="#" onClick={() => { goTerms() }} className={`${styles.btn} btn ${styles.btn_primary} mb-3 mb-md-0 me-md-3`}>Terms</a>
					<a href="#" onClick={() => { goPrivacy() }} className={`${styles.btn} btn ${styles.btn_primary}  mb-3 mb-md-0 me-md-3`}>Privacy</a>
					<a href="#" onClick={() => { goDpa() }} className={`${styles.btn_primary} btn  mb-3 mb-md-0 me-md-3`}>Data Processing Agreement</a>
				</div>
			</div>

			<div className={`${styles.scrollToTop}`} style={{ display: showScrollToTop ? 'block' : 'none' }}>
				{showScrollButton && (
					<img
						src={arrowLegal}
						alt="Arrow to the top"
						onClick={scrollToTop}
						style={{ cursor: 'pointer' }}
					/>
				)}
			</div>
		</div>
	)
}

export default Gdpr