//Styles
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import styles from './HomeNova.module.css'
//React Icons
import { BiSolidReport } from "react-icons/bi";
//Dev Components
import ChooseProject from '../../components/ChooseProject';
import ChooseFile from '../../components/ChooseFile';
import ProjectPreview from '../../components/ProjectPreview';
import DialogBox from '../../components/DialogBox';
//React Hooks
import { useEffect, useState } from 'react';
import { format } from 'date-fns';
import { enUS, pt, es } from 'date-fns/locale';

//UserHooks
import { useCheckLicenseLimits } from '../../hooks/useCheckLicenseLimits';
import { useLanguageValue } from '../../context/LanguageContext';
//firebase
import { useFetchDocument } from '../../hooks/useFetchDocument';
import { useFetchProjects } from '../../hooks/useFetchProjects';
import { limit } from 'firebase/firestore';
//Translation
import i18n from '../../i18n';
import { useTranslation } from 'react-i18next';

const HomeNova = ({ userSetLanguage, hideMenuItem, userId }) => {

    const [showChooseProject, setShowChooseProject] = useState(true);
    const [showChooseFile, setShowChooseFile] = useState(false);
    const [showPreview, setShowPreview] = useState(false);
    const [projectSelected, setProjectSelected] = useState("");
    const [idProject, setIdProject] = useState("");
    const [userDomain, setUserDomain] = useState(null);
    const [userProjects, setUserProjects] = useState(null);
    const [limitAvaliable, setLimitAvaliable] = useState(true);
    const [limitPptAvaliable, setLimitPptAvaliable] = useState(true);
    const [limitVideoAvaliable, setLimitVideoAvaliable] = useState(true);
    const [domainActive, setDomainActive] = useState(true);
    const [domainStatus, setDomainStatus] = useState("");
    const [domainPlan, setDomainPlan] = useState("");
    const [action, setAction] = useState("");
    const [openDialogBox, setOpenDialogBox] = useState(false);
    const [msgDialog, setMsgDialog] = useState("");
    const [optionsDialog, setOptionsDialog] = useState([]);
    const [failedPayment, setFailedPayment] = useState(false);

    const { dataFetch, pptVideoInfos, checkSubscriptionStatus, checkDomainProjectsLimit } = useCheckLicenseLimits(userId);
    const { document: domain, loadingDocDomain, errorDocDomain } = useFetchDocument("Domains", userId);
    const { documents: projects, loadingProjects, error } = useFetchProjects("Folder", "", userId);

    const { t } = useTranslation(['homenova']);
    const { preferenceLanguage } = useLanguageValue();

    const handleChangeProjectSelected = (project) => {
        if (limitAvaliable && domainPlan != "trial-15" && domainPlan != "free") {
            setProjectSelected(project);
        } else if (project === "file" && (domainPlan === "trial-15" || domainPlan != "free") && (limitAvaliable && !limitPptAvaliable || !limitVideoAvaliable)) {
            setMsgDialog(t(`homenova:messages.Você já atingiu o limite de projetos SCORM da sua licença`))
            setOptionsDialog(["Ok"])
        } else if (!limitAvaliable) {
            setMsgDialog(t(`homenova:messages.Você já atingiu o limite de projetos do mês`))
            setOptionsDialog(["Ok"])
        } else {
            setProjectSelected(project);
        }
    }

    const changeComponentView = () => {
        if (projectSelected === "file") {
            setShowChooseProject(false);
            setShowChooseFile(true);
            setShowPreview(false);
        } else if (projectSelected === "assessment") {
            setShowChooseProject(false);
            setShowChooseFile(false);
            setShowPreview(true);
        } else if (projectSelected === "content") {
            setShowChooseProject(false);
            setShowChooseFile(false);
            setShowPreview(true);
        } else if (projectSelected === "subtitle") {
            setShowChooseProject(false);
            setShowChooseFile(true);
            setShowPreview(false);
        } else {
            setShowChooseProject(true);
            setShowChooseFile(false);
            setShowPreview(false);
        }
    }

    const changeProject = (id) => {
        setIdProject(id);
        setShowChooseFile(false);
        setShowPreview(true);
    }

    const returnHome = () => {
        setShowChooseProject(true);
        setShowChooseFile(false);
        setShowPreview(false);
        setIdProject("");
        setProjectSelected("");
    }

    const actionCloseDialogBox = (f, option) => {
        setAction("");
        setOpenDialogBox(false);
        setMsgDialog("")
        setOptionsDialog([])
    }

    const executeDomainTrial = () => {
        setMsgDialog(t(`homenova:messages.O período do seu plano Trial expirou`))
        setDomainActive(false)
        setOptionsDialog([t(`homenova:messages.SAIBA MAIS`)])
    }

    const executeDomainCancelled = () => {
        const cancelledData = domain.last_cancel_date ? domain.last_cancel_date.toDate() : null;
        const formatedDate = cancelledData ? format(
            cancelledData,
            preferenceLanguage === "Inglês" ? 'MMM/dd/yyyy' : 'dd/MMM/yyyy',
            {
                locale: preferenceLanguage === "Português" ? pt : preferenceLanguage === "Inglês" ? enUS : es
            }
        ) : null;
        setDomainActive(false)
        const dateMessage = formatedDate ? ` em ${formatedDate}` : '';
        setMsgDialog(t(`homenova:messages.Essa assinatura foi cancelada`, {
            dateMessage: dateMessage
        }))
        setOptionsDialog([t(`homenova:messages.Sim`), t(`homenova:messages.Não`)])
    }

    const executePaymentFailed = () => {
        setFailedPayment(true)
        setDomainActive(false)
        setMsgDialog(t(`homenova:messages.Ocorreu um problema com sua cobrança`))
        setOptionsDialog(["Ok"])
    }

    useEffect(() => {
        projectSelected && changeComponentView();
    }, [projectSelected])

    useEffect(() => {
        if (dataFetch) {
            const status = checkSubscriptionStatus();
            setDomainStatus(status);
            if (projects) {
                const [limitAvailable, limitPptAvailable, limitVideoAvailable] = checkDomainProjectsLimit();
                setLimitAvaliable(limitAvailable);
                setLimitPptAvaliable(limitPptAvailable);
                setLimitVideoAvaliable(limitVideoAvailable);
            } else {
                setLimitAvaliable(true)
                setLimitPptAvaliable(true);
                setLimitVideoAvaliable(true);
            }
        }
    }, [projects, dataFetch])

    useEffect(() => {
        if (domainStatus) {
            switch (domainStatus) {
                case "active":
                    setDomainActive(true);
                    break;
                case "expired":
                    executeDomainTrial();
                    break;
                case "canceled":
                    executeDomainCancelled();
                    break;
                case "past_due":
                    executePaymentFailed();
                    break;
                default:
            }
        }
    }, [domainStatus])

    useEffect(() => {
        if (projects && projects.lenght > 0) {
            const limit = checkDomainProjectsLimit();
            setLimitAvaliable(limit);
        } else {
            setLimitAvaliable(true)
        }
    }, [projects])

    useEffect(() => {
        if (msgDialog) {
            setOpenDialogBox(true)
        }
    }, [msgDialog])

    useEffect(() => {
        hideMenuItem("newproject")
    }, [])

    useEffect(() => {
        if (preferenceLanguage) {
            var language = "en";
            switch (preferenceLanguage) {
                case "Português":
                    language = "pt";
                    break;
                case "Espanhol":
                    language = "es";
                    break;
                case "Inglês":
                    language = "en";
                    break;
                default:
                    language = "en";
            }
            changeLanguage(language);
            i18n.changeLanguage(language);
        }
    }, [preferenceLanguage])

    const changeLanguage = (language) => {
        localStorage.setItem('preferenceLanguage', language);
        i18n.changeLanguage(language);
    };

    useEffect(() => {
        if (domain) {
            setDomainPlan(domain.plan);
        }
    }, [domain])

    return (
        <div >
            {showChooseProject &&
                <div className={`row ${styles.row_heigth} m-0 p-0"`}>
                    <ChooseProject
                        handleChangeProjectSelected={handleChangeProjectSelected}
                        domainActive={domainActive}
                    />
                </div>}
            {showChooseFile &&
                <div className={`row ${styles.row_heigth} m-0 p-0"`}>
                    <ChooseFile
                        handleChangeProjectSelected={handleChangeProjectSelected}
                        changeProject={changeProject}
                        domainPlan={domainPlan}
                        projectType={projectSelected}
                    />
                </div>}
            {showPreview &&
                <ProjectPreview
                    id={idProject}
                    projectSelected={projectSelected}
                    returnHome={returnHome}
                    limitAvaliable={true}
                    projectType={projectSelected}
                />}
            {openDialogBox && <DialogBox
                action={action}
                actionScript={actionCloseDialogBox}
                questionDialog={msgDialog}
                optionsDialog={optionsDialog}
                actualFolder={"Folder"}
                foldersMove={""}
                isopen={true}
            />}
        </div>
    )
}

export default HomeNova