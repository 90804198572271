import { useEffect, useState } from 'react';
import * as XLSX from 'xlsx';
import { read, utils } from 'xlsx';


const useExcelToJson = (sheetName) => {
  const [jsonData, setJsonData] = useState(null);

  useEffect(() => { (async() => {

    const f = await (await fetch("https://www.quizai.biz/languages/stringsQuizAI.xlsx")).arrayBuffer();
    const wb = read(f); 
    const ws = wb.Sheets[sheetName];
    const data = utils.sheet_to_json(ws); 
    setJsonData(data); 
  })(); }, []);

  return jsonData;
};

export default useExcelToJson;
