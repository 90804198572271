import styles from './ChangePassword.module.css'
import { useState, useEffect } from 'react';
import { useLanguageValue } from '../context/LanguageContext';
import { getAuth, sendPasswordResetEmail } from 'firebase/auth';

import DialogBox from './DialogBox';
import useExcelToJson from '../hooks/useLoadExcelLanguage'
//Translation
import i18n from '../i18n';
import { useTranslation } from 'react-i18next';

const ChangePassword = ({ userEmail, isActive }) => {

    const [email, setEmail] = useState(userEmail);
    const [openDialog, setOpenDialog] = useState(false);
    const [showInfo, setShowInfo] = useState(false);
    const [pageTexts, setPageTexts] = useState("");
    const auth = getAuth();

    const { t } = useTranslation(['myaccount']);
    const { preferenceLanguage } = useLanguageValue();
    const sheetName = 'account';
    const jsonData = useExcelToJson(sheetName);
    const [message, setMessage] = useState(t('myaccount:changepassword.receberá mensagem no seu e-mail cadastrado'));

    const handleResetPassword = () => {
        setOpenDialog(true);
        setMessage(t('myaccount:changepassword.receberá mensagem no seu e-mail cadastrado'))
        sendPasswordResetEmail(auth, email)
            .catch((error) => {
                setMessage(t('myaccount:changepassword.enviar email redefinição senha', {
                    error: error.message,
                    interpolation: { prefix: '${', suffix: '}' }
                }));
            });
    };

    const handleClosDialogBox = () => {
        setOpenDialog(false);
    }

    useEffect(() => {
        if (jsonData && preferenceLanguage) {

            const newPageTexts = jsonData.map((data) => data[preferenceLanguage]);
            setPageTexts(newPageTexts);
        }
    }, [jsonData, preferenceLanguage])

    useEffect(() => {
        if (preferenceLanguage) {
            var language = "en";
            switch (preferenceLanguage) {
                case "Português":
                    language = "pt";
                    break;
                case "Espanhol":
                    language = "es";
                    break;
                case "Inglês":
                    language = "en";
                    break;
                default:
                    language = "en";
            }
            changeLanguage(language);
            i18n.changeLanguage(language);
        }
    }, [preferenceLanguage])

    const changeLanguage = (language) => {
        localStorage.setItem('preferenceLanguage', language);
        i18n.changeLanguage(language);
    };

    return (
        <div>
            <div className={`row mt-3 d-flex align-items-center`}>
                <h6 className={`col-auto`}>{`Receba um e-mail para redefinir sua senha:`}</h6>
                <div onMouseOver={() => setShowInfo(true)} onMouseLeave={() => setShowInfo(false)} className="col-auto">
                    <a >
                        <i className={`bi bi-info-circle-fill fs-4 ${styles.icon_info}`}></i>
                    </a>
                </div>
                {showInfo && (
                    <div
                        className={`col-4 ${styles.info_boxes}`}
                    >
                        <div
                            className={`alert alert-info alert-dismissible fade show ${styles.alert_costumization}`}
                            role="alert"
                        >
                            <strong>{t('myaccount:changepassword.Importante')}</strong> {t('myaccount:changepassword.receberá mensagem no seu e-mail cadastrado')}
                        </div>
                    </div>
                )}

                <div className={`mt-2`}>
                    <button
                        className={`${styles.btn_style}`}
                        style={{ height: "40px" }}
                        disabled={!isActive}
                        onClick={handleResetPassword} >{t('myaccount:changepassword.Redefinir senha')}</button>
                </div>
            </div>
            <p></p>

            {openDialog && <DialogBox
                isopen={true}
                questionDialog={message}
                optionsDialog={["Ok"]}
                actionScript={handleClosDialogBox}
                action={""}
            />}
        </div>
    )
}

export default ChangePassword