import React from 'react';
import styles from './Plans.module.css';

const CrossIcon = () => (
    <span className={`${styles.cross_icon}`}>
       <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <path d="M6 18L18 6M6 6l12 12"></path>
        </svg>
    </span> 
);

export default CrossIcon;
