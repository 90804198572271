//React Hooks
import { useState, useEffect } from 'react';
//Dev Hooks
import { useAuthValue } from '../context/AuthContext';
//Translation
import i18n from '../i18n';
import { useTranslation } from 'react-i18next';
import { useLanguageValue } from '../context/LanguageContext';

export const useConvertPPTtoText = () => {
  const { user } = useAuthValue();
  const [pptGenerated, setPptGenerated] = useState(false);
  const [errorConvertingPptText, setErrorConvertingPptText] = useState("");
  const [textPpt, setTextPpt] = useState("");
  const [textType, setTextType] = useState('usertext');
  const [msgText, setMsgText] = useState("");
  const [idProject, setIdProject] = useState(null);
  const [userId, setUserId] = useState(user.uid)

  const { preferenceLanguage } = useLanguageValue();
  const { t } = useTranslation(['choosefile']);

  useEffect(() => {
    if (preferenceLanguage) {
      var language = "en";
      switch (preferenceLanguage) {
        case "Português":
          language = "pt"
          break;
        case "Espanhol":
          language = "es"
          break;
        case "Inglês":
          language = "en"
          break;
      }
      changeLanguage(language);
      i18n.changeLanguage(language);
    }
  }, [preferenceLanguage])

  const changeLanguage = (language) => {
    localStorage.setItem('preferenceLanguage', language);
    i18n.changeLanguage(language);
  };

  const convertPpttoText = async (url) => {
    setMsgText("Lendo o conteúdo do PPT")
    let pptxPath = "";
    const regex = /\/o\/(.*?)\?alt=media/;
    const matches = url.match(regex);

    if (matches && matches.length > 1) {
      pptxPath = matches[1];
    } else {
      setErrorConvertingPptText(t('choosefile:messages.Não foi possível extrair o caminho relativo'));
    }

    try {
      const response = await fetch(`https://us-central1-quizzai-4b3cd.cloudfunctions.net/convertPPTtoText?userId=${userId}&projectId=${idProject}&pptxPath=${pptxPath}`, {
        method: 'GET',
      });
      if (response.status === 200) {
        setPptGenerated(true)
        const data = await response.json();
        setTextType("usertext");
        const pptText = data.extractedText
        setTextPpt(pptText);
        return pptText;
      } else {
        setErrorConvertingPptText(t('choosefile:messages.Erro na extração do texto do PPT'));
      }
    } catch (error) {
      setErrorConvertingPptText(t('choosefile:messages.Erro na solicitação'));
    }
  }
  return { convertPpttoText, errorConvertingPptText }
}