//Styles
import styles from './ShowPlanDetails.module.css'
//React Hooks
import { useEffect, useState, useRef } from 'react';
//React Routes
import { useNavigate, useLocation, json } from 'react-router-dom';
//Firebase
import { useFetchProjects } from '../hooks/useFetchProjects';
import { useFetchDocument } from '../hooks/useFetchDocument'
import { useAuthValue } from '../context/AuthContext';
import { Timestamp } from 'firebase/firestore';
import { useLanguageValue } from '../context/LanguageContext';
//Translation
import i18n from '../i18n';
import { useTranslation } from 'react-i18next';

const ShowPlanDetails = () => {

    const { t } = useTranslation(['navbar']);
    const { preferenceLanguage } = useLanguageValue();

    const [userTotalMonthProjects, setUserTotalMonthProjects] = useState();
    const [userMonthProjectsLimit, setUserMonthProjectsLimit] = useState();
    const [formattedPlan, setFormattedPlan] = useState("");
    const [userDomainPlan, setUserDomainPlan] = useState("");
    const [folder, setFolder] = useState("Folder");
    const [search, setSearch] = useState();

    const { user } = useAuthValue();
    const { document: domain, loadingDocDomain, errorDocDomain } = useFetchDocument("Domains", user.uid)
    const { documents: projects, loadingProjects, error } = useFetchProjects(folder, search, user.uid);

    const navigate = useNavigate();

    const goCatalog = () => {
        navigate("/seuperfil", { state: { tab: "Detalhes do plano" } })
    }

    const checkTotalProjectsMonth = () => {
        const filteredProjects = projects.filter((project) => {
            if (project.id === "folder") {
                return false;
            }

            const signatureDateTime = domain.signature_date.toDate();
            const actualDateTime = Timestamp.now().toDate();

            signatureDateTime.setHours(0, 0, 0, 0);
            actualDateTime.setHours(0, 0, 0, 0);

            const signatureDateDay = signatureDateTime.getDate();
            const actualDay = actualDateTime.getDate();
            const actualMonth = actualDateTime.getMonth();
            const actualYear = actualDateTime.getFullYear();
            const baseMonth = signatureDateDay > actualDay ? actualMonth - 1 : actualMonth;
            const baseDate = new Date(actualYear, baseMonth, signatureDateDay);

            const creationDate = project.creation_date.toDate();
            creationDate.setHours(0, 0, 0, 0);

            return creationDate >= baseDate && creationDate <= actualDateTime;
        });

        const totalMonthProjects = filteredProjects.length;
        return totalMonthProjects;
    };

    const changeDomainPlan = () => {
        if (userDomainPlan.includes("trial")) {
            setFormattedPlan("Trial");
        } else if (userDomainPlan === 'pro-monthly') {
            setFormattedPlan(t(`navbar:plandetails.Pro Mensal`));
        } else if (userDomainPlan === 'pro-annual') {
            setFormattedPlan(t(`navbar:plandetails.Pro Anual`));
        } else {
            setFormattedPlan("Basic (free)");;
        }
    }

    useEffect(() => {
        if (domain && projects) {
            const totalProjects = checkTotalProjectsMonth();
            setUserTotalMonthProjects(totalProjects)
        }
    }, [domain, projects])

    useEffect(() => {
        if (domain) {
            setUserMonthProjectsLimit(domain.monthly_project_limit);
            setUserDomainPlan(domain.plan)
        }
    }, [domain])

    useEffect(() => {
        changeDomainPlan();
    }, [userDomainPlan])

    useEffect(() => {
        if (preferenceLanguage) {
            var language = "en";
            switch (preferenceLanguage) {
                case "Português":
                    language = "pt";
                    break;
                case "Espanhol":
                    language = "es";
                    break;
                case "Inglês":
                    language = "en";
                    break;
                default:
                    language = "en";
            }
            changeLanguage(language);
            i18n.changeLanguage(language);
            changeDomainPlan()
        }
    }, [preferenceLanguage])

    const changeLanguage = (language) => {
        localStorage.setItem('preferenceLanguage', language);
        i18n.changeLanguage(language);
    };

    return (
        <div>
            {userMonthProjectsLimit &&
                <div className="col-auto card p-3 d-flex justify-content-center">
                    <div className="row d-flex align-items-center">
                        <h6 className="col-auto">{t(`navbar:plandetails.Uso mensal`)}</h6>
                        <span className="col-auto">
                            <h4>{`${userTotalMonthProjects}/${userMonthProjectsLimit}`}</h4>
                        </span>
                        <div
                            className={`col-auto fw-semibold ms-2 d-flex justify-content-center ${styles.round_pill} ${styles.text_plan}`}
                        >
                            {formattedPlan}
                        </div>
                        <div className={`col-auto`}>
                            <a href="#" onClick={() => goCatalog()} className={`${styles.my_plan}`}>{t(`navbar:plandetails.Meu Plano`)}</a>
                        </div>
                    </div>
                    {userMonthProjectsLimit > 0 ? (
                        <div
                            className="progress mt-3"
                            role="progressbar"
                            aria-label="Basic example"
                            aria-valuenow={(userTotalMonthProjects / userMonthProjectsLimit) * 100}
                            aria-valuemin={0}
                            aria-valuemax={100}
                        >
                            <div
                                className="progress-bar progress_bar"
                                style={{
                                    width: `${(userTotalMonthProjects / userMonthProjectsLimit) * 100}%`,
                                    backgroundColor: "#265287",
                                }}
                            ></div>
                        </div>
                    ) : (
                        <p>No progress to show</p>
                    )}
                </div>}
        </div>

    )
}

export default ShowPlanDetails