import React, { useState, useEffect, useRef, useCallback } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

import Accordion from 'react-bootstrap/Accordion';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import styles from './Help.module.css';

const Help = ({ userSetLanguage, hideMenuItem }) => {

  const accordionRefs = [
    useRef(null),
    useRef(null),
    useRef(null),
  ];



  /* --- INICIO --- adicionar para retirar o padding do container parent da página */
  useEffect(() => {
    const capsula = document.querySelector('.container');
    if (capsula) {
      capsula.classList.add('p-0');
    }
  }, []);
  /* --- FIM --- adicionar para retirar o padding do container parent da página */

  /* --- INICIO DA ANIMAÇÃO POR SCROLL --- */
  const fadeInElements = useRef([]);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.2,
    };

    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add(styles.fadeIn);
          observer.unobserve(entry.target);
        }
      });
    }, options);

    fadeInElements.current.forEach(element => {
      observer.observe(element);
    });

    return () => {
      observer.disconnect();
    };
  }, []);
  /* --- FIM DA ANIMAÇÃO POR SCROLL --- */

  // INICIO PAUSAR DEMAIS VIDEOS
  const [activeAccordionKey, setActiveAccordionKey] = useState(null);

  const handleAccordionTabClick = useCallback((eventKey) => {
    setActiveAccordionKey(eventKey); // Define o novo valor diretamente
    pauseAllVideos();
  }, []);


  const pauseAllVideos = () => {
    // Iterar por todas as referências de Accordion
    accordionRefs.forEach(accordionRef => {
      if (accordionRef.current) {
        const iframeElements = accordionRef.current.querySelectorAll('iframe');
        // Iterar pelos elementos <iframe> e pausar os vídeos
        iframeElements.forEach((iframe) => {
          iframe.contentWindow.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*');
        });
      }
    });
  };

  useEffect(() => {
    hideMenuItem("help")
  }, [])

  // FIM PAUSAR DEMAIS VIDEOS
  return (
    <div className={`${styles.bg}`}>
      <section id="help" className={`${styles.call_to_action} ${styles.hideFadeIn} ${styles.secao}`} ref={el => fadeInElements.current.push(el)}>
        <div className={`${styles.overlay}`}></div>
        <div className={`container text-center ${styles.text_md_left}`}>
          <div className={`row`}>
            <div className={`col-sm-12 col-md-6 ${styles.cabecalho}`}>
              <h1>QuizAI – Help</h1>
              <p>This guide is designed to provide you with a clear and detailed step-by-step on how to use all the features of QuizAI.</p>
            </div>
          </div>
        </div>
      </section>

      <>
        <div className={`${styles.sanfona} ${styles.hideFadeIn}`} ref={el => fadeInElements.current.push(el)}>
          <h1>Create New Projects</h1>
          <Container className={`${styles.containerCustom}`}>
            <Row className={`justify-content-center align-items-center`}>
              <Col md={10} lg={8}>
                <div>
                  <Accordion defaultActiveKey={[]} activeKey={activeAccordionKey} ref={accordionRefs[0]}>
                    <Accordion.Item eventKey="0">
                      <Accordion.Header className={styles.customAccordionHeader} onClick={() => handleAccordionTabClick()}> How to create a new project?</Accordion.Header>
                      <Accordion.Body className={styles.videoYoutube}>
                        <iframe src="https://www.youtube.com/embed/wLyzAvjhMGc" id="vd1" title="How to create a new project in QuizAi" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header className={styles.customAccordionHeader} onClick={() => handleAccordionTabClick()}> Question creation – Manual registration</Accordion.Header>
                      <Accordion.Body className={styles.videoYoutube}>
                        <iframe src="https://www.youtube.com/embed/A9QnqMtnIoo" id="vd2" title="How to create through manual registration in QuizAI" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                      <Accordion.Header className={styles.customAccordionHeader} onClick={() => handleAccordionTabClick()}> Create projects using the selection of topics</Accordion.Header>
                      <Accordion.Body className={styles.videoYoutube}>
                        <iframe src="https://www.youtube.com/embed/LDrNZFzYlkI" id="vd3" title="Create projects using the selection of topics in QuizAI" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                      <Accordion.Header className={styles.customAccordionHeader} onClick={() => handleAccordionTabClick()}> Create projects using existing text</Accordion.Header>
                      <Accordion.Body className={styles.videoYoutube}>
                        <iframe src="https://www.youtube.com/embed/fSWrI5qUCZ4" id="vd4" title="Create projects using existing text in QuizAI" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="4">
                      <Accordion.Header className={styles.customAccordionHeader} onClick={() => handleAccordionTabClick()}> Create projects using anexisting text from a website</Accordion.Header>
                      <Accordion.Body className={styles.videoYoutube}>
                        <iframe src="https://www.youtube.com/embed/dc8TEUouO3o" id="vd5" title="Create projects using anexisting text from a website in QuizAI" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="5">
                      <Accordion.Header className={styles.customAccordionHeader} onClick={() => handleAccordionTabClick()}> Create projects generating text and questions using AI</Accordion.Header>
                      <Accordion.Body className={styles.videoYoutube}>
                        <iframe src="https://www.youtube.com/embed/Ysi1ImHhD3Y" id="vd6" title="Create projects generating text and questions using AI in QuizAI" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="6">
                      <Accordion.Header className={styles.customAccordionHeader} onClick={() => handleAccordionTabClick()}> Create projects generating a case with questions using AI</Accordion.Header>
                      <Accordion.Body className={styles.videoYoutube}>
                        <iframe src="https://www.youtube.com/embed/UGWsOdjJNco" id="vd7" title="Create projects generating a case with questions using AI in QuizAI" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="7">
                      <Accordion.Header className={styles.customAccordionHeader} onClick={() => handleAccordionTabClick()}> Reviewing a project</Accordion.Header>
                      <Accordion.Body className={styles.videoYoutube}>
                        <iframe src="https://www.youtube.com/embed/2YQNOdf2aBk" id="vd8" title="Review projects in QuizAI" style={{ height: '100%', width: '100%' }} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        <div className={`${styles.sanfona}  ${styles.hideFadeIn} `} ref={el => fadeInElements.current.push(el)}>
          <h1>Template selection and publishing</h1>
          <Container className={`${styles.containerCustom}`}>
            <Row className={`justify-content-center align-items-center`}>
              <Col md={10} lg={8}>
                <div>
                  <Accordion defaultActiveKey={[]} activeKey={activeAccordionKey} ref={accordionRefs[1]}>
                    <Accordion.Item eventKey="0">
                      <Accordion.Header className={styles.customAccordionHeader} onClick={() => handleAccordionTabClick()}> Choosing a template</Accordion.Header>
                      <Accordion.Body className={styles.videoYoutube}>
                        <iframe src="https://www.youtube.com/embed/bZ384PREczc" id="vd9" title="YouTube Video" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header className={styles.customAccordionHeader} onClick={() => handleAccordionTabClick()}> How to export a Project</Accordion.Header>
                      <Accordion.Body className={styles.videoYoutube}>
                        <iframe src="https://www.youtube.com/embed/0CPsLr6v5C0" id="vd10" title="Export projects in QuizAI: Easy steps!" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        <div className={`${styles.sanfona}  ${styles.hideFadeIn}  ${styles.ultimaSanfona}`} ref={el => fadeInElements.current.push(el)}>
          <h1>Organizing and managing projects</h1>
          <Container className={`${styles.containerCustom}`}>
            <Row className={`justify-content-center align-items-center`}>
              <Col md={10} lg={8}>
                <div>
                  <Accordion defaultActiveKey={[]} activeKey={activeAccordionKey} ref={accordionRefs[2]}>
                    <Accordion.Item eventKey="0">
                      <Accordion.Header className={styles.customAccordionHeader} onClick={() => handleAccordionTabClick()}> Creating and managing folders</Accordion.Header>
                      <Accordion.Body className={styles.videoYoutube}>
                        <iframe src="https://www.youtube.com/embed/EAXmmtbwVig" id="vd11" title="Organize projects using the selection of topics in QuizAI" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header className={styles.customAccordionHeader} onClick={() => handleAccordionTabClick()}> How to edit a project</Accordion.Header>
                      <Accordion.Body className={styles.videoYoutube}>
                        <iframe src="https://www.youtube.com/embed/3x-kzDZyEpI" id="vd12" title="Manage projects in QuizAI" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </>

    </div>
  );
}

export default Help;
