import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { useLanguageValue } from '../context/LanguageContext';
import useExcelToJson from '../hooks/useLoadExcelLanguage';

import styles from './SlidesCarousel.module.css';
import { useState, useEffect } from 'react';

import React from 'react'

const SlidesCarousel = ({ listUrlSlides }) => {

    const [slide, setSlide] = useState(0);
    const [qtdSlides, setQtdSlides] = useState(1);
    const [slides, setSlides] = useState(listUrlSlides);
    const [loadingSlides, setLoadingSlides] = useState(true)
    const [progress, setProgress] = useState(0);
    const [pageTexts, setPageTexts] = useState([]);
    const [slidePage, setSlidePage] = useState(1);

    const { preferenceLanguage } = useLanguageValue();
    const sheetName = 'newproject';
    const jsonData = useExcelToJson(sheetName);

    const nextCarousel = () => {
        if (slide < slides.length) {
            setSlide(slide + 1)
        } else {
            setSlide(0)
        }
        //const prevButton = document.querySelector('.introNext').click();
    }

    const prevCarousel = () => {
        if (slide > 0) {
            setSlide(slide - 1)
        } else {
            setSlide(slides.length)
        }
        //const prevButton = document.querySelector('.introPrev').click();
    }

    useEffect(() => {
        if (jsonData && preferenceLanguage) {
            const newPageTexts = jsonData.map((data) => data[preferenceLanguage]);
            setPageTexts(newPageTexts);
        }
    }, [jsonData, preferenceLanguage])

    useEffect(() => {
        const fetchImages = async () => {
            try {
                const totalImages = slides.length;
                let loadedImages = 0;

                for (const imageUrl of slides) {
                    const response = await fetch(imageUrl);

                    if (!response.ok) {
                        throw new Error('Erro ao carregar a imagem');
                    }

                    const totalBytes = response.headers.get('content-length');
                    const reader = response.body.getReader();
                    let loadedBytes = 0;

                    while (true) {
                        const { done, value } = await reader.read();
                        if (done) {
                            break;
                        }

                        loadedBytes += value.length;
                        loadedImages += 1;
                        const currentProgress = (loadedImages / totalImages) * 100;
                        setProgress(currentProgress);
                    }
                }

                setLoadingSlides(false);
            } catch (error) {
                console.error(error);
                setLoadingSlides(false);
            }
        };

        fetchImages();
    }, []);

    return (
        <div>
            {loadingSlides
                ? <div class="d-flex justify-content-center align-items-center mt-5 mb-5">
                    <div class="text-center">
                        <div class="spinner-border text-primary" role="status">
                            <span class="visually-hidden"></span>
                        </div>
                        <p>{pageTexts[162]}</p>
                    </div>
                </div>
                : <div className={`d-flex justify-content-center align-items-center mt-5 mb-5`}>
                    <div className="row align-items-center">
                        <div className="col-auto">
                            <button className={` ${styles.button_intro_carousel}`} onClick={() => prevCarousel()} disabled={slide === 0 ? true : false}>
                                <i className={`bi bi-chevron-left ${slide === 0 ? styles.carousel_icon_disabled : styles.carousel_icon}`}></i>
                            </button>
                        </div>
                        <div className={`col-10 ${styles.carousel_div}`}>
                            {slides &&
                                <div>
                                    <img src={slides[slide]} alt={slides[slide]} className={`d-block ${styles.carousel_images}`} />
                                </div>}
                        </div>
                        <div className="col-auto">
                            <button className={` ${styles.button_intro_carousel}`} onClick={() => nextCarousel()} disabled={slide === slides.length -1 ? true : false}>
                                <i className={`bi bi-chevron-right ${slide === 3 ? styles.carousel_icon_disabled : styles.carousel_icon}`}></i>
                            </button>
                        </div>
                    </div>
                </div>}

        </div>
    )
}

export default SlidesCarousel