import { db } from '../firebase/config';
import { useInsertDocument } from './useInsertDocument';
import { useUpdateDocument } from './useUpdateDocument';
import { useAuthValue } from '../context/AuthContext';
import { Timestamp } from 'firebase/firestore';

export const useCreateProject = () => {
  
    const { user } = useAuthValue();

    const userID = user.uid;

    const { insertDocument, responseInsert } = useInsertDocument("Domains", userID);
    const { updateDocument, responseUpdate } = useUpdateDocument("Domains", userID)

    const createProject = async (projectInfos) =>  {
        const projectDescription = projectInfos.projectDescription;
        const language = projectInfos.language;
        const projectName = projectInfos.projectName;
        const introAssessment = projectInfos.introAssessment;
        const projectContent = projectInfos.projectContent ? projectInfos.projectContent : "";
        const assessment = projectInfos.assessment;
        const idProject = projectInfos.idProject;
        const projectFolder = projectInfos.projectFolder;
        const projectSubject = projectInfos.subject;
        const projectTemplate = projectInfos.projectTemplate;
        const includeContent = projectInfos.includeContent;
        const includeSlides = projectInfos.includeSlides;
        const includeCase = projectInfos.includeCase;
        const showTogether = projectInfos.presentTogether;
        const pptText = projectInfos.textPpt;
        const pathToSlides = projectInfos.pathToSlides;
        const videoUrl = projectInfos.videoUrl;
        const videoPath = projectInfos.videoPath;
        const includeVideo = projectInfos.includeVideo;
        const showVideoTogether = projectInfos.presentVideoTogether;
        const videoText = projectInfos.videoTranscript;
        const contentGenerated = projectInfos.content_generated ? projectInfos.content_generated  : "";
        const listUrlSlides = projectInfos.listUrlSlides;
        const menuItems = projectInfos.menuItems;
        const showProgresss = projectInfos.showProgress;
        const menuFreeNavigation = projectInfos.menu_free_navigation;
        const templateid = projectInfos.template_id;
        const subtitle = projectInfos.subtitle ? projectInfos.subtitle : "";
        const languageSubtitles = projectInfos.subtitleLanguage ? projectInfos.subtitleLanguage : "";
        const projectSize = projectInfos.projectSize ? projectInfos.projectSize : 0;

        const actionDoc = projectInfos.actionDoc;

        //Se tem idProject, será um update
        const id = idProject ? idProject : projectName + Timestamp.now();
        const updateInfos = actionDoc === "update"
          ? {
            last_updata_by: userID, 
            last_update_date: Timestamp.now()}
          : {}
          
        const newDocument = {
            content_generated: contentGenerated,
            created_by: userID,
            deleted_by: "",
            domain: userID,
            folder: projectFolder,
            html_url: "",
            id: id,
            include_case: includeCase,
            include_content: includeContent,
            include_slides: includeSlides,
            include_video: includeVideo,
            intro_assessment: introAssessment,
            mp3_file: "",
            pdf_file: "",
            podcast_url: "",
            ppt_text: pptText ? pptText : null,
            video_text: videoText ? videoText : null,
            path_to_slides: pathToSlides ? pathToSlides : null,
            ppt_assessent_together: showTogether,
            project_active: true,
            project_content: projectContent,
            project_description: projectDescription,
            project_image_file: "",
            project_image_url: "",
            project_language: language,
            project_name: projectName,
            project_subject: projectSubject,
            project_template: projectTemplate ? projectTemplate : "default",
            project_text: "",
            assessment: assessment,
            template_id: templateid,
            video_assessment_together: showVideoTogether,
            video_file: videoPath ? videoPath : null,
            video_url: videoUrl ? videoUrl : null,
            word_file: "",
            listUrlSlides: listUrlSlides,
            menu_items: menuItems,
            show_progress: showProgresss,
            menu_free_navigation: menuFreeNavigation,
            subtitle: subtitle,
            subtitle_language: languageSubtitles,
            project_size: projectSize,
          }

          actionDoc === "insert" 
            ? await insertDocument(newDocument)
            : await updateDocument({...newDocument, ...updateInfos});

    }

    return {createProject};
}