
//Hooks
import { useState, useEffect } from 'react';
import { useAskChatGPT } from '../hooks/useAskChatGPT';

const AssessmentSubject = ({ handleShowSetup, handleSetSubject, projectName, handleSplitTopics, handleSetUserText, AIHelp }) => {

  console.log("AssessmentSubject.js");

  const [subject, setSubject] = useState("");
  const [showTopics, setShowTopics] = useState(false);
  const [automaticGenerate, setAutimaticGenerate] = useState(true);
  const [userText, setUserText] = useState("");
  const [textAutomaticGenerate, SetTextAutomaticGenerate] = useState("");
  const [textTopic, setTextTopic] = useState("");
  const { handleOpenAiApi, listOfTopics, loading, error } = useAskChatGPT("");

  useEffect(() => {
    setSubject(projectName);
    handleSetSubject(projectName);
  }, [projectName])

  const handleChangeAutomaticGenerate = (a) => {
    setAutimaticGenerate(a);
  }

  const handleChangeSetTopics = (t) => {
    setShowTopics(t);
    handleShowSetup(!t);
  }

  const handleChangeSubject = (s) => {
    setSubject(s);
    handleSetSubject(subject);
  }

  const handleChangeUserText = (text) => {
    setUserText(text);
    handleSetUserText(text);
  }

  const handleAskChatGPT = async () => {
    const res = await handleOpenAiApi({ subject: subject, userText: userText, orderGPT: "topics" });
  }


  useEffect(() => {

    if (automaticGenerate) {
      SetTextAutomaticGenerate("Inteligência Artificial (desmarque aqui caso você queira inserir seu texto para gerar a avaliação).");
      setTextTopic("Sugerir principais tópicos sobre o tema?");
    } else {
      SetTextAutomaticGenerate("Inserir texto (marque aqui caso você queira gerar a avaliação a partir de um texto fornecido pela IA).");
      setTextTopic("Sugerir principais tópicos do seu texto?");
    }

  }, [automaticGenerate])

  useEffect(() => {
    if (listOfTopics.length > 0) {
      handleSplitTopics(listOfTopics);
    }
  }, [listOfTopics]);

  return (
    <div className="row">
      <p></p>
      <label className="col-8">
        Assunto da avaliação:
        <input
          type="text"
          required
          name="subject"
          value={subject}
          onChange={(e) => handleChangeSubject(e.target.value)}
        />
      </label>
      <p></p>
      {AIHelp && <div>
        Avaliação deve ser gerada a partir de onde?
        <div className="row">
          <div className="form-check form-switch">
            <label className="form-check-label">
              {textAutomaticGenerate}
              {loading
                ? <input
                  className="form-check-input"
                  id="flex-switch"
                  type="checkbox"
                  disabled />
                : <input
                  className="form-check-input"
                  id="flex-switch"
                  type="checkbox"
                  checked={automaticGenerate}
                  onChange={(e) => handleChangeAutomaticGenerate(e.target.checked)}
                />
              }
            </label>
          </div>
        </div>
        {!automaticGenerate && (
        <div>
          <form>
            <label>
              <h4>Cole abaixo seu texto: </h4>
              <textarea
                type="text"
                name="intro"
                placeholder="Cole seu texto"
                rows={10}
                value={userText}
                onChange={(e) => handleChangeUserText(e.target.value)}
              />
            </label>
            
          </form>
        </div>
      )}
      <p></p>
      <div className="row">
        <div className="form-check form-switch">
          <label className="form-check-label">
            {textTopic}
            {loading
              ? <input
                className="form-check-input"
                id="flex-switch"
                type="checkbox"
                disabled />
              : <input
                className="form-check-input"
                id="flex-switch"
                type="checkbox"
                onChange={(e) => handleChangeSetTopics(e.target.checked)}
              />
            }
          </label>
        </div>
      </div>
      </div>}
      
      <p></p>
      {loading
        ? <h3>Aguarde...</h3>
        : showTopics
          ? <button className="btn btn-primary col-2" onClick={handleAskChatGPT}>Pesquisar</button>
          : null
      }
      <p></p>
    </div>
  );
}
export default AssessmentSubject;
