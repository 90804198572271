import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import $ from 'jquery';
import Popper from 'popper.js';
import styles from './Navbar.module.css'
import ShowPlanDetails from './ShowPlanDetails';
import { NavLink, useLocation } from 'react-router-dom';
import { useFetchDocument } from '../hooks/useFetchDocument'
import { useAuthentication } from '../hooks/useAuthentications';
import { useAuthValue } from '../context/AuthContext';
import { useState, useEffect } from 'react';
import { useLanguageValue } from '../context/LanguageContext';
import useExcelToJson from '../hooks/useLoadExcelLanguage';
import quizAiLogo from '../images/logoQuizAI.png';
import ptflag from '../images/pt-br-flag.png'
import spflag from '../images/es-es-flag.png'
import enflag from '../images/en-us-flag.png'
//Translation
import i18n from '../i18n';
import { useTranslation } from 'react-i18next';

const Navbar = ({ changeSiteLanguage, hideItem }) => {

    const location = useLocation();
    const [userId, setUserId] = useState();
    const { user } = useAuthValue();
    const { logout } = useAuthentication();
    const { document: userInfo, loadingDocDomain, errorDocDomain } = useFetchDocument("Users", userId)

    const { t } = useTranslation(['navbar']);
    const { preferenceLanguage } = useLanguageValue();
    const userLanguage = navigator.language;

    const sheetName = 'navbar';
    const jsonData = useExcelToJson(sheetName);
    const [userName, setUserName] = useState("");
    const [pageTexts, setPageTexts] = useState("");
    const [preferenceBrowserLanguage, setPreferenceBrowserLanguage] = useState("");
    const [flagLanguage, setFlagLanguage] = useState("");
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [menuOpen, setMenuOpen] = useState(true);

    const changeUserLanguage = (lang) => {
        changeSiteLanguage(lang)
    }

    const changeProjectLanguage = (lang) => {
        if (lang === "pt") {
            setPreferenceBrowserLanguage("Português");
            changeUserLanguage("Português")
            setFlagLanguage(ptflag)
        } else if (lang === "sp") {
            setPreferenceBrowserLanguage("Espanhol");
            changeUserLanguage("Espanhol")
            setFlagLanguage(spflag)
        } else {
            setPreferenceBrowserLanguage("Inglês");
            changeUserLanguage("Inglês")
            setFlagLanguage(enflag)
        }
        changeLanguage(lang);
        i18n.changeLanguage(lang);
    }

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    useEffect(() => {
        switch (userLanguage) {
            case "pt-BR":
            case "pt":
                setPreferenceBrowserLanguage("Português");
                setFlagLanguage(ptflag)
                break;
            case "en-US":
            case "en":
                setPreferenceBrowserLanguage("Inglês");
                setFlagLanguage(enflag)
                break;
            case "es-ES":
            case "es":
                setPreferenceBrowserLanguage("Espanhol");
                setFlagLanguage(spflag)
                break;
            default:
                setPreferenceBrowserLanguage("Inglês");
                setFlagLanguage(enflag)
                break;
        }
    }, [userLanguage]);

    useEffect(() => {
        user && setUserId(user.uid)
    }, [user])

    useEffect(() => {
        userInfo && setUserName(userInfo.name);
    }, [userInfo])

    useEffect(() => {
        if (jsonData && preferenceLanguage && preferenceBrowserLanguage) {
            const newPageTexts = jsonData.map((data) => data[user ? preferenceLanguage : preferenceBrowserLanguage]);
            setPageTexts(newPageTexts);
        }
    }, [jsonData, preferenceLanguage, preferenceBrowserLanguage])

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
            if (window.innerWidth < 768) {
                setMenuOpen(false);
            } else {
                setMenuOpen(true);
            }
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (window.innerWidth < 768) {
            setMenuOpen(false);
        } else {
            setMenuOpen(true);
        }
    }, [])

    useEffect(() => {
        if (preferenceLanguage) {
            var language = "en";
            switch (preferenceLanguage) {
                case "Português":
                    language = "pt";
                    break;
                case "Espanhol":
                    language = "es";
                    break;
                case "Inglês":
                    language = "en";
                    break;
                default:
                    language = "en";
            }
            changeLanguage(language);
            i18n.changeLanguage(language);
        }
    }, [preferenceLanguage])

    const changeLanguage = (language) => {
        console.log("changeLanguage: " + language)
        localStorage.setItem('preferenceLanguage', language);
        i18n.changeLanguage(language);
    };

    return (
        <nav className={`navbar row navbar-expand-lg ${styles.navbar} justify-content-lg-between justify-content-md-between justify-content-sm-center `} id="bottom-navbar-container">
            <div className={`d-flex col-auto align-items-cente`}>
                <NavLink to="/" className={styles.brand}>
                    <img src={quizAiLogo} alt="QuizAI" className={styles.logo} />
                </NavLink>
            </div>
            {!user && <button
                className="navbar-toggler"
                type="button"
                onClick={() => toggleMenu()}
                aria-expanded={menuOpen}
                aria-label="Toggle navigation"
            >
                <i className="bi bi-list"></i>
            </button>}

            {user && <div className={`d-flex col-auto justify-content-center align-items-center`}>
                <ShowPlanDetails></ShowPlanDetails>
            </div>}

            {!user &&
                <div className={`col-auto d-flex collapse navbar-collapse justify-content-end `} id="bottom-navbar">
                    {menuOpen && <ul className="navbar-nav col-auto d-flex align-items-center ">
                        <li className={`nav-item`}>
                            {!user && <div className="col-auto">
                                <NavLink
                                    to="/home"
                                    className={`${location.pathname === '/home' ? styles.selected : styles.active} `}
                                >
                                    Home
                                </NavLink>
                            </div>}
                        </li>
                        <li className={`nav-item`}>
                            {!user && <div className="col-auto">
                                <NavLink
                                    to="/features"
                                    className={`${location.pathname === '/features' ? styles.selected : styles.active} `}
                                >
                                    {t(`navbar:menu.Funcionalidades`)}
                                </NavLink>
                            </div>}
                        </li>
                        <li className="nav-item">
                            {!user && <div className="col-auto">
                                <NavLink
                                    to="/faq"
                                    className={`${location.pathname === '/faq' ? styles.selected : styles.active} `}
                                >
                                    {t(`navbar:menu.FAQ`)}
                                </NavLink>
                            </div>}
                        </li>
                        <li className="nav-item">
                            {!user && <div className="col-auto">
                                <NavLink
                                    to="/catalog"
                                    className={`${location.pathname === '/catalog' ? styles.selected : styles.active} `}
                                >
                                    {t(`navbar:menu.Planos`)}
                                </NavLink>
                            </div>}
                        </li>
                        <li className="nav-item">
                            {!user && <div className="col-auto">
                                <NavLink
                                    to="/login"
                                    className={`${location.pathname === '/login' ? styles.selected : styles.active} `}
                                >
                                    {t(`navbar:menu.Entrar`)}
                                </NavLink>
                            </div>}
                        </li>
                        <li className="nav-item">
                            {!user && <div className="col-auto">
                                <NavLink
                                    to="/register"
                                    className={`${location.pathname === '/register' ? styles.selected : styles.active} `}
                                >
                                    {t(`navbar:menu.Cadastro`)}
                                </NavLink>
                            </div>}
                        </li>
                        <li className="nav-item">
                            {!user && <div className="col-auto">
                                <NavLink
                                    to="#"
                                    className="nav-link dropdown-toggle d-flex align-items-center"
                                    role="button"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                >
                                    <div className={`mt-1 ms-1`}>
                                        <button onClick={logout}>
                                            <img src={flagLanguage} alt="" className={` ${styles.flag}`} />
                                        </button>
                                    </div>
                                </NavLink>
                                <li className={`nav-item dropdown d-flex justify-content-center ${styles.links_list}`}>
                                    <ul className={`dropdown-menu ${styles.flags_list} `} id="dropdown-menu">
                                        <li className={`d-block`}>
                                            {preferenceBrowserLanguage != "Português" && <div className={`ms-3 mt-1 `}>
                                                <button onClick={() => changeProjectLanguage("pt")}>
                                                    <img src={ptflag} alt="" className={` ${styles.flag}`} />
                                                </button>
                                            </div>}
                                            {preferenceBrowserLanguage != "Espanhol" && <div className={`ms-3 mt-1`}>
                                                <button onClick={() => changeProjectLanguage("sp")}>
                                                    <img src={spflag} alt="" className={`${styles.flag}`} />
                                                </button>
                                            </div>}
                                            {preferenceBrowserLanguage != "Inglês" && <div className={`ms-3 mt-1`}>
                                                <button onClick={() => changeProjectLanguage("en")}>
                                                    <img src={enflag} alt="" className={`${styles.flag}`} />
                                                </button>
                                            </div>}
                                        </li>
                                    </ul>
                                </li>
                            </div>}
                        </li>

                    </ul>}


                </div>
            }
            {user && <div className="col-auto d-flex justify-content-end ">
                <div className={`col-auto ${styles.menu}`}>

                    <NavLink
                        to="#"
                        className="nav-link dropdown-toggle d-flex align-items-center"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                    >
                        <i className="bi bi-person-circle align-text-bottom me-2 fs-4"></i>
                        {userName}
                    </NavLink>
                    <li className={`nav-item dropdown ${styles.links_list}`}>
                        <ul className="dropdown-menu" id="dropdown-menu">
                            {hideItem != "myprojects" && <li>
                                <NavLink
                                    to="/myprojects"
                                    className="nav-link d-flex align-items-center ms-2"
                                    activeClassName={styles.active}
                                >
                                    <i className="bi bi-files align-text-bottom me-2 fs-5"></i>
                                    {t(`navbar:menu.Meus projetos`)}
                                </NavLink>
                            </li>}
                            {hideItem != "newproject" && <li>
                                <NavLink
                                    to="/"
                                    className="nav-link d-flex align-items-center ms-2"
                                    activeClassName={styles.active}
                                >
                                    <i className="bi bi-file-earmark-plus align-text-bottom me-2 fs-5"></i>
                                    {t(`navbar:menu.Novo projeto`)}
                                </NavLink>
                            </li>}
                            {hideItem != "myaccount" && <li>
                                <NavLink
                                    to="/Seuperfil"
                                    className="nav-link d-flex align-items-center ms-2"
                                    activeClassName={styles.active}
                                >
                                    <i className="bi bi-person-fill align-text-bottom me-2 fs-5"></i>
                                    {t(`navbar:menu.Minha conta`)}
                                </NavLink>
                            </li>}
                            {hideItem != "help" && <li>
                                <NavLink
                                    to="/help"
                                    className="nav-link d-flex align-items-center ms-2"
                                    activeClassName={styles.active}
                                >
                                    <i className="bi bi-question-circle align-text-bottom me-2 fs-5"></i>
                                    {t(`navbar:menu.Ajuda`)}
                                </NavLink>
                            </li>}
                            <li>
                                <div className={`d-flex align-items-center ms-1 mt-1 ${styles.logoutButton}`}>
                                    <button onClick={logout}>
                                        <i className="bi bi-box-arrow-left align-text-bottom me-2 fs-5"></i> {t(`navbar:menu.Sair`)}
                                    </button>
                                </div>
                            </li>
                        </ul>
                    </li>
                </div>
            </div>}
        </nav >
    )
}

export default Navbar;