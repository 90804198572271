import styles from './CreateAssessment.module.css';

// Hooks
import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useAskChatGPT } from '../../hooks/useAskChatGPT';

// Components
import AssessmentSubject from '../../components/AssessmentSubject';
import AssessmentTopics from '../../components/AssessmentTopics';
import AssessmentSetup from '../../components/AssessmentSetup';
import SimpleChoiceQuestion from '../../components/SimpleChoiceQuestion';
import DialogBox from '../../components/DialogBox';

const CreateEvaluation = () => {

  const location = useLocation();
  const projectName = location.state.projectName ? location.state.projectName : "";
  const projectDescription = location.state.projectDescription ? location.state.projectDescription : "";
  const projectFolder = location.state.projectFolder ? location.state.projectFolder : "Folder";
  const AIHelp = location.state.AIHelp;
  const asssessmentUser = location.state.asssessmentUser ? location.state.asssessmentUser : null;
  const { handleOpenAiApi, assessmentGPT, loading, error, generateGPT } = useAskChatGPT();

  const [generateAssessment, setGenerateAssessment] = useState();
  const [generate, setGenerate] = useState();
  const [assessment, setAssessment] = useState();
  const [msgDialog, setMsgDialog] = useState("");
  const [optionsDialog, setOptionsDialog] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);

  // Objects changed in AssessmentSubject Component
  const [subject, setSubject] = useState("");
  const [showSetup, setShowSetup] = useState(true);
  const [topics, setTopics] = useState("");
  const [showSubjectWithTopics, setShowSubjectWithTopics] = useState(false);
  const [userText, setUserText] = useState("");

  // Objects changed in AssessmentTopic Component
  const [selectedTopics, setSelectedTopics] = useState([]);

  // Objects changed in AssessmentSetup Component
  const [qtyQuestions, setQtyQuestions] = useState(10);
  const [qtyChoice, setQtyChoice] = useState(4);
  const [feedback, setFeedback] = useState("com");
  const [level, setLevel] = useState("médio");
  const [language, setLanguage] = useState("português");

  // Objects changed in AskGPT
  const [errorGPT, setErrorGPT] = useState(error);

  // Objects changed in SimpleQuestionSetup Component


  const handleAskChatGPT = async () => {
    try {
      const res = await handleOpenAiApi({
        subject,
        userText,
        selectedTopics,
        qtyQuestions,
        qtyChoice,
        feedback,
        level,
        language,
        orderGPT: "assessment"
      });
    } catch (error) {
      setErrorGPT(error);
    }
  };

  const handleClosDialogBox = () => {
    setOpenDialog(false)
  }


  useEffect(() => {
    if (assessmentGPT) {
      setAssessment(assessmentGPT)
      setGenerateAssessment(generateGPT);
    }
  }, [assessmentGPT, generateGPT]);

  useEffect(() => {
    if (!AIHelp) {
      const newQuestion = [{
        alternatives: [
          { option: "a", text: "" },
          { option: "b", text: "" },
          { option: "c", text: "" },
          { option: "d", text: "" },
        ],
        ask_question: "",
        correct_answer: "",
        feedback: ""
      }]
      setSubject(projectName)
      setAssessment(newQuestion)
      setGenerateAssessment(true)
    }
  }, [])

  // Assessment Subject Methods
  const handleShowSetup = (showSetup) => {
    setShowSetup(showSetup);
  }
  const handleSetSubject = (subject) => {
    setSubject(subject);
  }
  const handleSplitTopics = (topics) => {
    setTopics(topics);
    setShowSubjectWithTopics(true);
  }

  const handleSetUserText = (usertext) => {
    setUserText(usertext);
  }

  // Assessment Topic Methods
  const handleSetTopics = (topic) => {
    if (selectedTopics.includes(topic)) {
      setSelectedTopics(selectedTopics.filter(item => item !== topic));
    } else {
      setSelectedTopics([...selectedTopics, topic]);
    }
  }

  useEffect(() => {
    if(error){
      setOpenDialog(true);
      setOptionsDialog(["Ok"]);
      const textMsg = "Falha na chamada ao ChatGPT devido a grande fluxo de solicitações nesse momento. Por favor, tente novamente mais tarde.";
      setMsgDialog(textMsg);
    }
  }, [error])

  // Assessment Setup Methods
  const handleChangeConfig = (qtyQ, qtyC, feedback, level, language) => {
    setQtyQuestions(qtyQ);
    setQtyChoice(qtyC);
    feedback ? setFeedback("with") : setFeedback("without");
    setLevel(level);
    setLanguage(language);
  }

  return (
    <div className="container-fluid">
      <div className={styles.container_fluid}>
        <h2>Projeto: {projectName}</h2>
        <p>Inicie configurando sua Avaliação</p>
      </div>
      {AIHelp && <div>
        {/* Mostrar componentes Subject e Setup sem a lista de tópicos */}
        {!generateAssessment && !showSubjectWithTopics && (
          <div>
            <div>
              {!showSubjectWithTopics && <AssessmentSubject
                handleShowSetup={handleShowSetup}
                handleSetSubject={handleSetSubject}
                projectName={projectName}
                handleSplitTopics={handleSplitTopics}
                handleSetUserText={handleSetUserText}
                AIHelp={AIHelp}
              />}
            </div>
            <p></p>
            <div>
              {showSetup && <AssessmentSetup
                AIHelp={AIHelp}
                handleChangeConfig={handleChangeConfig} />}
            </div>
          </div>
        )}

        {/* Mostar componente Setup ao lado da lista de tópicos */}
        {!generateAssessment && showSubjectWithTopics && (
          <div>
            <div className="row">
              <div className="card col-8">
                <AssessmentTopics topics={topics} handleSetTopics={handleSetTopics} />
              </div>
              <div className={"card col-4"}>
                <AssessmentSetup handleChangeConfig={handleChangeConfig} />
              </div>
            </div>
            <p></p>
          </div>
        )}

        {/* Mostar botão Gerar Avaliação condicionado a uma as 2 exibições acima */}
        {!generateAssessment && showSetup || !generateAssessment && showSubjectWithTopics

          ? loading
            ? <div className="row" >
              <div className="col-2">
                <h3>Carregando...</h3>
              </div>
              <div className="col-1">
                <div class="spinner-border text-primary" role="status">
                  <span class="visually-hidden">Carregando...</span>
                </div>
              </div>
            </div>
            : <button onClick={handleAskChatGPT} className="btn btn-primary">Gerar</button>
          : null}
      </div>}


      {/* Mostrar avaliação*/}
      <p></p>
      {generateAssessment
        ? (<div >
          <SimpleChoiceQuestion
            actionDoc={"insert"}
            subject={subject}
            language={language}
            projectName={projectName}
            projectDescription={projectDescription}
            projectFolder={projectFolder}
            projectIntro={""}
            assessmentObject={assessment}
          />
        </div>)
        : null}
      <div>
        {openDialog && <DialogBox
          isopen={true}
          questionDialog={msgDialog}
          optionsDialog={optionsDialog}
          handleClosDialogBox={handleClosDialogBox}
        />}
      </div>
    </div>
  )
}

export default CreateEvaluation;